import React , { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Space, Table, Collapse, Button, DatePicker, Avatar, Badge, Modal, message, Typography, Spin } from 'antd';
import { AGISAPIURL, DATEFORMAT, UNIDATEFORMAT, UNIDATETIMEFORMAT, TASK_CATEGORY_PEST_CONTROL, MENUPATH_PESTCONTROL, MENUPATH_PESTCONTROLUPDATE, MENUPATH_PESTCONTROLCREATENEW,
    AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE, LOADING, VALUE_NONE, PAGESIZE, APPJSON } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, getUserSiteId, refreshUserSession, getUserSiteName } from '../Common/UserSession';
import { reportError } from '../Common/Utility';
import { formLayout } from '../Common/Layout';
import BatchSelect from '../Common/BatchSelect';
import AreaSelect from '../Common/AreaSelect';
import TrueFalseSelect from '../Common/TrueFalseSelect';
import PestControlDistributionTable from './PestControlDistributionTable';
import BatchDistributionTable from '../Batch/BatchDistributionTable';
import BatchActivitiesSummaryTable from '../Batch/BatchActivitiesSummaryTable';
import ButtonToBatchUpdatePage from '../Common/ButtonToBatchUpdatePage';
import moment from 'moment';
import axios from 'axios';
import { PlusCircleTwoTone, InboxOutlined, InfoCircleTwoTone, QuestionCircleTwoTone, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

const PestControlTable = () => {
    const FileDownload = require('js-file-download');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    const [activityTableData, setActivityTableData] = useState([]);
    const [area, setArea] = useState(0);
    const [item_location_to, setItemLocationTo] = useState(0);
    const [item_location_from, setItemLocationFrom] = useState(0);
    const [batch, setBatch] = useState(0);
    const [item_item, setItemItem] = useState(0);
    const [is_batch_active, setIsBatchActive] = useState(true);
    const [searchActiveBatch, setSearchActiveBatch] = useState(true);
    const [lifecycle, setLifecycle] = useState(0);
    const [task_type, setTaskType] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [taskTypeVal, setTaskTypeVal] = useState("");
    const [batchVal, setBatchVal] = useState("");
    const [lifecycleVal, setLifecycleVal] = useState("");
    const [areaVal, setAreaVal] = useState("");
    const [itemLocationToVal, setItemLocationToVal] = useState("");
    const [itemItemVal, setItemItemVal] = useState("");

    const SearchActivity = (currentPage) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "activity/", {
            params: {
                site: getUserSiteId(),
                area: area,
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batch,
                item_item: item_item,
                item_location_from: item_location_from,
                item_location_to: item_location_to,
                task_type: task_type,
                task_category: TASK_CATEGORY_PEST_CONTROL,
                lifecycle: lifecycle,
                is_batch_active: (is_batch_active !== "") ? ((is_batch_active) ? 1 : 0) : "",
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectActivityRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(dateRange[0] != null) searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(task_type != 0) searchCriteria = `${searchCriteria}\nTask: ${taskTypeVal}`
        if(item_item != 0) searchCriteria = `${searchCriteria}\nPlant Species: ${itemItemVal}`
        if(is_batch_active !== "") searchCriteria = `${searchCriteria}\nIs Active: ${is_batch_active ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}`
        if(batch != 0) searchCriteria = `${searchCriteria}\nBatch Code: ${batchVal}`
        if(lifecycle != 0) searchCriteria = `${searchCriteria}\nLifecycle: ${lifecycleVal}`
        if(area != 0) searchCriteria = `${searchCriteria}\nPest Control Zone: ${areaVal}`
        if(item_location_to != 0) searchCriteria = `${searchCriteria}\nPest Controlled On: ${itemLocationToVal}`

        axios.get(`${AGISAPIURL}download/activity/pestcontrol/`, {
            params: {
                site: getUserSiteId(),
                area: area,
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batch,
                item_item: item_item,
                item_location_from: item_location_from,
                item_location_to: item_location_to,
                task_type: task_type,
                task_category: TASK_CATEGORY_PEST_CONTROL,
                lifecycle: lifecycle,
                is_batch_active: (is_batch_active !== "") ? ((is_batch_active) ? 1 : 0) : "",
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Pest Control ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const deleteActivity = (id) => {
        setIsLoading(true);

        axios.delete(AGISAPIURL + "activity/delete/" + id, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then(() => {
            message.info(`Activity ID ${id} deleted!`);
            SearchActivity(currentPage);
        })
        .catch( error => {
            setIsLoading(false);
            reportError(error, "Failed to delete activity. " + error.message);
        })
        .finally(() => {
            refreshUserSession();
        });
    };

    useEffect(() => {
        SearchActivity(currentPage);
    }, []);

    const collectActivityRawData = ( response ) => {
        const data = []
        response.data.results.forEach( activity => {
            let batchCode = VALUE_NONE;
            let batchId = "";
            let batchIsActive = "";
            let batchLifecycle = "";
            if (activity.batch) {
                batchCode = activity.batch.batch_code;
                batchId = activity.batch.id;
                batchIsActive = activity.batch.is_active;
                batchLifecycle = activity.batch.lifecycle;
            };

            data.push({
                key: activity.id,
                id: activity.id,
                timestamp: moment(activity.timestamp).format(UNIDATETIMEFORMAT),
                batch_id: batchId,
                batch: batchCode,
                batch_isActive: batchIsActive,
                batch_lifecycle: batchLifecycle,
                item_item_id: activity.item_item.id,
                item_item: activity.item_item.description,
                task_type: activity.task_type.task,
                task_category: activity.task_type.task_category.description,
                lifecycle: activity.lifecycle.name,
                work_phase: activity.work_phase.name,
                actual_executed_datetime: moment(activity.actual_executed_datetime).format(UNIDATETIMEFORMAT),
                updated_on: moment(activity.updated_on).format(UNIDATETIMEFORMAT),
                subactivity_count: activity.subactivity.length,
                subactivity: collectSubActivityRawData(activity.subactivity),
            });
        });
        setActivityTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const collectSubActivityRawData = ( response ) => {
        const data = []
        response.forEach( subactivity => {
            data.push({
                key: subactivity.id,
                id: subactivity.id,
                timestamp: moment(subactivity.timestamp).format(UNIDATETIMEFORMAT),
                activity: subactivity.activity,
                area: subactivity.area.area_code,
                item_location_to: subactivity.item_location_to.description,
            })
        });
        return data;
    };

    const onChangeDateRange = (value) => {
        setDateRange(value);
    };

    const onChangeArea = (key, value) => {
        setArea(key);
        setAreaVal(value.children);
    };

    const onChangeItemLocationTo = (key, value) => {
        setItemLocationTo(key);
        setItemLocationToVal(value.children);
    };

    const onChangeBatch = (key, value) => {
        setBatch(key);
        setBatchVal(value.children);
    };

    const onChangeItemItem = (key, value) => {
        setItemItem(key);
        setItemItemVal(value.children);
    };

    const onChangeTaskType = (key, value) => {
        setTaskType(key);
        setTaskTypeVal(value.children);
    };

    const onChangeLifecycle = (key, value) => {
        setLifecycle(key);
        setLifecycleVal(value.children);
    };

    const onChangeIsBatchActive = (value) => {
        setIsBatchActive(value);
    };

    const onSearch = () => {
        setCurrentPage(1);
        SearchActivity(1);
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onClickTableRow = (record, rowIndex) => {
        return {
            onClick: () => {
                navigate(MENUPATH_PESTCONTROLUPDATE, {state: {record: record}});
            }
        };
    };

    const onClickLocations = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Activity: {record.task_type} ({record.item_item})</h3><h3>Batch Code: {record.batch} <InfoCircleTwoTone onClick={(e) => showBatchPlantDistributionPopup(e, record)} /></h3></div>,
            icon: null,
            width: '60%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <PestControlDistributionTable dataSource={record.subactivity} />,
        })
    };

    //---------------------------
    // On delete
    //---------------------------
    const onDelete = (e, key) => {
        e.stopPropagation();

        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Delete activity confirmed?</p></Space>,
            onOk() { deleteActivity(key) },
            onCancel() {},
            centered: true
        });
    };

    //--------------------------
    // Show Batch Plant Distribution
    //--------------------------
    const showBatchPlantDistributionPopup = (e, record) => {
        const modal = Modal.info({
            title: <div><h3><b>BATCH OVERVIEW</b> - Batch ID: {record.batch}<ButtonToBatchUpdatePage batchId={record.batch_id} buttonName="Go to Batch Update Page" navigate={navigate} lastPage={MENUPATH_PESTCONTROL}/></h3></div>,
            icon: null,
            width: '70%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <><BatchDistributionTable batchId={record.batch_id} /><BatchActivitiesSummaryTable batchId={record.batch_id} /></>
        });
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchActivity(page);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onCreateNewActivity = () => {
        navigate(MENUPATH_PESTCONTROLCREATENEW);
    };

    const columns = [
        { title: 'Date', dataIndex: 'actual_executed_datetime', sorter: (a, b) => a.actual_executed_datetime.localeCompare(b.actual_executed_datetime) },
        { title: 'Batch Code', dataIndex: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Task Type', dataIndex: 'task_type', sorter: (a, b) => a.task_type.localeCompare(b.task_type) },
        { title: 'Plant Species', dataIndex: 'item_item', sorter: (a, b) => a.item_item.localeCompare(b.item_item) },
        { title: 'Medium(s)', dataIndex: 'subactivity_count', sorter: (a, b) => a.subactivity_count - b.subactivity_count,
            render: (subactivity_count, record) => {
                if (subactivity_count) { return (
                    <Badge count={subactivity_count} onClick={(e) => onClickLocations(e, record)}>
                        <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<InboxOutlined />} />
                    </Badge>);
                } else return (<>{VALUE_NONE}</>);
            }
        },
        { title: 'Life Cycle', dataIndex: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Work Phase', dataIndex: 'work_phase', sorter: (a, b) => a.work_phase.localeCompare(b.work_phase) },
        { title: 'Timestamp', dataIndex: 'timestamp', sorter: (a, b) => a.timestamp.localeCompare(b.timestamp) },
        { title: 'Action', dataIndex: 'action',
            render: (_, record) => {
                return (record.batch_isActive) ? (
                    <Typography.Link title="Sure to delete this activity?" onClick={(e) => onDelete(e, record.key) }>Delete</Typography.Link>
                ) : (
                    <Typography.Link disabled title="The batch needs to be an active batch." onClick={(e) => onDelete(e, record.key) }>Delete</Typography.Link>
                );
            },
        },
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search Pest Control Activity" key="1">
                    <Form form={form} {...formLayout}>
                        <Form.Item initialValue={dateRange} name="daterange" label="Date Range">
                            <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                        </Form.Item>

                        <Row justify="center">
                            <Col span={12}>
                                <BatchSelect withBlank={true} form={form} defaultBatch={batch} defaultItem={item_item} defaultSearchActiveBatch={searchActiveBatch} defaultLifecycle={lifecycle} defaultTaskCategory={TASK_CATEGORY_PEST_CONTROL} defaultTask={task_type}
                                    onBatchChange={onChangeBatch} onItemChange={onChangeItemItem} onLifecycleChange={onChangeLifecycle} onTaskChange={onChangeTaskType} itemLabel="Plant Species" />
                            </Col>

                            <Col span={12}>
                                <AreaSelect withBlank={true} form={form} defaultArea={area} defaultItem={item_location_to} defaultAreaTypeList={[AREA_TYPE_FRUITY_VEGE, AREA_TYPE_LEAFY_VEGE]} onAreaChange={onChangeArea} onItemChange={onChangeItemLocationTo} areaLabel="Zone" itemLabel="Location" />

                                <Form.Item initialValue={is_batch_active} name="isBatchActive" label="Is Batch Active">
                                    <TrueFalseSelect withBlank={true} defaultSelect={OTHERSYSPARAM("YES")} onChange={onChangeIsBatchActive}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>
            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewActivity} type="primary"><PlusCircleTwoTone /> New Pest Control Activity</Button></Col></Row>
            <Row><Col><Space><br/></Space></Col></Row>

            <Table onRow={onClickTableRow} columns={columns} dataSource={activityTableData}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: PAGESIZE,
                    current: currentPage,
                    total: totalRecord,
                    showTotal: showTotal,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                }}/>

            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewActivity} type="primary"><PlusCircleTwoTone /> New Pest Control Activity</Button></Col></Row>
        </Spin>
        </>
    );
};

export default PestControlTable;