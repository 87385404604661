import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Typography, Card, DatePicker, Modal, message, Tag, Input, Divider, Select, Badge, Avatar, Spin } from 'antd';
import { AGISAPIURL, STATUS_CLOSED, UNIDATETIMEFORMAT, STATUS_OPEN, UNIDATEFORMAT, ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT, LOADING, VALUE_NONE } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, getUserSiteId, refreshUserSession } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import BatchDistributionTable from './BatchDistributionTable';
import BatchActivitiesSummaryTable from './BatchActivitiesSummaryTable';
import axios from 'axios';
import moment from 'moment';
import { reportError } from "../Common/Utility";
import { CloseCircleFilled, QuestionCircleTwoTone, CloseSquareFilled, SaveOutlined, InboxOutlined, ContainerOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import BatchItemAreaTable from './BatchItemAreaTable';


const { Content, Footer } = Layout;
const { confirm } = Modal;
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const BatchUpdate = () => {

    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");
    const paddingCardGrid = OTHERSYSPARAM("CARD_GRID_PADDING");

    const [form] = Form.useForm();
    const [closeBatchForm] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [createdOn, setCreatedOn] = useState(location.state.record.created_on);
    const [updatedOn, setUpdatedOn] = useState(location.state.record.updated_on);
    const [item, setItem] = useState(location.state.record.item);
    const [item_group, setItemGroup] = useState(location.state.record.item_group);
    const [area, setArea] = useState(location.state.record.area);
    const [item_id, setItemId] = useState(location.state.record.item_id !== VALUE_NONE ? location.state.record.item_id : 0);
    const [item_group_id, setItemGroupId] = useState(location.state.record.item_group_id);
    const [batchCode, setBatchCode] = useState(location.state.record.batch_code);
    const [statusType, setStatusType] = useState(location.state.record.status_type);
    const [statusId, setStatusId] = useState(location.state.record.status_id);
    const [lifecycle, setLifecycle] = useState(location.state.record.lifecycle);
    const [work_phase, setWorkPhase] = useState(location.state.record.work_phase);
    const [workProg, setWorkProg] = useState(location.state.record.work_programme);
    const [workProgId, setWorkProgId] = useState(location.state.record.work_programme_id !== VALUE_NONE ? location.state.record.work_programme_id : null);
    const [expectedCompletionDate, setExpectedCompletionDate] = useState(location.state.record.expected_completion_date)
    const [actualCompletionDate, setActualCompletionDate] = useState(location.state.record.actual_completion_date !== VALUE_NONE ? moment(location.state.record.actual_completion_date).format(UNIDATETIMEFORMAT) : VALUE_NONE)
    const [remark, setRemark] = useState(location.state.record.remark !== "" ? location.state.record.remark : "-");
    const [daysFromStart, setDaysFromStart] = useState(location.state.record.days_from_start)
    const [disableCancelButton, setDisableCancelButton] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState((location.state.record.is_active) ? false : true);
    const [disableCloseButton, setDisableCloseButton] = useState((location.state.record.is_active) ? false : true);
    const [workProgOption, setWorkProgOption] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const updateBatch = (expected_completion_date, work_programme) => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setDisableCloseButton("disabled");
        setIsLoading(true);

        axios.patch(AGISAPIURL + "batch/update/" + location.state.record.id, {
            expected_completion_date: expected_completion_date.format(UNIDATEFORMAT),
            work_programme: work_programme,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Batch ${response.data.batch_code} updated.`);
            navigate(OTHERSYSPARAM("lastPage"));
        })
        .catch( error => {
            reportError(error, "Failed to update data. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setDisableCloseButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const closeBatch = (values) => {
        setIsLoading(true);

        axios.patch(AGISAPIURL + "batch/update/" + location.state.record.id, {
            status_type: STATUS_CLOSED,
            is_active: false,
            actual_completion_date: values.actualCompletionDate.format(UNIDATETIMEFORMAT),
            remark: values.remark,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Batch ${response.data.batch_code} has been closed.`);
            navigate(OTHERSYSPARAM("lastPage"));
        })
        .catch( error => {
            reportError(error, "Failed to close batch. " + error.message);
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getWorkProgramme = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofileworkprogramme/", {
            params: {
                site: getUserSiteId(),
                item: item_id,
                item_group: item_group_id,
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setWorkProgOption(response.data.results.map( itemprofileworkprogramme => <Option key={itemprofileworkprogramme.work_programme.id} value={itemprofileworkprogramme.work_programme.id}>{itemprofileworkprogramme.work_programme.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    const onClickItemArea = (e, dataSource) => {
        const modal = Modal.info({
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <BatchItemAreaTable dataSource={dataSource} />
        })
    };

    const showItem = (item) => {
        if (Array.isArray(item)) { return (
            <Badge count={item.length} onClick={(e) => onClickItemArea(e, item)}>
                <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<ContainerOutlined />} />
            </Badge>);
        }
        else return item;
    };

    const showArea = (area) => {
        if (Array.isArray(area)) { return (
            <Badge count={area.length} onClick={(e) => onClickItemArea(e, area)}>
                <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<InboxOutlined />} />
            </Badge>);
        }
        else return area;
    };

    useEffect(() => {
        Modal.destroyAll();
        getWorkProgramme();
    }, []);

    const onBack = () => {
        navigate(OTHERSYSPARAM("lastPage"));
    };

    const onCancel = () => {
        navigate(OTHERSYSPARAM("lastPage"));
    };

    const columnTitleCompleted = (title) => {
        return (
            <>{title}: <InfoCircleTwoTone onClick={(e) => showColumnDescriptionCompleted(e, title)} /></>
        );
    };

    const columnTitleNoOfDaysFromStart = (title) => {
        return (
            <>{title}: <InfoCircleTwoTone onClick={(e) => showColumnDescriptionNoOfDaysFromStart(e, title)} /></>
        );
    };

    const showColumnDescriptionCompleted = (e, title) => {
        const modal = Modal.info({
            title: <div><h3>Definition of the Label - {title}</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <>
                <p>For <b>Planting Batch</b>: If Yes, it indicates all plant in this batch has been fully <b>harvested</b>.</p>
                <p>For <b>Soil Maintenance Batch</b>: If Yes, it indicates all planting medium in this batch has been fully <b>released</b>.</p>
            </>
        });
    };

    const showColumnDescriptionNoOfDaysFromStart = (e, title) => {
        const modal = Modal.info({
            title: <div><h3>Definition of the Label - {title}</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <>
                <p>For <b>Planting Batch</b>: The number of days that have passed since the first <b>sowing</b> activity.</p>
                <p>For <b>Soil Maintenance Batch</b>: The number of days that have passed since the first <b>soil maintenance</b> activity.</p>
            </>
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (value) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Edit batch confirmed?</p></Space>,
            onOk() { updateBatch(value.expectedCompletionDate, value.work_programme) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // Close Batch
    //---------------------------
    const onClose = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: (
                <>
                    <Space><p>Close Batch confirmed?</p></Space>
                    <Form form={closeBatchForm} onFinish={values => closeBatch(values)} autoComplete="off">
                        <Form.Item initialValue={moment()} name="actualCompletionDate" label="Actual Completion Date" rules={[{required: true, message: 'Please input the Actual Completion Date for this Batch before closure!'}]}>
                            <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment()}} disabledDate={disabledDateAfterToday}/>
                        </Form.Item>

                        <Form.Item name="remark" label="Remark">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Form>
                </>
                ),
            onOk() {
                closeBatchForm.validateFields()
                .then(() => {
                    closeBatchForm.submit();
                })
                .catch( error => {
                    reportError(error, "Failed to update batch. " + closeBatchForm.getFieldError('actualCompletionDate'));
                });
            },
            onCancel() {},
            centered: true,
            width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"),
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Batch Update" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Edit Batch</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center"><Col span={12}>
                    <Card title={<Title level={5}>{`Batch Code: ${batchCode}`}<br/></Title>} extra={<Button htmlType='button' hidden={disableCloseButton} disabled={disableCloseButton} onClick={onClose} danger><CloseSquareFilled/>Close Batch</Button>}>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Item Group:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{item_group}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Item:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{showItem(item)}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Area:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{showArea(area)}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Work Programme:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{workProg}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Created On:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{createdOn}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Updated On:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{updatedOn}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Batch Status:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{statusType}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Life Cycle:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{lifecycle}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Work Phase:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{work_phase}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Active:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{ location.state.record.is_active ? <Tag color="success">{OTHERSYSPARAM("YES")}</Tag> : <Tag color="error">{OTHERSYSPARAM("NO")}</Tag> }</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>{columnTitleNoOfDaysFromStart('Number of Days from the Start')}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{daysFromStart}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Expected Completion Date:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{ expectedCompletionDate !== VALUE_NONE ? moment(location.state.record.expected_completion_date).format(UNIDATEFORMAT) : VALUE_NONE }</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Actual Completion Date:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{actualCompletionDate}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>{columnTitleCompleted('Completed')}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{ location.state.record.is_harvested ? <Tag color="success">{OTHERSYSPARAM("YES")}</Tag> : <Tag color="error">{OTHERSYSPARAM("NO")}</Tag> }</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Remark:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{remark}</Card.Grid>
                    </Card>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <BatchDistributionTable batchId={location.state.record.id} showPlant={item_group_id === ITEM_GROUP_LEAFY_PLANT || item_group_id === ITEM_GROUP_FRUITY_PLANT} showMedium={!(item_group_id === ITEM_GROUP_LEAFY_PLANT || item_group_id === ITEM_GROUP_FRUITY_PLANT)}/>
                    <Row><Col><Space><br/></Space></Col></Row>
                    <BatchActivitiesSummaryTable batchId={location.state.record.id} />
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row justify="center"><Col span={12}>
                    <Divider orientation="left">Edit Batch Fields</Divider>
                    <Form form={form} onFinish={onSave} autoComplete="off">
                        <Form.Item initialValue={expectedCompletionDate !== VALUE_NONE ? moment(location.state.record.expected_completion_date) : ""} name="expectedCompletionDate" label="Expected Completion / Harvesting Date" rules={[{required: true, message: 'Please input the Expected Completion / Harvesting Date for this Batch!'}]}>
                            <DatePicker format={UNIDATEFORMAT} showTime={{defaultValue: expectedCompletionDate !== VALUE_NONE ? moment(location.state.record.expected_completion_date) : ""}}/>
                        </Form.Item>

                        <Form.Item initialValue={workProgId} name="work_programme" label="Work Programme" hidden={statusId !== STATUS_OPEN ? true : false} /*rules={[{required: true, message: 'Please select the Work Programme for update!'}]}*/>
                            <Select showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {workProgOption}
                            </Select>
                        </Form.Item>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}/>
                            <Col span={12} offset={1}>
                                <Button htmlType="submit" hidden={disableSubmitButton} disabled={disableSubmitButton} type="primary"><SaveOutlined/>Save</Button>
                                <Button htmlType="button" disabled={disableCancelButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                            </Col>
                            <Col span={6}/>
                        </Row>
                    </Form>
                </Col></Row>
            </Content>
            <Footer><Row justify="center"><PageHeader title="Batch Update" subTitle="Edit Batch" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default BatchUpdate;