import React from 'react';
import { Table, Typography } from 'antd';

const { Title, Text } = Typography;

const HarvestingDistributionTable = ({dataSource_from, dataSource_to}) => {

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const harvestFromColumns = [
        { title: 'Planting Medium', dataIndex: 'item_location_from', sorter: (a, b) => a.item_location_from.localeCompare(b.item_location_from) },
        { title: 'Zone', dataIndex: 'area_from', sorter: (a, b) => a.area_from.localeCompare(b.area_from) },
        // { title: 'Quantity (Planting Medium)', dataIndex: 'quantity_tool_from', sorter: (a, b) => a.quantity_tool_from - b.quantity_tool_from },
        { title: 'Total Plant Harvested (Planting Medium Harvested in %)', dataIndex: 'quantity_item_from', sorter: (a, b) => a.quantity_item_from - b.quantity_item_from,
            render: (quantity_item_from, record) => {
                if (record.quantity_item_uom_perc_from) return (<Text>{parseInt(quantity_item_from)} / {record.maxUnit_from} {record.quantity_item_uom_from} ({parseInt(record.quantity_item_perc_from)} {record.quantity_item_uom_perc_from})</Text>)
                else return (<Text>{parseInt(quantity_item_from)} / {record.maxUnit_from} {record.quantity_item_uom_from}</Text>)
            }
        },
    ];

    const harvestToColumns = [
        { title: 'Harvest Bin', dataIndex: 'item_location_to', sorter: (a, b) => a.item_location_to.localeCompare(b.item_location_to) },
        { title: 'Zone', dataIndex: 'area_to', sorter: (a, b) => a.area_to.localeCompare(b.area_to) },
        // { title: 'Quantity (Harvest Bin)', dataIndex: 'quantity_tool_to', sorter: (a, b) => a.quantity_tool_to - b.quantity_tool_to },
        { title: 'Total Harvested', dataIndex: 'quantity_item_to', sorter: (a, b) => a.quantity_item_to - b.quantity_item_to,
            render: (quantity_item_to, record) => {
                return (<Text>{parseFloat(quantity_item_to)} {record.quantity_item_uom_to}</Text>)
            }
        },
    ];

    const titleFrom = () => {
        return (<Title level={5}>Harvested From</Title>)
    };

    const titleTo = () => {
        return (<Title level={5}>Harvested To</Title>)
    };


    return (
        <>
            <Table title={titleFrom} columns={harvestFromColumns} dataSource={dataSource_from} pagination={{size: "small", position: ["bottomCenter"], total: dataSource_from.length, showTotal: showTotal}}/>
            <Table title={titleTo} columns={harvestToColumns} dataSource={dataSource_to} pagination={{size: "small", position: ["bottomCenter"], total: dataSource_to.length, showTotal: showTotal}}/>
        </>
    );
};

export default HarvestingDistributionTable;