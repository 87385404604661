import React from 'react';
import { Table } from 'antd';


const BinReturnsDistributionTable = ({dataSource}) => {

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Bin', dataIndex: 'item_location', sorter: (a, b) => a.item_location.localeCompare(b.item_location) },
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'Quantity', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
    ];

    return (
        <>
            <Table columns={columns} dataSource={dataSource} pagination={{size: "small", position: ["bottomCenter"], total: dataSource.length, showTotal: showTotal}}/>
        </>
    );
};

export default BinReturnsDistributionTable;