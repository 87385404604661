import { message } from 'antd';


export const reportError = (error, customMessage="") => {
    // Error message duration in seconds
    const MAXLEN = 200
    const second = 5

    // non_field_errors
    const obj = error?.response?.data?.non_field_errors
    if(obj !== undefined) {
        //alert(0)
        message.error(obj.join(" "))
    }
    else {
        // Other error
        const otherErrorMsg = error?.response?.data
        //console.log(otherErrorMsg)

        if(otherErrorMsg !== undefined) {
            if(otherErrorMsg?.detail !== undefined)  {
                //alert(1)
                message.error(otherErrorMsg.detail, second)
            }
            else if(typeof(otherErrorMsg) === "object") {
                //alert(2)
                let msg = ""
                for(var key in otherErrorMsg) msg = `${msg} ${otherErrorMsg[key]}`
                message.error(msg, second)
            }
            else {
                //alert(otherErrorMsg)
                if(otherErrorMsg.length < MAXLEN)
                    message.error(otherErrorMsg, second)
                //else
                    //message.error("Error message is too long to be displayed.")
            }
        }
    }

    //message.error(error.message)
    if(customMessage !== "") message.error(customMessage)
};