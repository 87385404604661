import React, { useState } from 'react';
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Modal, message, Input, Spin } from 'antd';
import { AGISAPIURL, LOADING } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import { formLayout } from '../Common/Layout';
import axios from 'axios';
import { reportError } from "../Common/Utility";
import { QuestionCircleTwoTone, SaveOutlined, LockOutlined } from '@ant-design/icons';


const { Content, Footer } = Layout;
const { confirm } = Modal;

const UserChangePassword = () => {

    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const [disableSubmitButton, setDisableSubmitButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const changePassword = () => {
        // Disable buttons.
        setDisableSubmitButton("disabled");
        setIsLoading(true);

        axios.patch(AGISAPIURL + "user/changepassword/", {
            current_password: form.getFieldValue('currentPassword'),
            new_password: form.getFieldValue('newPassword'),
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Password changed!`)
        })
        .catch( error => {
            reportError(error, "Failed to change passowrd.");
        })
        .finally(() => {
            setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        form.validateFields()
        .then( values => {
            if(values.newPassword.trim().length < 8) {
                message.warn("Invalid new password.");
                return
            };

            if(values.confirmNewPassword.trim().length < 8) {
                message.warn("Invalid confirm new password.");
                return
            };

            if(values.newPassword != values.confirmNewPassword) {
                message.warn("New password do not match.");
                return
            }
            else {
                confirm({
                    icon: <QuestionCircleTwoTone />,
                    content: <Space><p>Change password confirmed?</p></Space>,
                    onOk() { changePassword() },
                    onCancel() {},
                    centered: true
                });
            };
        })
        .catch( error => {
            message.warn("Required field validation failed.");
            return
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="User Change Password">
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Change your password</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout} autoComplete="off">
                    <Form.Item name="currentPassword" label="Current Password" rules={[{required: true, message: 'Current Password is required!'}]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="Current Password"/>
                    </Form.Item>

                    <Form.Item name="newPassword" label="New Password" rules={[{required: true, message: 'New Password is required!'}]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="New Password"/>
                    </Form.Item>

                    <Form.Item name="confirmNewPassword" label="Confirm New Password" rules={[{required: true, message: 'Confirm New Password is required!'}]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="Confirm New Password"/>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center"><Button htmlType="submit" hidden={disableSubmitButton} disabled={disableSubmitButton} type="primary"><SaveOutlined/>Save</Button></Row>
                </Form>

            </Content>
            <Footer><Row justify="center"><PageHeader title="User Change Password" subTitle="Change your password"></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default UserChangePassword;