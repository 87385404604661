import React, { useState, useEffect } from 'react';
import { Pie } from '@ant-design/plots';
import { Spin, Row, Col, Typography, Space, Table } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';

const { Title, Text } = Typography;

const DashboardChartsAndTableQuantity = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [quantityTableData, setQuantityTableData] = useState([]);

    const getQuantitySummary = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "plantspeciesallquantity/", {
            params: {
                site: getUserSiteId(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectQuantityRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectQuantityRawData = ( response ) => {
        const data = []
        response.data.results.forEach( plantSpeciesQuantityAll => {
            let uom = "";
            if (plantSpeciesQuantityAll.quantity_secondary_harvesting_uom) uom = plantSpeciesQuantityAll.quantity_secondary_harvesting_uom.unit;

            data.push({
                key: plantSpeciesQuantityAll.id,
                id: plantSpeciesQuantityAll.id,
                item: plantSpeciesQuantityAll.item_item.description,
                quantity_item_uom: plantSpeciesQuantityAll.quantity_item_uom.unit,
                quantity_item_all: parseInt(plantSpeciesQuantityAll.quantity_item),
                quantity_item_nursing: parseInt(plantSpeciesQuantityAll.quantity_nursing),
                quantity_item_planting: parseInt(plantSpeciesQuantityAll.quantity_planting),
                quantity_item_harvesting: parseInt(plantSpeciesQuantityAll.quantity_harvesting),
                quantity_item_secondary_harvesting: parseInt(plantSpeciesQuantityAll.quantity_secondary_harvesting),
                quantity_item_secondary_harvesting_uom: uom,
            });
        });
        setQuantityTableData(data);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getQuantitySummary();
    }, []);

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Plant Species', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Nursing (Unit)', dataIndex: 'quantity_item_nursing', align: "right", sorter: (a, b) => a.quantity_item_nursing - b.quantity_item_nursing },
        { title: 'Planting (Unit)', dataIndex: 'quantity_item_planting', align: "right", sorter: (a, b) => a.quantity_item_planting - b.quantity_item_planting },
        { title: 'Harvesting (Unit)', dataIndex: 'quantity_item_secondary_harvesting', align: "right", sorter: (a, b) => a.quantity_item_secondary_harvesting - b.quantity_item_secondary_harvesting,
            render: (quantity_item_secondary_harvesting, record) => {
                if (quantity_item_secondary_harvesting) return (<Text>{parseInt(record.quantity_item_harvesting)} ({quantity_item_secondary_harvesting} {record.quantity_item_secondary_harvesting_uom})</Text>)
                else return (<Text>{parseInt(record.quantity_item_harvesting)}</Text>)
            }
        },
        { title: 'All (Unit)', dataIndex: 'quantity_item_all', align: "right", sorter: (a, b) => a.quantity_item_all - b.quantity_item_all },
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Row justify='center'>
                <Col span={24} style={{textAlign: "center"}}>
                    <Title level={3}>Plants Distribution across Work Phases</Title>
                </Col>
            </Row>

            <Row justify='center'>
                <Col span={12} style={{textAlign: "center"}}><Title level={5}>Quantity (All Phases)</Title></Col>
                <Col span={12} style={{textAlign: "center"}}><Title level={5}>Quantity (Nursing Phase)</Title></Col>
            </Row>

            <Row justify='center'>
                <Col span={1}></Col>
                <Col span={11}>
                    <Pie data={quantityTableData.filter(item => item.quantity_item_all !== 0)} angleField='quantity_item_all' colorField='item' radius={0.7}
                        label={{type: 'outer', content: (value) => {return `${value.item} - ${value.quantity_item_all} ${value.quantity_item_uom} (${(value.percent * 100).toFixed(2)}%)`}}}
                        interactions={[{type: 'pie-legend-active'}, {type: 'element-active'}]} />
                </Col>
                <Col span={11}>
                    <Pie data={quantityTableData.filter(item => item.quantity_item_nursing !== 0)} angleField='quantity_item_nursing' colorField='item' radius={0.7}
                        label={{type: 'outer', content: (value) => {return `${value.item} - ${value.quantity_item_nursing} ${value.quantity_item_uom} (${(value.percent * 100).toFixed(2)}%)`}}}
                        interactions={[{type: 'pie-legend-active'}, {type: 'element-active'}]} />
                </Col>
                <Col span={1}></Col>
            </Row>

            <Row justify='center'>
                <Col span={12} style={{textAlign: "center"}}><Title level={5}>Quantity (Planting Phase)</Title></Col>
                <Col span={12} style={{textAlign: "center"}}><Title level={5}>Quantity (Harvesting Phase)</Title></Col>
            </Row>

            <Row justify='center'>
                <Col span={1}></Col>
                <Col span={11}>
                    <Pie data={quantityTableData.filter(item => item.quantity_item_planting !== 0)} angleField='quantity_item_planting' colorField='item' radius={0.7}
                        label={{type: 'outer', content: (value) => {return `${value.item} - ${value.quantity_item_planting} ${value.quantity_item_uom} (${(value.percent * 100).toFixed(2)}%)`}}}
                        interactions={[{type: 'pie-legend-active'}, {type: 'element-active'}]} />
                </Col>
                <Col span={11}>
                    <Pie data={quantityTableData.filter(item => item.quantity_item_secondary_harvesting !== 0)} angleField='quantity_item_secondary_harvesting' colorField='item' radius={0.7}
                        label={{type: 'outer', content: (value) => {return `${value.item} - ${value.quantity_item_secondary_harvesting} ${value.quantity_item_secondary_harvesting_uom} (${(value.percent * 100).toFixed(2)}%)`}}}
                        interactions={[{type: 'pie-legend-active'}, {type: 'element-active'}]} />
                </Col>
                <Col span={1}></Col>
            </Row>

            <Row><Col><Space><br/></Space></Col></Row>
            <Row>
                <Col span={3}></Col>
                <Col span={18}><Table columns={columns} dataSource={quantityTableData} pagination={{position: ["bottomCenter"], total: quantityTableData.length, showTotal: showTotal}}/></Col>
                <Col span={3}></Col>
            </Row>


        </Spin>
    );
};

export default DashboardChartsAndTableQuantity;