import React , { useEffect, useState } from 'react';
import { Table, Typography, Spin, Statistic, Row, Col, Collapse, Form, DatePicker, Space, Button, Select, Radio, message } from 'antd';
import { AGISAPIURL, LOADING, UNIDATEFORMAT, UNIDATETIMEFORMAT, DATEFORMAT, TASK_CATEGORY_PLANTING, TASK_TYPE_SOWING, APPJSON } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, getUserSiteId, refreshUserSession, getUserSiteName } from '../Common/UserSession';
import { reportError } from '../Common/Utility';
import { formLayout } from '../Common/Layout';
import moment from 'moment';
import axios from 'axios';
import { DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select;

const SowingBacklogsTable = () => {
    const FileDownload = require('js-file-download');
    const [form] = Form.useForm();
    const [sowingBacklogsData, setSowingBacklogsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState(0);
    const [lastNDays, setLastNDays] = useState(3);
    const [showLastNDays, setShowLastNDays] = useState(true);
    const [showDateRange, setShowDateRange] = useState(false);
    const [totalExpected, setTotalExpected] = useState(0);
    const [totalActual, setTotalActual] = useState(0);
    const [itemOption, setItemOption] = useState("");
    const [lastNDaysOption, setLastNDaysOption] = useState("");
    const [dateRange, setDateRange] = useState([moment(moment().subtract(lastNDays, "days"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    const [customDateRange, setCustomDateRange] = useState([moment(moment().subtract(lastNDays, "days"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);

    const [itemVal, setItemVal] = useState("");

    const SearchBacklogs = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "sowingbacklogs/", {
            params: {
                site: getUserSiteId(),
                item: item,
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectSowingBacklogsData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(dateRange[0] != null) searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(item != 0) searchCriteria = `${searchCriteria}\nPlant Species: ${itemVal}`

        axios.get(`${AGISAPIURL}download/sowingbacklogs/`, {
            params: {
                site: getUserSiteId(),
                item: item,
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Sowing Backlogs ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    };

    const getItem = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofilebytasktype/", {
            params: {
                task_type: TASK_TYPE_SOWING,
                task_category: TASK_CATEGORY_PLANTING,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
            setItemOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const setLastNDaysOptions = () => {
        let options = [];
        for (let i = 0; i <= 90; i++) {options = options.concat(<Option key={i} value={i}>{i}</Option>)};
        setLastNDaysOption(options);
    };

    useEffect(() => {
        setLastNDaysOptions();
        getItem();
        SearchBacklogs();
    }, []);

    const collectSowingBacklogsData = ( response ) => {
        const data = [];
        let tempExpected = 0;
        let tempActual = 0;
        response.data.results.forEach( backlogs => {
            tempExpected = tempExpected + parseInt(backlogs.expected_quantity_item);
            tempActual = tempActual + parseInt(backlogs.actual_quantity_item);
            data.push({
                key: backlogs.id,
                id: backlogs.id,
                schedule_date: moment(backlogs.schedule_date).format(UNIDATEFORMAT),
                work_plan: backlogs.work_plan.description,
                work_programme: backlogs.work_programme.description,
                item: backlogs.item.description,
                expected_quantity_item: parseInt(backlogs.expected_quantity_item),
                actual_quantity_item: parseInt(backlogs.actual_quantity_item),
            });
        });
        setSowingBacklogsData(data);
        setTotalExpected(tempExpected);
        setTotalActual(tempActual);
    };

    const onChangePeriod = (e) => {
        switch(e.target.value) {
            case "1":
                setShowLastNDays(true);
                setShowDateRange(false);
                setDateRange([moment(moment().subtract(lastNDays, "days"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
                break;

            case "2":
                setShowLastNDays(false);
                setShowDateRange(true);
                setDateRange(customDateRange);
                break
        };
    };

    const onChangeLastNDays = (value) => {
        setLastNDays(value);
        setDateRange([moment(moment().subtract(value, "days"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    };

    const onChangeDateRange = (value) => {
        setCustomDateRange(value);
        setDateRange(value);
    };

    const onChangeItem = (key, value) => {
        if (key !== undefined) {
            setItem(key);
            setItemVal(value.children);
        } else {
            setItem(0);
            setItemVal("");
        };
    };

    const onClearItem = () => {
        onChangeItem(0, "");
    };

    const onSearch = () => {
        SearchBacklogs();
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const title = () => {
        let color = "red";
        if (totalActual >= totalExpected) color = "green";

        return (<>
            <Title level={4}>Sowing Backlogs</Title>
            <Row>
                <Col span={17} style={{textAlign: "left"}}><Statistic valueStyle={{ color: color }} title="Total Backlogs (Unit)" value={totalExpected - totalActual} /></Col>
                <Col span={3} style={{textAlign: "left"}}><Statistic valueStyle={{ color: 'green' }} title="Total Expected Amount (Unit)" value={totalExpected} /></Col>
                <Col span={4} style={{textAlign: "right"}}><Statistic valueStyle={{ color: 'blue' }} title="Total Actual Amount (Unit)" value={totalActual} /></Col>
            </Row>
        </>);
    };

    const columns = [
        { title: 'Date', dataIndex: 'schedule_date', sorter: (a, b) => a.schedule_date.localeCompare(b.schedule_date) },
        { title: 'Work Plan', dataIndex: 'work_plan', sorter: (a, b) => a.work_plan.localeCompare(b.work_plan) },
        { title: 'Work Programme', dataIndex: 'work_programme', sorter: (a, b) => a.work_programme.localeCompare(b.work_programme) },
        { title: 'Plant Species', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Expected Amount', dataIndex: 'expected_quantity_item', sorter: (a, b) => a.expected_quantity_item - b.expected_quantity_item },
        { title: 'Actual Amount', dataIndex: 'actual_quantity_item', align: "right", sorter: (a, b) => a.actual_quantity_item - b.actual_quantity_item,
            render: (actual_quantity_item, record) => {
                if (actual_quantity_item < record.expected_quantity_item) return (<Text type="danger">{actual_quantity_item}</Text>)
                else return (<Text type="success">{actual_quantity_item}</Text>);
            }
        },
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search Sowing Backlogs" key="1">
                    <Form form={form} {...formLayout}>
                        <Row justify="center">
                            <Col span={7}></Col>
                            <Col span={12} style={{textAlign: "left"}}>
                                <Radio.Group defaultValue="1" buttonStyle='solid' onChange={onChangePeriod}>
                                    <Radio.Button value="1">Last N days</Radio.Button>
                                    <Radio.Button value="2">Custom Date</Radio.Button>
                                </Radio.Group>
                            </Col>
                            <Col span={5}></Col>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>

                        <Form.Item initialValue={lastNDays} name="lastNdays" label="Last Number of Days (0 - 90)" hidden={!showLastNDays}>
                            <Select onChange={onChangeLastNDays} showSearch optionFilterProp="children">
                                {lastNDaysOption}
                            </Select>
                        </Form.Item>

                        <Form.Item initialValue={dateRange} name="daterange" label="Date Range" hidden={!showDateRange}>
                            <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                        </Form.Item>

                        <Form.Item initialValue={item} name="item" label="Plant Species">
                            <Select onChange={onChangeItem} allowClear={true} onClear={onClearItem} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {itemOption}
                            </Select>
                        </Form.Item>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>
            <Table title={title} columns={columns} dataSource={sowingBacklogsData} pagination={{position: ["bottomCenter"], total: sowingBacklogsData.length, showTotal: showTotal}}/>
        </Spin>
    );
};

export default SowingBacklogsTable;