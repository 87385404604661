import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, Modal, DatePicker, Table, Input, InputNumber, Radio, Spin } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import { formLayoutWithTable } from '../Common/Layout';
import { AGISAPIURL, UNIDATETIMEFORMAT, TASK_TYPE_HARVEST, AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE, UNIT_OF_MEASUREMENT_PERCENTAGE, UNIT_OF_MEASUREMENT_PERCENTAGE_SIGN,
    UNIT_OF_MEASUREMENT_UNIT, ITEM_GROUP_LEAFY_BATAS, ITEM_GROUP_FRUITY_BATAS, MENUPATH_HARVESTINGCREATENEWPAGE2, STATUS_INPROGRESS, LIFECYCLE_SEEDLING, LIFECYCLE_VEGETATIVE,
    LIFECYCLE_MATURITY, LOADING, VALUE_NONE, UNIDATEFORMAT, RESTRICT_FLAG, WARNING_FLAG } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { CaretRightFilled, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
const EditableContext = React.createContext(null);


const { Option } = Select;
const { confirm, error } = Modal;
const { Content, Footer } = Layout;

const HarvestingCreateNewPage1 = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const default_batch = location.state ? location.state.record.batch : null;
    const default_item_item = location.state ? location.state.record.item_item : null;
    const default_area_from_list = location.state ? location.state.record.area_from : [];
    const default_actual_completion_date = location.state ? moment(location.state.record.actualExecutedDate, UNIDATETIMEFORMAT) : moment();
    const default_quantity_item_from_uom = location.state ? location.state.record.quantity_item_from_uom : UNIT_OF_MEASUREMENT_PERCENTAGE;
    const default_quantity_item_from_uom_sign = location.state ? location.state.record.quantity_item_from_uom_sign : UNIT_OF_MEASUREMENT_PERCENTAGE_SIGN;
    const default_item_location_from_list = location.state ? location.state.record.item_location_from_list : null;

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemItemOption, setItemItemOption] = useState("");
    const [batchOption, setBatchOption] = useState("");
    const [areaOption, setAreaOption] = useState("");
    const [itemLocationFromTableData, setItemLocationFromTableData] = useState([]);
    const [unitOfMeasurementOption, setUnitOfMeasurementOption] = useState("");
    const [task_type, setTaskType] = useState(TASK_TYPE_HARVEST);
    const [item_item, setItemItem] = useState(default_item_item);
    const [batch, setBatch] = useState(default_batch);
    const [areaList, setAreaList] = useState(default_area_from_list);
    const [area_type_list, setAreaTypeList] = useState([AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE]);
    const [disableButton, setDisableButton] = useState("");
    const [disableUomButton, setDisableUomButton] = useState("");
    const [quantity_item_from_uom, setQuantityItemFromUom] = useState(default_quantity_item_from_uom);
    const [quantity_item_from_uom_sign, setQuantityItemFromUomSign] = useState(default_quantity_item_from_uom_sign);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBatch, setIsLoadingBatch] = useState(false);
    const [batchCode, setBatchCode] = useState("");
    const [batchLifecycle, setBatchLifecycle] = useState("");
    const [batchLifecycleId, setBatchLifecycleId] = useState(0);
    const [batchExpectedHarvestingDate, setBatchExpectedHarvestingDate] = useState(moment());

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);  // The selectRowKeys name shall not be change as this is required for Antd to control over the selected rows
    const quantity_item_label = "Total Amount of Plant To Harvest in ";

    const getItemItem = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofilebytasktype/", {
            params: {
                site: getUserSiteId(),
                task_type: task_type,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemItemOption(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getBatch = (itemId) => {
        setIsLoadingBatch(true);

        axios.get(AGISAPIURL + "batchavailableharvesting/", {
            params: {
                site: getUserSiteId(),
                item: itemId,
                area_type_list: area_type_list,
                status_type: STATUS_INPROGRESS,
                lifecycle_list: [LIFECYCLE_SEEDLING, LIFECYCLE_VEGETATIVE, LIFECYCLE_MATURITY],
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setBatchOption(response.data.results.map( batch => <Option key={batch.id} value={batch.id}>{batch.batch_code}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingBatch(false);
            refreshUserSession();
        });
    };

    const getItemIdUsingBatch = (batchId) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batch/" + batchId, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemItem(response.data.item.id);
            setBatchCode(response.data.batch_code);
            setBatchLifecycle(response.data.lifecycle.name);
            setBatchLifecycleId(response.data.lifecycle.id);
            setBatchExpectedHarvestingDate(moment(response.data.expected_completion_date));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message);
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    let promiseGetArea = function (batchId) {
        return new Promise(function(myResolve, myReject) {
            setIsLoading(true);

            axios.get(AGISAPIURL + "batcharea/", {
                params: {
                    site: getUserSiteId(),
                    batch: batchId,
                    area_type_list: area_type_list,
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`},
            })
            .then( response => {
                let options = response.data.results.map( area => {
                        let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                        return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                    });
                myResolve(options);
            })
            .catch( error => {
                myReject(error);
            })
            .finally(() => {
                setIsLoading(false);
                refreshUserSession();
            });
        });
    };

    let promiseGetItemLocationFrom = function ({batch = "", areaList = [], uom = UNIT_OF_MEASUREMENT_UNIT}) {
        return new Promise(function(myResolve, myReject) {
            setIsLoading(true);

            axios.get(AGISAPIURL + "batchiteminventorysummary/", {
                params: {
                    batch: batch,
                    area_list: areaList,
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`},
            })
            .then( response => {
                const data = []
                response.data.results.forEach( batchItemInventoryTransactionSummary => {
                    let quantity_item = 100;
                    if (uom === UNIT_OF_MEASUREMENT_UNIT) quantity_item = parseInt(batchItemInventoryTransactionSummary.quantity_item);

                    data.push({
                        key: batchItemInventoryTransactionSummary.item_tool.id,
                        id: batchItemInventoryTransactionSummary.item_tool.id,
                        item_code: batchItemInventoryTransactionSummary.item_tool.item_code,
                        description: batchItemInventoryTransactionSummary.item_tool.description,
                        item_area: batchItemInventoryTransactionSummary.area.area_code,
                        quantity_tool: batchItemInventoryTransactionSummary.quantity_tool,
                        quantity_item: quantity_item,
                        quantity_item_max: parseInt(batchItemInventoryTransactionSummary.quantity_item),
                        total_item: parseInt(batchItemInventoryTransactionSummary.quantity_item),
                    });
                });
                myResolve(data);
            })
            .catch( error => {
                myReject(error);
            })
            .finally(() => {
                setIsLoading(false);
                refreshUserSession();
            });
        })
    };

    const getItemLocationFrom = ({batch = "", areaList = [], uom = UNIT_OF_MEASUREMENT_UNIT}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batchiteminventorysummary/", {
            params: {
                batch: batch,
                area_list: areaList,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemLocationFromRawData(response, uom);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectItemLocationFromRawData = ( response, uom ) => {
        const data = []
        response.data.results.forEach( batchItemInventoryTransactionSummary => {
            let quantity_item = 100;
            if (uom === UNIT_OF_MEASUREMENT_UNIT) quantity_item = parseInt(batchItemInventoryTransactionSummary.quantity_item);

            data.push({
                key: batchItemInventoryTransactionSummary.item_tool.id,
                id: batchItemInventoryTransactionSummary.item_tool.id,
                item_code: batchItemInventoryTransactionSummary.item_tool.item_code,
                description: batchItemInventoryTransactionSummary.item_tool.description,
                item_area: batchItemInventoryTransactionSummary.area.area_code,
                quantity_tool: batchItemInventoryTransactionSummary.quantity_tool,
                quantity_item: quantity_item,
                quantity_item_max: parseInt(batchItemInventoryTransactionSummary.quantity_item),
                total_item: parseInt(batchItemInventoryTransactionSummary.quantity_item),
            });
        });
        setItemLocationFromTableData(data);
    };

    const getUnitOfMeasurement = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemgroupuom/", {
            params: {
                item_group_list: [ITEM_GROUP_LEAFY_BATAS, ITEM_GROUP_FRUITY_BATAS],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = [];
            response.data.results.map( itemgroupuom => {
                if (itemgroupuom.unit_of_measurement.id === UNIT_OF_MEASUREMENT_UNIT || itemgroupuom.unit_of_measurement.id === UNIT_OF_MEASUREMENT_PERCENTAGE) {
                    options = options.concat(<Radio.Button key={itemgroupuom.unit_of_measurement.id} value={itemgroupuom.unit_of_measurement.id} label={itemgroupuom.unit_of_measurement.unit}>{itemgroupuom.unit_of_measurement.unit}</Radio.Button>);
                };
            });
            setUnitOfMeasurementOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const onChangeItemItem = (value) => {
        setItemItem(value);
    };

    const onChangeBatch = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                area_from: [],
                item_location_from_list: "",
            })
            setAreaList([]);
            onChangeItemLocationFrom([]);
            setBatch(value);

            getItemIdUsingBatch(value);
            promiseGetArea(value).then(
                function(value) {setAreaOption(value)},
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            );
            getItemLocationFrom({ batch: value, uom: quantity_item_from_uom });
        };
    };

    const onChangeArea = (value) => {
        onChangeItemLocationFrom([]);
        setAreaList(value);
        getItemLocationFrom({ batch: batch, areaList: value, uom: quantity_item_from_uom });
    };

    const onChangeQuantityItemUom = (e) => {
        setQuantityItemFromUom(e.target.value);
        setQuantityItemFromUomSign(e.target.label);
        onChangeItemLocationFrom([]);
        getItemLocationFrom({ batch: batch, areaList: areaList, uom: e.target.value });
    };

    const onChangeItemLocationFrom = (newSelectedRowKeys) => {
        let list = []
        newSelectedRowKeys.forEach( rowKey => {
            const indexLoc = itemLocationFromTableData.findIndex((location) => rowKey === location.id);
            list.push({
                id: rowKey,
                quantity_tool: itemLocationFromTableData[indexLoc]['quantity_tool'],
                quantity_item: itemLocationFromTableData[indexLoc]['quantity_item'],
                quantity_item_max: itemLocationFromTableData[indexLoc]['quantity_item_max'],
                total_item: itemLocationFromTableData[indexLoc]['total_item'],
            });
        });

        form.setFieldsValue({
            item_location_from_list: list,
        });

        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeItemLocationFrom,
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getItemItem();
        getBatch();
        promiseGetArea(batch).then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getUnitOfMeasurement();

        if (location.state) {
            promiseGetItemLocationFrom({ batch: batch, areaList: default_area_from_list, uom: quantity_item_from_uom }).then(
                function(value) {
                    form.setFieldsValue({ item_location_from_list: default_item_location_from_list });

                    let newData = []
                    let selectedKeys = []
                    value.forEach(data => {
                        const index = default_item_location_from_list.findIndex((item) => data.key === item.id);
                        if (index > -1) {
                            data.quantity_tool = default_item_location_from_list[index].quantity_tool
                            data.quantity_item = default_item_location_from_list[index].quantity_item
                            data.quantity_item_max = default_item_location_from_list[index].quantity_item_max
                            data.total_item = default_item_location_from_list[index].total_item
                            selectedKeys.push(default_item_location_from_list[index].id)
                        }
                        newData.push(data);
                    })
                    setItemLocationFromTableData(newData);
                    setSelectedRowKeys(selectedKeys);
                },
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            )
        }
    }, []);

    const onItemItemSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                batch: "",
                area_from: [],
                item_location_from_list: "",
            })
            setBatch("");
            setAreaList([]);
            onChangeItemLocationFrom([]);
            setItemItem(value);

            getBatch(value);
            promiseGetArea().then(
                function(value) {setAreaOption(value)},
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            );
            getItemLocationFrom({ uom: quantity_item_from_uom });
        };
    };

    const onItemItemClear = () => {
        setItemItem();
        form.setFieldsValue({
            batch: "",
            area_from: [],
            item_location_from_list: "",
        })
        setBatch("");
        setAreaList([]);
        onChangeItemLocationFrom([]);

        getBatch();
        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getItemLocationFrom({ uom: quantity_item_from_uom });
    };

    const onBatchClear = () => {
        setBatch();
        form.setFieldsValue({
            area_from: [],
            item_location_from_list: "",
        })
        setAreaList([]);
        onChangeItemLocationFrom([]);

        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getItemLocationFrom({ uom: quantity_item_from_uom });
    };

    const onAreaClear = () => {
        setAreaList([]);
        form.setFieldsValue({
            item_location_from_list: "",
        })
        onChangeItemLocationFrom([]);
        getItemLocationFrom({ batch: batch, uom: quantity_item_from_uom });
    };

    const onBack = () => {
        navigate(OTHERSYSPARAM("lastPage"));
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(OTHERSYSPARAM("lastPage")) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (values) => {
        if (RESTRICT_FLAG && ([LIFECYCLE_SEEDLING, LIFECYCLE_VEGETATIVE].includes(batchLifecycleId) || batchExpectedHarvestingDate > moment())) {
            let title = "";
            let content = "";
            if ([LIFECYCLE_SEEDLING, LIFECYCLE_VEGETATIVE].includes(batchLifecycleId)) {
                title = "Batch " + batchCode + " is not in Maturity Lifecycle!";
                content = "Current Lifecycle: " + batchLifecycle;
            }
            else if (batchExpectedHarvestingDate > moment()) {
                title = "Batch " + batchCode + " has not reached its Expected Harvesting Date yet!";
                content = "Expected Harvesting Date: " + batchExpectedHarvestingDate.format(UNIDATEFORMAT);
            };

            error({
                title: <p>{title}</p>,
                content: <p>{content}</p>,
                centered: true
            });
        }
        else if (WARNING_FLAG && ([LIFECYCLE_SEEDLING, LIFECYCLE_VEGETATIVE].includes(batchLifecycleId) || batchExpectedHarvestingDate > moment())) {
            let title = "";
            let content = "";
            if ([LIFECYCLE_SEEDLING, LIFECYCLE_VEGETATIVE].includes(batchLifecycleId)) {
                title = "Batch " + batchCode + " is not in Maturity Lifecycle!";
                content = "Current Lifecycle: " + batchLifecycle;
            }
            else if (batchExpectedHarvestingDate > moment()) {
                title = "Batch " + batchCode + " has not reached its Expected Harvesting Date yet!";
                content = "Expected Harvesting Date: " + batchExpectedHarvestingDate.format(UNIDATEFORMAT);
            };

            confirm({
                icon: <ExclamationCircleTwoTone />,
                title: <p>{title}</p>,
                content: <><p>{content}</p><p>Please confirm the Batch Code and press 'Confirm To Proceed' to the next page.</p></>,
                onOk() {
                    values.actualExecutedDate = values.actualExecutedDate.format(UNIDATETIMEFORMAT);
                    values.quantity_item_from_uom = quantity_item_from_uom;
                    values.quantity_item_from_uom_sign = quantity_item_from_uom_sign;
                    values.item_item = item_item;
                    if (location.state !== null) {
                        values.item_location_to_list = location.state.record.item_location_to_list ? location.state.record.item_location_to_list : null;
                        values.quantity_item_to_secondary_uom = location.state.record.quantity_item_to_secondary_uom ? location.state.record.quantity_item_to_secondary_uom : null;
                        values.quantity_item_to_secondary_uom_sign = location.state.record.quantity_item_to_secondary_uom_sign ? location.state.record.quantity_item_to_secondary_uom_sign : null;
                        values.area_to = location.state.record.area_to ? location.state.record.area_to : null;
                        values.total_weight = location.state.record.total_weight ? location.state.record.total_weight : null;
                    }
                    navigate(MENUPATH_HARVESTINGCREATENEWPAGE2, {state: {record: values}});
                },
                onCancel() {},
                okText: "Confirm To Proceed",
                centered: true
            });
        }
        else {
            confirm({
                icon: <QuestionCircleTwoTone />,
                content: <Space><p>Confirm the data inserted is correct and move on to the next page?</p></Space>,
                onOk() {
                    values.actualExecutedDate = values.actualExecutedDate.format(UNIDATETIMEFORMAT);
                    values.quantity_item_from_uom = quantity_item_from_uom;
                    values.quantity_item_from_uom_sign = quantity_item_from_uom_sign;
                    values.item_item = item_item;
                    if (location.state !== null) {
                        values.item_location_to_list = location.state.record.item_location_to_list ? location.state.record.item_location_to_list : null;
                        values.quantity_item_to_secondary_uom = location.state.record.quantity_item_to_secondary_uom ? location.state.record.quantity_item_to_secondary_uom : null;
                        values.quantity_item_to_secondary_uom_sign = location.state.record.quantity_item_to_secondary_uom_sign ? location.state.record.quantity_item_to_secondary_uom_sign : null;
                        values.area_to = location.state.record.area_to ? location.state.record.area_to : null;
                        values.total_weight = location.state.record.total_weight ? location.state.record.total_weight : null;
                    }
                    navigate(MENUPATH_HARVESTINGCREATENEWPAGE2, {state: {record: values}});
                },
                onCancel() {},
                centered: true
            });
        };
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onChangeTable = () => {
        // To restore the item_location_from_list as changing the table pagination/sorting/filter will reset the fields in the form
        onChangeItemLocationFrom(selectedRowKeys);
    };

    const EditableRow = ({ index, ...props }) => {
        const [form1] = Form.useForm();
        return (
            <Form form={form1} component={false}>
                <EditableContext.Provider value={form1}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ editable, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = <></>
        let errorMessage = ""
        const form1 = useContext(EditableContext);

        const save = async () => {
            try {
                let tempKeyList = selectedRowKeys.slice(0);
                if (tempKeyList.indexOf(record.key) === -1) {
                    tempKeyList.push(record.key);
                    onChangeItemLocationFrom(tempKeyList);
                };

                const row = await form1.validateFields();
                const newData = [...itemLocationFromTableData];
                const newDataIndex = newData.findIndex((item) => record.key === item.key);
                if (quantity_item_from_uom === UNIT_OF_MEASUREMENT_UNIT) {
                    row['total_item'] = row['quantity_item'];
                }
                else if (quantity_item_from_uom === UNIT_OF_MEASUREMENT_PERCENTAGE) {
                    row['total_item'] = row['quantity_item'] * parseInt(newData[newDataIndex]['quantity_item_max']) / 100;
                }
                else {
                    row['total_item'] = VALUE_NONE;
                };

                if (newDataIndex > -1) {
                    const item = newData[newDataIndex];
                    newData.splice(newDataIndex, 1, { ...item, ...row });
                    setItemLocationFromTableData(newData);
                } else {
                    newData.push(row);
                    setItemLocationFromTableData(newData);
                }

                // Update form data with location list
                const newLocationList = form.getFieldValue('item_location_from_list');
                const indexLoc = newLocationList.findIndex((location) => record.key === location.id);
                newLocationList[indexLoc] = { id: record.key, quantity_tool: newData[newDataIndex]['quantity_tool'], quantity_item: row['quantity_item'], quantity_item_max: newData[newDataIndex]['quantity_item_max'], total_item: row['total_item'] }
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        switch(title) {
            case quantity_item_label + "%":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={1} max={100} addonAfter="%"/>;
                errorMessage = "with numbers only";
                break;
            case quantity_item_label + "KG":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={0.001}/>;
                errorMessage = "with numbers only";
                break;
            case quantity_item_label + "Unit":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={1} max={record.quantity_item_max}/>;
                inputType = "integer";
                errorMessage = "with integer only";
                break;

            default:
                inputNode = inputType === 'number' ? <InputNumber onPressEnter={save} onBlur={save}/> : <Input onPressEnter={save} onBlur={save}/>;
        };

        let childNode = children;
        if (editable) {
            childNode = (
                <Form.Item initialValue={record.quantity_item} name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `Please Input ${title} ${errorMessage}!`, type: inputType}]}>
                    {inputNode}
                </Form.Item>
            );
        };
        return <td {...restProps}>{childNode}</td>;
    };

    const defaultColumns = [
        { title: 'Zone', dataIndex: 'item_area', sorter: (a, b) => a.item_area.localeCompare(b.item_area) },
        { title: 'Medium Type(s)', dataIndex: 'item_code', sorter: (a, b) => a.item_code.localeCompare(b.item_code) },
        // { title: 'Quantity (Planting Medium)', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: 'Balance Units of Plant', dataIndex: 'quantity_item_max', sorter: (a, b) => a.quantity_item_max - b.quantity_item_max },
        { title: quantity_item_label + quantity_item_from_uom_sign, dataIndex: 'quantity_item', editable: 1, sorter: (a, b) => a.quantity_item - b.quantity_item,
            render: (quantity_item) => {
                if (quantity_item_from_uom === UNIT_OF_MEASUREMENT_PERCENTAGE) return <span>{quantity_item}{quantity_item_from_uom_sign}</span>;
                else return <span>{quantity_item}</span>;
            },
        },
        { title: 'Total Units of Plant to Harvest', dataIndex: 'total_item', sorter: (a, b) => a.total_item - b.total_item,
            render: (total_item) => {
                return (<b>{total_item}</b>);
            },
        },
    ]

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Harvesting Activity" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Harvesting Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={values => onSave(values)} {...formLayoutWithTable} autoComplete="off">
                    <Form.Item initialValue={default_item_item} name="item_item" label="Plant Species">
                        <Select onChange={(value) => onChangeItemItem(value)} onSelect={(value) => onItemItemSelect(value)} allowClear={true} onClear={onItemItemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemItemOption}
                        </Select>
                    </Form.Item>

                    <Spin spinning={isLoadingBatch} size="large" tip={LOADING}>
                        <Form.Item initialValue={default_batch} name="batch" label="Batch Code" rules={[{required: true, message: 'Please select the Batch Code for this Harvesting Activity!'}]}>
                            <Select onChange={(value) => onChangeBatch(value)} allowClear={true} onClear={onBatchClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {batchOption}
                            </Select>
                        </Form.Item>
                    </Spin>

                    <Form.Item initialValue={default_actual_completion_date} name="actualExecutedDate" label="Actual Harvesting Date" rules={[{required: true, message: 'Please input the Actual Harvesting Date!'}]}>
                        <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: default_actual_completion_date}} disabledDate={disabledDateAfterToday}/>
                    </Form.Item>

                    <Form.Item initialValue={default_area_from_list} name="area_from" label="Zone (Harvest From)">
                        <Select onChange={(value) => onChangeArea(value)} allowClear={true} mode='multiple' onClear={onAreaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {areaOption}
                        </Select>
                    </Form.Item>

                    <Form.Item initialValue={default_quantity_item_from_uom} name="quantity_item_from_uom" label="UOM of Plant Harvested">
                        <Radio.Group onChange={onChangeQuantityItemUom} buttonStyle="solid" disabled={disableUomButton}>
                            {unitOfMeasurementOption}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name="item_location_from_list" label="Harvest From" rules={[{required: true, message: 'Please select at least one Batas Harvesting Location!'}]} tooltip={{ title: "Please select a Harvesting Zone first", icon: <ExclamationCircleTwoTone/>}}>
                        <Table components={{ body: {row: EditableRow, cell: EditableCell} }} rowClassName={() => 'editable-row'} rowSelection={rowSelection} columns={columns} dataSource={itemLocationFromTableData} onChange={onChangeTable} pagination={{ size: 'small',  position: ["bottomCenter"], total: itemLocationFromTableData.length, showTotal: showTotal }}/>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><CaretRightFilled/>Next</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Harvesting Activity" subTitle="Create New Harvesting Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default HarvestingCreateNewPage1;