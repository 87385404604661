import { useEffect } from 'react';
import "antd/dist/antd.min.css";
import "./App.css";

import RounterManager from './component/Router';

function App() {
    useEffect(() => {document.title = "AGIS"}, []);
    return (<RounterManager />);
};

export default App;
