import React, { useState, useEffect } from 'react';
import { Bar } from '@ant-design/plots';
import { UNIDATEFORMAT } from '../Common/SysParameters';
import moment from 'moment';


const WorkPlanCharts = ({workPlanTableData}) => {

    const [workPlanData, setWorkPlanData] = useState([]);
    const [workPlanDesc, setWorkPlanDesc] = useState({});
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();

    const ConstructWorkPlanData = () => {
        let description = {};
        const data = [];
        let min = 0;
        let max = 0;

        workPlanTableData.forEach( workPlan => {
            workPlan.date_range_list.forEach( dateRange => {

                // Get the smallest date
                if (min === 0) min = moment(dateRange.start_date).valueOf()
                else if (moment(dateRange.start_date).valueOf() < min) min = moment(dateRange.start_date).valueOf();

                // Get the largest date
                if (max === 0) max = moment(dateRange.end_date).valueOf()
                else if (moment(dateRange.end_date).valueOf() > max) max = moment(dateRange.end_date).add(1, "days").valueOf();

                data.push({
                    work_plan_code: workPlan.work_plan_code,
                    date_range: [moment(dateRange.start_date).valueOf(), moment(dateRange.end_date).add(1, "days").valueOf()],
                });
            });
            description[workPlan.work_plan_code] = workPlan.description;
        });
        setWorkPlanData(data);
        setWorkPlanDesc(description);
        setMinDate(min);
        setMaxDate(max);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        ConstructWorkPlanData();
    }, [workPlanTableData]);

    // https://github.com/ant-design/ant-design-charts/issues/1876 - [BUG] Label is only shown on the first bar in a range.
    return (
        <Bar
            data={workPlanData}
            xField='date_range'
            yField='work_plan_code'
            seriesField='work_plan_code'
            isRange={true}
            xAxis={{
                type: 'time',
                min: minDate,
                max: maxDate,
                tickMethod: 'time-pretty',
                grid: {line: {style: { stroke: 'black', lineWidth: 1, strokeOpacity: 0.5 }}},
            }}
            yAxis={{
                label: {
                    formatter: (value) => {
                        if (workPlanDesc[value]) return `${value}\n${workPlanDesc[value]}`
                        else return `${value}`
                    },
                },
            }}
            label={{
                position: 'middle',
                layout: [{ type: 'adjust-color' }],
                content: (value) => {
                    return `${moment(value.date_range[0]).format(UNIDATEFORMAT)}\nto\n${moment(value.date_range[1]).subtract(1, "days").format(UNIDATEFORMAT)}`;
                },
            }}
            tooltip={{
                formatter: (datum) => ({
                    name: datum.work_plan_code,
                    value: `${moment(datum.date_range[0]).format(UNIDATEFORMAT)} to ${moment(datum.date_range[1]).subtract(1, "days").format(UNIDATEFORMAT)}`
                }),
            }}
        />
    );
};

export default WorkPlanCharts;