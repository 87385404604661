import { getUserSiteName } from '../Common/UserSession';


const DashboardPowerBI = () => {

    const getPowerBIEmbedLink = () => {
        if (getUserSiteName() == "Kinarut Organic Vege Farm")
            return <iframe title="dashboard_kinarut_site" width="1780" height="860" src="https://app.powerbi.com/reportEmbed?reportId=f4e6a5e8-f7c6-4d38-af6f-fead2ae25aec&autoAuth=true&ctid=24b6d2b8-5590-4f8b-a11b-def0bfaedf16" frameborder="0" allowFullScreen="true" />
        else if (getUserSiteName() == "Tuaran Organic Vege Farm")
            return <iframe title="dashboard_tuaran_site" width="1780" height="860" src="https://app.powerbi.com/reportEmbed?reportId=fa7ec806-1f18-4cb7-b041-83b1614411f8&autoAuth=true&ctid=24b6d2b8-5590-4f8b-a11b-def0bfaedf16" frameborder="0" allowFullScreen="true" />
    };

    return (<>{getPowerBIEmbedLink()}</>);
};

export default DashboardPowerBI;