import React, { useState, useEffect } from 'react';
import { Timeline, Steps, Space, Row, Col } from 'antd';
import { UNIT_OF_TIME_DAY_SIGN } from '../Common/SysParameters';


const { Step } = Steps;

const WorkTaskTimeline = ({dataSource}) => {
    const [workStageStepsData, setWorkStageStepsData] = useState("");
    const [workTaskTimelineItem, setWorkTaskTimelineItem] = useState("");

    const getWorkStagesAndTasks = (dataSource, currentStage) => {
        let workStageDayRangeList = [];
        let workStageChildren = [];
        let workTaskChildren = [];
        let dayFrom = 0;
        let dayTo = 0;

        // Constructing Steps with Work Stages
        dataSource.work_stage_list.forEach(workStage => {
            workStageChildren.push(
                <Step key={workStage.id}
                    title={`Stage ${workStage.seq}: ${workStage.work_phase}`}
                    description={`Lifecycle: ${workStage.lifecycle}`}
                    subTitle={`${workStage.duration} ${workStage.duration_uom}`}>
                </Step>
            );

            if (workStage.duration_uom === UNIT_OF_TIME_DAY_SIGN) {
                dayFrom = dayTo + 1;
                dayTo = dayTo + parseInt(workStage.duration);

                workStageDayRangeList.push({
                    dayFrom: dayFrom,
                    dayTo: dayTo,
                });
            }
            else {
                workStageDayRangeList.push({
                    dayFrom: 0,
                    dayTo: 0,
                });
            };
        });
        setWorkStageStepsData(<Steps progressDot size='default' current={currentStage} onChange={onChangeStage}>{workStageChildren}</Steps>);

        // Constructing Timeline with Work Tasks
        if ( workStageDayRangeList[currentStage]["dayFrom"] !== 0 || workStageDayRangeList[currentStage]["dayTo"] !== 0 ) {
            let color = "";
            dataSource.work_task_list.forEach(workTask => {
                if (workTask.execute_on_uom === UNIT_OF_TIME_DAY_SIGN) {
                    if (parseInt(workTask.execute_on) >= workStageDayRangeList[currentStage]["dayFrom"] &&
                        parseInt(workTask.execute_on) <= workStageDayRangeList[currentStage]["dayTo"])
                        color='blue'
                    else color='green';
                }
                else color='green';

                workTaskChildren.push(
                    <Timeline.Item
                        key={workTask.id}
                        position='right'
                        color={color}
                        label={`${workTask.execute_on_uom} ${workTask.execute_on} ------- ${workTask.lifecycle}`}
                        children={`${workTask.task_type}`}>
                    </Timeline.Item>
                );
            });
        }
        else {
            dataSource.work_task_list.forEach(workTask => {
                workTaskChildren.push(
                    <Timeline.Item
                        key={workTask.id}
                        position='right'
                        color='green'
                        label={`${workTask.execute_on_uom} ${workTask.execute_on} ------- ${workTask.lifecycle}`}
                        children={`${workTask.task_type}`}>
                    </Timeline.Item>
                );
            });
        };
        setWorkTaskTimelineItem(workTaskChildren);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getWorkStagesAndTasks(dataSource, 0);
    }, []);

    const onChangeStage = (value) => {
        getWorkStagesAndTasks(dataSource, value);
    };

    return (
        <>
            {workStageStepsData}

            <Row><Col><Space><br/></Space></Col></Row>
            <Row><Col><Space><br/></Space></Col></Row>
            <Row><Col><Space><br/></Space></Col></Row>

            <Timeline>{workTaskTimelineItem}</Timeline>
        </>
    );
};

export default WorkTaskTimeline;