import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, Modal, DatePicker, Table, message, Spin, Switch } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import { formLayoutWithTable } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_SOILMAINTENANCE, UNIDATETIMEFORMAT, AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE, ITEM_GROUP_LEAFY_BATAS, ITEM_GROUP_FRUITY_BATAS,
    TASK_CATEGORY_SOIL_MAINTENANCE, TASK_TYPE_REVIVE, TASK_TYPE_RELEASE_MEDIUM, STATUS_EMPTY, STATUS_INPROGRESS, LIFECYCLE_DEGRADATION,
    LIFECYCLE_DEPLETION, WORK_PHASE_PARTIAL_SOIL_RESTORATION, WORK_PHASE_FULL_SOIL_RESTORATION, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { SaveOutlined, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
const EditableContext = React.createContext(null);


const { Option } = Select;
const { confirm } = Modal;
const { Content, Footer } = Layout;

const SoilMaintenanceCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [taskTypeOption, setTaskTypeOption] = useState("");
    const [batchOption, setBatchOption] = useState("");
    const [areaOption, setAreaOption] = useState("");
    const [itemLocationToTableData, setItemLocationToTableData] = useState([]);
    const [task_type, setTaskType] = useState(0);
    const [area, setArea] = useState(0);
    const [batch, setBatch] = useState(0);
    const [showMatched, setShowMatched] = useState(true);
    const [disableButton, setDisableButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBatch, setIsLoadingBatch] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);  // The selectRowKeys name shall not be change as this is required for Antd to control over the selected rows

    const createActivity = (actual_completion_date) => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "activity/create/soilmaintenance/", {
            site: getUserSiteId(),
            batch: batch,
            task_type: task_type,
            lifecycle: task_type === TASK_TYPE_REVIVE ? LIFECYCLE_DEGRADATION : LIFECYCLE_DEPLETION,
            work_phase: task_type === TASK_TYPE_REVIVE ? WORK_PHASE_PARTIAL_SOIL_RESTORATION : WORK_PHASE_FULL_SOIL_RESTORATION,
            item_location_to_list: form.getFieldValue('item_location_to_list'),
            actual_executed_datetime: actual_completion_date.format(UNIDATETIMEFORMAT),
            status_type_batch: STATUS_INPROGRESS,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New activity ${response.data[0].id} created.`);
            navigate(MENUPATH_SOILMAINTENANCE);
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getTaskType = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "tasktype/", {
            params: {
                task_category: TASK_CATEGORY_SOIL_MAINTENANCE,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setTaskTypeOption(response.data.results.map( task_type => task_type.id === TASK_TYPE_RELEASE_MEDIUM ? null : <Option key={task_type.id} value={task_type.id}>{task_type.task}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getArea = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "area/", {
            params: {
                site: getUserSiteId(),
                area_type_list: [AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options = options.concat(response.data.results.map( area => {
                    let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                    return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                }));
            setAreaOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getBatch = ({area = "", task_type = "", show_matched = ""}) => {
        setIsLoadingBatch(true);

        axios.get(AGISAPIURL + "batchavailablesoilmaintenance/", {
            params: {
                site: getUserSiteId(),
                area: area,
                task_type: task_type,
                item_group_list: [ITEM_GROUP_LEAFY_BATAS, ITEM_GROUP_FRUITY_BATAS],
                is_active: 1,
                show_matched: show_matched ? 1 : 0,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setBatchOption(response.data.results.map( batch => <Option key={batch.id} value={batch.id}>{batch.batch_code}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingBatch(false);
            refreshUserSession();
        });
    };

    const getItemLocationTo = ({area_list = [], task_type = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "item/", {
            params: {
                area_list: area_list,
                task_type: task_type,
                status_type: STATUS_EMPTY,
                is_inventory_repository: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemLocationToRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectItemLocationToRawData = ( response ) => {
        const data = []
        response.data.results.forEach( item => {
            data.push({
                key: item.id,
                id: item.id,
                area: item.area.area_code,
                item: item.item_code,
                status_type: item.status_type.description,
                status_type_id: item.status_type.id,
                planting_cycle_after_revive: item.planting_cycle_after_revive,
                planting_cycle_after_rehabilitate: item.planting_cycle_after_rehabilitate,
            });
        });
        setItemLocationToTableData(data);
    };

    const onChangeTaskType = (value) => {
        if(value !== undefined){
            setTaskType(value);
            form.setFieldsValue({
                batch: "",
                item_location_to_list: "",
            })
            setBatch();
            onChangeItemLocationTo([]);

            getBatch({ area: area, task_type: value, show_matched: showMatched });
            getItemLocationTo({ area_list: [area], task_type: value });
        };
    };

    const onChangeArea = (value) => {
        if(value !== undefined){
            setArea(value);
            form.setFieldsValue({
                batch: "",
                item_location_to_list: "",
            })
            setBatch();
            onChangeItemLocationTo([]);

            getBatch({ area: value, task_type: task_type, show_matched: showMatched });
            getItemLocationTo({ area_list: [value], task_type: task_type });
        };
    };

    const onChangeBatch = (value) => {
        if(value !== undefined){
            setBatch(value);
        }
        else setBatch();
    };

    const onShowMatchedClick = (value) => {
        form.setFieldsValue({
            batch: "",
        })
        setBatch();
        setShowMatched(value);

        getBatch({ area: area, task_type: task_type, show_matched: value });
    };

    const onChangeItemLocationTo = (newSelectedRowKeys) => {
        let list = []
        newSelectedRowKeys.forEach( rowKey => {
            list.push({id: rowKey});
        });

        form.setFieldsValue({
            item_location_to_list: list,
        });

        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeItemLocationTo,
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getTaskType();
        getArea();
        getBatch({});
    }, []);

    const onTaskTypeClear = () => {
        setTaskType();
        form.setFieldsValue({
            batch: "",
            item_location_to_list: "",
        })
        setBatch();
        onChangeItemLocationTo([]);

        getBatch({ area: area, show_matched: showMatched });
        getItemLocationTo({ area_list: [area] });
    };

    const onAreaClear = () => {
        setArea(0);
        form.setFieldsValue({
            batch: "",
            item_location_to_list: "",
        })
        setBatch();
        onChangeItemLocationTo([]);

        getBatch({ task_type: task_type, show_matched: showMatched });
        getItemLocationTo({ task_type: task_type });
    };

    const onBatchClear = () => {
        setBatch();
    };

    const onBack = () => {
        navigate(MENUPATH_SOILMAINTENANCE);
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_SOILMAINTENANCE) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (values) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create Soil Maintenance activity confirmed?</p></Space>,
            onOk() { createActivity(values.actualExecutedDate) },
            onCancel() {},
            centered: true
        });
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onChangeTable = () => {
        // To restore the item_location_to_list as changing the table pagination/sorting/filter will reset the fields in the form
        onChangeItemLocationTo(selectedRowKeys);
    };

    const EditableRow = ({ index, ...props }) => {
        const [form1] = Form.useForm();
        return (
            <Form form={form1} component={false}>
                <EditableContext.Provider value={form1}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const columns = [
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'Medium', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Status', dataIndex: 'status_type', sorter: (a, b) => a.status_type.localeCompare(b.status_type) },
        { title: 'No. Of Planting Cycle (Since Last Revive)', dataIndex: 'planting_cycle_after_revive', sorter: (a, b) => a.planting_cycle_after_revive - b.planting_cycle_after_revive },
        { title: 'No. Of Planting Cycle (Since Last Rehabilitate)', dataIndex: 'planting_cycle_after_rehabilitate', sorter: (a, b) => a.planting_cycle_after_rehabilitate - b.planting_cycle_after_rehabilitate },
    ]

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Soil Maintenance Activity" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Soil Maintenance Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={values => onSave(values)} {...formLayoutWithTable} autoComplete="off">
                    <Form.Item name="task_type" label="Task Type" rules={[{required: true, message: 'Please select the Task Type for this Soil Maintenance Activity!'}]}>
                        <Select onChange={(value) => onChangeTaskType(value)} allowClear={true} onClear={onTaskTypeClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {taskTypeOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="area_to" label="Zone">
                        <Select onChange={(value) => onChangeArea(value)} allowClear={true} onClear={onAreaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {areaOption}
                        </Select>
                    </Form.Item>

                    <Form.Item valuePropName="show_matched" label="Show Expected Batch Only" tooltip={{ title: "Show only batches that match with the expectation of its Work Programme", icon: <ExclamationCircleTwoTone/>}}>
                        <Switch onClick={onShowMatchedClick} defaultChecked={showMatched}/>
                    </Form.Item>

                    <Spin spinning={isLoadingBatch} size="large" tip={LOADING}>
                        <Form.Item name="batch" label="Batch Code" rules={[{required: true, message: 'Please select the Batch Code for this Soil Maintenance Activity!'}]}>
                            <Select onChange={(value) => onChangeBatch(value)} allowClear={true} onClear={onBatchClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {batchOption}
                            </Select>
                        </Form.Item>
                    </Spin>

                    <Form.Item initialValue={moment()} name="actualExecutedDate" label="Actual Soil Maintenance Date" rules={[{required: true, message: 'Please input the Actual Soil Maintenance Date!'}]}>
                        <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment()}} disabledDate={disabledDateAfterToday}/>
                    </Form.Item>

                    <Form.Item name="item_location_to_list" label="Location" rules={[{required: true, message: 'Please select at least one Location!'}]} tooltip={{ title: "Please select a Zone first", icon: <ExclamationCircleTwoTone/>}}>
                        <Table components={{ body: {row: EditableRow} }} rowClassName={() => 'editable-row'} rowSelection={rowSelection} columns={columns} dataSource={itemLocationToTableData} onChange={onChangeTable} pagination={{ size: 'small',  position: ["bottomCenter"], total: itemLocationToTableData.length, showTotal: showTotal }}/>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><SaveOutlined/>Save</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Soil Maintenance Activity" subTitle="Create New Soil Maintenance Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default SoilMaintenanceCreateNew;