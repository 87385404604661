import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Spin, Card, Typography, Tag, Table, Collapse, Form, Button, Divider, message, Input, Modal, Switch } from 'antd';
import { AGISAPIURL, UNIDATEFORMAT, LOADING, VALUE_NONE, MENUPATH_WORKPROGRAMME } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import axios from 'axios';
import moment from 'moment';
import { reportError } from "../Common/Utility";
import { CheckOutlined, CloseCircleFilled, QuestionCircleTwoTone, SaveOutlined, DeleteFilled } from '@ant-design/icons';
import WorkPlanListTable from "./WorkPlanListTable";
import WorkTaskTimeline from "./WorkTaskTimeline";


const { Content, Footer } = Layout;
const { confirm } = Modal;
const { Title } = Typography;
const { Panel } = Collapse;

const WorkProgrammeUpdate = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");
    const paddingCardGrid = OTHERSYSPARAM("CARD_GRID_PADDING");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [workProgId, setWorkProgId] = useState(location.state.record.id);
    const [createdOn, setCreatedOn] = useState(location.state.record.created_on);
    const [updatedOn, setUpdatedOn] = useState(location.state.record.updated_on);
    const [workProgCode, setWorkProgCode] = useState(location.state.record.work_programme_code);
    const [description, setDescription] = useState(location.state.record.description);
    const [item, setItem] = useState(location.state.record.item);
    const [quantityItem, setQuantityItem] = useState(location.state.record.quantity_item);
    const [workday, setWorkday] = useState(location.state.record.workday);
    const [workPlanTableData, setWorkPlanTableData] = useState(location.state.record.work_plan_list);
    const [isWorkProgActive, setIsWorkProgActive] = useState(location.state.record.is_active);
    const [disableCancelButton, setDisableCancelButton] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState("");
    const [disableDeleteButton, setDisableDeleteButton] = useState(location.state.record.work_plan_list ? ((!location.state.record.has_batch && location.state.record.work_plan_list.length === 0) ? "" : "disable") : "disable");
    const [isLoading, setIsLoading] = useState(false);

    const updateWorkProgramme = (description) => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setIsLoading(true);

        axios.patch(AGISAPIURL + "workprogramme/update/" + workProgId, {
            description: description,
            is_active: isWorkProgActive,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Work Programme ${response.data.work_programme_code} updated.`);
            navigate(OTHERSYSPARAM("lastPage"));
        })
        .catch( error => {
            reportError(error, "Failed to update data. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const deleteWorkProgramme = () => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setIsLoading(true);

        axios.delete(AGISAPIURL + "workprogramme/delete/" + location.state.record.id, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then(() => {
            message.info(`Work Programme ${location.state.record.id} deleted!`);
            navigate(MENUPATH_WORKPROGRAMME);
        })
        .catch( error => {
            reportError(error, "Failed to delete work programme. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getWorkPlanList = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "workplan/", {
            params: {
                site: getUserSiteId(),
                work_programme: workProgId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectWorkPlanListRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    useEffect(() => {
        Modal.destroyAll();
        if (!location.state.record.work_plan_list) getWorkPlanList();
    }, []);

    const collectWorkPlanListRawData = ( response ) => {
        const data = [];
        response.data.forEach( workPlan => {
            data.push({
                key: workPlan.id,
                id: workPlan.id,
                work_plan_code: workPlan.work_plan_code,
                description: workPlan.description,
                is_active: workPlan.is_active,
                is_in_effect: workPlan.is_in_effect,
                date_range_list: collectDateRangeListRawData(workPlan.date_range_list),
            });
        });
        setWorkPlanTableData(data);
        if (!location.state.record.has_batch && data.length === 0) setDisableDeleteButton("")
        else setDisableDeleteButton("disable");
    };

    const collectDateRangeListRawData = ( response ) => {
        const data = [];
        response.forEach( dataRange => {
            data.push({
                key: dataRange.id,
                id: dataRange.id,
                start_date: dataRange.start_date ? moment(dataRange.start_date).format(UNIDATEFORMAT) : VALUE_NONE,
                end_date: dataRange.end_date ? moment(dataRange.end_date).format(UNIDATEFORMAT) : VALUE_NONE,
            });
        });
        return data;
    };

    const onClickIsWorkProgrammeActive = (value) => {
        setIsWorkProgActive(value);
        if (!value)
            Modal.warning({
                title: 'Warning when deactivating the Work Programme!',
                content: (<div>
                    <p>Note that the de-activation of this work programme will be reflected in all work plans that this work programme has attached to.</p>
                </div>),
                width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"),
          });
    };

    const onBack = () => {
        navigate(OTHERSYSPARAM("lastPage"));
    };

    const onCancel = () => {
        navigate(OTHERSYSPARAM("lastPage"));
    };

    //---------------------------
    // On delete
    //---------------------------
    const onDelete = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Delete work programme confirmed?</p></Space>,
            onOk() { deleteWorkProgramme() },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (value) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Edit work programme confirmed?</p></Space>,
            onOk() { updateWorkProgramme(value.description) },
            onCancel() {},
            centered: true
        });
    };

    const columns = [
        { title: 'Monday', dataIndex: 'monday', render: (monday) => {return(monday ? <CheckOutlined /> : <>-</>)} },
        { title: 'Tuesday', dataIndex: 'tuesday', render: (tuesday) => {return(tuesday ? <CheckOutlined /> : <>-</>)} },
        { title: 'Wednesday', dataIndex: 'wednesday', render: (wednesday) => {return(wednesday ? <CheckOutlined /> : <>-</>)} },
        { title: 'Thursday', dataIndex: 'thursday', render: (thursday) => {return(thursday ? <CheckOutlined /> : <>-</>)} },
        { title: 'Friday', dataIndex: 'friday', render: (friday) => {return(friday ? <CheckOutlined /> : <>-</>)} },
        { title: 'Saturday', dataIndex: 'saturday', render: (saturday) => {return(saturday ? <CheckOutlined /> : <>-</>)} },
        { title: 'Sunday', dataIndex: 'sunday', render: (sunday) => {return(sunday ? <CheckOutlined /> : <>-</>)} },
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Work Programme Update" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Edit Work Programme</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center"><Col span={14}>
                    <Card title={<Title level={5}>{`Work Programme: ${description}`}<br/></Title>}>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Work Programme Code:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{workProgCode}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Plant Species:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{item}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Unit Amount:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{quantityItem}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Created On:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{createdOn}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Updated On:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{updatedOn}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Planned Work Day:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}><Table columns={columns} dataSource={[{key: 1, id: 1, ...workday}]} pagination={false}/></Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Active:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{ location.state.record.is_active ? <Tag color="success">{OTHERSYSPARAM("YES")}</Tag> : <Tag color="error">{OTHERSYSPARAM("NO")}</Tag> }</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '100%', textAlign: 'left', padding: paddingCardGrid}}>
                            <Collapse>
                                <Panel header="Planned Task" key="1">
                                    <WorkTaskTimeline dataSource={location.state.record} />
                                </Panel>
                            </Collapse>
                        </Card.Grid>
                    </Card>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Collapse>
                        <Panel header={<Title level={5}>Assigned Work Plan(s):</Title>} key="1">
                            {workPlanTableData ? <WorkPlanListTable dataSource={workPlanTableData} navigate={navigate}/> : <></>}
                        </Panel>
                    </Collapse>
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row justify="center"><Col span={12}>
                    <Divider orientation="left">Edit Work Programme Fields</Divider>
                    <Form form={form} onFinish={onSave} autoComplete="off">
                        <Form.Item initialValue={description} name="description" label="Description" rules={[{required: true, message: 'Please input the description for this Work Programme!'}]}>
                            <Input maxLength={50} allowClear={true} showCount />
                        </Form.Item>

                        <Form.Item valuePropName="is_work_programme_active" label="Is Work Programme Active">
                            <Switch onClick={onClickIsWorkProgrammeActive} defaultChecked={isWorkProgActive}/>
                        </Form.Item>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={12} offset={3}>
                                <Button htmlType="button" hidden={disableDeleteButton} disabled={disableDeleteButton} type="primary" onClick={onDelete} danger><DeleteFilled/>Delete</Button>
                                <Button htmlType="submit" hidden={disableSubmitButton} disabled={disableSubmitButton} type="primary"><SaveOutlined/>Save</Button>
                                <Button htmlType="button" disabled={disableCancelButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col></Row>
            </Content>
            <Footer><Row justify="center"><PageHeader title="Work Programme Update" subTitle="Edit Work Programme" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default WorkProgrammeUpdate;