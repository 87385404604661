import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Table, Collapse, Form, Button, Select, Spin, Typography, Modal, DatePicker, message } from 'antd';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, refreshUserSession, getUserSiteName } from "../Common/UserSession";
import axios from 'axios';
import { AGISAPIURL, UNIDATEFORMAT, UNIDATETIMEFORMAT, DATEFORMAT, LOADING, PAGESIZE, VALUE_NONE, MENUPATH_STOCKTRANSACTION, ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT,
    ITEM_GROUP_FERTILIZER, ITEM_GROUP_WATER, ITEM_GROUP_PEST_CONTROL, APPJSON } from '../Common/SysParameters';
import { reportError } from '../Common/Utility';
import { formLayout } from '../Common/Layout';
import { InfoCircleTwoTone, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import ButtonToBatchUpdatePage from '../Common/ButtonToBatchUpdatePage';


const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

const StockTransactionTable = () => {
    const FileDownload = require('js-file-download');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [taskTypeOption, setTaskTypeOption] = useState("");
    const [lifecycleOption, setLifecycleOption] = useState("");
    const [itemOption, setItemOption] = useState("");
    const [areaOption, setAreaOption] = useState("");
    const [stockTransactionTableData, setStockTransactionTableData] = useState([]);
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    const [area, setArea] = useState(0);
    const [item, setItem] = useState(0);
    const [taskType, setTaskType] = useState(0);
    const [lifecycle, setLifecycle] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [taskTypeVal, setTaskTypeVal] = useState("");
    const [itemVal, setItemVal] = useState("");
    const [areaVal, setAreaVal] = useState("");
    const [lifecycleVal, setLifecycleVal] = useState("");

    const SearchStockTransaction = (currentPage) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "inventorytransaction/", {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                item: item,
                area: area,
                lifecycle: lifecycle,
                task_type: taskType,
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectStockTransactionRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getTask = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "tasktype/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( tasktype => <Option key={tasktype.id} value={tasktype.id}>{tasktype.task}</Option>));
            setTaskTypeOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItem = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofileforyield/", {
            params: {
                item_group_list: [ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT, ITEM_GROUP_FERTILIZER, ITEM_GROUP_WATER, ITEM_GROUP_PEST_CONTROL],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
            setItemOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getArea = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "area/", {
            params: {
                site: getUserSiteId(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( area => {
                    let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                    return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                }));
            setAreaOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getLifecycle = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "lifecycle/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( lifecycle => <Option key={lifecycle.id} value={lifecycle.id}>{lifecycle.name}</Option>));
            setLifecycleOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    // Download pdf, excel search result.
    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(dateRange[0] != null) searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(taskType != 0) searchCriteria = `${searchCriteria}\nTask Type: ${taskTypeVal}`
        if(item != 0) searchCriteria = `${searchCriteria}\nItem: ${itemVal}`
        if(area != 0) searchCriteria = `${searchCriteria}\nArea (Zone): ${areaVal}`
        if(lifecycle != 0) searchCriteria = `${searchCriteria}\nLifecycle: ${lifecycleVal}`

        axios.get(`${AGISAPIURL}download/stocktransaction/`, {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                item: item,
                area: area,
                lifecycle: lifecycle,
                task_type: taskType,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Stock Transaction ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    };

    useEffect(() => {
        SearchStockTransaction(currentPage);
        getTask();
        getItem();
        getArea();
        getLifecycle();
    }, []);

    const collectStockTransactionRawData = ( response ) => {
        const data = []
        response.data.results.forEach( invTrans => {
            let quantity_item_secondary_uom = VALUE_NONE;
            let reference_id = VALUE_NONE;
            if (invTrans.quantity_item_secondary_uom) quantity_item_secondary_uom = invTrans.quantity_item_secondary_uom.unit;
            if (invTrans.reference_transaction) reference_id = invTrans.reference_transaction;

            data.push({
                key: invTrans.id,
                id: invTrans.id,
                timestamp: moment(invTrans.timestamp).format(UNIDATETIMEFORMAT),
                quantity_item: parseInt(invTrans.quantity_item),
                quantity_item_secondary: parseFloat(invTrans.quantity_item_secondary),
                area: invTrans.area.area_code,
                batch: invTrans.batch.batch_code,
                batch_id: invTrans.batch.id,
                item_item: invTrans.item_item.description,
                item_tool: invTrans.item_tool.item_code,
                quantity_item_secondary_uom: quantity_item_secondary_uom,
                task_type: invTrans.task_type.task,
                lifecycle: invTrans.lifecycle.name,
                remark: invTrans.remark,
                reference_id: reference_id,
            })
        });
        setStockTransactionTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const onChangeDateRange = (value) => {
        setDateRange(value);
    };

    const onChangeTaskType = (key, value) => {
        if (key !== undefined) {
            setTaskType(key);
            setTaskTypeVal(value.children);
        } else {
            setTaskType(0);
            setTaskTypeVal("");
        };
    };

    const onChangeItem = (key, value) => {
        if (key !== undefined) {
            setItem(key);
            setItemVal(value.children);
        } else {
            setItem(0);
            setItemVal("");
        };
    };

    const onChangeArea = (key, value) => {
        if (key !== undefined) {
            setArea(key);
            setAreaVal(value.children);
        } else {
            setArea(0);
            setAreaVal("");
        };
    };

    const onChangeLifecycle = (key, value) => {
        if (key !== undefined) {
            setLifecycle(key);
            setLifecycleVal(value.children);
        } else {
            setLifecycle(0);
            setLifecycleVal("");
        };
    };

    const onSearch = () => {
        setCurrentPage(1);
        SearchStockTransaction(1);
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onClickRemark = (e, remark) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Remark</h3></div>,
            icon: null,
            width: '30%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <>{remark}</>
        })
    };

    const onClickReferenceId = (e, referenceId) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Remark</h3></div>,
            icon: null,
            width: '30%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <><Text>Please Refer </Text><Text keyboard>Trans ID {referenceId}</Text></>
        })
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchStockTransaction(page);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Transaction ID', dataIndex: 'id', sorter: (a, b) => a.id - b.id },
        { title: 'Timestamp', dataIndex: 'timestamp', sorter: (a, b) => a.timestamp.localeCompare(b.timestamp) },
        { title: 'Task Type', dataIndex: 'task_type', sorter: (a, b) => a.task_type.localeCompare(b.task_type) },
        { title: 'Batch', dataIndex: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch), render: (batch, record) => {
            return <ButtonToBatchUpdatePage batchId={record.batch_id} buttonName={batch} navigate={navigate} lastPage={MENUPATH_STOCKTRANSACTION}/>
        }},
        { title: 'Lifecycle', dataIndex: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Item', dataIndex: 'item_item', sorter: (a, b) => a.item_item.localeCompare(b.item_item) },
        { title: 'Medium', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Area', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'Quantity(s)', dataIndex: 'quantity_item', align: 'right', sorter: (a, b) => a.quantity_item - b.quantity_item },
        { title: 'Weight', dataIndex: 'quantity_item_secondary', align: 'right', sorter: (a, b) => a.quantity_item_secondary - b.quantity_item_secondary,
            render: (quantity_item_secondary, record) => {
                if (quantity_item_secondary) return <Text>{quantity_item_secondary} {record.quantity_item_secondary_uom}</Text>
                else return <Text>{quantity_item_secondary}</Text>
        }},
        { title: 'Remark', dataIndex: 'remark', align: 'center', sorter: (a, b) => a.remark.localeCompare(b.remark), render: (remark, record) => {
            if (remark) return <InfoCircleTwoTone style={{ fontSize: 20 }} onClick={(e) => onClickRemark(e, remark)}/>
            else if (record.reference_id !== VALUE_NONE) return <InfoCircleTwoTone style={{ fontSize: 20 }} onClick={(e) => onClickReferenceId(e, record.reference_id)}/>
            else return <Text>{remark}</Text>
        }},
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search Stock Transaction" key="1">
                    <Form form={form} {...formLayout}>
                        <Form.Item initialValue={dateRange} name="daterange" label="Date Range">
                            <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                        </Form.Item>

                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item initialValue={item} name="taskType" label="Task Type">
                                    <Select onChange={onChangeTaskType} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {taskTypeOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={item} name="item" label="Item">
                                    <Select onChange={onChangeItem} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {itemOption}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item initialValue={area} name="area" label="Area (Zone)">
                                    <Select onChange={onChangeArea} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {areaOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={lifecycle} name="lifecycle" label="Lifecycle">
                                    <Select onChange={onChangeLifecycle} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {lifecycleOption}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>

            <Table columns={columns} dataSource={stockTransactionTableData}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: PAGESIZE,
                    current: currentPage,
                    total: totalRecord,
                    showTotal: showTotal,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                }}/>
        </Spin>
        </>
    );
};

export default StockTransactionTable;