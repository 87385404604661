import React from 'react';
import { useLocation } from "react-router-dom";
import { Layout, Row, Col, BackTop, PageHeader, Descriptions, Space } from 'antd';
import { UNIDATEFORMAT } from '../Common/SysParameters';
import moment from 'moment';
import { OTHERSYSPARAM } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import AGISFooter from '../Common/AGISFooter';
import { walltiles } from '../Common/Layout';
import YieldSummaryTable from './YieldSummaryTable';


const { Content } = Layout;

const YieldSummaryMain = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const defaultDateRange = location.state?.dateRange ? [moment(location.state?.dateRange[0], UNIDATEFORMAT), moment(location.state?.dateRange[1], UNIDATEFORMAT)] : [] ;
    const inclExpectedHarvest = location.state?.inclExpectedHarvest;
    const forecastOnWorkProg = location.state?.forecastOnWorkProg;
    const disableForecast = location.state?.disableForecast;

    return (
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight, ...walltiles}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Yield Summary Report">
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Generate Yield Summary Report</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify="center"><Col span={22}>
                    <YieldSummaryTable dateRangeDefault={defaultDateRange} inclExpectedHarvestDefault={inclExpectedHarvest} forecastOnWorkProgDefault={forecastOnWorkProg} disableForecastDefault={disableForecast} />
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

            </Content>
            <AGISFooter />
            <BackTop />
        </Layout>
    );
};

export default YieldSummaryMain;