import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from '@ant-design/plots';
import { Spin, Row, Col, Typography, Statistic, Card, Space } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, VALUE_NONE, ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT, MENUPATH_DASHBOARDWORKPROGRAMMEBYSPECIES } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from "axios";

const { Title } = Typography;

const DashboardChartsWorkProgramme = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [batchData, setBatchData] = useState([]);
    const [totalWorkProg, setTotalWorkProg] = useState(0);
    const [withWorkProg, setWithWorkProg] = useState(0);
    const [withoutWorkProg, setWithoutWorkProg] = useState(0);

    const SearchBatch = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batch/", {
            params: {
                site: getUserSiteId(),
                item_group_list: [ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT],
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectBatchRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message);
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectBatchRawData = ( response ) => {
        let tempData = {};
        let tempWorkProg = 0;
        let tempWithoutWorkProg = 0;
        let work_programme_list = [];
        const data = [];
        response.data.forEach( batch => {
            let work_programme = VALUE_NONE;
            if (batch.work_programme) {
                work_programme = batch.work_programme.work_programme_code;
                tempWorkProg += 1;
                if (!work_programme_list.includes(work_programme)) work_programme_list.push(work_programme);
            }
            else tempWithoutWorkProg += 1;

            if (tempData[batch.item.description] == undefined) {
                tempData[batch.item.description] = {};
                tempData[batch.item.description][work_programme] = {
                    key: batch.id,
                    id: batch.id,
                    item: batch.item.description,
                    item_id: batch.item.id,
                    work_programme: work_programme,
                    total_quantity_item: parseInt(batch.total_quantity_item),
                    count: 1,
                };
            }
            else if (tempData[batch.item.description][work_programme] == undefined) {
                tempData[batch.item.description][work_programme] = {
                    key: batch.id,
                    id: batch.id,
                    item: batch.item.description,
                    item_id: batch.item.id,
                    work_programme: work_programme,
                    total_quantity_item: parseInt(batch.total_quantity_item),
                    count: 1,
                };
            }
            else {
                tempData[batch.item.description][work_programme]["count"] += 1;
                tempData[batch.item.description][work_programme]["total_quantity_item"] += parseInt(batch.total_quantity_item);
            }
        });

        for (const key1 in tempData) {
            for (const key2 in tempData[key1]) {
                data.push(tempData[key1][key2]);
            };
        };

        setBatchData(data);
        setTotalWorkProg(work_programme_list.length);
        setWithWorkProg(tempWorkProg);
        setWithoutWorkProg(tempWithoutWorkProg);
    };

    const onClickChart = (plot) => {
        plot.on('element:click', (...args) => {
            navigate(MENUPATH_DASHBOARDWORKPROGRAMMEBYSPECIES, {state: {record: args[0].data.data}});
        });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchBatch();
    }, []);

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Row justify='center'>
                <Col span={24} style={{textAlign: "center"}}>
                    <Title level={3}>Batch Distribution across Work Programmes</Title>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Total Work Programmes</Title>}
                            value={totalWorkProg}
                            valueStyle={{ color: 'blue' }}
                        />
                    </Card>
                </Col>

                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Planned Active Batches</Title>}
                            value={withWorkProg}
                            valueStyle={{ color: 'green' }}
                        />
                    </Card>
                </Col>

                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Unplanned Active Batches</Title>}
                            value={withoutWorkProg}
                            valueStyle={{ color: 'red' }}
                        />
                    </Card>
                </Col>
            </Row>

            <Row><Col><Space><br/></Space></Col></Row>

            <Row justify='center'>
                <Col span={24}>
                    <Column
                        data={batchData}
                        xField='item'
                        yField='count'
                        isStack={true}
                        seriesField='work_programme'
                        onReady={onClickChart}
                        maxColumnWidth={100}
                        label={{
                            position: 'middle',
                            layout: [{type: 'adjust-color'}],
                            content: (value) => {return `${value.count} - ${value.total_quantity_item} Unit(s)`}
                        }}
                    />
                </Col>
            </Row>
        </Spin>
    );
};

export default DashboardChartsWorkProgramme;