import React, { useState, useEffect } from "react";
import { Table, Tag } from 'antd';
import { OTHERSYSPARAM } from '../Common/UserSession';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';

const WorkProgrammePriorityDragTable = ({form, defaultTableData, resetPriority}) => {
    const [workProgTableData, setWorkProgTableData] = useState(defaultTableData ? defaultTableData : []);

    const setPriority = (tableData) => {
        let priority = 1;

        tableData.forEach(workProg => {
            workProg.priority = priority;
            priority = priority + 1;
        });
        setWorkProgTableData(tableData);
        form.setFieldsValue({ work_programme_list: tableData });
    };

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setWorkProgTableData((previous) => {
                let updatedData = null;

                const activeIndex = previous.findIndex((i) => i.key === active.id);
                const overIndex = previous.findIndex((i) => i.key === over?.id);
                updatedData = arrayMove(previous, activeIndex, overIndex);

                setPriority(updatedData);
                return updatedData;
            });
        };
    };

    const Row = ({ children, ...props }) => {
        const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({id: props['data-row-key']});
        const style = {...props.style, transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }), transition, ...(isDragging ? { position: 'relative', zIndex: 1} : {})};

        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                { React.Children.map(children, (child) => {
                    if (child.key === 'sort') return React.cloneElement(child, {children: (<MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move'}} {...listeners}/>)})
                    return child;
                })}
            </tr>
        );
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if (resetPriority) setPriority(defaultTableData.sort((a, b) => a.priority - b.priority));
        form.setFieldsValue({ work_programme_list: defaultTableData });
    }, []);

    const columns = [
        { key: 'sort' },
        { title: 'Priority', dataIndex: 'priority', defaultSortOrder: 'ascend', editable: 1, sorter: (a, b) => a.priority - b.priority },
        { title: 'Work Programme Code', dataIndex: 'work_programme_code', sorter: (a, b) => a.work_programme_code.localeCompare(b.work_programme_code) },
        { title: 'Description', dataIndex: 'description', sorter: (a, b) => a.description.localeCompare(b.description) },
        { title: 'Plant Species', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Unit Amount', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item },
        { title: 'Active', dataIndex: 'is_active', sorter: (a, b) => a.is_active - b.is_active, render: (is_active) => {
            if (is_active) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
    ]

    return (
        <>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext items={workProgTableData.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                    <Table components={{body: { row: Row }}} rowKey="key" columns={columns} dataSource={workProgTableData} pagination={false}/>
                </SortableContext>
            </DndContext>
        </>
    );
};

export default WorkProgrammePriorityDragTable;