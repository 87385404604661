import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, message, Modal, Table, Input, InputNumber, Radio, Statistic, notification, Spin, Tag } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, refreshUserSession, getUserAuthToken } from '../Common/UserSession';
import { formLayoutWithTable } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_TRANSFERTOBATASCREATENEWPAGE1, TASK_TYPE_TRANSFER_TO_BATAS, AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE,
    UNIT_OF_MEASUREMENT_PERCENTAGE, UNIT_OF_MEASUREMENT_UNIT_SIGN, UNIT_OF_MEASUREMENT_UNIT, ITEM_GROUP_LEAFY_BATAS, ITEM_GROUP_FRUITY_BATAS, STATUS_FULLY_OCCUPIED, STATUS_BLOCKED,
    STATUS_REST, STATUS_RETIRED, STATUS_AVAILABLE, STATUS_EMPTY, STATUS_INPROGRESS, STATUS_PARTIALLY_OCCUPIED, LIFECYCLE_SEEDLING, WORK_PHASE_PLANTING, LOADING, VALUE_NONE,
    STATUS_TYPE_WARNING_LIST, RESTRICT_FLAG, WARNING_FLAG } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';
import { SaveOutlined, ExclamationCircleOutlined, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';
const EditableContext = React.createContext(null);


const { Option } = Select;
const { confirm, error } = Modal;
const { Content, Footer } = Layout;

const TransferToBatasCreateNewPage2 = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const prevRecord = location.state.record;
    const batch = prevRecord.batch;
    const item_item = prevRecord.item_item;
    const item_item_code = prevRecord.item_item_code;
    const item_location_from_list = prevRecord.item_location_from_list;
    const actual_completion_date = prevRecord.actualExecutedDate;
    const quantity_item_from_uom = prevRecord.quantity_item_from_uom;
    const default_area_to_list = prevRecord.area_to ? prevRecord.area_to : [];
    const default_item_location_to_list = prevRecord.item_location_to_list ? prevRecord.item_location_to_list : [];
    const default_quantity_item_to_uom = prevRecord.quantity_item_to_uom ? prevRecord.quantity_item_to_uom : UNIT_OF_MEASUREMENT_UNIT;
    const default_quantity_item_to_uom_sign = prevRecord.quantity_item_to_uom_sign ? prevRecord.quantity_item_to_uom_sign : UNIT_OF_MEASUREMENT_UNIT_SIGN;

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [areaOption, setAreaOption] = useState("");
    const [itemLocationToTableData, setItemLocationToTableData] = useState([]);
    const [unitOfMeasurementOption, setUnitOfMeasurementOption] = useState("");
    const [task_type, setTaskType] = useState(TASK_TYPE_TRANSFER_TO_BATAS);
    const [areaList, setAreaList] = useState(default_area_to_list);
    const [area_type_list, setAreaTypeList] = useState([AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE]);
    const [disableButton, setDisableButton] = useState("");
    const [disableUomButton, setDisableUomButton] = useState("");
    const [quantity_item_to_uom, setQuantityItemToUom] = useState(default_quantity_item_to_uom);
    const [quantity_item_to_uom_sign, setQuantityItemToUomSign] = useState(default_quantity_item_to_uom_sign);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);  // The selectRowKeys name shall not be change as this is required for Antd to control over the selected rows
    const quantity_item_label = "Amount of Plant To Transfer (Per Planting Medium) in ";
    const [unitToTransfer, setUnitToTransfer] = useState(0);
    const [actualUnitTransfer, setActualUnitTransfer] = useState(0);
    const [color, setColor] = useState('red');
    const [warningList, setWarningList] = useState([]);

    const createActivity = () => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "activity/create/transfertobatas/", {
            site: getUserSiteId(),
            batch: batch,
            task_type: task_type,
            item_location_to_list: form.getFieldValue('item_location_to_list'),
            item_location_from_list: item_location_from_list,
            actual_executed_datetime: actual_completion_date,
            lifecycle: LIFECYCLE_SEEDLING,
            work_phase: WORK_PHASE_PLANTING,
            quantity_tool_uom: UNIT_OF_MEASUREMENT_UNIT,
            quantity_item_from_uom: quantity_item_from_uom,
            quantity_item_to_uom: quantity_item_to_uom,
            status_type_batch: STATUS_INPROGRESS,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New activity ${response.data[0].id} created.`);
            // response.data[1].map(subactivity => message.info(`New sub-activity ${subactivity.id} created.`));
            // response.data[2].map(inventoryTransaction => message.info(`New inventory transaction ${inventoryTransaction.id} created.`));
            navigate(OTHERSYSPARAM("lastPage"));
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    let promiseGetArea = function () {
        return new Promise(function(myResolve, myReject) {
            setIsLoading(true);

            axios.get(AGISAPIURL + "area/", {
                params: {
                    site: getUserSiteId(),
                    area_type_list: area_type_list,
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`},
            })
            .then( response => {
                let options = response.data.results.map( area => {
                        let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                        return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                    });
                myResolve(options);
            })
            .catch( error => {
                myReject(error);
            })
            .finally(() => {
                setIsLoading(false);
                refreshUserSession();
            });
        });
    };

    const getItemLocationTo = ({areaList = [], uom = UNIT_OF_MEASUREMENT_UNIT}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "item/transfertobatas/", {
            params: {
                area_list: areaList,
                is_inventory_repository: 1,
                item_in_repository: item_item,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemLocationToRawData(response, uom);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectItemLocationToRawData = ( response, uom ) => {
        const data = []
        response.data.results.forEach( item => {
            data.push({
                key: item.id,
                id: item.id,
                item_code: item.item_code,
                description: item.description,
                item_area: item.area.area_code,
                status_type: item.status_type.description,
                status_type_id: item.status_type.id,
                quantity_tool: 1,
                quantity_item_each: 0,
                quantity_item_max: parseInt(item.available_amount_left),
                total_item: 0,
                planting_history: item.planting_history,
                same_plant_species: item.same_plant_species,
            });
        });
        setItemLocationToTableData(data);
    };

    const getUnitOfMeasurement = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemgroupuom/", {
            params: {
                item_group_list: [ITEM_GROUP_LEAFY_BATAS, ITEM_GROUP_FRUITY_BATAS],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = [];
            response.data.results.map( itemgroupuom => {
                if (itemgroupuom.unit_of_measurement.id === UNIT_OF_MEASUREMENT_UNIT || itemgroupuom.unit_of_measurement.id === UNIT_OF_MEASUREMENT_PERCENTAGE) {
                    options = options.concat(<Radio.Button key={itemgroupuom.unit_of_measurement.id} value={itemgroupuom.unit_of_measurement.id} label={itemgroupuom.unit_of_measurement.unit}>{itemgroupuom.unit_of_measurement.unit}</Radio.Button>);
                };
            });
            setUnitOfMeasurementOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    let promiseGetItemLocationTo = function (areaList, uom) {
        return new Promise(function(myResolve, myReject) {
            setIsLoading(true);

            axios.get(AGISAPIURL + "item/transfertobatas/", {
                params: {
                    area_list: areaList,
                    is_inventory_repository: 1,
                    item_in_repository: item_item,
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`},
            })
            .then( response => {
                const data = []
                response.data.results.forEach( item => {
                    data.push({
                        key: item.id,
                        id: item.id,
                        item_code: item.item_code,
                        description: item.description,
                        item_area: item.area.area_code,
                        status_type: item.status_type.description,
                        status_type_id: item.status_type.id,
                        quantity_tool: 1,
                        quantity_item_each: 0,
                        quantity_item_max: parseInt(item.available_amount_left),
                        total_item: 0,
                        planting_history: item.planting_history,
                        same_plant_species: item.same_plant_species,
                    });
                });
                myResolve(data);
            })
            .catch( error => {
                myReject(error);
            })
            .finally(() => {
                setIsLoading(false);
                refreshUserSession();
            });
        })
    };

    const updateTotalUnitToTransfer = (locationFromList, actualUnit) => {
        let totalUnit = 0;
        locationFromList.forEach(item => totalUnit = totalUnit + item.total_item);
        setUnitToTransfer(totalUnit);
        setColorCode(actualUnit, totalUnit);
        return totalUnit;
    };

    const updateTotalActualUnitToTransfer = (locationToList, expectedUnit) => {
        let totalUnit = 0;
        locationToList.forEach(item => totalUnit = totalUnit + item.total_item);
        setActualUnitTransfer(totalUnit);
        setColorCode(totalUnit, expectedUnit);
    };

    const onChangeArea = (value) => {
        setAreaList(value);
        onChangeItemLocationTo([]);
        getItemLocationTo({ areaList: value, uom: quantity_item_to_uom });
    };

    const onChangeQuantityItemUom = (e) => {
        setQuantityItemToUom(e.target.value);
        setQuantityItemToUomSign(e.target.label);
        onChangeItemLocationTo([]);
        getItemLocationTo({ areaList: areaList, uom: e.target.value });
    };

    const onChangeItemLocationTo = (newSelectedRowKeys) => {
        let list = []
        newSelectedRowKeys.forEach( rowKey => {
            const indexLoc = itemLocationToTableData.findIndex((location) => rowKey === location.id);
            list.push({
                id: rowKey,
                item_code: itemLocationToTableData[indexLoc]["item_code"],
                status_type: itemLocationToTableData[indexLoc]["status_type"],
                status_type_id: itemLocationToTableData[indexLoc]["status_type_id"],
                quantity_tool: itemLocationToTableData[indexLoc]['quantity_tool'],
                quantity_item_each: itemLocationToTableData[indexLoc]['quantity_item_each'],
                quantity_item_max: itemLocationToTableData[indexLoc]['quantity_item_max'],
                total_item: itemLocationToTableData[indexLoc]['total_item'],
                planting_history: itemLocationToTableData[indexLoc]['planting_history'],
                same_plant_species: itemLocationToTableData[indexLoc]['same_plant_species'],
            });
        });

        form.setFieldsValue({
            item_location_to_list: list,
        });

        updateTotalActualUnitToTransfer(list, unitToTransfer);
        setSelectedRowKeys(newSelectedRowKeys);
        checkIfNonComplianceBatasSelected(list);
    };

    const setColorCode = (actualValue, expectedValue) => {
        if (actualValue < expectedValue) setColor('red');
        else if (actualValue > expectedValue) setColor('orange');
        else if (actualValue == expectedValue) setColor('green');
    };

    const checkIfNonComplianceBatasSelected = (locationToList) => {
        let newWarningList = []
        locationToList.forEach( location_to => {
            // Return warning if batas is non-empty or same plant species is transferred.
            if (STATUS_TYPE_WARNING_LIST.includes(location_to["status_type_id"]) || location_to["same_plant_species"]) {
                newWarningList.push({
                    id: location_to["id"],
                    item_code: location_to["item_code"],
                    status_type: location_to["status_type"],
                    planting_history: location_to["planting_history"],
                });
            };
        });
        setWarningList(newWarningList);
    };

    const onSelectItemLocationTo = (record, selected, currentUnit) => {
        let quantity = 0;
        let quantityMax = record.quantity_item_max * record.quantity_tool;

        if (selected) {
            // Fill up the remaining plant units when selected
            if (currentUnit < unitToTransfer) {
                if (quantityMax <= (unitToTransfer - currentUnit)) { quantity = quantityMax }
                else if (quantityMax > (unitToTransfer - currentUnit)) { quantity = unitToTransfer - currentUnit }
            };
        };

        // Update table
        if (quantity_item_to_uom === UNIT_OF_MEASUREMENT_PERCENTAGE) { record.quantity_item_each = parseFloat(quantity / record.quantity_item_max * 100) / record.quantity_tool }
        else { record.quantity_item_each = quantity / record.quantity_tool }
        record.total_item = quantity;

        const newData = [...itemLocationToTableData];
        const index = newData.findIndex((item) => record.id === item.key);
        if (index > -1) {
            newData.splice(index, 1, { ...record });
            setItemLocationToTableData(newData);
        } else {
            newData.push(record);
            setItemLocationToTableData(newData);
        };

        return currentUnit + quantity;
    };

    const onSelectAllItemLocationTo = (selected, selectRows, changeRows) => {
        let currentUnit = actualUnitTransfer;
        changeRows.forEach(record => { currentUnit = onSelectItemLocationTo(record, selected, currentUnit) });
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeItemLocationTo,
        onSelect: (record, selected) => { onSelectItemLocationTo(record, selected, actualUnitTransfer) },
        onSelectAll: onSelectAllItemLocationTo,
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        let tmpUnitToTransfer = unitToTransfer;
        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getUnitOfMeasurement();
        tmpUnitToTransfer = updateTotalUnitToTransfer(item_location_from_list, actualUnitTransfer);

        if (location.state.record.area_to !== null) {
            promiseGetItemLocationTo(default_area_to_list, quantity_item_to_uom).then(
                function(value) {
                    form.setFieldsValue({ item_location_to_list: default_item_location_to_list });
                    updateTotalActualUnitToTransfer(default_item_location_to_list, tmpUnitToTransfer);

                    let newData = []
                    let selectedKeys = []
                    value.forEach(data => {
                        const index = default_item_location_to_list.findIndex((item) => data.key === item.id);
                        if (index > -1) {
                            data.item_code = default_item_location_to_list[index].item_code
                            data.status_type = default_item_location_to_list[index].status_type
                            data.status_type_id = default_item_location_to_list[index].status_type_id
                            data.quantity_tool = default_item_location_to_list[index].quantity_tool
                            data.quantity_item_each = default_item_location_to_list[index].quantity_item_each
                            data.quantity_item_max = default_item_location_to_list[index].quantity_item_max
                            data.total_item = default_item_location_to_list[index].total_item
                            selectedKeys.push(default_item_location_to_list[index].id)
                        }
                        newData.push(data);
                    })
                    setItemLocationToTableData(newData);
                    setSelectedRowKeys(selectedKeys);
                    checkIfNonComplianceBatasSelected(default_item_location_to_list);
                },
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            )
        }

    }, []);

    const onAreaClear = () => {
        form.setFieldsValue({
            item_location_to_list: "",
        })
        onChangeItemLocationTo([]);

        getItemLocationTo({});
    };

    const onBack = () => {
        prevRecord.area_to = form.getFieldValue('area_to');
        prevRecord.item_location_to_list = form.getFieldValue('item_location_to_list');
        prevRecord.quantity_item_to_uom = quantity_item_to_uom;
        prevRecord.quantity_item_to_uom_sign = quantity_item_to_uom_sign;
        navigate(MENUPATH_TRANSFERTOBATASCREATENEWPAGE1, {state: {record: prevRecord}});
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(OTHERSYSPARAM("lastPage")) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        if (actualUnitTransfer < unitToTransfer) {
            notification['error']({ placement: 'top', message: 'Unit To Transfer Is Not Fulfilled!', description: `Actual Units To Transfer: ${actualUnitTransfer}; Expected Units To Transfer: ${unitToTransfer}. Please select more planting mediums under the "Transfer To" table.` });
        }
        else if (actualUnitTransfer > unitToTransfer) {
            notification['error']({ placement: 'top', message: 'Excessive Unit To Transfer!', description: `The Actual Units To Transfer has exceeded the Expected Units To Transfer. Actual Units To Transfer: ${actualUnitTransfer}; Expected Units To Transfer: ${unitToTransfer}. Please de-select some of the planting mediums under the "Transfer To" table.` });
        }
        else if (RESTRICT_FLAG && warningList.length) {
            error({
                title: <p>Non-Compliance Batas has been selected! (Non-Empty or repeated plant species)</p>,
                content: (<>
                    <Space><p><b>Plant Species:</b> {item_item_code}</p></Space>
                    <Space><p><b>Medium - Status - Planting History:</b></p></Space>
                    {warningList.map( warningInfo => <p key={warningInfo["id"]}>{warningInfo["item_code"]} - {warningInfo["status_type"]} - {warningInfo["planting_history"]}</p>)}
                </>),
                centered: true
            });
        }
        else if (WARNING_FLAG && warningList.length) {
            confirm({
                icon: <ExclamationCircleTwoTone />,
                title: <p>Non-Compliance Batas has been selected! (Non-Empty or repeated plant species)</p>,
                content: (<>
                    <Space><p>Please confirm you are selecting the correct batas and press 'Confirm To Proceed' with Transfer To Batas activity creation.</p></Space>
                    <Space><p><b>Plant Species:</b> {item_item_code}</p></Space>
                    <Space><p><b>Medium - Status - Planting History:</b></p></Space>
                    {warningList.map( warningInfo => <p key={warningInfo["id"]}>{warningInfo["item_code"]} - {warningInfo["status_type"]} - {warningInfo["planting_history"]}</p>)}
                </>),
                onOk() { createActivity() },
                onCancel() {},
                okText: "Confirm To Proceed",
                centered: true
            });
        }
        else {
            confirm({
                icon: <QuestionCircleTwoTone />,
                content: <Space><p>Create Transfer To Batas activity confirmed?</p></Space>,
                onOk() { createActivity() },
                onCancel() {},
                centered: true
            });
        };
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onChangeTable = () => {
        // To restore the item_location_to_list as changing the table pagination/sorting/filter will reset the fields in the form
        onChangeItemLocationTo(selectedRowKeys);
    };

    const EditableRow = ({ index, ...props }) => {
        const [form1] = Form.useForm();
        return (
            <Form form={form1} component={false}>
                <EditableContext.Provider value={form1}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ editable, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = <></>
        let errorMessage = ""
        const form1 = useContext(EditableContext);

        const save = async () => {
            try {
                let tempKeyList = selectedRowKeys.slice(0);
                if (tempKeyList.indexOf(record.key) === -1) {
                    tempKeyList.push(record.key);
                    onChangeItemLocationTo(tempKeyList);
                };

                const row = await form1.validateFields();
                const newData = [...itemLocationToTableData];
                const newDataIndex = newData.findIndex((item) => record.key === item.key);
                if (quantity_item_to_uom === UNIT_OF_MEASUREMENT_UNIT) {
                    row['total_item'] = newData[newDataIndex]['quantity_tool'] * row['quantity_item_each'];
                }
                else if (quantity_item_to_uom === UNIT_OF_MEASUREMENT_PERCENTAGE) {
                    row['total_item'] = newData[newDataIndex]['quantity_tool'] * row['quantity_item_each'] * parseInt(newData[newDataIndex]['quantity_item_max']) / 100;
                }
                else {
                    row['total_item'] = VALUE_NONE;
                };

                if (newDataIndex > -1) {
                    const item = newData[newDataIndex];
                    newData.splice(newDataIndex, 1, { ...item, ...row });
                    setItemLocationToTableData(newData);
                } else {
                    newData.push(row);
                    setItemLocationToTableData(newData);
                }

                // Update form data with location list
                const newLocationList = form.getFieldValue('item_location_to_list');
                const indexLoc = newLocationList.findIndex((location) => record.key === location.id);
                newLocationList[indexLoc] = {
                    id: record.key,
                    item_code: newData[newDataIndex]["item_code"],
                    status_type: newData[newDataIndex]["status_type"],
                    status_type_id: newData[newDataIndex]["status_type_id"],
                    quantity_tool: newData[newDataIndex]['quantity_tool'],
                    quantity_item_each: row['quantity_item_each'],
                    quantity_item_max: row['quantity_item_max'],
                    total_item: row['total_item'],
                    planting_history: newData[newDataIndex]['planting_history'],
                    same_plant_species: newData[newDataIndex]['same_plant_species'],
                };

                // Update Actual Units To Transfer
                let totalUnit = 0;
                tempKeyList.forEach( rowKey => {
                    const index = newData.findIndex((location) => rowKey === location.id);
                    totalUnit = totalUnit + newData[index]['total_item'];
                });
                setActualUnitTransfer(totalUnit);
                setColorCode(totalUnit, unitToTransfer);

            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        switch(title) {
            case quantity_item_label + "%":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={1} max={100} addonAfter={`% of ${record.quantity_item_max}`}/>;
                errorMessage = "with numbers only";
                break;
            case quantity_item_label + "KG":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={0.001}/>;
                errorMessage = "with numbers only";
                break;
            case quantity_item_label + "Unit":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={1} max={record.quantity_item_max} addonAfter={`/ ${record.quantity_item_max}`}/>;
                inputType = "integer";
                errorMessage = "with integer only";
                break;

            default:
                inputNode = inputType === 'number' ? <InputNumber onPressEnter={save} onBlur={save}/> : <Input onPressEnter={save} onBlur={save}/>;
        };

        let childNode = children;
        if (editable) {
            childNode = (
                <Form.Item initialValue={record.quantity_item_each} name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `Please Input ${title} ${errorMessage}!`, type: inputType}]}>
                    {inputNode}
                </Form.Item>
            );
        };
        return <td {...restProps}>{childNode}</td>;
    };

    const defaultColumns = [
        { title: 'Zone', dataIndex: 'item_area', sorter: (a, b) => a.item_area.localeCompare(b.item_area) },
        { title: 'Planting Medium(s)', dataIndex: 'item_code', sorter: (a, b) => a.item_code.localeCompare(b.item_code) },
        { title: 'Status', dataIndex: 'status_type', sorter: (a, b) => a.status_type.localeCompare(b.status_type),
            render: (status_type, record) => {
                if (record.status_type_id === STATUS_EMPTY) return <Tag color="success">{status_type}</Tag>
                else if (record.status_type_id === STATUS_PARTIALLY_OCCUPIED) return <Tag color="processing" style={{borderStyle: 'dashed'}}>{status_type}</Tag>
                else if (record.status_type_id === STATUS_FULLY_OCCUPIED) return <Tag color="processing">{status_type}</Tag>
                else if (record.status_type_id === STATUS_BLOCKED) return <Tag color="error">{status_type}</Tag>
                else if (record.status_type_id === STATUS_REST) return <Tag color="warning">{status_type}</Tag>
                else if (record.status_type_id === STATUS_RETIRED) return <Tag color="default">{status_type}</Tag>
                else if (record.status_type_id === STATUS_AVAILABLE) return <Tag color="success">{status_type}</Tag>
                else return <>{status_type}</>
            },
        },
        // { title: 'Quantity (Planting Medium)', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: quantity_item_label + quantity_item_to_uom_sign, dataIndex: 'quantity_item_each', editable: 1, sorter: (a, b) => a.quantity_item_each - b.quantity_item_each,
            render: (quantity_item_each, record) => {
                if (quantity_item_to_uom === UNIT_OF_MEASUREMENT_PERCENTAGE) return <span><b>{quantity_item_each}{quantity_item_to_uom_sign}</b> x {record.quantity_item_max}</span>;
                else return <span><b>{quantity_item_each}</b> / {record.quantity_item_max}</span>;
            },
        },
        { title: 'Total Units of Plant to Transfer', dataIndex: 'total_item', sorter: (a, b) => a.total_item - b.total_item,
            render: (total_item, record) => {
                return (<><b>{total_item}</b> / {record.quantity_tool * record.quantity_item_max}</>);
            },
        },
        { title: 'Planting History', dataIndex: 'planting_history', sorter: (a, b) => a.planting_history.localeCompare(b.planting_history) },
    ]

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Transfer To Batas Activity" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Transfer To Batas Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="end"><Col span={22}/><Col span={2} style={{textAlign: "center"}}><Statistic valueStyle={{ color: color }} title="Units To Transfer" value={actualUnitTransfer} suffix={`/ ${unitToTransfer}`} /></Col><Col span={2}/></Row>
                <Form form={form} onFinish={values => onSave(values)} {...formLayoutWithTable} autoComplete="off">
                    <Form.Item initialValue={default_area_to_list} name="area_to" label="Zone (Transfer To)">
                        <Select onChange={(value) => onChangeArea(value)} allowClear={true} mode='multiple' onClear={onAreaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {areaOption}
                        </Select>
                    </Form.Item>

                    <Form.Item initialValue={default_quantity_item_to_uom} name="quantity_item_to_uom" label="UOM of Plant Transferred">
                        <Radio.Group onChange={onChangeQuantityItemUom} buttonStyle="solid" disabled={disableUomButton}>
                            {unitOfMeasurementOption}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name="item_location_to_list" label="Transfer To" rules={[{required: true, message: 'Please select at least one Batas Transfer Location!'}]} tooltip={{ title: "Please select a Transfer Zone first", icon: <ExclamationCircleTwoTone/>}}>
                        <Table components={{ body: {row: EditableRow, cell: EditableCell} }} rowClassName={() => 'editable-row'} rowSelection={rowSelection} columns={columns} dataSource={itemLocationToTableData} onChange={onChangeTable} pagination={{ size: 'small',  position: ["bottomCenter"], total: itemLocationToTableData.length, showTotal: showTotal }}/>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><SaveOutlined/>Save</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Transfer To Batas Activity" subTitle="Create New Transfer To Batas Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default TransferToBatasCreateNewPage2;