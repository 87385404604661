import React,  { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import { AGISAPIURL, LOADING } from "./SysParameters";
import { reportError } from "./Utility";
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from "./UserSession";
import axios from "axios";

const { Option } = Select;

const StatusTypeSelect = ({withBlank, onChange, is_batch="", is_item="", is_task="", is_area=""}) => {
    const [option, setOption] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getStatusType = (is_batch, is_item, is_task, is_area) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "statustype/", {
            params: {
                is_batch: is_batch,
                is_item: is_item,
                is_task: is_task,
                is_area: is_area,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( statustype => <Option key={statustype.id} value={statustype.id}>{statustype.description}</Option>));
            setOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (key, value) => {
        if(value !== undefined)
            onChange(key, value);
        else
            onChange(0, "");
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getStatusType(is_batch, is_item, is_task, is_area);
    }, []);

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Select onChange={onSelectionChange} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                {option}
            </Select>
        </Spin>
        </>
    );
};

export default StatusTypeSelect;