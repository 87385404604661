import React from 'react';
import { Table, Typography } from 'antd';
import { VALUE_NONE, UNIT_OF_MEASUREMENT_KILOGRAM_SIGN } from '../Common/SysParameters';
import { ExclamationCircleTwoTone } from '@ant-design/icons';


const { Text } = Typography;

const YieldItemTable = ({dataSource}) => {

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const footer = () => {
        return (
            <>
                <Text><ExclamationCircleTwoTone twoToneColor="#eb2f96" /> Note: The Expected Yield is calculated using the average yield based on planting bed ratios</Text>
                <Text strong> (For Germination Lifecycle only)</Text>
            </>
        );
    };

    const columns = [
        { title: 'Medium', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Medium Type', dataIndex: 'item_tool_type', sorter: (a, b) => a.item_tool_type.localeCompare(b.item_tool_type) },
        { title: 'Quantity', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item,
            render: (quantity_item, record) =>  {
                if (record.quantity_item_uom != VALUE_NONE) return <Text>{quantity_item} {record.quantity_item_uom}</Text>
                else return <Text>{quantity_item}</Text>
            }
        },
        { title: 'Expected Yield', dataIndex: 'yield_amount_kg', sorter: (a, b) => a.yield_amount_kg - b.yield_amount_kg,
            render: (yield_amount_kg, record) => {
                if (record.is_average_yield_used) return <Text>{yield_amount_kg} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN} <ExclamationCircleTwoTone twoToneColor="#eb2f96" /></Text>
                else return <Text>{yield_amount_kg} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}</Text>
            }
        },
    ];

    return (
        <Table columns={columns} dataSource={dataSource} pagination={{size: "small", position: ["bottomCenter"], total: dataSource.length, showTotal: showTotal}} footer={footer}/>
    );
};

export default YieldItemTable;