import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from '@ant-design/plots';
import { Spin, Row, Col, Typography, DatePicker, Form, Button, Statistic, Card, Space, Checkbox, message } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, UNIDATEFORMAT, UNIT_OF_MEASUREMENT_KILOGRAM_SIGN, MENUPATH_DASHBOARDYIELDSUMMARYBYSPECIES, MENUPATH_YIELDSUMMARY } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import { ArrowRightOutlined } from '@ant-design/icons';
import moment from "moment";
import axios from "axios";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const DashboardChartsYieldSummary = () => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const inclExpectedHarvest = useRef(true);
    const forecastOnWorkProg = useRef(true);
    const disableForecast = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [yieldSummaryData, setYieldSummaryData] = useState([]);
    const [totalExpected, setTotalExpected] = useState(0);
    const [totalActual, setTotalActual] = useState(0);
    const [dateRange, setDateRange] = useState([moment(moment().subtract(1, "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);

    const SearchBatchYieldSummary = () => {
        setIsLoading(true);

        if (forecastOnWorkProg.current && !disableForecast.current) {
            axios.post(AGISAPIURL + "batchforecast/generate/", {
                site: getUserSiteId(),
                toDate: dateRange[1].format(UNIDATEFORMAT),
            }, {
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Batch Forecast generated.");
                SearchAllBatchSummary();
            })
            .catch( error => {
                reportError(error, "Failed to generate Batch Forecast. " + error.message)
            })
            .finally(() => {
                refreshUserSession();
            })
        }
        else SearchAllBatchSummary();
    };

    const SearchAllBatchSummary = () => {
        axios.get(AGISAPIURL + "allbatchwithforecastsummary/", {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                incl_expected_harvest: inclExpectedHarvest.current ? 1 : 0,
                forecast_on_work_programme: forecastOnWorkProg.current ? 1 : 0,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectBatchYieldData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectBatchYieldData = ( response ) => {
        let totalYieldBySpecies = {}
        const data = [];
        let tempExpected = 0;
        let tempActual = 0;
        response.data.forEach( batch => {
            let expected_yield = 0;
            if (batch.is_forecast) expected_yield = parseFloat(batch.all_sowed)
            else expected_yield = collectAllSowed(batch.all_sowed);
            tempExpected = tempExpected + expected_yield;
            tempActual = tempActual + parseFloat(batch.total_harvested_kg);

            // Consolidate total yield by species
            if (totalYieldBySpecies[batch.item.description] == undefined) { totalYieldBySpecies[batch.item.description] = {item_id: batch.item.id, expected_yield: 0, actual_harvested: 0, forecast_yield: 0} }
            if (batch.is_forecast) totalYieldBySpecies[batch.item.description]["forecast_yield"] += expected_yield
            else totalYieldBySpecies[batch.item.description]["expected_yield"] += expected_yield;
            totalYieldBySpecies[batch.item.description]["actual_harvested"] += parseFloat(batch.total_harvested_kg);
        });

        for (const [key, value] of Object.entries(totalYieldBySpecies)) {
            data.push({
                item: key,
                item_id: value["item_id"],
                name: "Expected",
                sub_name: "Forecast",
                total_yield: value["forecast_yield"],
                percentage_archived: parseFloat(value["forecast_yield"] / parseFloat(value["expected_yield"] + value["forecast_yield"])),
                dateRange: [dateRange[0].format(UNIDATEFORMAT), dateRange[1].format(UNIDATEFORMAT)],
            });

            data.push({
                item: key,
                item_id: value["item_id"],
                name: "Expected",
                sub_name: "Existing",
                total_yield: value["expected_yield"],
                percentage_archived: parseFloat(value["expected_yield"] / parseFloat(value["expected_yield"] + value["forecast_yield"])),
                dateRange: [dateRange[0].format(UNIDATEFORMAT), dateRange[1].format(UNIDATEFORMAT)],
            });

            data.push({
                item: key,
                item_id: value["item_id"],
                name: "Actual",
                sub_name: "Actual",
                total_yield: value["actual_harvested"],
                percentage_archived: parseFloat(value["actual_harvested"] / parseFloat(value["expected_yield"] + value["forecast_yield"])),
                dateRange: [dateRange[0].format(UNIDATEFORMAT), dateRange[1].format(UNIDATEFORMAT)],
            });
        };

        setYieldSummaryData(data);
        setTotalExpected(tempExpected);
        setTotalActual(tempActual);
    };

    const collectAllSowed = (response) => {
        let totalYield = 0;
        response.forEach( value => totalYield += parseFloat(value.yield_amount_kg));
        return totalYield;
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchBatchYieldSummary();
    }, []);

    const onChangeDateRange = (value) => {
        setDateRange(value);

        // The Forecast option is available only if future date has been selected (Include Today)
        if (moment(value[1], UNIDATEFORMAT).isBefore(moment(), 'day')) {
            disableForecast.current = true;

            // Uncheck the Forecast option when it is disabled
            forecastOnWorkProg.current = false;
            form.getFieldValue('options').splice(1, 1);
        }
        else disableForecast.current = false;
    };

    const onChange = (checkValues) => {
        if (checkValues.includes("incl_expected_harvest")) inclExpectedHarvest.current = true
        else inclExpectedHarvest.current = false;

        if (checkValues.includes("forecast_on_work_programme")) forecastOnWorkProg.current = true
        else forecastOnWorkProg.current = false;
    };

    const onApply = () => {
        SearchBatchYieldSummary();
    };

    const onClickChart = (plot) => {
        let isElementClick = false;

        plot.on('element:click', (...args) => {
            isElementClick = true;
            navigate(MENUPATH_DASHBOARDYIELDSUMMARYBYSPECIES, {state: {
                record: args[0].data.data,
                inclExpectedHarvest: inclExpectedHarvest.current,
                forecastOnWorkProg: forecastOnWorkProg.current,
                disableForecast: disableForecast.current,
            }});
        });

        plot.on('plot:click', () => {
            if (!isElementClick) navigate(MENUPATH_YIELDSUMMARY, {state: {
                dateRange: [dateRange[0].format(UNIDATEFORMAT), dateRange[1].format(UNIDATEFORMAT)],
                inclExpectedHarvest: inclExpectedHarvest.current,
                forecastOnWorkProg: forecastOnWorkProg.current,
                disableForecast: disableForecast.current,
            }});
        });
    };

    const linkToMoreDetails = () => {
        navigate(MENUPATH_YIELDSUMMARY, {state: {
            dateRange: [dateRange[0].format(UNIDATEFORMAT), dateRange[1].format(UNIDATEFORMAT)],
            inclExpectedHarvest: inclExpectedHarvest.current,
            forecastOnWorkProg: forecastOnWorkProg.current,
            disableForecast: disableForecast.current,
        }});
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Row justify='space-between'>
                <Col span={22} style={{textAlign: "center"}}>
                    <Title level={3}>Yield Summary</Title>
                    <Title level={5}>From {dateRange[0].format(UNIDATEFORMAT)} to {dateRange[1].format(UNIDATEFORMAT)}</Title>

                    <Space><br/></Space>

                    <Form form={form}>
                        <Row justify='center'>
                            <Col span={14} style={{textAlign: "left"}}>
                                <Form.Item initialValue={dateRange} name="daterange" label="Harvest Date Range">
                                    <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}} />
                                </Form.Item>

                                <Form.Item initialValue={(inclExpectedHarvest.current ? ["incl_expected_harvest"] : []).concat(forecastOnWorkProg.current ? ["forecast_on_work_programme"] : [])} name="options" label="Options">
                                    <Checkbox.Group options={[
                                        {label: 'Include In-Progress Batch(es) with Planned Harvest Date that falls within the above selected Harvest Date Range', value: 'incl_expected_harvest'},
                                        {label: 'Forecast future Batch(es) based on Work Plan (Include uncreated Batch(es) planned for today)', value: 'forecast_on_work_programme', disabled: disableForecast.current},
                                    ]} onChange={onChange} />
                                </Form.Item>
                            </Col>

                            <Col span={2}>
                                <Button onClick={onApply} type="primary">Apply</Button>
                                <Button onClick={linkToMoreDetails} type="link"><Text underline>More Details <ArrowRightOutlined /></Text></Button>
                            </Col>
                        </Row>
                    </Form>

                    <Column
                        data={yieldSummaryData}
                        isGroup={true}
                        isStack={true}
                        xField='item'
                        yField='total_yield'
                        seriesField='sub_name'
                        groupField='name'
                        legend={{
                            custom: true,
                            position: 'bottom',
                            items: [{
                                name: 'Forecast',
                                marker: { symbol: 'square', style: {fill: '#4096ff', opacity: 0.5} },
                            },
                            {
                                name: 'Existing',
                                marker: { symbol: 'square', style: {fill: '#4096ff'} },
                            },
                            {
                                name: 'Actual',
                                marker: { symbol: 'square', style: {fill: '#73d13d'} },
                            }],
                            itemName: {
                                formatter: (item) => {
                                    if (item === 'Forecast' || item === 'Existing') return `Expected (${item})`
                                    else return `${item}`
                                }
                            },
                        }}
                        marginRatio={0}
                        appendPadding={20}
                        onReady={onClickChart}
                        columnStyle={({ sub_name }) => {
                            if (sub_name === 'Forecast') return { fillOpacity: 0.5 }
                            else return { fillOpacity: 1 }
                        }}
                        color={({ sub_name }) => {
                            if (sub_name === 'Forecast' || sub_name === 'Existing') return '#4096ff'
                            else if (sub_name === 'Actual') return '#73d13d'
                            else return '#d9d9d9'
                        }}
                        // pattern={({ sub_name }) => {
                        //     if (sub_name === 'Forecast') return { type: 'line', cfg: {rotation: 135} }
                        //     else return { type: null }
                        // }}
                        label={{
                            position: 'middle',
                            layout: [{type: 'adjust-color'}],
                            formatter: (value) => `${(value.percentage_archived * 100).toFixed(1)}%`,
                        }}
                        tooltip={{
                            formatter: (datum) => ({
                                name: `${datum.name} (${datum.sub_name})`,
                                value: `${datum.total_yield.toFixed(2)} ${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}`
                            }),
                        }}
                    />
                </Col>

                <Col span={2}>
                    <Card bordered={false}><Statistic title={`Expected Yield (${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN})`} precision={2} value={totalExpected} valueStyle={{ color: 'blue' }} /></Card>
                    <Card bordered={false}><Statistic title={`Actual Harvested (${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN})`} precision={2} value={totalActual} valueStyle={{ color: 'green' }} suffix={`(${((totalActual / totalExpected) * 100).toFixed(2)}%)`} /></Card>
                    <Card bordered={false}><Statistic title={`Difference (${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN})`} precision={2} value={totalExpected - totalActual} valueStyle={{ color: 'red' }} suffix={`(${(((totalExpected - totalActual) / totalExpected) * 100).toFixed(2)}%)`} /></Card>
                </Col>
            </Row>
        </Spin>
    );
};

export default DashboardChartsYieldSummary;