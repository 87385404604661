import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Column } from '@ant-design/plots';
import { Spin, Row, Col, Layout, Space, PageHeader, Descriptions, Table, Tag } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, MENUPATH_DASHBOARD, STATUS_EMPTY, STATUS_PARTIALLY_OCCUPIED, STATUS_FULLY_OCCUPIED, STATUS_BLOCKED, STATUS_REST, STATUS_RETIRED, STATUS_AVAILABLE } from '../Common/SysParameters';
import AGISHeader from '../Common/AGISHeader';
import { reportError } from "../Common/Utility";
import axios from 'axios';

const { Content, Footer } = Layout;

const DashboardChartsBatasByZone = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const area = location.state.record.area;
    const area_id = location.state.record.area_id;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [batasData, setBatasData] = useState([]);

    const SearchBatasStatus = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofilebatas/", {
            params: {
                site: getUserSiteId(),
                area: area_id,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectBatasDetails(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectBatasDetails = ( response ) => {
        const data = []
        response.data.results.forEach( itemProfile => {
            data.push({
                key: itemProfile.id,
                id: itemProfile.id,
                item_tool: itemProfile.item.item_code,
                area: itemProfile.item.area.area_code,
                item_group: itemProfile.item_group.name,
                item_type: itemProfile.item_type.item_type_code,
                status_type: itemProfile.item.status_type.description,
                status_type_id: itemProfile.item.status_type.id,
                current_item_list: itemProfile.item.current_item_list,
                amount_occupied: parseInt(itemProfile.item.amount_occupied),
                amount_occupied_percentage: parseInt(itemProfile.item.percentage_occupied),

            });
        });
        setBatasData(data);
    };

    const onBack = () => {
        navigate(MENUPATH_DASHBOARD);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchBatasStatus();
    }, []);

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Planting Bed', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Planting Bed Group', dataIndex: 'item_group', sorter: (a, b) => a.item_group.localeCompare(b.item_group) },
        { title: 'Planting Bed Type', dataIndex: 'item_type', sorter: (a, b) => a.item_type.localeCompare(b.item_type) },
        { title: 'Status', dataIndex: 'status_type', sorter: (a, b) => a.status_type.localeCompare(b.status_type),
            render: (status_type, record) => {
                if (record.status_type_id === STATUS_EMPTY) return <Tag color="success">{status_type}</Tag>
                else if (record.status_type_id === STATUS_PARTIALLY_OCCUPIED) return <Tag color="processing" style={{borderStyle: 'dashed'}}>{status_type}</Tag>
                else if (record.status_type_id === STATUS_FULLY_OCCUPIED) return <Tag color="processing">{status_type}</Tag>
                else if (record.status_type_id === STATUS_BLOCKED) return <Tag color="error">{status_type}</Tag>
                else if (record.status_type_id === STATUS_REST) return <Tag color="warning">{status_type}</Tag>
                else if (record.status_type_id === STATUS_RETIRED) return <Tag color="default">{status_type}</Tag>
                else if (record.status_type_id === STATUS_AVAILABLE) return <Tag color="success">{status_type}</Tag>
                else return <>{status_type}</>
            },
        },
        { title: 'Plant(s)', dataIndex: 'current_item_list', sorter: (a, b) => a.current_item_list.localeCompare(b.current_item_list) },
        { title: 'Unit Plants', dataIndex: 'amount_occupied', sorter: (a, b) => a.amount_occupied - b.amount_occupied },
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title={`${area} - Planting Bed Distribution Chart`} onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">A column chart with slider showing the distribution of all planting bed in {area}</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Column
                        data={batasData}
                        xField='item_tool'
                        yField='amount_occupied_percentage'
                        label={{
                            position: 'middle',
                            layout: [{type: 'adjust-color'}],
                            formatter: (value) => `${value.amount_occupied}\n(${value.amount_occupied_percentage}%)`,
                        }}
                        slider={{ start: 0, end: 1 }}
                    />
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Table columns={columns} dataSource={batasData} pagination={{position: ["bottomCenter"], total: batasData.length, showTotal: showTotal}}/>
                </Col></Row>

            </Content>
            <Footer><Row justify="center"><PageHeader title={`${area} - Planting Bed Distribution Chart`} subTitle={`A column chart with slider showing the distribution of all planting bed in ${area}`} onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default DashboardChartsBatasByZone;