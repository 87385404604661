import React from "react";
import { Select } from 'antd';
import { OTHERSYSPARAM } from "./UserSession";

const { Option } = Select;

//----------
// Component
//----------
const TrueFalseSelect = ({withBlank, defaultSelect, onChange}) => {
    let options = []
    if(withBlank) options.push(<Option value={""} key={0}>{" "}</Option>);
    options.push(<Option value={true} key={1}>{OTHERSYSPARAM("YES")}</Option>);
    options.push(<Option value={false} key={2}>{OTHERSYSPARAM("NO")}</Option>);

    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (value) => {
        if(value !== undefined)
            onChange(value);
        else
            onChange("");
    };

    return (
        <Select defaultValue={defaultSelect} onChange={(value) => onSelectionChange(value)} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
            {options}
        </Select>
    );
};

export default TrueFalseSelect;