import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Bar } from '@ant-design/plots';
import { Spin, Row, Col, Typography, Statistic, Card, Space } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, MENUPATH_DAILYTODOTRANSFERTOBATAS, MENUPATH_DASHBOARDTRANSFERTOBATASBYSPECIES } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';

const { Title } = Typography;

const DashboardChartsTransferToBatas = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [dailyToDoTransferToBatasData, setDailyToDoTransferToBatasData] = useState([]);
    const [totalUnits, setTotalUnits] = useState(0);
    const [totalTrays, setTotalTrays] = useState(0);
    const [totalBatches, setTotalBatches] = useState(0);

    const SearchDailyToDoTransferToBatas = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "dailytodotransfertobatas/", {
            params: {
                site: getUserSiteId(),
                sorting_type: "plant_species",
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectDailyToDoTransferToBatas(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectDailyToDoTransferToBatas = ( response ) => {
        const tempData = {}
        const data = []
        let unitCnt = 0
        let trayCnt = 0
        let batch = []
        response.data.results.forEach( sowingSummary => {
            const [quantity_item, quantity_tool] = collectQuantityData(sowingSummary.item_location);
            if (tempData[sowingSummary.item_item.description] == undefined) {
                tempData[sowingSummary.item_item.description] = {
                    key: sowingSummary.id,
                    id: sowingSummary.id,
                    item_item: sowingSummary.item_item.description,
                    item_item_id: sowingSummary.item_item.id,
                    quantity_item: 0,
                    quantity_tool: 0,
                };
            };
            tempData[sowingSummary.item_item.description]["quantity_item"] += quantity_item;
            tempData[sowingSummary.item_item.description]["quantity_tool"] += quantity_tool;
            unitCnt += quantity_item;
            trayCnt += quantity_tool;
            if (!batch.includes(sowingSummary.batch.batch_code)) batch.push(sowingSummary.batch.batch_code);
        });

        for (const key in tempData) { data.push(tempData[key]) };
        setDailyToDoTransferToBatasData(data);
        setTotalUnits(unitCnt);
        setTotalTrays(trayCnt);
        setTotalBatches(batch.length);
    };

    const collectQuantityData = ( response ) => {
        let quantity_item = 0
        let quantity_tool = 0
        response.forEach( invTransSummary => {
            quantity_item += parseInt(invTransSummary.quantity_item);
            quantity_tool += parseFloat(invTransSummary.quantity_tool);
        });
        return [quantity_item, quantity_tool];
    };

    const onClickChart = (plot) => {
        let isElementClick = false;

        plot.on('element:click', (...args) => {
            isElementClick = true;
            navigate(MENUPATH_DASHBOARDTRANSFERTOBATASBYSPECIES, {state: {record: args[0].data.data}});
        });

        plot.on('plot:click', () => {
            if (!isElementClick) navigate(MENUPATH_DAILYTODOTRANSFERTOBATAS);
        });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchDailyToDoTransferToBatas();
    }, []);

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Row justify='center'>
                <Col span={24} style={{textAlign: "center"}}>
                    <Title level={5}>Ready For Transfer To Batas</Title>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Total Units To Transfer</Title>}
                            value={totalUnits}
                            valueStyle={{ color: 'green' }}
                        />
                    </Card>
                </Col>

                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Total Trays</Title>}
                            value={totalTrays}
                            valueStyle={{ color: 'orange' }}
                        />
                    </Card>
                </Col>

                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Total Batches</Title>}
                            value={totalBatches}
                            valueStyle={{ color: 'blue' }}
                        />
                    </Card>
                </Col>
            </Row>

            <Row><Col><Space><br/></Space></Col></Row>

            <Row justify='center'>
                <Col span={24}>
                    <Bar
                        data={dailyToDoTransferToBatasData}
                        xField='quantity_item'
                        yField='item_item'
                        seriesField='item_item'
                        onReady={onClickChart}
                        legend={false}
                        label={{
                            position: 'middle',
                            layout: [{type: 'adjust-color'}],
                            content: (value) => {return `${value.quantity_item} - ${Math.ceil(value.quantity_tool)} Tray(s)`}
                        }}
                    />
                </Col>
            </Row>
        </Spin>
    );
};

export default DashboardChartsTransferToBatas;