import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Column } from '@ant-design/plots';
import { Spin, Row, Col, Typography, Statistic, Card, Space } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, MENUPATH_DASHBOARDBATASBYZONE, STATUS_EMPTY, STATUS_FULLY_OCCUPIED, STATUS_PARTIALLY_OCCUPIED } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';

const { Title } = Typography;

const DashboardChartsBatas = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [batasData, setBatasData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalEmpty, setTotalEmpty] = useState(0);
    const [totalPartial, setTotalPartial] = useState(0);
    const [totalFullyOccupied, setTotalFullyOccupied] = useState(0);

    const SearchBatasStatus = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofilebatas/", {
            params: {
                site: getUserSiteId(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectBatasDetails(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectBatasDetails = ( response ) => {
        let totalQuantityByStatus = {}
        let data = []
        let totalCnt = 0
        let emptyCnt = 0
        let partialCnt = 0
        let fullyOccupiedCnt = 0
        response.data.results.forEach( itemProfile => {
            data.push({
                key: itemProfile.item.id,
                id: itemProfile.item.id,
                area: itemProfile.item.area.area_code,
                area_id: itemProfile.item.area.id,
                status_type: itemProfile.item.status_type.description,
                total_quantity: parseFloat(itemProfile.item.total_quantity),
            })

            // Consolidate total quantiy group by area and status
            if (totalQuantityByStatus[itemProfile.item.area.area_code] == undefined) { totalQuantityByStatus[itemProfile.item.area.area_code] = {} }
            if (totalQuantityByStatus[itemProfile.item.area.area_code][itemProfile.item.status_type.description] == undefined) { totalQuantityByStatus[itemProfile.item.area.area_code][itemProfile.item.status_type.description] = 0 }
            totalQuantityByStatus[itemProfile.item.area.area_code][itemProfile.item.status_type.description] += parseFloat(itemProfile.item.total_quantity);

            // Calculate Statistics
            if (itemProfile.item.status_type.id === STATUS_EMPTY) { emptyCnt += parseFloat(itemProfile.item.total_quantity) }
            else if (itemProfile.item.status_type.id === STATUS_PARTIALLY_OCCUPIED) { partialCnt += parseFloat(itemProfile.item.total_quantity) }
            else if (itemProfile.item.status_type.id === STATUS_FULLY_OCCUPIED) { fullyOccupiedCnt += parseFloat(itemProfile.item.total_quantity) }
            totalCnt += parseFloat(itemProfile.item.total_quantity);
        });

        for (const key in data) { data[key]["total_quantity_by_status"] = totalQuantityByStatus[data[key]["area"]][data[key]["status_type"]] };
        setBatasData(data);
        setTotalCount(totalCnt);
        setTotalEmpty(emptyCnt);
        setTotalPartial(partialCnt);
        setTotalFullyOccupied(fullyOccupiedCnt);
    };

    const onClickChart = (plot) => {
        plot.on('element:click', (...args) => {
            navigate(MENUPATH_DASHBOARDBATASBYZONE, {state: {record: args[0].data.data}});
        });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchBatasStatus();
    }, []);

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Row justify='center'>
                <Col span={24} style={{textAlign: "center"}}>
                    <Title level={5}>Status Of Planting Bed</Title>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Total Empty</Title>}
                            value={totalEmpty / totalCount * 100}
                            precision={2}
                            valueStyle={{ color: 'green' }}
                            prefix={`${totalEmpty} (`}
                            suffix="%)"
                        />
                    </Card>
                </Col>

                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Total Partially Occupied</Title>}
                            value={totalPartial / totalCount * 100}
                            precision={2}
                            valueStyle={{ color: 'orange' }}
                            prefix={`${totalPartial} (`}
                            suffix="%)"
                        />
                    </Card>
                </Col>

                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Total Fully Occuppied</Title>}
                            value={totalFullyOccupied / totalCount * 100}
                            precision={2}
                            valueStyle={{ color: 'red' }}
                            prefix={`${totalFullyOccupied} (`}
                            suffix="%)"
                        />
                    </Card>
                </Col>
            </Row>

            <Row><Col><Space><br/></Space></Col></Row>

            <Row justify='center'>
                <Col span={24}>
                    <Column
                        data={batasData}
                        isStack={true}
                        isPercent={true}
                        xField='area'
                        yField='total_quantity'
                        seriesField='status_type'
                        appendPadding={20}
                        maxColumnWidth={100}
                        onReady={onClickChart}
                        label={{
                            position: 'middle',
                            layout: [{type: 'adjust-color'}],
                            formatter: (value) => `${value.total_quantity_by_status} (${(value.total_quantity * 100).toFixed(1)}%)`,
                        }}
                    />
                </Col>
            </Row>
        </Spin>
    );
};

export default DashboardChartsBatas;