import React , { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Table, Spin, Button, Tag, Modal, Typography, Form, DatePicker, Collapse, Select, Tooltip, Checkbox, message } from 'antd';
import { AGISAPIURL, LOADING, PAGESIZE, DATEFORMAT, UNIDATEFORMAT, UNIDATETIMEFORMAT, VALUE_NONE, UNIT_OF_MEASUREMENT_KILOGRAM_SIGN, ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT,
    MENUPATH_YIELDSUMMARY, APPJSON } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, getUserSiteId, getUserSiteName, refreshUserSession } from '../Common/UserSession';
import { reportError } from '../Common/Utility';
import moment from 'moment';
import axios from 'axios';
import { formLayout } from '../Common/Layout';
import { InfoCircleTwoTone, QuestionCircleTwoTone, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import YieldItemTable from './YieldItemTable';
import TrueFalseSelect from '../Common/TrueFalseSelect';
import BatchDistributionTable from '../Batch/BatchDistributionTable';
import BatchActivitiesSummaryTable from '../Batch/BatchActivitiesSummaryTable';
import ButtonToBatchUpdatePage from '../Common/ButtonToBatchUpdatePage';


const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

const YieldSummaryTable = ({ dateRangeDefault = [], inclExpectedHarvestDefault = true, forecastOnWorkProgDefault = true, disableForecastDefault = false }) => {
    const FileDownload = require('js-file-download');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemOption, setItemOption] = useState("");
    const [workProgOption, setWorkProgOption] = useState("");
    const [lifecycleOption, setLifecycleOption] = useState("");
    const [batchYieldTableData, setBatchYieldTableData] = useState([]);
    const [dateRange, setDateRange] = useState(dateRangeDefault.length ? dateRangeDefault : [moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    const [item, setItem] = useState(0);
    const [lifecycle, setLifecycle] = useState(0);
    const [workProg, setWorkProg] = useState(0);
    const [isActive, setIsActive] = useState("");
    const [isHarvested, setIsHarvested] = useState("");
    const [inclExpectedHarvest, setInclExpectedHarvest] = useState(inclExpectedHarvestDefault);
    const [forecastOnWorkProg, setForecastOnWorkProg] = useState(forecastOnWorkProgDefault);
    const [disableForecast, setDisableForecast] = useState(disableForecastDefault);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingItem, setIsLoadingItem] = useState(false);
    const [isLoadingWorkProg, setIsLoadingWorkProg] = useState(false);
    const [isLoadingLifecycle, setIsLoadingLifecycle] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [itemVal, setItemVal] = useState("");
    const [lifecycleVal, setLifecycleVal] = useState("");
    const [workProgVal, setWorkProgVal] = useState("");

    const SearchBatchYieldSummary = ({currentPage = "", generate = false}) => {
        setIsLoading(true);

        if (forecastOnWorkProg && generate && !disableForecast) {
            axios.post(AGISAPIURL + "batchforecast/generate/", {
                site: getUserSiteId(),
                toDate: dateRange[1].format(UNIDATEFORMAT),
            }, {
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Batch Forecast generated.");
                SearchAllBatchSummary(currentPage);
            })
            .catch( error => {
                reportError(error, "Failed to generate Batch Forecast. " + error.message)
            })
            .finally(() => {
                refreshUserSession();
            })
        }
        else SearchAllBatchSummary(currentPage);
    };

    const SearchAllBatchSummary = (currentPage) => {
        axios.get(AGISAPIURL + "allbatchwithforecastsummary/", {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                item: item,
                lifecycle: lifecycle,
                work_programme: workProg,
                is_active: (isActive !== "") ? ((isActive) ? 1 : 0) : "",
                is_harvested: (isHarvested !== "") ? ((isHarvested) ? 1 : 0) : "",
                incl_expected_harvest: inclExpectedHarvest ? 1 : 0,
                forecast_on_work_programme: forecastOnWorkProg ? 1 : 0,
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectBatchYieldData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItem = () => {
        setIsLoadingItem(true);

        axios.get(AGISAPIURL + "itemprofileforyield/", {
            params: {
                item_group_list: [ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
            setItemOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingItem(false);
            refreshUserSession();
        });
    };

    const getWorkProgramme = ({itemId = ""}) => {
        setIsLoadingWorkProg(true);

        axios.get(AGISAPIURL + "workprogrammeforyield/", {
            params: {
                site: getUserSiteId(),
                item: itemId,
                item_group_list: [ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options.push(<Option key={VALUE_NONE} value={VALUE_NONE}>{VALUE_NONE}</Option>); // None
            options = options.concat(response.data.results.map( itemprofileworkprog => <Option key={itemprofileworkprog.work_programme.id} value={itemprofileworkprog.work_programme.id}>{itemprofileworkprog.work_programme.description}</Option>));
            setWorkProgOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingWorkProg(false);
            refreshUserSession();
        });
    };

    const getLifecycle = ({itemId = ""}) => {
        setIsLoadingLifecycle(true);

        axios.get(AGISAPIURL + "lifecycleforyield/", {
            params: {
                item: itemId,
                item_group_list: [ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( lifecycle => <Option key={lifecycle.id} value={lifecycle.id}>{lifecycle.name}</Option>));
            setLifecycleOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingLifecycle(false);
            refreshUserSession();
        });
    };

    // Download pdf, excel search result.
    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(dateRange[0] != null) searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(item != 0) searchCriteria = `${searchCriteria}\nPlant Species: ${itemVal}`
        if(lifecycle != 0) searchCriteria = `${searchCriteria}\nLifecycle: ${lifecycleVal}`
        if(workProg != 0) searchCriteria = `${searchCriteria}\nWork Programme: ${workProgVal}`
        if(isActive !== "") searchCriteria = `${searchCriteria}\nIs Active: ${isActive ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}`
        if(isHarvested !== "") searchCriteria = `${searchCriteria}\nIs Harvested: ${isHarvested ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}`
        if(inclExpectedHarvest !== "") searchCriteria = `${searchCriteria}${inclExpectedHarvest ? '\nInclude In-Progress Batch(es) with Planned Harvest Date that falls within the above selected Harvest Date Range' : ''}`
        if(forecastOnWorkProg !== "") searchCriteria = `${searchCriteria}${forecastOnWorkProg ? '\nForecast future Batch(es) based on Work Plan (Include uncreated Batch(es) planned for today)' : ''}`

        axios.get(`${AGISAPIURL}download/yieldlisting/`, {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                item: item,
                lifecycle: lifecycle,
                work_programme: workProg,
                is_active: (isActive !== "") ? ((isActive) ? 1 : 0) : "",
                is_harvested: (isHarvested !== "") ? ((isHarvested) ? 1 : 0) : "",
                incl_expected_harvest: inclExpectedHarvest ? 1 : 0,
                forecast_on_work_programme: forecastOnWorkProg ? 1 : 0,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Yield Listing ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    };

    // Download summary excel report.
    const downloadSummaryReport = () => {
        setIsLoading(true);

        // Build search criteria string for display in excel
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(dateRange[0] != null) searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(item != 0) searchCriteria = `${searchCriteria}\nPlant Species: ${itemVal}`
        if(lifecycle != 0) searchCriteria = `${searchCriteria}\nLifecycle: ${lifecycleVal}`
        if(workProg != 0) searchCriteria = `${searchCriteria}\nWork Programme: ${workProgVal}`
        if(isActive !== "") searchCriteria = `${searchCriteria}\nIs Active: ${isActive ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}`
        if(isHarvested !== "") searchCriteria = `${searchCriteria}\nIs Harvested: ${isHarvested ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}`
        if(inclExpectedHarvest !== "") searchCriteria = `${searchCriteria}${inclExpectedHarvest ? '\nInclude In-Progress Batch(es) with Planned Harvest Date that falls within the above selected Harvest Date Range' : ''}`
        if(forecastOnWorkProg !== "") searchCriteria = `${searchCriteria}${forecastOnWorkProg ? '\nForecast future Batch(es) based on Work Plan (Include uncreated Batch(es) planned for today)' : ''}`

        axios.get(`${AGISAPIURL}download/yieldsummary/`, {
            params: {
                site: getUserSiteId(),
                site_name: getUserSiteName(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                item: item,
                lifecycle: lifecycle,
                work_programme: workProg,
                is_active: (isActive !== "") ? ((isActive) ? 1 : 0) : "",
                is_harvested: (isHarvested !== "") ? ((isHarvested) ? 1 : 0) : "",
                incl_expected_harvest: inclExpectedHarvest ? 1 : 0,
                forecast_on_work_programme: forecastOnWorkProg ? 1 : 0,
                search_criteria: searchCriteria,
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                FileDownload(response.data, `Yield Summary Report ${now}.xlsx`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download summary report.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    };

    useEffect(() => {
        SearchBatchYieldSummary({ currentPage: currentPage });
        getItem();
        getWorkProgramme({});
        getLifecycle({});
    }, []);

    const collectBatchYieldData = ( response ) => {
        const data = []
        response.data.results.forEach( batch => {
            let batch_id = VALUE_NONE;
            let actual_harvest_date = VALUE_NONE;
            let expected_completion_date = VALUE_NONE;
            let lifecycle = VALUE_NONE;
            let work_programme = VALUE_NONE;
            let all_sowed = VALUE_NONE;
            let quantity_uom = VALUE_NONE;
            let quantity = 0;
            let total_yield = 0;

            if (batch.is_forecast) {
                quantity_uom = batch.quantity_uom.unit;
                quantity = parseInt(batch.quantity);
                total_yield = parseFloat(batch.all_sowed).toFixed(2);
            }
            else {
                [all_sowed, total_yield] = collectAllSowed(batch.all_sowed);
            };

            if (batch.batch) batch_id = batch.batch.id;
            if (batch.actual_harvest_date) actual_harvest_date = moment(batch.actual_harvest_date).format(UNIDATEFORMAT);
            if (batch.expected_completion_date) expected_completion_date = moment(batch.expected_completion_date).format(UNIDATEFORMAT);
            if (batch.lifecycle) lifecycle = batch.lifecycle.name;
            if (batch.work_programme) work_programme = batch.work_programme.description;

            data.push({
                key: batch.id,
                id: batch.id,
                batch_id: batch_id,
                batch_code: batch.batch_code,
                item: batch.item.description,
                all_sowed: all_sowed,
                work_programme: work_programme,
                is_active: batch.is_active,
                lifecycle: lifecycle,
                is_harvested: batch.is_harvested,
                remark: batch.remark,
                actual_harvest_date: actual_harvest_date,
                expected_completion_date: expected_completion_date,
                total_harvested_kg: parseFloat(batch.total_harvested_kg),
                quantity: quantity,
                quantity_uom: quantity_uom,
                total_yield: total_yield,
                is_forecast: batch.is_forecast,
            })
        });
        setBatchYieldTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const collectAllSowed = (response) => {
        const data = [];
        let totalYield = 0;
        response.forEach( value => {
            data.push({
                id: value.id,
                key: value.id,
                item_tool: value.item_tool.description,
                item_tool_type: value.item_tool_type.item_type_code,
                quantity_item: parseFloat(value.quantity_item),
                quantity_item_uom: value.quantity_item_uom.unit,
                yield_amount_kg: parseFloat(value.yield_amount_kg).toFixed(2),
                is_average_yield_used: value.is_average_yield_used,
            });

            totalYield += parseFloat(value.yield_amount_kg);
        });
        return [data, totalYield.toFixed(2)];
    };

    const onChangeDateRange = (value) => {
        setDateRange(value);

        // The Forecast option is available only if future date has been selected (Include Today)
        if (moment(value[1], UNIDATEFORMAT).isBefore(moment(), 'day')) {
            setDisableForecast(true);

            // Uncheck the Forecast option when it is disabled
            setForecastOnWorkProg(false);
            form.getFieldValue('options').splice(1, 1);
        }
        else setDisableForecast(false);
    };

    const onChange = (checkValues) => {
        if (checkValues.includes("incl_expected_harvest")) setInclExpectedHarvest(true)
        else setInclExpectedHarvest(false);

        if (checkValues.includes("forecast_on_work_programme")) setForecastOnWorkProg(true)
        else setForecastOnWorkProg(false);
    };

    const onChangeItem = (key, value) => {
        if(key !== undefined){
            setWorkProg(0);
            setWorkProgVal("");
            setLifecycle(0);
            setLifecycleVal("");
            form.setFieldsValue({
                work_programme: "",
                lifecycle: "",
            })
            setItem(key);
            setItemVal(value.children);

            getWorkProgramme({ itemId: key });
            getLifecycle({ itemId: key });
        };
    };

    const onChangeLifecycle = (key, value) => {
        if (key !== undefined) {
            setLifecycle(key);
            setLifecycleVal(value.children);
        } else {
            setLifecycle(0);
            setLifecycleVal("");
        };
    };

    const onChangeWorkProgramme = (key, value) => {
        if (key !== undefined) {
            setWorkProg(key);
            setWorkProgVal(value.children);
        } else {
            setWorkProg(0);
            setWorkProgVal("");
        };
    };

    const onChangeIsActive = (value) => {
        setIsActive(value);
    };

    const onChangeIsHarvested = (value) => {
        setIsHarvested(value);
    };

    const onItemClear = () => {
        setWorkProg(0);
        setWorkProgVal("");
        setLifecycle(0);
        setLifecycleVal("");
        form.setFieldsValue({
            work_programme: "",
            lifecycle: "",
        })
        setItem(0);
        setItemVal("");

        getWorkProgramme({});
        getLifecycle({});
    };

    const onSearch = () => {
        setCurrentPage(1);
        SearchBatchYieldSummary({ currentPage: 1, generate: true });
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchBatchYieldSummary({ currentPage: page });
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columnTitleHarvestDate = (title) => {
        return (<Text>{title} <Tooltip placement='bottomLeft' title="The First Actual Harvesting Date"><QuestionCircleTwoTone /></Tooltip></Text>);
    };

    const columnTitleCompleted = (title) => {
        return (
            <>{title} <InfoCircleTwoTone onClick={(e) => showColumnDescriptionCompleted(e, title)} /></>
        );
    };

    const onClickAllSowed = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Batch: {record.batch_code} ({record.item})</h3>{record.work_programme !== VALUE_NONE ? <h3>Work Programme: {record.work_programme}</h3> : <></>}</div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <YieldItemTable dataSource={record.all_sowed}/>
        })
    };

    const showColumnDescriptionCompleted = (e, title) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Definition of the Column Title - {title}</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <>
                <p>For <b>Planting Batch</b>: If Yes, it indicates all plant in this batch has been fully <b>harvested</b>.</p>
                <p>For <b>Soil Maintenance Batch</b>: If Yes, it indicates all planting medium in this batch has been fully <b>released</b>.</p>
            </>
        });
    };

    //--------------------------
    // Show Batch Plant Distribution
    //--------------------------
    const showBatchPlantDistributionPopup = (e, record) => {
        const modal = Modal.info({
            title: <div><h3><b>BATCH OVERVIEW</b> - Batch ID: {record.batch_code}<ButtonToBatchUpdatePage batchId={record.batch_id} buttonName="Go to Batch Update Page" navigate={navigate} lastPage={MENUPATH_YIELDSUMMARY}/></h3></div>,
            icon: null,
            width: '70%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <><BatchDistributionTable batchId={record.batch_id} /><BatchActivitiesSummaryTable batchId={record.batch_id} /></>
        });
    };

    const columns = [
        { title: 'Batch Code', dataIndex: 'batch_code', sorter: (a, b) => a.batch_code.localeCompare(b.batch_code), render: (batch_code, record) => {
            if (record.is_forecast) return <Text><Tag bordered={true} color='cyan'>Forecast</Tag> {batch_code}</Text>
            else return <Text>{batch_code} <InfoCircleTwoTone onClick={(e) => showBatchPlantDistributionPopup(e, record)} /></Text>
        }},
        { title: 'Planned Harvest Date', dataIndex: 'expected_completion_date', sorter: (a, b) => a.expected_completion_date.localeCompare(b.expected_completion_date) },
        { title: columnTitleHarvestDate('Actual Harvest Date'), dataIndex: 'actual_harvest_date', sorter: (a, b) => a.actual_harvest_date.localeCompare(b.actual_harvest_date) },
        { title: 'Item', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Work Programme', dataIndex: 'work_programme', sorter: (a, b) => a.work_programme.localeCompare(b.work_programme) },
        { title: 'Active', dataIndex: 'is_active', sorter: (a, b) => a.is_active - b.is_active, render: (is_active) => {
            if (is_active) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
        { title: 'Life Cycle', dataIndex: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: columnTitleCompleted('Completed'), dataIndex: 'is_harvested', sorter: (a, b) => a.is_harvested - b.is_harvested, render: (is_harvested) => {
            if (is_harvested) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
        { title: 'Expected Yield', dataIndex: 'all_sowed', render: (all_sowed, record) => {
            if (record.is_forecast) return <Text>{record.total_yield} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN} <Tooltip title={`Based on ${record.quantity} ${record.quantity_uom} using average yield.`}><InfoCircleTwoTone twoToneColor="#FFCC00" /></Tooltip></Text>
            else return <Text>{record.total_yield} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN} <InfoCircleTwoTone twoToneColor="#FFCC00" onClick={(e) => onClickAllSowed(e, record)} /></Text>
        }},
        { title: 'Total Harvested', dataIndex: 'total_harvested_kg', sorter: (a, b) => a.total_harvested_kg - b.total_harvested_kg, render: (total_harvested_kg, record) => {
            if (record.total_yield > total_harvested_kg) return <Text type="danger">{total_harvested_kg} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}</Text>
            else return <Text type="success">{total_harvested_kg} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}</Text>
        }},
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search Batch" key="1">
                    <Form form={form} {...formLayout}>
                        <Form.Item initialValue={dateRange} name="daterange" label="Harvest Date Range">
                            <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                        </Form.Item>

                        <Form.Item initialValue={(inclExpectedHarvest ? ["incl_expected_harvest"] : []).concat(forecastOnWorkProg ? ["forecast_on_work_programme"] : [])} name="options" label="Options">
                            <Checkbox.Group options={[
                                {label: 'Include In-Progress Batch(es) with Planned Harvest Date that falls within the above selected Harvest Date Range', value: 'incl_expected_harvest'},
                                {label: 'Forecast future Batch(es) based on Work Plan (Include uncreated Batch(es) planned for today)', value: 'forecast_on_work_programme', disabled: disableForecast},
                            ]} onChange={onChange} />
                        </Form.Item>

                        <Row justify="center">
                            <Col span={12}>
                                <Spin spinning={isLoadingItem} size="large" tip={LOADING}>
                                    <Form.Item initialValue={item} name="item" label="Item">
                                        <Select onChange={onChangeItem} allowClear={true} onClear={onItemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                            {itemOption}
                                        </Select>
                                    </Form.Item>
                                </Spin>

                                <Spin spinning={isLoadingWorkProg} size="large" tip={LOADING}>
                                    <Form.Item initialValue={workProg} name="work_programme" label="Work Prog.">
                                        <Select onChange={onChangeWorkProgramme} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                            {workProgOption}
                                        </Select>
                                    </Form.Item>
                                </Spin>

                                <Spin spinning={isLoadingLifecycle} size="large" tip={LOADING}>
                                    <Form.Item initialValue={lifecycle} name="lifecycle" label="Lifecycle">
                                        <Select onChange={onChangeLifecycle} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                            {lifecycleOption}
                                        </Select>
                                    </Form.Item>
                                </Spin>
                            </Col>

                            <Col span={12}>
                                <Form.Item initialValue={isActive} name="isActive" label="Is Active">
                                    <TrueFalseSelect withBlank={true} defaultSelect={" "} onChange={onChangeIsActive}/>
                                </Form.Item>

                                <Form.Item initialValue={isHarvested} name="isHarvested" label="Is Completed">
                                    <TrueFalseSelect withBlank={true} defaultSelect={" "} onChange={onChangeIsHarvested}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>
            <Row><Col span={24} style={{textAlign: "right"}}><Button title='Download Summary Excel Report' type="primary" htmlType="button" onClick={downloadSummaryReport} icon={<FileExcelOutlined />}>Download Summary Report</Button></Col></Row>
            <Row><Col><Space><br/></Space></Col></Row>

            <Table columns={columns} dataSource={batchYieldTableData}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: PAGESIZE,
                    current: currentPage,
                    total: totalRecord,
                    showTotal: showTotal,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                }}/>

            <Row><Col span={24} style={{textAlign: "right"}}><Button title='Download Summary Excel Report' type="primary" htmlType="button" onClick={downloadSummaryReport} icon={<FileExcelOutlined />}>Download Summary Report</Button></Col></Row>
        </Spin>
    );
};

export default YieldSummaryTable;