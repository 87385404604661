import React from 'react';
import { Row, Col, Space } from 'antd';
import { Column } from '@ant-design/plots';
import { UNIT_OF_MEASUREMENT_KILOGRAM_SIGN } from '../Common/SysParameters';
import DashboardChartsYieldSummaryBatchTable from './DashboardChartsYieldSummaryBatchTable';


const DashboardChartsYieldSummaryBySpeciesByBatch = ({yieldSummaryData, yieldSummaryTableData, navigate}) => {

    return (
        <>
        <Row justify='center'><Col span={22}>
            <Column
                data={yieldSummaryData}
                isGroup={true}
                isStack={true}
                xField='batch'
                yField='total_yield'
                seriesField='sub_name'
                groupField='name'
                legend={{
                    custom: true,
                    position: 'bottom',
                    items: [{
                        name: 'Existing',
                        marker: { symbol: 'square', style: {fill: '#4096ff'} },
                    },
                    {
                        name: 'Actual',
                        marker: { symbol: 'square', style: {fill: '#73d13d'} },
                    },
                    {
                        name: 'Forecast',
                        marker: { symbol: 'square', style: {fill: '#4096ff', opacity: 0.5} },
                    }],
                    itemName: {
                        formatter: (item) => {
                            if (item === 'Forecast' || item === 'Existing') return `Expected (${item})`
                            else return `${item}`
                        }
                    },
                }}
                marginRatio={0}
                appendPadding={20}
                columnStyle={({ sub_name }) => {
                    if (sub_name === 'Forecast') return { fillOpacity: 0.5 }
                    else return { fillOpacity: 1 }
                }}
                color={({ sub_name }) => {
                    if (sub_name === 'Forecast' || sub_name === 'Existing') return '#4096ff'
                    else if (sub_name === 'Actual') return '#73d13d'
                    else return '#d9d9d9'
                }}
                // pattern={({ sub_name }) => {
                //     if (sub_name === 'Forecast') return { type: 'line', cfg: {rotation: 135} }
                //     else return { type: null }
                // }}
                label={{
                    position: 'top',
                    layout: [{type: 'adjust-color'}],
                    formatter: (value) => `${value.total_yield.toFixed(2)} ${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}`,
                }}
                tooltip={{
                    formatter: (datum) => ({
                        name: `${datum.name} (${datum.sub_name})`,
                        value: `${datum.total_yield.toFixed(2)} ${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}`
                    }),
                }}
                xAxis={{ label: { autoRotate: true, autoHide: false } }}
            />
        </Col></Row>

        <Row><Col><Space><br/></Space></Col></Row>

        <Row justify='center'><Col span={22}>
            <DashboardChartsYieldSummaryBatchTable tableData={yieldSummaryTableData} navigate={navigate}/>
        </Col></Row>
        </>
    );
};

export default DashboardChartsYieldSummaryBySpeciesByBatch;