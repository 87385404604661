import React,  { useEffect, useState } from "react";
import { Select, Form, Switch, Row, Col, Spin } from "antd";
import { AGISAPIURL, AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE, ITEM_GROUP_LEAFY_BATAS, ITEM_GROUP_FRUITY_BATAS, LOADING } from "../Common/SysParameters";
import { getUserSiteId, getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "../Common/UserSession";
import { reportError } from "../Common/Utility";
import TrueFalseSelect from "../Common/TrueFalseSelect";
import axios from "axios";

const { Option } = Select;

const SoilMaintenanceSelect = ({withBlank, form, defaultBatch, defaultItemArea, defaultItem, defaultSearchActiveBatch, defaultLifecycle, defaultTaskCategory, defaultTask, defaultIsBatchActive,
    onBatchChange, onItemAreaChange, onItemChange, onLifecycleChange, onTaskChange, onIsBatchActiveChange, useBatch = "",
    taskLabel = "Task", itemAreaLabel = "Area", itemLabel = "Item", activeBatchLabel = "Active Batch", batchLabel = "Batch Code", lifecycleLabel = "Life Cycle", isBatchActiveLabel = "Is Batch Active"}) => {
    const [batchOption, setBatchOption] = useState("");
    const [itemAreaOption, setItemAreaOption] = useState("");
    const [itemOption, setItemOption] = useState("");
    const [lifecycleOption, setLifecycleOption] = useState("");
    const [taskOption, setTaskOption] = useState("");
    const [item, setItem] = useState(defaultItem);
    const [itemArea, setItemArea] = useState(defaultItemArea);
    const [task, setTask] = useState(defaultTask);
    const [taskCategory, setTaskCategory] = useState(defaultTaskCategory);
    const [isActiveBatch, setIsActiveBatch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingItem, setIsLoadingItem] = useState(false);
    const [isLoadingBatch, setIsLoadingBatch] = useState(false);

    const showBatch = onBatchChange != null;
    const showItem = onItemChange != null;
    const showItemArea = onItemAreaChange != null;
    const showLifecycle = onLifecycleChange != null;
    const showTask = onTaskChange != null;

    const getBatch = ({itemId = "", isActive = "", itemArea = ""}) => {
        setIsLoadingBatch(true);

        axios.get(AGISAPIURL + "batch/", {
            params: {
                site: getUserSiteId(),
                item: itemId,
                item_group_list: [ITEM_GROUP_LEAFY_BATAS, ITEM_GROUP_FRUITY_BATAS],
                area: itemArea,
                is_active: (isActive !== "") ? ((isActive) ? 1 : 0) : "",
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.map( batch => <Option key={batch.id} value={batch.id}>{batch.batch_code}</Option>));
            setBatchOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingBatch(false);
            refreshUserSession();
        });
    };

    const getItemArea = (area_type_list = []) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "area/", {
            params: {
                site: getUserSiteId(),
                area_type_list: area_type_list,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( area => {
                    let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                    return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                }));
            setItemAreaOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItem = ({area_list = [], useBatchFlag = "", task_type = "", task_category = ""}) => {
        setIsLoadingItem(true);

        axios.get(AGISAPIURL + "itemprofilebytasktype/", {
            params: {
                area_list: area_list,
                use_batch: useBatchFlag,
                task_type: task_type,
                task_category: task_category,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
            setItemOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingItem(false);
            refreshUserSession();
        });
    };

    const getLifecycle = ({itemId = "", taskId = "", task_category = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "lifecyclebytasktypeitem/", {
            params: {
                item: itemId,
                task_type: taskId,
                task_category: task_category,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( lifecyclebytaskitem => <Option key={lifecyclebytaskitem.lifecycle.id} value={lifecyclebytaskitem.lifecycle.id}>{lifecyclebytaskitem.lifecycle.name}</Option>));
            setLifecycleOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getTask = (taskCategoryId) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "tasktype/", {
            params: {
                task_category: taskCategoryId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( tasktype => <Option key={tasktype.id} value={tasktype.id}>{tasktype.task}</Option>));
            setTaskOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const batchChange = (key, value) => {
        if(value !== undefined)
            onBatchChange(key, value);
        else
            onBatchChange(0, "");
    };

    const itemAreaChange = (key, value) => {
        if(value !== undefined)
            onItemAreaChange(key, value);
        else
            onItemAreaChange(0, "");
    };

    const itemChange = (key, value) => {
        if(value !== undefined)
            onItemChange(key, value);
        else
            onItemChange(0, "");
    };

    const lifecycleChange = (key, value) => {
        if(value !== undefined)
            onLifecycleChange(key, value);
        else
            onLifecycleChange(0, "");
    };

    const taskChange = (key, value) => {
        if(value !== undefined)
            onTaskChange(key, value);
        else
            onTaskChange(0, "");
    };

    const itemAreaClear = () => {
        onItemAreaChange(0, "");
        form.setFieldsValue({
            item: "",
            batch_code: "",
            lifecycle: "",
        })
        if (showItem) onItemChange(0, "");
        if (showBatch) onBatchChange(0, "");
        if (showLifecycle) onLifecycleChange(0, "");

        if (showItem) getItem({ useBatchFlag: useBatch, task_type: task, task_category: taskCategory });
        if (showBatch) getBatch({ isActive: isActiveBatch });
        if (showLifecycle) getLifecycle({ taskId: task, task_category: taskCategory });
    };

    const itemClear = () => {
        onItemChange(0, "");
        form.setFieldsValue({
            batch_code: "",
            lifecycle: "",
        })
        if (showBatch) onBatchChange(0, "");
        if (showLifecycle) onLifecycleChange(0, "");
        setItem();

        if (showBatch) getBatch({ isActive: isActiveBatch, itemArea: itemArea });
        if (showLifecycle) getLifecycle({ taskId: task, task_category: taskCategory });
    };

    const taskClear = () => {
        onTaskChange(0, "");
        form.setFieldsValue({
            item: "",
            batch_code: "",
            lifecycle: "",
        })
        if (showItem) onItemChange(0, "");
        if (showBatch) onBatchChange(0, "");
        if (showLifecycle) onLifecycleChange(0, "");
        setTask();

        if (showItem) getItem({ useBatchFlag: useBatch, task_category: taskCategory });
        if (showBatch) getBatch({ isActive: isActiveBatch, itemArea: itemArea });
        if (showLifecycle) getLifecycle({ task_category: taskCategory });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if (showItemArea) getItemArea([AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE]);
        if (showItem) getItem({ useBatchFlag: useBatch, task_type: defaultTask, task_category: defaultTaskCategory });
        if (showBatch) getBatch({ itemId: defaultItem, isActive: isActiveBatch, itemArea: defaultItemArea });
        if (showLifecycle) getLifecycle({ itemId: defaultItem, taskId: defaultTask, task_category: defaultTaskCategory });
        if (showTask) getTask(defaultTaskCategory);
    }, []);

    const onItemAreaSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                item: "",
                batch_code: "",
                lifecycle: "",
            })
            if (showItem) onItemChange(0, "");
            if (showBatch) onBatchChange(0, "");
            if (showLifecycle) onLifecycleChange(0, "");
            setItemArea(value);

            if (showItem) getItem({ area_list: [value], useBatchFlag: useBatch, task_type: task, task_category: taskCategory });
            if (showBatch) getBatch({ isActive: isActiveBatch, itemArea: value });
            if (showLifecycle) getLifecycle({ taskId: task, task_category: taskCategory });
        };
    };

    const onItemSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                batch_code: "",
                lifecycle: "",
            })
            if (showBatch) onBatchChange(0, "");
            if (showLifecycle) onLifecycleChange(0, "");
            setItem(value);

            if (showBatch) getBatch({ itemId: value, isActive: isActiveBatch, itemArea: itemArea });
            if (showLifecycle) getLifecycle({ itemId: value, taskId: task, task_category: taskCategory });
        };
    };

    const onActiveBatchClick = (value) => {
        form.setFieldsValue({
            batch_code: "",
        })
        if (showBatch) onBatchChange(0, "");
        setIsActiveBatch(value);

        if (showBatch) getBatch({ itemId: item, isActive: value, itemArea: itemArea });
    };

    const onTaskSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                item: "",
                batch_code: "",
                lifecycle: "",
            })
            if (showItem) onItemChange(0, "");
            if (showBatch) onBatchChange(0, "");
            if (showLifecycle) onLifecycleChange(0, "");
            setTask(value);

            if (showItem) getItem({ useBatchFlag: useBatch, task_type: value, task_category: taskCategory });
            if (showBatch) getBatch({ isActive: isActiveBatch, itemArea: itemArea });
            if (showLifecycle) getLifecycle({ taskId: value, task_category: taskCategory });
        };
    };

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Row justify="center">
                <Col span={12}>
                    <Form.Item initialValue={defaultTask} name="task" label={taskLabel} hidden={!showTask}>
                        <Select onChange={taskChange} onSelect={(value) => onTaskSelect(value)} allowClear={true} onClear={taskClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {taskOption}
                        </Select>
                    </Form.Item>

                    <Form.Item valuePropName="active_batch" label={activeBatchLabel} hidden={!showBatch}>
                        <Switch onClick={onActiveBatchClick} defaultChecked={defaultSearchActiveBatch}/>
                    </Form.Item>

                    <Spin spinning={isLoadingBatch} size="large" tip={LOADING}>
                        <Form.Item initialValue={defaultBatch} name="batch_code" label={batchLabel} hidden={!showBatch}>
                            <Select onChange={batchChange} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {batchOption}
                            </Select>
                        </Form.Item>
                    </Spin>

                    <Form.Item initialValue={defaultLifecycle} name="lifecycle" label={lifecycleLabel} hidden={!showLifecycle}>
                        <Select onChange={lifecycleChange} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {lifecycleOption}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item initialValue={defaultItemArea} name="item_area" label={itemAreaLabel} hidden={!showItemArea}>
                        <Select onChange={itemAreaChange} onSelect={(value) => onItemAreaSelect(value)} allowClear={true} onClear={itemAreaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemAreaOption}
                        </Select>
                    </Form.Item>

                    <Spin spinning={isLoadingItem} size="large" tip={LOADING}>
                        <Form.Item initialValue={defaultItem} name="item" label={itemLabel} hidden={!showItem}>
                            <Select onChange={itemChange} onSelect={(value) => onItemSelect(value)} allowClear={true} onClear={itemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {itemOption}
                            </Select>
                        </Form.Item>
                    </Spin>

                    <Form.Item initialValue={defaultIsBatchActive} name="isBatchActive" label={isBatchActiveLabel}>
                        <TrueFalseSelect withBlank={withBlank} defaultSelect={defaultIsBatchActive ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")} onChange={onIsBatchActiveChange}/>
                    </Form.Item>
                </Col>
            </Row>
        </Spin>
        </>
    );

};

export default SoilMaintenanceSelect;