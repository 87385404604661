import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LOGIN_PATH, MENUPATH_CONSOLIDATEDCONSOLE, MENUPATH_DASHBOARD, MENUPATH_DASHBOARDTRANSFERTOBATASBYSPECIES, MENUPATH_DASHBOARDHARVESTINGBYSPECIES, MENUPATH_DASHBOARDBATASBYZONE,
    MENUPATH_DASHBOARDSOWINGHISTORYBYSPECIES, MENUPATH_DASHBOARDWORKPROGRAMMEBYSPECIES, MENUPATH_DRONEVIEWDASHBOARD, MENUPATH_SOWING, MENUPATH_SOWINGCREATENEW, MENUPATH_SOWINGUPDATE,
    MENUPATH_TRANSFERTOBATAS, MENUPATH_TRANSFERTOBATASCREATENEWPAGE1, MENUPATH_TRANSFERTOBATASCREATENEWPAGE2, MENUPATH_TRANSFERTOBATASUPDATE, MENUPATH_HARVESTING, MENUPATH_HARVESTINGCREATENEWPAGE1,
    MENUPATH_HARVESTINGCREATENEWPAGE2, MENUPATH_HARVESTINGUPDATE, MENUPATH_BINRETURNS, MENUPATH_BINRETURNSCREATENEW, MENUPATH_BINRETURNSUPDATE, MENUPATH_CROPFAILURE, MENUPATH_CROPFAILUREUPDATE,
    MENUPATH_CROPFAILURECREATENEW, MENUPATH_FERTILIZATION, MENUPATH_FERTILIZATIONUPDATE, MENUPATH_FERTILIZATIONCREATENEW, MENUPATH_SOILMAINTENANCE, MENUPATH_SOILMAINTENANCEUPDATE,
    MENUPATH_SOILMAINTENANCECREATENEW, MENUPATH_SOILMAINTENANCERELEASEMEDIUM, MENUPATH_INVENTORYSUMMARY, MENUPATH_YIELDSUMMARY, MENUPATH_STOCKTRANSACTION, MENUPATH_DAILYTODOHARVEST,
    MENUPATH_DAILYTODOTRANSFERTOBATAS, MENUPATH_DAILYTODOSOWING, MENUPATH_ITEM, MENUPATH_ITEMCREATENEW, MENUPATH_ITEMUPDATE, MENUPATH_BATCH, MENUPATH_ACTIVITY, TEMPLATE_PATH,
    MENUPATH_BATCHCREATENEW, MENUPATH_BATCHUPDATE, MENUPATH_BATCHITEMSELECT, MENUPATH_ACTIVITYCREATENEW, MENUPATH_ACTIVITYUPDATE, MENUPATH_ACTIVITYSUMMARYREPORT, MENUPATH_PESTCONTROL,
    MENUPATH_PESTCONTROLCREATENEW, MENUPATH_PESTCONTROLUPDATE, MENUPATH_WORKPROGRAMME, MENUPATH_WORKPROGRAMMECREATENEW, MENUPATH_WORKPROGRAMMEUPDATE, MENUPATH_WORKPLAN, MENUPATH_WORKPLANCREATENEW,
    MENUPATH_WORKPLANUPDATE, MENUPATH_WORKPROGRAMMETRANSFERTABLE, MENUPATH_DASHBOARDYIELDSUMMARYBYSPECIES, MENUPATH_USER, MENUPATH_USERCREATENEW, MENUPATH_USERCHANGEPASSWORD, MENUPATH_USERUPDATE,
    MENUPATH_DASHBOARDPOWERBI } from "./Common/SysParameters";
import Login from "./Login";
import DashboardMain from "./Dashboard/DashboardMain";
import DashboardChartsTransferToBatasBySpecies from "./Dashboard/DashboardChartsTransferToBatasBySpecies";
import DashboardChartsSowingHistoryBySpecies from "./Dashboard/DashboardChartsSowingHistoryBySpecies";
import DashboardChartsYieldSummaryBySpecies from "./Dashboard/DashboardChartsYieldSummaryBySpecies";
import DashboardChartsHarvestingBySpecies from "./Dashboard/DashboardChartsHarvestingBySpecies";
import DashboardChartsBatasByZone from "./Dashboard/DashboardChartsBatasByZone";
import DashboardChartsWorkProgrammeBySpecies from "./Dashboard/DashboardChartsWorkProgrammeBySpecies";
import DashboardPowerBIMain from "./DashboardPowerBI/DashboardPowerBIMain";
import DroneViewDashboardMain from "./DroneViewDashboardMain";
import ConsolidatedConsoleMain from "./ConsolidatedConsoleMain";
import SowingMain from "./Sowing/SowingMain";
import SowingCreateNew from "./Sowing/SowingCreateNew";
import SowingUpdate from "./Sowing/SowingUpdate";
import TransferToBatasMain from "./TransferToBatas/TransferToBatasMain";
import TransferToBatasCreateNewPage1 from "./TransferToBatas/TransferToBatasNewPage1";
import TransferToBatasCreateNewPage2 from "./TransferToBatas/TransferToBatasNewPage2";
import TransferToBatasUpdate from "./TransferToBatas/TransferToBatasUpdate";
import HarvestingMain from "./Harvesting/HarvestingMain";
import HarvestingCreateNewPage1 from "./Harvesting/HarvestingNewPage1";
import HarvestingCreateNewPage2 from "./Harvesting/HarvestingNewPage2";
import HarvestingUpdate from "./Harvesting/HarvestingUpdate";
import BinReturnsMain from "./BinReturns/BinReturnsMain";
import BinReturnsCreateNew from "./BinReturns/BinReturnsCreateNew";
import BinReturnsUpdate from "./BinReturns/BinReturnsUpdate";
import CropFailureMain from "./CropFailure/CropFailureMain";
import CropFailureCreateNew from "./CropFailure/CropFailureCreateNew";
import CropFailureUpdate from "./CropFailure/CropFailureUpdate";
import FertilizationMain from "./Fertilization/FertilizationMain";
import FertilizationCreateNew from "./Fertilization/FertilizationCreateNew";
import FertilizationUpdate from "./Fertilization/FertilizationUpdate";
import PestControlMain from "./PestControl/PestControlMain";
import PestControlCreateNew from "./PestControl/PestControlCreateNew";
import PestControlUpdate from "./PestControl/PestControlUpdate";
import SoilMaintenanceMain from "./SoilMaintenance/SoilMaintenanceMain";
import SoilMaintenanceCreateNew from "./SoilMaintenance/SoilMaintenanceCreateNew";
import SoilMaintenanceUpdate from "./SoilMaintenance/SoilMaintenanceUpdate";
import SoilMaintenanceReleaseMedium from "./SoilMaintenance/SoilMaintenanceReleaseMedium";
import InventorySummaryMain from "./Report/InventorySummaryMain";
import YieldSummaryMain from "./Report/YieldSummaryMain";
import StockTransactionMain from "./Report/StockTransactionMain";
import DailyToDoHarvestMain from "./Report/DailyToDoHarvestMain";
import DailyToDoTransferToBatasMain from "./Report/DailyToDoTransferToBatasMain";
import DailyToDoSowingMain from "./Report/DailyToDoSowingMain";
import ItemMain from "./Item/ItemMain";
import ItemCreateNew from "./Item/ItemCreateNew";
import ItemUpdate from "./Item/ItemUpdate";
import BatchMain from "./Batch/BatchMain";
import BatchCreateNew from "./Batch/BatchCreateNew";
import BatchUpdate from "./Batch/BatchUpdate";
import BatchItemSelect from "./Batch/BatchItemSelect";
import WorkProgrammeMain from "./WorkProgramme/WorkProgrammeMain";
import WorkProgrammeCreateNew from "./WorkProgramme/WorkProgrammeCreateNew";
import WorkProgrammeUpdate from "./WorkProgramme/WorkProgrammeUpdate";
import WorkPlanMain from "./WorkPlan/WorkPlanMain";
import WorkPlanCreateNew from "./WorkPlan/WorkPlanCreateNew";
import WorkPlanUpdate from "./WorkPlan/WorkPlanUpdate";
import WorkProgrammeTransferTable from "./WorkPlan/WorkProgrammeTransferTable";
import ActivityMain from "./Activity/ActivityMain";
import ActivityCreateNew from "./Activity/ActivityCreateNew";
import ActivityUpdate from "./Activity/ActivityUpdate";
import ActivitySummaryMain from "./Report/ActivitySummaryMain";
import UserMain from "./Account/UserMain";
import UserCreateNew from "./Account/UserCreateNew";
import UserChangePassword from "./Account/UserChangePassword";
import UserUpdate from "./Account/UserUpdate";
import TemplatePage from "./TemplatePage";


const RounterManager = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path={LOGIN_PATH} element={<Login />} />

            <Route path={MENUPATH_DASHBOARD} element={<DashboardMain />} />
            <Route path={MENUPATH_DASHBOARDTRANSFERTOBATASBYSPECIES} element={<DashboardChartsTransferToBatasBySpecies />} />
            <Route path={MENUPATH_DASHBOARDSOWINGHISTORYBYSPECIES} element={<DashboardChartsSowingHistoryBySpecies />} />
            <Route path={MENUPATH_DASHBOARDYIELDSUMMARYBYSPECIES} element={<DashboardChartsYieldSummaryBySpecies />} />
            <Route path={MENUPATH_DASHBOARDHARVESTINGBYSPECIES} element={<DashboardChartsHarvestingBySpecies />} />
            <Route path={MENUPATH_DASHBOARDBATASBYZONE} element={<DashboardChartsBatasByZone />} />
            <Route path={MENUPATH_DASHBOARDWORKPROGRAMMEBYSPECIES} element={<DashboardChartsWorkProgrammeBySpecies />} />

            <Route path={MENUPATH_DASHBOARDPOWERBI} element={<DashboardPowerBIMain />} />
            <Route path={MENUPATH_DRONEVIEWDASHBOARD} element={<DroneViewDashboardMain />} />
            <Route path={MENUPATH_CONSOLIDATEDCONSOLE} element={<ConsolidatedConsoleMain />} />

            <Route path={MENUPATH_SOWING} element={<SowingMain />} />
            <Route path={MENUPATH_SOWINGCREATENEW} element={<SowingCreateNew />} />
            <Route path={MENUPATH_SOWINGUPDATE} element={<SowingUpdate />} />

            <Route path={MENUPATH_TRANSFERTOBATAS} element={<TransferToBatasMain />} />
            <Route path={MENUPATH_TRANSFERTOBATASCREATENEWPAGE1} element={<TransferToBatasCreateNewPage1 />} />
            <Route path={MENUPATH_TRANSFERTOBATASCREATENEWPAGE2} element={<TransferToBatasCreateNewPage2 />} />
            <Route path={MENUPATH_TRANSFERTOBATASUPDATE} element={<TransferToBatasUpdate />} />

            <Route path={MENUPATH_HARVESTING} element={<HarvestingMain />} />
            <Route path={MENUPATH_HARVESTINGCREATENEWPAGE1} element={<HarvestingCreateNewPage1 />} />
            <Route path={MENUPATH_HARVESTINGCREATENEWPAGE2} element={<HarvestingCreateNewPage2 />} />
            <Route path={MENUPATH_HARVESTINGUPDATE} element={<HarvestingUpdate />} />

            <Route path={MENUPATH_BINRETURNS} element={<BinReturnsMain />} />
            <Route path={MENUPATH_BINRETURNSCREATENEW} element={<BinReturnsCreateNew />} />
            <Route path={MENUPATH_BINRETURNSUPDATE} element={<BinReturnsUpdate />} />

            <Route path={MENUPATH_CROPFAILURE} element={<CropFailureMain />} />
            <Route path={MENUPATH_CROPFAILURECREATENEW} element={<CropFailureCreateNew />} />
            <Route path={MENUPATH_CROPFAILUREUPDATE} element={<CropFailureUpdate />} />

            <Route path={MENUPATH_FERTILIZATION} element={<FertilizationMain />} />
            <Route path={MENUPATH_FERTILIZATIONCREATENEW} element={<FertilizationCreateNew />} />
            <Route path={MENUPATH_FERTILIZATIONUPDATE} element={<FertilizationUpdate />} />

            <Route path={MENUPATH_PESTCONTROL} element={<PestControlMain />} />
            <Route path={MENUPATH_PESTCONTROLCREATENEW} element={<PestControlCreateNew />} />
            <Route path={MENUPATH_PESTCONTROLUPDATE} element={<PestControlUpdate />} />

            <Route path={MENUPATH_SOILMAINTENANCE} element={<SoilMaintenanceMain />} />
            <Route path={MENUPATH_SOILMAINTENANCECREATENEW} element={<SoilMaintenanceCreateNew />} />
            <Route path={MENUPATH_SOILMAINTENANCEUPDATE} element={<SoilMaintenanceUpdate />} />
            <Route path={MENUPATH_SOILMAINTENANCERELEASEMEDIUM} element={<SoilMaintenanceReleaseMedium />} />

            <Route path={MENUPATH_INVENTORYSUMMARY} element={<InventorySummaryMain />} />
            <Route path={MENUPATH_YIELDSUMMARY} element={<YieldSummaryMain />} />
            <Route path={MENUPATH_STOCKTRANSACTION} element={<StockTransactionMain />} />
            <Route path={MENUPATH_DAILYTODOHARVEST} element={<DailyToDoHarvestMain />} />
            <Route path={MENUPATH_DAILYTODOTRANSFERTOBATAS} element={<DailyToDoTransferToBatasMain />} />
            <Route path={MENUPATH_DAILYTODOSOWING} element={<DailyToDoSowingMain />} />
            <Route path={MENUPATH_ACTIVITYSUMMARYREPORT} element={<ActivitySummaryMain />} />

            <Route path={MENUPATH_ITEM} element={<ItemMain />} />
            <Route path={MENUPATH_ITEMCREATENEW} element={<ItemCreateNew />} />
            <Route path={MENUPATH_ITEMUPDATE} element={<ItemUpdate />} />

            <Route path={MENUPATH_BATCH} element={<BatchMain />} />
            <Route path={MENUPATH_BATCHCREATENEW} element={<BatchCreateNew />} />
            <Route path={MENUPATH_BATCHUPDATE} element={<BatchUpdate />} />
            <Route path={MENUPATH_BATCHITEMSELECT} element={<BatchItemSelect />} />

            <Route path={MENUPATH_WORKPROGRAMME} element={<WorkProgrammeMain />} />
            <Route path={MENUPATH_WORKPROGRAMMECREATENEW} element={<WorkProgrammeCreateNew />} />
            <Route path={MENUPATH_WORKPROGRAMMEUPDATE} element={<WorkProgrammeUpdate />} />

            <Route path={MENUPATH_WORKPLAN} element={<WorkPlanMain />} />
            <Route path={MENUPATH_WORKPLANCREATENEW} element={<WorkPlanCreateNew />} />
            <Route path={MENUPATH_WORKPLANUPDATE} element={<WorkPlanUpdate />} />
            <Route path={MENUPATH_WORKPROGRAMMETRANSFERTABLE} element={<WorkProgrammeTransferTable />} />

            <Route path={MENUPATH_ACTIVITY} element={<ActivityMain />} />
            <Route path={MENUPATH_ACTIVITYCREATENEW} element={<ActivityCreateNew />} />
            <Route path={MENUPATH_ACTIVITYUPDATE} element={<ActivityUpdate />} />

            <Route path={MENUPATH_USER} element={<UserMain />} />
            <Route path={MENUPATH_USERCREATENEW} element={<UserCreateNew />} />
            <Route path={MENUPATH_USERCHANGEPASSWORD} element={<UserChangePassword />} />
            <Route path={MENUPATH_USERUPDATE} element={<UserUpdate />} />

            <Route path={TEMPLATE_PATH} element={<TemplatePage />} />
        </Routes>
    </BrowserRouter>
);

export default RounterManager;