import React , { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Space, Table, Collapse, Button, DatePicker, Avatar, Badge, Modal, Spin } from 'antd';
import { AGISAPIURL, UNIDATEFORMAT, UNIDATETIMEFORMAT, MENUPATH_ACTIVITYCREATENEW, MENUPATH_ACTIVITYUPDATE, LOADING, VALUE_NONE, PAGESIZE } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, getUserSiteId, refreshUserSession } from '../Common/UserSession';
import { reportError } from '../Common/Utility';
import { formLayout } from '../Common/Layout';
import BatchSelect from '../Common/BatchSelect';
import AreaSelect from '../Common/AreaSelect';
import moment from 'moment';
import axios from 'axios';
import { PlusCircleTwoTone, CompassOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const ActivityTable = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    const [activityTableData, setActivityTableData] = useState([]);
    const [task_category, setTaskCategory] = useState(0);
    const [task_type, setTaskType] = useState(0);
    const [area, setArea] = useState(0);
    const [item_location_to, setItemLocationTo] = useState(0);
    const [item_location_from, setItemLocationFrom] = useState(0);
    const [batch, setBatch] = useState(0);
    const [item_item, setItemItem] = useState(0);
    const [searchActiveBatch, setSearchActiveBatch] = useState(true);
    const [lifecycle, setLifecycle] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const SearchActivity = (currentPage) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "activity/", {
            params: {
                site: getUserSiteId(),
                area: area,
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batch,
                item_item: item_item,
                item_location_from: item_location_from,
                item_location_to: item_location_to,
                task_type: task_type,
                task_category: task_category,
                lifecycle: lifecycle,
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectActivityRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    useEffect(() => {
        SearchActivity(currentPage);
    }, []);

    const collectActivityRawData = ( response ) => {
        const data = []
        response.data.results.forEach( activity => {
            let itemLocationFromCode = VALUE_NONE;
            let batchCode = VALUE_NONE;
            let batchId = "";
            if (activity.item_location_from) itemLocationFromCode = activity.item_location_from.description;
            if (activity.batch) {
                batchCode = activity.batch.batch_code;
                batchId = activity.batch.id;
            };

            data.push({
                key: activity.id,
                id: activity.id,
                timestamp: moment(activity.timestamp).format(UNIDATETIMEFORMAT),
                batch_id: batchId,
                batch: batchCode,
                item_item_id: activity.item_item.id,
                item_item: activity.item_item.description,
                task_type: activity.task_type.task,
                task_category: activity.task_type.task_category.description,
                lifecycle: activity.lifecycle.name,
                work_phase: activity.work_phase.name,
                actual_executed_datetime: moment(activity.actual_executed_datetime).format(UNIDATETIMEFORMAT),
                updated_on: moment(activity.updated_on).format(UNIDATETIMEFORMAT),
                subactivity_count: activity.subactivity.length,
                subactivity: collectSubActivityRawData(activity.subactivity),
            });
        });
        setActivityTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const collectSubActivityRawData = ( response ) => {
        const data = []
        response.forEach( subactivity => {
            let itemLocationFromCode = VALUE_NONE;
            if (subactivity.item_location_from) itemLocationFromCode = subactivity.item_location_from.description;

            data.push({
                key: subactivity.id,
                id: subactivity.id,
                timestamp: moment(subactivity.timestamp).format(UNIDATETIMEFORMAT),
                activity: subactivity.activity,
                area: subactivity.area.area_code,
                item_location_from: itemLocationFromCode,
                item_location_to: subactivity.item_location_to.description,
            })
        });
        return data;
    };

    const onChangeDateRange = (value) => {
        setDateRange(value);
    };

    const onChangeTaskCategory = (value) => {
        setTaskCategory(value);
    };

    const onChangeTaskType = (value) => {
        setTaskType(value);
    };

    const onChangeArea = (value) => {
        setArea(value);
    };

    const onChangeItemLocationFrom = (value) => {
        setItemLocationFrom(value);
    };

    const onChangeItemLocationTo = (value) => {
        setItemLocationTo(value);
    };

    const onChangeBatch = (value) => {
        setBatch(value);
    };

    const onChangeItemItem = (value) => {
        setItemItem(value);
    };

    const onChangeLifecycle = (value) => {
        setLifecycle(value);
    };

    const onSearch = () => {
        setCurrentPage(1);
        SearchActivity(1);
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onClickTableRow = (record, rowIndex) => {
        return {
            onClick: () => {
                navigate(MENUPATH_ACTIVITYUPDATE, {state: {record: record}});
            }
        };
    };

    const onClickLocations = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Activity: {record.task_type} ({record.item_item})</h3><h3>Batch Code: {record.batch}</h3></div>,
            icon: null,
            width: '30%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <Table columns={columnsSubActivity} dataSource={record.subactivity} pagination={{size: "small", position: ["bottomCenter"], total: record.subactivity.length, showTotal: showTotal}}/>,
        })
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchActivity(page);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onCreateNewActivity = () => {
        navigate(MENUPATH_ACTIVITYCREATENEW);
    };

    const columns = [
        { title: 'Timestamp', dataIndex: 'timestamp', sorter: (a, b) => a.timestamp.localeCompare(b.timestamp) },
        { title: 'Batch Code', dataIndex: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Task Category', dataIndex: 'task_category', sorter: (a, b) => a.task_category.localeCompare(b.task_category) },
        { title: 'Task', dataIndex: 'task_type', sorter: (a, b) => a.task_type.localeCompare(b.task_type) },
        { title: 'Item', dataIndex: 'item_item', sorter: (a, b) => a.item_item.localeCompare(b.item_item) },
        { title: 'Locations', dataIndex: 'subactivity_count', sorter: (a, b) => a.subactivity_count - b.subactivity_count,
            render: (subactivity_count, record) => {
                if (subactivity_count) { return (
                    <Badge count={subactivity_count} onClick={(e) => onClickLocations(e, record)}>
                        <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<CompassOutlined />} />
                    </Badge>);
                } else return (<>{VALUE_NONE}</>);
            }
        },
        { title: 'Life Cycle', dataIndex: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Work Phase', dataIndex: 'work_phase', sorter: (a, b) => a.work_phase.localeCompare(b.work_phase) },
    ];

    const columnsSubActivity = [
        { title: 'Source Location', dataIndex: 'item_location_from', sorter: (a, b) => a.item_location_from.localeCompare(b.item_location_from) },
        { title: 'Destination Location', dataIndex: 'item_location_to', sorter: (a, b) => a.item_location_to.localeCompare(b.item_location_to) },
        { title: 'Destination Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
    ]

    return (
        <>
            <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search Activity" key="1">
                    <Form form={form} {...formLayout}>
                        <Form.Item initialValue={dateRange} name="daterange" label="Date Range">
                            <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                        </Form.Item>

                        <AreaSelect withBlank={true} form={form} defaultArea={area} defaultItem={item_location_to} onAreaChange={onChangeArea} onItemChange={onChangeItemLocationTo}/>
                        <BatchSelect withBlank={true} form={form} defaultBatch={batch} defaultItem={item_item} defaultSearchActiveBatch={searchActiveBatch} defaultLifecycle={lifecycle} defaultTaskCategory={task_category} defaultTask={task_type}
                            onBatchChange={onChangeBatch} onItemChange={onChangeItemItem} onLifecycleChange={onChangeLifecycle} onTaskCategoryChange={onChangeTaskCategory} onTaskChange={onChangeTaskType} />

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} offset={3}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>
            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewActivity} type="primary"><PlusCircleTwoTone /> New Activity</Button></Col></Row>
            <Row><Col><Space><br/></Space></Col></Row>

            <Table onRow={onClickTableRow} columns={columns} dataSource={activityTableData}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: PAGESIZE,
                    current: currentPage,
                    total: totalRecord,
                    showTotal: showTotal,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                }}/>

            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewActivity} type="primary"><PlusCircleTwoTone /> New Activity</Button></Col></Row>
            </Spin>
        </>
    );
};

export default ActivityTable;