import React , { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Table, Modal, Typography, Spin, Tag, Avatar, Badge, Space, Button, message, Row, Col, DatePicker } from 'antd';
import { AGISAPIURL, DATEFORMAT, UNIDATEFORMAT, UNIDATETIMEFORMAT, LOADING, MENUPATH_HARVESTINGCREATENEWPAGE1, UNIT_OF_MEASUREMENT_PERCENTAGE, UNIT_OF_MEASUREMENT_PERCENTAGE_SIGN,
    MENUPATH_DAILYTODOHARVEST, APPJSON, VALUE_NONE } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, getUserSiteId, refreshUserSession, setLastPage, getUserSiteName } from '../Common/UserSession';
import { reportError } from '../Common/Utility';
import ButtonToBatchUpdatePage from '../Common/ButtonToBatchUpdatePage';
import BatchDistributionTable from '../Batch/BatchDistributionTable';
import BatchActivitiesSummaryTable from '../Batch/BatchActivitiesSummaryTable';
import HarvestingLocationTable from './HarvestingLocationTable';
import moment from 'moment';
import axios from 'axios';
import { InfoCircleTwoTone, ClockCircleOutlined, InboxOutlined, QuestionCircleTwoTone, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;
const { confirm } = Modal;

const DailyToDoHarvestTable = () => {
    const FileDownload = require('js-file-download');
    const navigate = useNavigate();
    const [itemBatchTableData, setItemBatchTableData] = useState([]);
    const [date, setDate] = useState(moment());
    const [isLoading, setIsLoading] = useState(false);

    const SearchDailyToDo = (date) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "dailytodoharvesting/", {
            params: {
                site: getUserSiteId(),
                date: date.format(UNIDATEFORMAT),
                sorting_type: "expected_harvesting_date",
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectInventoryTransactionSummaryData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        axios.get(`${AGISAPIURL}download/dailytodo/harvesting/`, {
            params: {
                site: getUserSiteId(),
                date: date.format(UNIDATEFORMAT),
                sorting_type: "expected_harvesting_date",
                search_criteria: `Site: ${getUserSiteName()}\nDate: ${date.format(DATEFORMAT)}`,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Daily To-Do Harvesting ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    };

    const downloadWorkerSummary = () => {
        setIsLoading(true);

        axios.get(`${AGISAPIURL}download/dailytodo/harvestingworkersummary/`, {
            params: {
                site: getUserSiteId(),
                site_name: getUserSiteName(),
                date: date.format(UNIDATEFORMAT),
                sorting_type: "expected_harvesting_date",
                search_criteria: `Site: ${getUserSiteName()}\nDate: ${date.format(DATEFORMAT)}`,
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                FileDownload(response.data, `Daily To-Do Harvesting Worker Summary ${now}.xlsx`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download worker summary.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    };

    useEffect(() => {
        SearchDailyToDo(date);
    }, []);

    const collectInventoryTransactionSummaryData = ( response ) => {
        const data = []
        response.data.results.forEach( invTransSummary => {
            let item_locations = [];
            item_locations = collectLocationRawData(invTransSummary.item_location);
            data.push({
                key: invTransSummary.id,
                id: invTransSummary.id,
                batch: invTransSummary.batch.batch_code,
                batch_id: invTransSummary.batch.id,
                item_item: invTransSummary.item_item.description,
                item_item_id: invTransSummary.item_item.id,
                area: invTransSummary.area.area_code,
                area_id: invTransSummary.area.id,
                expected_harvesting_date: moment(invTransSummary.expected_harvesting_date),
                location_count: invTransSummary.item_location.length,
                item_locations: item_locations,
            });
        });
        setItemBatchTableData(data);
    };

    const collectLocationRawData = ( response ) => {
        const data = []
        response.forEach( invTransSummary => {
            let worker = VALUE_NONE;
            if (invTransSummary.worker) worker = invTransSummary.worker.name;
            data.push({
                key: invTransSummary.id,
                id: invTransSummary.id,
                item_tool: invTransSummary.item_tool.item_code,
                area: invTransSummary.area.area_code,
                quantity_item: parseInt(invTransSummary.quantity_item),
                occupied_perc: parseInt(invTransSummary.occupied_perc),
                worker: worker,
            });
        });
        return data;
    };

    //--------------------------
    // Show Batch Plant Distribution
    //--------------------------
    const showBatchPlantDistributionPopup = (e, record) => {
        const modal = Modal.info({
            title: <div><h3><b>BATCH OVERVIEW</b> - Batch ID: {record.batch}<ButtonToBatchUpdatePage batchId={record.batch_id} buttonName="Go to Batch Update Page" navigate={navigate} lastPage={MENUPATH_DAILYTODOHARVEST}/></h3></div>,
            icon: null,
            width: '70%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <><BatchDistributionTable batchId={record.batch_id} /><BatchActivitiesSummaryTable batchId={record.batch_id} /></>
        });
    };

    const onClickLocations = (e, record) => {
        const modal = Modal.info({
            title: <h3>Batch Code: {record.batch} <InfoCircleTwoTone onClick={(e) => showBatchPlantDistributionPopup(e, record)} /></h3>,
            icon: null,
            width: '60%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <HarvestingLocationTable dataSource={record.item_locations} />,
        })
    };

    const onHarvest = (record) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Proceed with creating Harvest activity?</p></Space>,
            onOk() {
                setLastPage(MENUPATH_DAILYTODOHARVEST);
                navigate(MENUPATH_HARVESTINGCREATENEWPAGE1, {state: {
                    record: {
                        batch: record.batch_id,
                        item_item: record.item_item_id,
                        area_from: [record.area_id],
                        actualExecutedDate: moment().format(UNIDATETIMEFORMAT),
                        quantity_item_from_uom: UNIT_OF_MEASUREMENT_PERCENTAGE,
                        quantity_item_from_uom_sign: UNIT_OF_MEASUREMENT_PERCENTAGE_SIGN,
                        item_location_from_list: [],
                    }
                }});
            },
            onCancel() {},
            centered: true,
        });
    };

    const onDateChange = (value) => {
        setDate(value);
        SearchDailyToDo(value);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const title = () => {
        return (
            <Title level={4}>
                <Row justify="center">
                    <Col span={12} style={{textAlign: "left"}}>Ready For Harvesting ({date.format(UNIDATEFORMAT)})</Col>
                    <Col span={12} style={{textAlign: "right"}}>
                        <Button title='Download Worker Summary' type="primary" htmlType="button" onClick={downloadWorkerSummary} icon={<FileExcelOutlined />}>Download Worker Summary</Button>
                        <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />}>Download PDF</Button>
                        <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />}>Download Excel</Button>
                    </Col>
                    <Col span={24} style={{textAlign: "left"}}><DatePicker defaultValue={date} onChange={onDateChange} format={UNIDATEFORMAT} clearIcon={false} /></Col>
                </Row>
            </Title>
        );
    };

    const columns = [
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'Batch Code', dataIndex: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch), render: (batch, record) => {
                return (<><Text>{batch}</Text> <InfoCircleTwoTone onClick={(e) => showBatchPlantDistributionPopup(e, record)} /></>);
            }
        },
        { title: 'Plant Species', dataIndex: 'item_item', sorter: (a, b) => a.item_item.localeCompare(b.item_item) },
        { title: 'Location(s)', dataIndex: 'location_count', sorter: (a, b) => a.location_count - b.location_count,
            render: (location_count, record) => {
                return (
                    <Badge count={location_count} onClick={(e) => onClickLocations(e, record)}>
                        <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<InboxOutlined />} />
                    </Badge>);
            }
        },
        { title: 'Expected Harvesting Date', dataIndex: 'expected_harvesting_date', sorter: (a, b) => a.expected_harvesting_date - b.expected_harvesting_date, render: (expected_harvesting_date) => {
                if (expected_harvesting_date.startOf('day') < date.startOf('day')) {
                    return (<>{moment(expected_harvesting_date).format(UNIDATEFORMAT)} <Tag icon={<ClockCircleOutlined />} color='error'>Overdue</Tag></>);
                }
                else return (<>{moment(expected_harvesting_date).format(UNIDATEFORMAT)}</>);
            }
        },
        { title: 'Action', dataIndex: 'action',
            render: (_, record) => {
                return (<Typography.Link title="Sure to harvest?" onClick={() => onHarvest(record)}>Harvest</Typography.Link>)
            },
        },
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Table title={title} columns={columns} dataSource={itemBatchTableData} pagination={{position: ["bottomCenter"], total: itemBatchTableData.length, showTotal: showTotal}} />

            <Row justify="center"><Col span={24} style={{textAlign: "right"}}>
                <Button title='Download Worker Summary' type="primary" htmlType="button" onClick={downloadWorkerSummary} icon={<FileExcelOutlined />}>Download Worker Summary</Button>
                <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />}>Download PDF</Button>
                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />}>Download Excel</Button>
            </Col></Row>
        </Spin>
    );
};

export default DailyToDoHarvestTable;