import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Column } from '@ant-design/plots';
import { Spin, Row, Col, Layout, Space, PageHeader, Descriptions, Table, Tag, Modal } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, VALUE_NONE, MENUPATH_DASHBOARD, MENUPATH_BATCH, UNIDATEFORMAT } from '../Common/SysParameters';
import AGISHeader from '../Common/AGISHeader';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { InfoCircleTwoTone } from '@ant-design/icons';


const { Content, Footer } = Layout;

const DashboardChartsWorkProgrammeBySpecies = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const item = location.state.record.item;
    const item_id = location.state.record.item_id;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [batchData, setBatchData] = useState([]);

    const SearchBatch = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batch/", {
            params: {
                site: getUserSiteId(),
                item: item_id,
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectBatchRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message);
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectBatchRawData = ( response ) => {
        const data = []
        response.data.forEach( batch => {
            let expectDate = VALUE_NONE;
            let lifecycle = VALUE_NONE;
            let work_phase = VALUE_NONE;
            let work_programme = VALUE_NONE;
            if (batch.expected_completion_date) expectDate = moment(batch.expected_completion_date).format(UNIDATEFORMAT);
            if (batch.lifecycle) lifecycle = batch.lifecycle.name
            if (batch.work_phase) work_phase = batch.work_phase.name
            if (batch.work_programme) work_programme = batch.work_programme.description;

            data.push({
                key: batch.id,
                id: batch.id,
                batch_code: batch.batch_code,
                expected_completion_date: expectDate,
                item: batch.item.description,
                work_programme: work_programme,
                status_type: batch.status_type.description,
                lifecycle: lifecycle,
                work_phase: work_phase,
                is_harvested: batch.is_harvested,
                days_from_start: batch.days_from_start,
                total_quantity_item: parseInt(batch.total_quantity_item),
            })
        });
        setBatchData(data);
    };

    const onBack = () => {
        navigate(MENUPATH_DASHBOARD);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchBatch();
    }, []);

    const onClickTableRow = () => {
        return {
            onClick: () => {
                navigate(MENUPATH_BATCH);
            }
        };
    };

    const columnTitleCompleted = (title) => {
        return (
            <>{title} <InfoCircleTwoTone onClick={(e) => showColumnDescriptionCompleted(e, title)} /></>
        );
    };

    const columnTitleNoOfDaysFromStart = (title) => {
        return (
            <>{title} <InfoCircleTwoTone onClick={(e) => showColumnDescriptionNoOfDaysFromStart(e, title)} /></>
        );
    };

    const showColumnDescriptionCompleted = (e, title) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Definition of the Column Title - {title}</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <>
                <p>For <b>Planting Batch</b>: If Yes, it indicates all plant in this batch has been fully <b>harvested</b>.</p>
                <p>For <b>Soil Maintenance Batch</b>: If Yes, it indicates all planting medium in this batch has been fully <b>released</b>.</p>
            </>
        });
    };

    const showColumnDescriptionNoOfDaysFromStart = (e, title) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Definition of the Column Title - {title}</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <>
                <p>For <b>Planting Batch</b>: The number of days that have passed since the first <b>sowing</b> activity.</p>
                <p>For <b>Soil Maintenance Batch</b>: The number of days that have passed since the first <b>soil maintenance</b> activity.</p>
            </>
        });
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Batch Code', dataIndex: 'batch_code', sorter: (a, b) => a.batch_code.localeCompare(b.batch_code) },
        { title: 'Plant Species', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Work Programme', dataIndex: 'work_programme', sorter: (a, b) => a.work_programme.localeCompare(b.work_programme) },
        { title: 'Plant Quantity', dataIndex: 'total_quantity_item', sorter: (a, b) => a.total_quantity_item - b.total_quantity_item },
        { title: 'Status', dataIndex: 'status_type', sorter: (a, b) => a.status_type.localeCompare(b.status_type) },
        { title: 'Life Cycle', dataIndex: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Work Phase', dataIndex: 'work_phase', sorter: (a, b) => a.work_phase.localeCompare(b.work_phase) },
        { title: columnTitleCompleted('Completed'), dataIndex: 'is_harvested', sorter: (a, b) => a.is_harvested - b.is_harvested, render: (is_harvested) => {
            if (is_harvested) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
        { title: columnTitleNoOfDaysFromStart('No. of Days from Start'), dataIndex: 'days_from_start', sorter: (a, b) => a.days_from_start - b.days_from_start },
        { title: 'Expected Completion Date', dataIndex: 'expected_completion_date', sorter: (a, b) => a.expected_completion_date - b.expected_completion_date },
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title={`Chart of Batch Distribution across Work Programmes (${item})`} onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">A basic column chart showing batches with {item} quantity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Column
                        data={batchData}
                        xField='batch_code'
                        yField='total_quantity_item'
                        isGroup={true}
                        seriesField='work_programme'
                        legend={{ position: 'right' }}
                        appendPadding={20}
                        label={{
                            position: 'top',
                            layout: [{type: 'adjust-color'}],
                        }}
                        xAxis={{ label: { autoRotate: true, autoHide: false } }}
                    />
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Table onRow={onClickTableRow} columns={columns} dataSource={batchData} pagination={{position: ["bottomCenter"], total: batchData.length, showTotal: showTotal}}/>
                </Col></Row>

            </Content>
            <Footer><Row justify="center"><PageHeader title={`Chart of Batch Distribution across Work Programmes (${item})`} subTitle={`A basic column chart showing batches with ${item} quantity`} onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default DashboardChartsWorkProgrammeBySpecies;