import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Spin, Card, Typography, Tag, Modal, Divider, Form, Input, Button, message, DatePicker, Switch, Collapse } from 'antd';
import { AGISAPIURL, UNIDATEFORMAT, UNIDATETIMEFORMAT, VALUE_NONE, LOADING, MENUPATH_WORKPLAN, MENUPATH_WORKPROGRAMMETRANSFERTABLE, MENUPATH_WORKPLANUPDATE } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import axios from 'axios';
import moment from 'moment';
import { reportError } from "../Common/Utility";
import { CloseCircleFilled, QuestionCircleTwoTone, SaveOutlined, MinusCircleOutlined, PlusOutlined, DeleteFilled, EditOutlined, BarChartOutlined } from '@ant-design/icons';
import WorkProgrammeListTable from "./WorkProgrammeListTable";
import WorkProgrammePriorityDragTable from "./WorkProgrammePriorityDragTable";
import DateRangeListTable from "./DateRangeListTable";
import WorkPlanCharts from "./WorkPlanCharts";


const { Content, Footer } = Layout;
const { confirm } = Modal;
const { Title } = Typography;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const WorkPlanUpdate = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");
    const paddingCardGrid = OTHERSYSPARAM("CARD_GRID_PADDING");

    const location = useLocation();
    const prevRecord = location.state.record;
    const defaultWorkPlanId = prevRecord.id;
    const defaultDescription = prevRecord.description;
    const defaultWorkPlanCode = prevRecord.work_plan_code;
    const defaultIsActive = prevRecord.is_active;
    const defaultIsInEffect = prevRecord.is_in_effect;
    const defaultDateRangeData = prevRecord.date_range_list;
    const defaultWorkProgData = prevRecord.work_programme_list;
    const defaultResetPriority = prevRecord.hasOwnProperty('reset_priority') ? prevRecord.reset_priority : true;
    const modifiedWorkPlanCode = prevRecord.workPlanCode ? prevRecord.workPlanCode : defaultWorkPlanCode;
    const modifiedDescription = prevRecord.descriptionMod ? prevRecord.descriptionMod : defaultDescription;
    const modifiedIsActive = prevRecord.hasOwnProperty('isActive') ? prevRecord.isActive : defaultIsActive;
    const modifiedDateRangeData = prevRecord.dateRangeList ? prevRecord.dateRangeList.map(dataRange => ({daterange: [dataRange[0] !== VALUE_NONE ? moment(dataRange[0]) : null, dataRange[1] !== VALUE_NONE ? moment(dataRange[1]) : null]}))
    : defaultDateRangeData.map(dataRange => ({daterange: [dataRange.start_date !== VALUE_NONE ? moment(dataRange.start_date) : null, dataRange.end_date !== VALUE_NONE ? moment(dataRange.end_date) : null]}));
    const modifiedWorkProgData = prevRecord.workProgrammeList ? prevRecord.workProgrammeList : defaultWorkProgData;

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(modifiedIsActive);
    const [workProgTableData, setWorkProgTableData] = useState(defaultWorkProgData);
    const [workProgTableModData, setWorkProgTableModData] = useState(modifiedWorkProgData);
    const [workPlanData, setWorkPlanData] = useState("");
    const [disableCancelButton, setDisableCancelButton] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState("");
    const [disableDeleteButton, setDisableDeleteButton] = useState(defaultWorkProgData ? (defaultWorkProgData.length === 0 ? "" : "disable") : "disable");
    const [isLoading, setIsLoading] = useState(false);

    const updateWorkPlan = (workPlanCode, description) => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setIsLoading(true);
        let date_range_list = [];

        form.getFieldValue('date_range_list').forEach(dateRange => date_range_list.push([dateRange['daterange'][0].format(UNIDATEFORMAT), dateRange['daterange'][1].format(UNIDATEFORMAT)]));

        axios.patch(AGISAPIURL + "workplan/update/" + defaultWorkPlanId, {
            work_plan_code: workPlanCode,
            description: description,
            work_programme_list: form.getFieldValue('work_programme_list') ? form.getFieldValue('work_programme_list') : [],
            date_range_list: date_range_list,
            is_active: isActive,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Work Plan ${response.data[0].work_plan_code} updated.`);
            navigate(OTHERSYSPARAM("lastPage"));
        })
        .catch( error => {
            reportError(error, "Failed to update data. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const deleteWorkPlan = () => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setIsLoading(true);

        axios.delete(AGISAPIURL + "workplan/delete/" + defaultWorkPlanId, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then(() => {
            message.info(`Work Plan ${defaultWorkPlanId} deleted!`);
            navigate(MENUPATH_WORKPLAN);
        })
        .catch( error => {
            reportError(error, "Failed to delete work plan. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getWorkProgrammeList = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "workprogramme/", {
            params: {
                site: getUserSiteId(),
                work_plan: defaultWorkPlanId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectWorkProgrammeListRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const SearchWorkPlan = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "workplan/", {
            params: {
                site: getUserSiteId(),
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectWorkPlanRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectWorkPlanRawData = ( response ) => {
        const data = [];
        response.data.forEach( workPlan => {
            data.push({
                key: workPlan.id,
                id: workPlan.id,
                work_plan_code: workPlan.work_plan_code,
                description: workPlan.description,
                date_range_list: collectDateRangeListRawData(workPlan.date_range_list),
            });
        });
        setWorkPlanData(data);
    };

    const collectDateRangeListRawData = ( response ) => {
        const data = [];
        response.forEach( dataRange => {
            data.push({
                key: dataRange.id,
                id: dataRange.id,
                start_date: dataRange.start_date ? moment(dataRange.start_date).format(UNIDATEFORMAT) : VALUE_NONE,
                end_date: dataRange.end_date ? moment(dataRange.end_date).format(UNIDATEFORMAT) : VALUE_NONE,
            });
        });
        return data;
    };

    useEffect(() => {
        Modal.destroyAll();
        if (!defaultWorkProgData) getWorkProgrammeList();
        if (!workPlanData) SearchWorkPlan();
    }, []);

    const onClickActiveWorkPlan = (value) => {
        setIsActive(value);
    };

    const collectWorkProgrammeListRawData = ( response ) => {
        const data = [];
        response.data.forEach( workProg => {
            let item = VALUE_NONE;
            if (workProg.item) item = workProg.item.item.description;

            data.push({
                key: workProg.id,
                id: workProg.id,
                priority: workProg.priority,
                work_programme_code: workProg.work_programme_code,
                description: workProg.description,
                workday: workProg.workday,
                monday: workProg.workday.monday,
                tuesday: workProg.workday.tuesday,
                wednesday: workProg.workday.wednesday,
                thursday: workProg.workday.thursday,
                friday: workProg.workday.friday,
                saturday: workProg.workday.saturday,
                sunday: workProg.workday.sunday,
                item: item,
                quantity_item: parseInt(workProg.quantity_item),
                work_stage_list: collectWorkStageListRawData(workProg.work_stage_list),
                work_task_list: collectWorkTaskListRawData(workProg.work_task_list),
                is_active: workProg.workday.is_active,
                created_on: moment(workProg.created_on).format(UNIDATETIMEFORMAT),
                updated_on: moment(workProg.updated_on).format(UNIDATETIMEFORMAT),
                has_batch: workProg.has_batch,
            });
        });
        setWorkProgTableData(data);
        setWorkProgTableModData(data);
        if (data.length === 0) setDisableDeleteButton("")
        else setDisableDeleteButton("disable");
    };

    const collectWorkStageListRawData = ( response ) => {
        const data = [];
        response.forEach( workStage => {
            data.push({
                key: workStage.id,
                id: workStage.id,
                seq: workStage.seq,
                duration: parseInt(workStage.duration),
                duration_uom: workStage.duration_type.unit,
                lifecycle: workStage.lifecycle.name,
                work_phase: workStage.work_phase.name,
            });
        });
        return data;
    };

    const collectWorkTaskListRawData = ( response ) => {
        const data = [];
        response.forEach( workTask => {
            data.push({
                key: workTask.id,
                id: workTask.id,
                seq: workTask.seq,
                execute_on: parseInt(workTask.execute_on),
                execute_on_uom: workTask.execute_on_type.unit,
                task_type: workTask.task_type.task,
                lifecycle: workTask.work_stage.lifecycle.name,
                work_phase: workTask.work_stage.work_phase.name,
            });
        });
        return data;
    };

    const validateDateRange = (date_range_list) => {
        let dateList = [];
        let overlapped = false;
        date_range_list.forEach(dateRangeRef => {
            if (dateRangeRef != undefined) {
                dateList.forEach(dateRangeExist => {
                    if (!(moment(dateRangeRef["daterange"][0]).isAfter(moment(dateRangeExist[1]), 'date') ||
                    moment(dateRangeRef["daterange"][1]).isBefore(moment(dateRangeExist[0]), 'date'))) overlapped = true;
                });
                dateList.push(dateRangeRef["daterange"]);
            };
        });

        if (overlapped) return Promise.reject(new Error('Date ranges have been overlapped!'));
    };

    const onBack = () => {
        navigate(OTHERSYSPARAM("lastPage"));
    };

    const onCancel = () => {
        navigate(OTHERSYSPARAM("lastPage"));
    };

    const onAddRemoveWorkProgramme = () => {
        let record = null;
        let date_range_list = [];
        let work_programme_key_list = [];
        record = form.getFieldsValue();

        if (form.getFieldValue('date_range_list')) form.getFieldValue('date_range_list').forEach(dateRange => {if (dateRange !== undefined) date_range_list.push([dateRange['daterange'][0].format(UNIDATEFORMAT), dateRange['daterange'][1].format(UNIDATEFORMAT)])});
        if (form.getFieldValue('work_programme_list')) form.getFieldValue('work_programme_list').forEach(workProg => work_programme_key_list.push(workProg.id));
        record.workPlanCode = defaultWorkPlanCode.split('-')[0] + "-" + record.workPlanCode + "-" + defaultWorkPlanCode.split('-').splice(2).join('-')
        record.descriptionMod = record.description;
        record.isActive = isActive;
        record.dateRangeList = date_range_list;
        record.workProgrammeList = record.work_programme_list;
        record.work_programme_key_list = work_programme_key_list;

        record.id = defaultWorkPlanId;
        record.description = defaultDescription;
        record.work_plan_code = defaultWorkPlanCode;
        record.is_active = defaultIsActive;
        record.is_in_effect = defaultIsInEffect;
        record.date_range_list = defaultDateRangeData;
        record.work_programme_list = defaultWorkProgData;

        navigate(MENUPATH_WORKPROGRAMMETRANSFERTABLE, {state: {title: "Work Plan Update", description: "Edit Work Plan", returnPage: MENUPATH_WORKPLANUPDATE, record: record }});
    };

    const onShowWorkPlanChart = () => {
        let date_range_list = [];
        form.getFieldValue('date_range_list').forEach(dateRange => {
            if (dateRange) date_range_list.push({
                start_date: moment(dateRange['daterange'][0]),
                end_date: moment(dateRange['daterange'][1])
            });
        });

        const workPlanTableData = workPlanData.slice();
        if (date_range_list.length) {
            workPlanTableData.push({
                work_plan_code: `(*Currently Editing) ${defaultWorkPlanCode}\n${defaultDescription}`,
                date_range_list: date_range_list,
            });
        };

        const modal = Modal.info({
            title: <div><h3>Work Plan Chart</h3></div>,
            icon: null,
            width: '100%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <WorkPlanCharts workPlanTableData={workPlanTableData}/>
        })
    };

    //---------------------------
    // On delete
    //---------------------------
    const onDelete = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Delete work plan confirmed?</p></Space>,
            onOk() { deleteWorkPlan() },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (value) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Edit work plan confirmed?</p></Space>,
            onOk() { updateWorkPlan(value.workPlanCode, value.description) },
            onCancel() {},
            centered: true
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Work Plan Update" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Edit Work Plan</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center"><Col span={14}>
                    <Card title={<Title level={5}>{`Work Plan: ${defaultDescription}`}<br/></Title>}>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Work Plan Code:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{defaultWorkPlanCode}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Status:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{ defaultIsActive ? <Tag color="success">Active</Tag> : <Tag color="default">Inactive</Tag> }</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>In Effect:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{ defaultIsInEffect ? <Tag color="success">{OTHERSYSPARAM("YES")}</Tag> : <Tag color="error">{OTHERSYSPARAM("NO")}</Tag> }</Card.Grid>
                    </Card>
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify="center"><Col span={14}>
                    <Collapse>
                        <Panel header={<Title level={5}>Effective Date Range(s):</Title>} key="1">
                            {defaultDateRangeData ? <DateRangeListTable dataSource={defaultDateRangeData}/> : <></>}
                        </Panel>
                    </Collapse>
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify="center"><Col span={20}>
                    <Title level={5}>Work Programme(s):</Title>
                    {workProgTableData ? <WorkProgrammeListTable dataSource={workProgTableData} navigate={navigate}/> : <></>}
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row justify="center"><Col span={12}>
                    <Divider orientation="left">Edit Work Plan Fields</Divider>
                    <Form form={form} onFinish={onSave} autoComplete="off">
                        <Form.Item initialValue={modifiedWorkPlanCode.split('-')[1]} name="workPlanCode" label="Work Plan Code"
                            rules={[{required: true, message: 'Please input the Work Plan Code for this Work Plan!'},
                                    {pattern: new RegExp("^[0-9a-zA-Z]+$"), message: "Accept only letter and numbers. No space or special character is allowed!"}]}
                        >
                            <Input addonBefore={modifiedWorkPlanCode.split('-')[0] + "-"} addonAfter={"-" + modifiedWorkPlanCode.split('-').splice(2).join('-')} style={{width: 350}} maxLength={10} showCount onInput={e => e.target.value = e.target.value.toUpperCase()}/>
                        </Form.Item>

                        <Form.Item initialValue={modifiedDescription} name="description" label="Description" rules={[{required: true, message: 'Please input the description for this Work Plan!'}]}>
                            <Input maxLength={50} allowClear={true} showCount />
                        </Form.Item>

                        <Form.Item valuePropName="isActive" label="Is Work Plan Active">
                            <Switch onClick={onClickActiveWorkPlan} defaultChecked={modifiedIsActive}/>
                        </Form.Item>

                        <Row justify="end">
                            <Col span={5}><Button onClick={onShowWorkPlanChart} type="primary" icon={<BarChartOutlined />} ghost>Show Work Plan Chart</Button></Col>
                        </Row>

                        <Form.Item name="date_range_list" label="Effective Date Range" rules={[{required: true, message: 'Please select at least one Effective Date Range!'}]}>
                            <Form.List initialValue={modifiedDateRangeData} name="date_range_list" rules={[{validator: async (_, date_range_list) => validateDateRange(date_range_list)}]}>
                                {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field) => (
                                        <Space key={field.key} align="baseline" size={19}>

                                            <Form.Item {...field} label="Date Range" name={[field.name, 'daterange']} rules={[{required: true, message: 'Please input the Date Range for this Work Plan!'}]}>
                                                <RangePicker format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}} disabledTime={true}/>
                                            </Form.Item>

                                            <MinusCircleOutlined onClick={() => {
                                                remove(field.name);
                                            }} />
                                        </Space>
                                    ))}

                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>Add Date Range</Button>
                                    </Form.Item>
                                    <Form.ErrorList errors={errors} />
                                </>
                                )}
                            </Form.List>
                        </Form.Item>

                        <Form.Item name="add_remove_workprogramme" label="Add/Remove Work Programme">
                            <Button htmlType="button" disabled={disableCancelButton} onClick={onAddRemoveWorkProgramme} type="primary"><EditOutlined/>Add/Remove Work Programme</Button>
                        </Form.Item>

                        { workProgTableModData ?
                        <Form.Item name="work_programme_list" label="Work Programme">
                            <WorkProgrammePriorityDragTable form={form} defaultTableData={workProgTableModData} resetPriority={defaultResetPriority}/>
                        </Form.Item>
                        : <></>}

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={12} offset={3}>
                                <Button htmlType="button" hidden={disableDeleteButton} disabled={disableDeleteButton} type="primary" onClick={onDelete} danger><DeleteFilled/>Delete</Button>
                                <Button htmlType="submit" hidden={disableSubmitButton} disabled={disableSubmitButton} type="primary"><SaveOutlined/>Save</Button>
                                <Button htmlType="button" disabled={disableCancelButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col></Row>
            </Content>
            <Footer><Row justify="center"><PageHeader title="Work Plan Update" subTitle="Edit Work Plan" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default WorkPlanUpdate;