import React, { useState, useEffect } from "react";
import { Line } from '@ant-design/plots';
import { Spin, Row, Col, Typography } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, UNIDATEFORMAT, UNIT_OF_MEASUREMENT_KILOGRAM_SIGN } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from "moment";
import axios from "axios";

const { Title } = Typography;

const DashboardChartsHarvestingPerformance = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [harvestingPerformanceData, setHarvestingPerformanceData] = useState([]);

    const SearchHarvestingPerformance = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "harvestingperformance/", {
            params: {
                site: getUserSiteId(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectHarvestingPerformance(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectHarvestingPerformance = ( response ) => {
        const data = []
        response.data.results.forEach( harvestingRecord => {
            data.push({
                id: harvestingRecord.id,
                item_item: harvestingRecord.item_item.description,
                actual_harvesting_date: moment(harvestingRecord.actual_harvesting_date).format(UNIDATEFORMAT),
                harvested_in_kg: parseFloat(harvestingRecord.harvested_in_kg),
            });
        });

        setHarvestingPerformanceData(data);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchHarvestingPerformance();
    }, []);

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Row justify='center'>
                <Col span={24} style={{textAlign: "center"}}>
                    <Title level={3}>Harvesting Performance</Title>
                </Col>
            </Row>

            <Row justify='center'>
                <Col span={24}>
                    <Line
                        data={harvestingPerformanceData}
                        xField='actual_harvesting_date'
                        yField='harvested_in_kg'
                        seriesField='item_item'
                        legend={{ position: 'top' }}
                        point={{ shape: 'circle', size: 2 }}
                        xAxis={{ type: 'time' }}
                        yAxis={{
                            label: { formatter: (value) => `${value} ${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}` },
                        }}
                        slider={{}}
                    />
                </Col>
            </Row>
        </Spin>
    );
};

export default DashboardChartsHarvestingPerformance;