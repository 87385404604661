import React from 'react';
import { Table, Button, Tag } from 'antd';
import { MENUPATH_SOILMAINTENANCERELEASEMEDIUM, STATUS_AVAILABLE, STATUS_BLOCKED, STATUS_EMPTY, STATUS_FULLY_OCCUPIED, STATUS_REST, STATUS_RETIRED, STATUS_PARTIALLY_OCCUPIED } from '../Common/SysParameters';


const SoilMaintenanceDistributionTable = ({dataSource, batchId, batch_isActive, releaseMedium, noOfDaysPassed, navigate}) => {

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onClickRelease = (e, record, batchId) => {
        navigate(MENUPATH_SOILMAINTENANCERELEASEMEDIUM, {state: {record: record, batchId: batchId}});
    };

    const columns = [
        { title: 'Medium', dataIndex: 'item_location_to', sorter: (a, b) => a.item_location_to.localeCompare(b.item_location_to) },
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'No. of Days Passed',
            render: () => {
                return (noOfDaysPassed);
            },
        },
        { title: 'Current Status', dataIndex: 'item_location_status_type', sorter: (a, b) => a.item_location_status_type.localeCompare(b.item_location_status_type),
            render: (item_location_status_type, record) => {
                if (record.item_location_status_type_id === STATUS_EMPTY) return <Tag color="success">{item_location_status_type}</Tag>
                else if (record.item_location_status_type_id === STATUS_PARTIALLY_OCCUPIED) return <Tag color="processing" style={{borderStyle: 'dashed'}}>{item_location_status_type}</Tag>
                else if (record.item_location_status_type_id === STATUS_FULLY_OCCUPIED) return <Tag color="processing">{item_location_status_type}</Tag>
                else if (record.item_location_status_type_id === STATUS_BLOCKED) return <Tag color="error">{item_location_status_type}</Tag>
                else if (record.item_location_status_type_id === STATUS_REST) return <Tag color="warning">{item_location_status_type}</Tag>
                else if (record.item_location_status_type_id === STATUS_RETIRED) return <Tag color="default">{item_location_status_type}</Tag>
                else if (record.item_location_status_type_id === STATUS_AVAILABLE) return <Tag color="success">{item_location_status_type}</Tag>
                else return <>{item_location_status_type}</>
            },
        },
    ];

    return (
        <>
            <div style={{textAlign: 'right'}} ><Button onClick={(e) => onClickRelease(e, dataSource, batchId)} disabled={!(batch_isActive && releaseMedium)} type="primary">Release Mediums</Button></div>
            <Table columns={columns} dataSource={dataSource} pagination={{size: "small", position: ["bottomCenter"], total: dataSource.length, showTotal: showTotal}}/>
        </>
    );
};

export default SoilMaintenanceDistributionTable;