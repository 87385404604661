import React from 'react';
import { Layout, Row, Col, BackTop, PageHeader, Descriptions, Space, Tabs } from 'antd';
import { OTHERSYSPARAM } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import AGISFooter from '../Common/AGISFooter';
import { walltiles } from '../Common/Layout';
import DashboardChartsWhatToSow from './DashboardChartsWhatToSow';
import DashboardChartsTransferToBatas from './DashboardChartsTransferToBatas';
import DashboardChartsHarvesting from './DashboardChartsHarvesting';
import DashboardChartsBatas from './DashboardChartsBatas';
import DashboardChartsHarvestingPerformance from './DashboardChartsHarvestingPerformance';
import DashboardChartsSowingHistory from './DashboardChartsSowingHistory';
import DashboardChartsWorkProgramme from './DashboardChartsWorkProgramme';
import DashboardChartsAndTableQuantity from './DashboardChartsAndTableQuantity';
import DashboardChartsYieldSummary from './DashboardChartsYieldSummary';
import { BarChartOutlined, LineChartOutlined, PieChartOutlined, FundOutlined } from '@ant-design/icons';


const { Content } = Layout;

const DashboardMain = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    return (
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight, ...walltiles}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Dashboard">
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Overview of the daily To-Do tasks, planting bed status, harvesting performance and sowing history charts</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Tabs defaultActiveKey="1" size="middle" style={{marginLeft: 30}} centered={true} animated={true}>
                    <Tabs.TabPane tab={<><BarChartOutlined />Daily To-Do and Current Status Charts</>} key="1">
                        <Row>
                            <Col span={1}/>
                            <Col span={10}><DashboardChartsWhatToSow /></Col>
                            <Col span={1}/>
                            <Col span={11}><DashboardChartsBatas /></Col>
                            <Col span={1}/>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>

                        <Row>
                            <Col span={1}/>
                            <Col span={10}><DashboardChartsTransferToBatas /></Col>
                            <Col span={2}/>
                            <Col span={10}><DashboardChartsHarvesting /></Col>
                            <Col span={1}/>
                        </Row>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={<><LineChartOutlined />Performance and History Charts</>} key="2">
                        <Row>
                            <Col span={1}/>
                            <Col span={22}><DashboardChartsHarvestingPerformance /></Col>
                            <Col span={1}/>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>

                        <Row>
                            <Col span={1}/>
                            <Col span={22}><DashboardChartsSowingHistory /></Col>
                            <Col span={1}/>
                        </Row>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={<><PieChartOutlined />General Stats Charts</>} key="3">
                        <Row>
                            <Col span={1}/>
                            <Col span={22}><DashboardChartsWorkProgramme /></Col>
                            <Col span={1}/>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>

                        <DashboardChartsAndTableQuantity />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={<><FundOutlined />Yield Summary and Forecast</>} key="4">
                        <Row>
                            <Col span={1}/>
                            <Col span={22}><DashboardChartsYieldSummary /></Col>
                            <Col span={1}/>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
            </Content>
            <AGISFooter />
            <BackTop />
        </Layout>
    );
};

export default DashboardMain;