import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Typography, Card, Modal, message, Tag, Collapse, Spin, Popover } from 'antd';
import { AGISAPIURL, MENUPATH_ITEM, STATUS_AVAILABLE, STATUS_BLOCKED, STATUS_EMPTY, STATUS_FULLY_OCCUPIED, STATUS_REST, STATUS_RETIRED, STATUS_PARTIALLY_OCCUPIED, LOADING, VALUE_NONE,
    VALUE_NOT_APPLICABLE } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import axios from 'axios';
import { reportError } from "../Common/Utility";
import { CloseCircleFilled, QuestionCircleTwoTone, InfoCircleTwoTone } from '@ant-design/icons';
import ItemDistributionTable from './ItemDistributionTable';


const { Content, Footer } = Layout;
const { Panel } = Collapse;
const { confirm } = Modal;
const { Title, Text } = Typography;

const ItemUpdate = () => {

    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");
    const paddingCardGrid = OTHERSYSPARAM("CARD_GRID_PADDING");

    let status_type = VALUE_NONE;

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [item, setItem] = useState(location.state.record.id);
    const [itemCode, setItemCode] = useState(location.state.record.item_code);
    const [description, setDescription] = useState(location.state.record.description);
    const [statusType, setStatusType] = useState(location.state.record.status_type);
    const [statusTypeId, setStatusTypeId] = useState(location.state.record.status_type_id);
    const [availableQuantity, setAvailableQuantity] = useState(location.state.record.available_quantity);
    const [amountOccupied, setAmountOccupied] = useState(location.state.record.amount_occupied);
    const [area, setArea] = useState(location.state.record.area);
    const [areaId, setAreaId] = useState(location.state.record.areaId);
    const [plantingCycleRevive, setPlantingCycleRevive] = useState(location.state.record.planting_cycle_after_revive);
    const [plantingCycleRehabilitate, setPlantingCycleRehabilitate] = useState(location.state.record.planting_cycle_after_rehabilitate);
    const [totalQuantity, setTotalQuantity] = useState(location.state.record.total_quantity);
    const [itemCategory, setItemCategory] = useState(location.state.record.item_category);
    const [itemGroup, setItemGroup] = useState(location.state.record.item_group);
    const [itemType, setItemType] = useState(location.state.record.item_type);
    const [itemVariety, setItemVariety] = useState(location.state.record.item_variety);
    const [itemVarietyYield, setItemVarietyYield] = useState(location.state.record.item_variety_yield);
    const [itemVarietyYieldUom, setItemVarietyYieldUom] = useState(location.state.record.item_variety_yield_uom);
    const [isInventoryRepository, setIsInventoryRepository] = useState(location.state.record.is_inventory_repository);
    const [itemProfileCode, setItemProfileCode] = useState("");
    const [useBatch, setUseBatch] = useState(0);
    const [itemTypeProp, setItemTypeProp] = useState("");
    const [itemTypePropSize, setItemTypePropSize] = useState(0);
    const [itemTypePropAreaFixed, setItemTypePropAreaFixed] = useState("");

    const [disableCancelButton, setDisableCancelButton] = useState("");
    // const [disableSubmitButton, setDisableSubmitButton] = useState((location.state.record.is_active) ? false : true);
    const [isLoading, setIsLoading] = useState(false);

    if (statusTypeId === STATUS_EMPTY) status_type = <Tag color="success">{statusType}</Tag>
    else if (statusTypeId === STATUS_PARTIALLY_OCCUPIED) status_type = <Tag color="processing" style={{borderStyle: 'dashed'}}>{statusType}</Tag>
    else if (statusTypeId === STATUS_FULLY_OCCUPIED) status_type = <Tag color="processing">{statusType}</Tag>
    else if (statusTypeId === STATUS_BLOCKED) status_type = <Tag color="error">{statusType}</Tag>
    else if (statusTypeId === STATUS_REST) status_type = <Tag color="warning">{statusType}</Tag>
    else if (statusTypeId === STATUS_RETIRED) status_type = <Tag color="default">{statusType}</Tag>
    else if (statusTypeId === STATUS_AVAILABLE) status_type = <Tag color="success">{statusType}</Tag>
    else status_type = <>{statusType}</>

    const searchItem = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofile/", {
            params: {
                area_list: [areaId],
                item: item,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message);
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const updateItem = () => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        // setDisableSubmitButton("disabled");
        setIsLoading(true);

        axios.patch(AGISAPIURL + "batch/update/" + location.state.record.id, {}, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Batch ${response.data.batch_code} updated.`);
            navigate(MENUPATH_ITEM);
        })
        .catch( error => {
            reportError(error, "Failed to update data. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            // setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchItem();
    }, []);

    const onBack = () => {
        navigate(MENUPATH_ITEM);
    };

    const onCancel = () => {
        navigate(MENUPATH_ITEM);
    };

    const collectItemRawData = ( response ) => {
        setItemProfileCode(response.data.results[0].item_profile_code);

        if (response.data.results[0].item_type) {
            setItemTypeProp(response.data.results[0].item_type.item_type_properties.description);
            setItemTypePropSize(response.data.results[0].item_type.item_type_properties.size + " " + response.data.results[0].item_type.item_type_properties.unit_of_measurement.unit);

            if (response.data.results[0].item_type.item_type_properties.is_area_fixed) setItemTypePropAreaFixed(<Tag color="success">{OTHERSYSPARAM("YES")}</Tag>)
            else setItemTypePropAreaFixed(<Tag color="error">{OTHERSYSPARAM("NO")}</Tag>)
        }
        else {
            setItemTypeProp(VALUE_NOT_APPLICABLE);
            setItemTypePropSize(0);
            setItemTypePropAreaFixed(VALUE_NOT_APPLICABLE);
        }

        if (response.data.results[0].use_batch) setUseBatch(<Tag color="success">{OTHERSYSPARAM("YES")}</Tag>)
        else setUseBatch(<Tag color="error">{OTHERSYSPARAM("NO")}</Tag>)
    };

    //--------------------------
    // Show Item Type Properties
    //--------------------------
    const showItemTypePropertiesPopup = () => {
        const modal = Modal.info({
            title: <div><h3>Item Type Properties:</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: (
                <><Card title={<Title level={5}>{itemTypeProp}<br/></Title>}>
                    <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Size:</Card.Grid>
                    <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{itemTypePropSize}</Card.Grid>
                    <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Area fixed:</Card.Grid>
                    <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{itemTypePropAreaFixed}</Card.Grid>
                </Card></>
            ),
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Edit item confirmed?</p></Space>,
            onOk() { updateItem() },
            onCancel() {},
            centered: true
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Item Update" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Edit Item</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center"><Col span={12}>
                    <Card title={<Title level={5}>{`Item Code: ${itemCode}`}<br/></Title>}>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Description:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{description}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Area (Zone):</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{area}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Inventory Repository:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{isInventoryRepository ? <Tag color="success">{OTHERSYSPARAM("YES")}</Tag> : <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Item Status:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{status_type}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Unit of Plants Occupied:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{parseInt(amountOccupied)}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Planting Cycle Count (Revive):</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{parseInt(plantingCycleRevive)}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Planting Cycle Count (Rehabilitate):</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{parseInt(plantingCycleRehabilitate)}</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Available Quantity:</Card.Grid>
                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{parseInt(availableQuantity)} / {parseInt(totalQuantity)}</Card.Grid>

                        <Card.Grid hoverable={false} style={{width: '100%', textAlign: 'left', padding: paddingCardGrid}}>
                            <Collapse>
                                <Panel header="Item Profile" key="1">
                                    <Card title={<Title level={5}>{`Item Profile Code: ${itemProfileCode}`}<br/></Title>}>
                                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Item Cateogry:</Card.Grid>
                                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{itemCategory}</Card.Grid>
                                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Item Group:</Card.Grid>
                                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{itemGroup}</Card.Grid>
                                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Item Type:</Card.Grid>
                                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{itemType} { itemType !== VALUE_NOT_APPLICABLE ? <InfoCircleTwoTone onClick={showItemTypePropertiesPopup} /> : <></>}</Card.Grid>
                                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Item Variety:</Card.Grid>
                                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{itemVariety}{ itemVarietyYield !== VALUE_NOT_APPLICABLE ? <Popover content={<Text strong>{itemVarietyYield} {itemVarietyYieldUom}</Text>} title={<Text strong>Expected Yield</Text>} trigger="click" placement="right"> <InfoCircleTwoTone /></Popover> : <></> }</Card.Grid>
                                        <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Use Batch:</Card.Grid>
                                        <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{useBatch}</Card.Grid>
                                    </Card>
                                </Panel>
                            </Collapse>
                        </Card.Grid>
                    </Card>

                    <Row><Col><Space><br/></Space></Col></Row>
                    { isInventoryRepository ? <ItemDistributionTable itemId={item}/> : <></>}
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row justify="center"><Col span={12}>
                    <Form form={form} onFinish={onSave} autoComplete="off">
                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}/>
                            <Col span={12} offset={3}><Button htmlType="button" disabled={disableCancelButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button></Col>
                            <Col span={6}/>
                        </Row>
                    </Form>
                </Col></Row>
            </Content>
            <Footer><Row justify="center"><PageHeader title="Item Update" subTitle="Edit Item" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default ItemUpdate;