import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Table, Collapse, Form, Button, Select, message, Spin } from 'antd';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, getUserSiteName, refreshUserSession } from "../Common/UserSession";
import axios from 'axios';
import { AGISAPIURL, MENUPATH_ITEMCREATENEW, MENUPATH_ITEMUPDATE, AREA_TYPE_ALL_LIST, APPJSON, UNIDATETIMEFORMAT, LOADING, VALUE_NOT_APPLICABLE, ITEM_GROUP_LEAFY_PLANT, PAGESIZE } from '../Common/SysParameters';
import { reportError } from '../Common/Utility';
import StatusTypeSelect from '../Common/StatusTypeSelect';
import { formLayout } from '../Common/Layout';
import { getItemTableColumns } from './ItemTableColumnsSelection';
import { PlusCircleTwoTone, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Panel } = Collapse;
const { Option } = Select;

const ItemTable = () => {
    const FileDownload = require('js-file-download')
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemTableData, setItemTableData] = useState([]);
    const [area, setArea] = useState(0);
    const [item, setItem] = useState(0);
    const [status_type, setStatusType] = useState(0);
    const [item_category, setItemCategory] = useState(0);
    const [item_group, setItemGroup] = useState(ITEM_GROUP_LEAFY_PLANT);
    const [item_type, setItemType] = useState(0);
    const [item_variety, setItemVariety] = useState(0);
    const [itemCategoryOption, setItemCategoryOption] = useState("");
    const [itemGroupOption, setItemGroupOption] = useState("");
    const [itemTypeOption, setItemTypeOption] = useState("");
    const [itemVarietyOption, setItemVarietyOption] = useState("");
    const [areaOption, setAreaOption] = useState("");
    const [itemOption, setItemOption] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingItem, setIsLoadingItem] = useState(false);
    const [isLoadingArea, setIsLoadingArea] = useState(false);
    const [columns, setColumns] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    // 2023-05-22
    // For pdf, excel search criteria.
    const [itemCategoryVal, setItemCategoryVal] = useState("");
    const [itemGroupVal, setItemGroupVal] = useState("Leafy Plant");
    const [itemTypeVal, setItemTypeVal] = useState("");
    const [itemVarietyVal, setItemVarietyVal] = useState("");
    const [areaVal, setAreaVal] = useState("");
    const [itemVal, setItemVal] = useState("");
    const [statusTypeVal, setStatusTypeVal] = useState("");

    const SearchItem = (currentPage) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemtable/", {
            params: {
                site: getUserSiteId(),
                area_type_list: AREA_TYPE_ALL_LIST,
                area: area,
                item: item,
                item_category: item_category,
                item_group: item_group,
                item_type: item_type,
                item_variety: item_variety,
                status_type: status_type,
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setColumns(getItemTableColumns(item_group));
            setIsLoading(false);
            refreshUserSession();
        });
    };

    // 2023-05-22
    // Download pdf, excel search result.
    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(item_category != 0) searchCriteria = `${searchCriteria}\nItem Category: ${itemCategoryVal}`
        if(item_group != 0) searchCriteria = `${searchCriteria}\nItem Group: ${itemGroupVal}`
        if(item_type != 0) searchCriteria = `${searchCriteria}\nItem Type: ${itemTypeVal}`
        if(item_variety != 0) searchCriteria = `${searchCriteria}\nItem Variety: ${itemVarietyVal}`
        if(area != 0) searchCriteria = `${searchCriteria}\nArea: ${areaVal}`
        if(item != 0) searchCriteria = `${searchCriteria}\nItem: ${itemVal}`
        if(status_type != 0) searchCriteria = `${searchCriteria}\nItem Status: ${statusTypeVal}`

        axios.get(`${AGISAPIURL}download/itemtable/`, {
            params: {
                site: getUserSiteId(),
                area_type_list: AREA_TYPE_ALL_LIST,
                area: area,
                item: item,
                item_category: item_category,
                item_group: item_group,
                item_type: item_type,
                item_variety: item_variety,
                status_type: status_type,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Item ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    }

    const getItemCategory = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemcategory/", {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( category => {
                    return <Option key={category.id} value={category.id}>{category.name}</Option>;
                }));
            setItemCategoryOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItemGroup = ({item_category = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofilegroup/", {
            params: {
                item_category: item_category,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => {
                    return <Option key={itemprofile.item_group.id} value={itemprofile.item_group.id}>{itemprofile.item_group.name}</Option>;
                }));
            setItemGroupOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItemType = ({item_category = "", item_group = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofiletype/", {
            params: {
                item_category: item_category,
                item_group: item_group,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => {
                    return <Option key={itemprofile.item_type.id} value={itemprofile.item_type.id}>{itemprofile.item_type.item_type_code}</Option>;
                }));
            setItemTypeOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItemVariety = ({item_category = "", item_group = "", item_type = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofilevariety/", {
            params: {
                item_category: item_category,
                item_group: item_group,
                item_type: item_type,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => {
                    return <Option key={itemprofile.item_variety.id} value={itemprofile.item_variety.id}>{itemprofile.item_variety.name}</Option>;
                }));
            setItemVarietyOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getArea = ({item_category = "", item_group = "", item_type = "", item_variety = ""}) => {
        setIsLoadingArea(true);

        axios.get(AGISAPIURL + "itemprofilearea/", {
            params: {
                site: getUserSiteId(),
                item_category: item_category,
                item_group: item_group,
                item_type: item_type,
                item_variety: item_variety,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => {
                    let zoneName = "[" + itemprofile.item.area.area_type.description + "] " + itemprofile.item.area.area_code;
                    return <Option key={itemprofile.item.area.id} value={itemprofile.item.area.id}>{zoneName}</Option>;
                }));
            setAreaOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingArea(false);
            refreshUserSession();
        });
    };

    const getItem = ({item_category = "", item_group = "", item_type = "", item_variety = "", area = ""}) => {
        setIsLoadingItem(true);

        axios.get(AGISAPIURL + "itemtable/", {
            params: {
                site: getUserSiteId(),
                area_type_list: AREA_TYPE_ALL_LIST,
                area: area,
                item_category: item_category,
                item_group: item_group,
                item_type: item_type,
                item_variety: item_variety,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.map( item => <Option key={item.id} value={item.id}>{item.description}</Option>));
            setItemOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingItem(false);
            refreshUserSession();
        });
    };

    useEffect(() => {
        SearchItem(currentPage);
        getItemCategory();
        getItemGroup({});
        getItemType({});
        getItemVariety({});
        getArea({});
        getItem({ item_group: item_group });
    }, []);

    const collectItemRawData = ( response ) => {
        const data = []
        response.data.results.forEach( item => {
            let area = VALUE_NOT_APPLICABLE;
            let areaId = 0;
            let status_type = VALUE_NOT_APPLICABLE;
            let status_type_id = 0;
            let amount_occupied_uom_sign = "";
            let item_type = VALUE_NOT_APPLICABLE;
            let item_variety = VALUE_NOT_APPLICABLE;
            let item_variety_yield = VALUE_NOT_APPLICABLE;
            let item_variety_yield_uom = VALUE_NOT_APPLICABLE;
            if (item.area) {
                area = item.area.area_code;
                areaId = item.area.id;
            };
            if (item.amount_occupied_uom) amount_occupied_uom_sign = item.amount_occupied_uom.unit;
            if (item.status_type) {
                status_type = item.status_type.description;
                status_type_id = item.status_type.id;
            }
            if (item.item_profile.item_type) item_type = item.item_profile.item_type.item_type_code;
            if (item.item_profile.item_variety) {
                item_variety = item.item_profile.item_variety.name;
                item_variety_yield = item.item_profile.item_variety.expected_yield;
                item_variety_yield_uom = item.item_profile.item_variety.expected_yield_uom.unit;
            };

            data.push({
                key: item.id,
                id: item.id,
                item_code: item.item_code,
                description: item.description,
                status_type: status_type,
                status_type_id: status_type_id,
                available_quantity: item.available_quantity,
                amount_occupied: item.amount_occupied,
                amount_occupied_uom_sign: amount_occupied_uom_sign,
                area: area,
                areaId: areaId,
                planting_cycle_after_revive: item.planting_cycle_after_revive,
                planting_cycle_after_rehabilitate: item.planting_cycle_after_rehabilitate,
                total_quantity: item.total_quantity,
                is_inventory_repository: item.is_inventory_repository,
                item_category: item.item_profile.item_category.name,
                item_group: item.item_profile.item_group.name,
                item_type: item_type,
                item_variety: item_variety,
                item_variety_yield: item_variety_yield,
                item_variety_yield_uom: item_variety_yield_uom,
            })
        });
        setItemTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const onChangeItemCategory = (key, value) => {
        if (key !== undefined) {
            setItemCategory(key);
            setItemCategoryVal(value.children);
        } else {
            setItemCategory(0);
            setItemCategoryVal("");
        };

        form.setFieldsValue({
            itemGroup: "",
            itemType: "",
            itemVariety: "",
            area: "",
            item: "",
        })
        setItemGroup(0);
        setItemGroupVal("");
        setItemType(0);
        setItemTypeVal("");
        setItemVariety(0);
        setItemVarietyVal("");
        setArea(0);
        setAreaVal("");
        setItem(0);
        setItemVal("");

        getItemGroup({ item_category: key });
        getItemType({ item_category: key });
        getItemVariety({ item_category: key });
        getArea({ item_category: key });
        getItem({ item_category: key });
    };

    const onChangeItemGroup = (key, value) => {
        if(key !== undefined) {
            setItemGroup(key);
            setItemGroupVal(value.children);
        } else {
            setItemGroup(0);
            setItemGroupVal("");
        };

        form.setFieldsValue({
            itemType: "",
            itemVariety: "",
            area: "",
            item: "",
        })
        setItemType(0);
        setItemTypeVal("");
        setItemVariety(0);
        setItemVarietyVal("");
        setArea(0);
        setAreaVal("");
        setItem(0);
        setItemVal("");

        getItemType({ item_category: item_category, item_group: key });
        getItemVariety({ item_category: item_category, item_group: key });
        getArea({ item_category: item_category, item_group: key });
        getItem({ item_category: item_category, item_group: key });
    };

    const onChangeItemType = (key, value) => {
        if (key !== undefined) {
            setItemType(key);
            setItemTypeVal(value.children);
        } else {
            setItemType(0);
            setItemTypeVal("");
        };

        form.setFieldsValue({
            itemVariety: "",
            area: "",
            item: "",
        })
        setItemVariety(0);
        setItemVarietyVal("");
        setArea(0);
        setAreaVal("");
        setItem(0);
        setItemVal("");

        getItemVariety({ item_category: item_category, item_group: item_group, item_type: key });
        getArea({ item_category: item_category, item_group: item_group, item_type: key });
        getItem({ item_category: item_category, item_group: item_group, item_type: key });
    };

    const onChangeItemVariety = (key, value) => {
        if (key !== undefined) {
            setItemVariety(key);
            setItemVarietyVal(value.children);
        } else {
            setItemVariety(0);
            setItemVarietyVal("");
        };

        form.setFieldsValue({
            area: "",
            item: "",
        })
        setArea(0);
        setAreaVal("");
        setItem(0);
        setItemVal("");

        getArea({ item_category: item_category, item_group: item_group, item_type: item_type, item_variety: key });
        getItem({ item_category: item_category, item_group: item_group, item_type: item_type, item_variety: key });
    };

    const onChangeArea = (key, value) => {
        if (key !== undefined) {
            setArea(key);
            setAreaVal(value.children);
        } else {
            setArea(0);
            setAreaVal("");
        };

        form.setFieldsValue({
            item: "",
        })
        setItem(0);
        setItemVal("");

        getItem({ item_category: item_category, item_group: item_group, item_type: item_type, item_variety: item_variety, area: key });
    };

    const onChangeItem = (key, value) => {
        if (key !== undefined) {
            setItem(key);
            setItemVal(value.children);
        } else {
            setItem(0);
            setItemVal("");
        };
    };

    const onChangeStatusType = (key, value) => {
        if (key !== undefined) {
            setStatusType(key);
            setStatusTypeVal(value.children);
        } else {
            setStatusType(0);
            setStatusTypeVal("");
        };
    };

    const itemCategoryClear = () => {
        form.setFieldsValue({
            itemCategory: "",
            itemGroup: "",
            itemType: "",
            itemVariety: "",
            area: "",
            item: "",
        })
        setItemGroup(0);
        setItemGroupVal("");
        setItemType(0);
        setItemTypeVal("");
        setItemVariety(0);
        setItemVarietyVal("");
        setArea(0);
        setAreaVal("");
        setItem(0);
        setItemVal("");
        setItemCategory(0);
        setItemCategoryVal("");

        getItemGroup({});
        getItemType({});
        getItemVariety({});
        getArea({});
        getItem({});
    };

    const itemGroupClear = () => {
        form.setFieldsValue({
            itemGroup: "",
            itemType: "",
            itemVariety: "",
            area: "",
            item: "",
        })
        setItemType(0);
        setItemTypeVal("");
        setItemVariety(0);
        setItemVarietyVal("");
        setArea(0);
        setAreaVal("");
        setItem(0);
        setItemVal("");
        setItemGroup(0);
        setItemGroupVal("");

        getItemType({ item_category: item_category });
        getItemVariety({ item_category: item_category });
        getArea({ item_category: item_category });
        getItem({ item_category: item_category });
    };

    const itemTypeClear = () => {
        form.setFieldsValue({
            itemType: "",
            itemVariety: "",
            area: "",
            item: "",
        })
        setItemVariety(0);
        setItemVarietyVal("");
        setArea(0);
        setAreaVal("");
        setItem(0);
        setItemVal("");
        setItemType(0);
        setItemTypeVal("");

        getItemVariety({ item_category: item_category, item_group: item_group });
        getArea({ item_category: item_category, item_group: item_group });
        getItem({ item_category: item_category, item_group: item_group });
    };

    const itemVarietyClear = () => {
        form.setFieldsValue({
            itemVariety: "",
            area: "",
            item: "",
        })
        setArea(0);
        setAreaVal("");
        setItem(0);
        setItemVal("");
        setItemVariety(0);
        setItemVarietyVal("");

        getArea({ item_category: item_category, item_group: item_group, item_type: item_type });
        getItem({ item_category: item_category, item_group: item_group, item_type: item_type });
    };

    const areaClear = () => {
        form.setFieldsValue({
            area: "",
            item: "",
        })
        setItem(0);
        setItemVal("");
        setArea(0);
        setAreaVal("");

        getItem({ item_category: item_category, item_group: item_group, item_type: item_type, item_variety: item_variety });
    };

    const onSearch = () => {
        setCurrentPage(1);
        SearchItem(1);
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onCreateNewItem = () => {
        // navigate(MENUPATH_ITEMCREATENEW);
    };

    const onClickTableRow = (record, rowIndex) => {
        return {
            onClick: () => {
                navigate(MENUPATH_ITEMUPDATE, {state: {record: record}});
            }
        };
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchItem(page);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse defaultActiveKey={['1']}>
                <Panel header="Search Item" key="1">
                    <Form form={form} {...formLayout}>
                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item initialValue={item_category} name="itemCategory" label="Item Category">
                                    <Select onChange={onChangeItemCategory} allowClear={true} onClear={itemCategoryClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {itemCategoryOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={item_group} name="itemGroup" label="Item Group">
                                    <Select onChange={onChangeItemGroup} allowClear={true} onClear={itemGroupClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {itemGroupOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={item_type} name="itemType" label="Item Type">
                                    <Select onChange={onChangeItemType} allowClear={true} onClear={itemTypeClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {itemTypeOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={item_variety} name="itemVariety" label="Item Variety">
                                    <Select onChange={onChangeItemVariety} allowClear={true} onClear={itemVarietyClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {itemVarietyOption}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Spin spinning={isLoadingArea} size="large" tip={LOADING}>
                                    <Form.Item initialValue={area} name="area" label="Area (Zone)">
                                        <Select onChange={onChangeArea} allowClear={true} onClear={areaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                            {areaOption}
                                        </Select>
                                    </Form.Item>
                                </Spin>

                                <Spin spinning={isLoadingItem} size="large" tip={LOADING}>
                                    <Form.Item initialValue={item} name="item" label="Item">
                                        <Select onChange={onChangeItem} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                            {itemOption}
                                        </Select>
                                    </Form.Item>
                                </Spin>

                                <Form.Item initialValue={status_type} name="status" label="Item Status">
                                    <StatusTypeSelect withBlank={true} onChange={onChangeStatusType} is_item={1}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>
            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewItem} type="primary"><PlusCircleTwoTone /> New Item</Button></Col></Row>
            <Row><Col><Space><br/></Space></Col></Row>

            <Table onRow={onClickTableRow} columns={columns} dataSource={itemTableData}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: PAGESIZE,
                    current: currentPage,
                    total: totalRecord,
                    showTotal: showTotal,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                }}/>

            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewItem} type="primary"><PlusCircleTwoTone /> New Item</Button></Col></Row>
        </Spin>
        </>
    );
};

export default ItemTable;