import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Table, Collapse, Form, Button, Select, Spin, Tag, Modal, Avatar, Badge } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from "../Common/UserSession";
import axios from 'axios';
import { AGISAPIURL, UNIDATEFORMAT, LOADING, MENUPATH_USERCREATENEW, MENUPATH_USERUPDATE } from '../Common/SysParameters';
import { reportError } from '../Common/Utility';
import { formLayout } from '../Common/Layout';
import moment from 'moment';
import TrueFalseSelect from '../Common/TrueFalseSelect';
import { PlusCircleTwoTone, ShopOutlined } from '@ant-design/icons';


const { Panel } = Collapse;
const { Option } = Select;

const UserTable = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [userOption, setUserOption] = useState("");
    const [groupOption, setGroupOption] = useState("");
    const [userTableData, setUserTableData] = useState([]);
    const [user, setUser] = useState(0);
    const [group, setGroup] = useState(0);
    const [isActive, setIsActive] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const SearchUser = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "user/", {
            params: {
                user: user,
                group: group,
                is_active: (isActive !== "") ? ((isActive) ? 1 : 0) : "",
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectUserRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getUser = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "user/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( user => <Option key={user.id} value={user.id}>{user.username}</Option>));
            setUserOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getGroup = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "usergroup/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( group => <Option key={group.id} value={group.id}>{group.name}</Option>));
            setGroupOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    useEffect(() => {
        SearchUser();
        getUser();
        getGroup();
    }, []);

    const collectUserRawData = ( response ) => {
        const data = []
        response.data.results.forEach( user => {
            data.push({
                key: user.id,
                id: user.id,
                username: user.username,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                isActive: user.is_active,
                dateJoined: moment(user.date_joined).format(UNIDATEFORMAT),
                groupId: user.group.id,
                group: user.group.name,
                defaultSiteId: user.default_site.site_data.id,
                defaultSite: user.default_site.site_data.name,
                allowedSites: collectAllowedSitesRawData(user.allowed_sites),
                whatsapp_contact: user.other_info.whatsapp_contact,
            })
        });
        setUserTableData(data);
    };

    const collectAllowedSitesRawData = ( response ) => {
        const data = []
        response.forEach( allowedSite => {
            data.push({
                key: allowedSite.site_data.id,
                id: allowedSite.site_data.id,
                site: allowedSite.site_data.name,
                is_default: allowedSite.default,
            })
        });
        return data;
    };

    const onChangeUser = (value) => {
        setUser(value);
    };

    const onChangeGroup = (value) => {
        setGroup(value);
    };

    const onChangeIsActive = (value) => {
        setIsActive(value);
    };

    const onSearch = () => {
        SearchUser();
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onCreateNewUser = () => {
        navigate(MENUPATH_USERCREATENEW);
    };

    const onClickTableRow = (record, rowIndex) => {
        return {
            onClick: () => {
                navigate(MENUPATH_USERUPDATE, {state: {record: record}});
            }
        };
    };

    const onClickAllowedSites = (e, allowedSites) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Allowed Site(s)</h3></div>,
            icon: null,
            width: '30%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: (<ul>{allowedSites.map( allowedSite => <li key={allowedSite["id"]}>{allowedSite["site"]}</li>)}</ul>),
        })
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Username', dataIndex: 'username', key: 'username', sorter: (a, b) => a.username.localeCompare(b.username) },
        { title: 'Email', dataIndex: 'email', key: 'email', sorter: (a, b) => a.email.localeCompare(b.email) },
        { title: 'Group', dataIndex: 'group', key: 'group', sorter: (a, b) => a.group.localeCompare(b.group) },
        { title: 'Is Active', dataIndex: 'isActive', key: 'isActive', sorter: (a, b) => a.isActive - b.isActive,
            render: (isActive) => {
                if(isActive)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        { title: 'Default Site', dataIndex: 'defaultSite', key: 'defaultSite', sorter: (a, b) => a.defaultSite.localeCompare(b.defaultSite) },
        { title: 'Allowed Sites(s)', dataIndex: 'allowedSites', render: (allowedSites) => {
            return (
                <Badge count={allowedSites.length} onClick={(e) => onClickAllowedSites(e, allowedSites)}>
                    <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<ShopOutlined />} />
                </Badge>);
        }},
        { title: 'Date Joined', dataIndex: 'dateJoined', key: 'dateJoined', sorter: (a, b) => a.dateJoined.localeCompare(b.dateJoined) },
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search User" key="1">
                    <Form form={form} {...formLayout}>
                        <Form.Item initialValue={user} name="user" label="Username">
                            <Select onChange={onChangeUser} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {userOption}
                            </Select>
                        </Form.Item>

                        <Form.Item initialValue={group} name="group" label="Group">
                            <Select onChange={onChangeGroup} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {groupOption}
                            </Select>
                        </Form.Item>

                        <Form.Item initialValue={isActive} name="isActive" label="Is Active">
                            <TrueFalseSelect withBlank={true} defaultSelect={" "} onChange={onChangeIsActive}/>
                        </Form.Item>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>
            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewUser} type="primary"><PlusCircleTwoTone /> New User</Button></Col></Row>
            <Row><Col><Space><br/></Space></Col></Row>

            <Table onRow={onClickTableRow} columns={columns} dataSource={userTableData} pagination={{size: "small", position: ["bottomCenter"], total: userTableData.length, showTotal: showTotal}}/>

            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewUser} type="primary"><PlusCircleTwoTone /> New User</Button></Col></Row>
        </Spin>
        </>
    );
};

export default UserTable;