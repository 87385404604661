import React from 'react';
import { Table } from 'antd';
import { UNIT_OF_MEASUREMENT_PERCENTAGE_SIGN } from '../Common/SysParameters';

const HarvestingLocationTable = ({dataSource}) => {

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Location', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Area', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'Unit Amount', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item },
        { title: 'Occupied Percentage', dataIndex: 'occupied_perc', sorter: (a, b) => a.occupied_perc - b.occupied_perc, render: (occupied_perc) => {
            return (<>{occupied_perc}{UNIT_OF_MEASUREMENT_PERCENTAGE_SIGN}</>)
        }},
        { title: 'Assigned Worker', dataIndex: 'worker', sorter: (a, b) => a.worker.localeCompare(b.worker) },
    ];

    return (
        <Table columns={columns} dataSource={dataSource} pagination={{size: "small", position: ["bottomCenter"], total: dataSource.length, showTotal: showTotal}}/>
    );
};

export default HarvestingLocationTable;