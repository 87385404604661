import React , { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Typography, Spin } from 'antd';
import { AGISAPIURL, UNIT_OF_MEASUREMENT_UNIT, UNIDATETIMEFORMAT, LOADING } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import { reportError } from "../Common/Utility";
import moment from 'moment';

const { Title, Text } = Typography;

const BatchActivitiesSummaryTable = ({batchId}) => {

    const [locationTableData, setLocationTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const searchCurrentLocation = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "activitysummary/", {
            params: {
                batch: batchId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectLocationRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectLocationRawData = ( response ) => {
        const data = []
        response.data.results.forEach( activitySummary => {
            let maxUnit = 0;
            let quantity_item_secondary_uom = "";
            let reference_id = "";
            if (activitySummary.item_repository_profile) {
                if (activitySummary.item_repository_profile.primary_uom.id === UNIT_OF_MEASUREMENT_UNIT) {
                    maxUnit = activitySummary.item_repository_profile.primary_quantity;
                }
                else if (activitySummary.item_repository_profile.secondary_uom.id === UNIT_OF_MEASUREMENT_UNIT) {
                    maxUnit = activitySummary.item_repository_profile.secondary_quantity;
                }
            }
            if (activitySummary.reference_transaction) reference_id = activitySummary.reference_transaction;
            if (activitySummary.quantity_item_secondary_uom) quantity_item_secondary_uom = activitySummary.quantity_item_secondary_uom.unit;

            data.push({
                key: activitySummary.id,
                id: activitySummary.id,
                item_item: activitySummary.item_item.item_code,
                item_tool: activitySummary.item_tool.item_code,
                task_type: activitySummary.task_type.task,
                area: activitySummary.area.area_code,
                quantity_tool: activitySummary.quantity_tool,
                quantity_item: activitySummary.quantity_item,
                quantity_item_uom: activitySummary.quantity_item_uom.unit,
                quantity_item_perc: activitySummary.quantity_item_perc,
                quantity_item_uom_perc: activitySummary.quantity_item_uom_perc.unit,
                maxUnit: parseInt(maxUnit * Math.ceil(Math.abs(activitySummary.quantity_tool))),
                quantity_item_secondary: activitySummary.quantity_item_secondary,
                quantity_item_secondary_uom: quantity_item_secondary_uom,
                actual_executed_datetime: moment(activitySummary.actual_executed_datetime).format(UNIDATETIMEFORMAT),
                reference_id: reference_id,
            })
        });
        setLocationTableData(data);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchCurrentLocation();
    }, []);

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Transaction ID', dataIndex: 'id', sorter: (a, b) => a.id - b.id },
        { title: 'Date', dataIndex: 'actual_executed_datetime', sorter: (a, b) => a.actual_executed_datetime.localeCompare(b.actual_executed_datetime) },
        { title: 'Task Type', dataIndex: 'task_type', sorter: (a, b) => a.task_type.localeCompare(b.task_type) },
        { title: 'Medium', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        // { title: 'Quantity', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: 'Item', dataIndex: 'item_item', sorter: (a, b) => a.item_item.localeCompare(b.item_item) },
        { title: 'Units of Item over Medium Size', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item,
            render: (quantity_item, record) => {
                if (record.quantity_item_uom_perc) return (<Text>{parseInt(quantity_item)} / {record.maxUnit} {record.quantity_item_uom} ({parseInt(record.quantity_item_perc)} {record.quantity_item_uom_perc})</Text>)
                else return (<Text>{parseInt(quantity_item)} / {record.maxUnit} {record.quantity_item_uom}</Text>)
            }
        },
        { title: 'Amount Harvested', dataIndex: 'quantity_item_secondary', sorter: (a, b) => a.quantity_item_secondary - b.quantity_item_secondary,
            render: (quantity_item_secondary, record) => {
                if (record.reference_id) return (<><Text>Please Refer </Text><Text keyboard>Trans ID {record.reference_id}</Text></>);
                else if (record.quantity_item_secondary_uom) return (<Text>{parseFloat(quantity_item_secondary)} {record.quantity_item_secondary_uom}</Text>);
            }
        },
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Title level={5}>[History] Activities Summary:</Title>
            <Table columns={columns} dataSource={locationTableData} pagination={{size: "small", position: ["bottomCenter"], total: locationTableData.length, showTotal: showTotal}}/>
        </Spin>
        </>
    );

};

export default BatchActivitiesSummaryTable;