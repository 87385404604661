import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Spin, Table, Transfer, Tag, Button } from 'antd';
import difference from 'lodash/difference';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import { reportError } from "../Common/Utility";
import axios from 'axios';
import { AGISAPIURL, LOADING, VALUE_NONE } from '../Common/SysParameters';
import { SaveOutlined, CloseCircleFilled } from '@ant-design/icons';


const { Content, Footer } = Layout;

const WorkProgrammeTransferTable = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const prevRecord = location.state.record;

    const navigate = useNavigate();
    const [targetKeys, setTargetKeys] = useState(prevRecord.work_programme_key_list);
    const [workProgTableData, setWorkProgTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getWorkProgramme = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "workprogramme/", {
            params: {
                site: getUserSiteId(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectWorkProgrammeRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectWorkProgrammeRawData = ( response ) => {
        const data = [];
        response.data.forEach( workProg => {
            let item = VALUE_NONE;
            if (workProg.item) item = workProg.item.item.description;

            data.push({
                key: workProg.id,
                id: workProg.id,
                work_programme_code: workProg.work_programme_code,
                description: workProg.description,
                item: item,
                quantity_item: parseInt(workProg.quantity_item),
                is_active: workProg.workday.is_active,
            });
        });
        setWorkProgTableData(data);
    };

    const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
        <Transfer {...restProps}>
            {({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys }) => {
                const columns = direction === 'left' ? leftColumns : rightColumns;
                const rowSelection = {
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows.map(({ key }) => key);
                        const diffKeys = selected ? difference(treeSelectedKeys, listSelectedKeys) : difference(listSelectedKeys, treeSelectedKeys);
                        onItemSelectAll(diffKeys, selected);
                    },
                    onSelect({ key }, selected) { onItemSelect(key, selected) },
                    selectedRowKeys: listSelectedKeys,
                };

                return (<Table rowSelection={rowSelection} columns={columns} dataSource={filteredItems} size="small" onRow={({ key }) => ({ onClick: () => { onItemSelect(key, !listSelectedKeys.includes(key)) } })}/>);
            }}
        </Transfer>
    );

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getWorkProgramme();
    }, []);

    const onChange = (nextTargetKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    const onSave = () => {
        let existingProg = [];
        let newProg = [];

        // Keep priority info for the existing Work Programme
        targetKeys.forEach(key => {
            if (prevRecord.workProgrammeList.some(item => item.id === key)) existingProg.push(prevRecord.workProgrammeList.find(item => item.id === key))
            else newProg.push(workProgTableData.find(item => item.id === key));  // Store newly added Work Programme at different list
        });
        prevRecord.workProgrammeList = existingProg.concat(newProg);  // Add the newly Work Programmes at the bottom
        prevRecord.reset_priority = true;
        navigate(location.state.returnPage, {state: {record: prevRecord}});
    };

    const onBack = () => {
        prevRecord.reset_priority = false;
        navigate(location.state.returnPage, {state: {record: prevRecord}});
    };

    const columns = [
        { title: 'Work Programme Code', dataIndex: 'work_programme_code', sorter: (a, b) => a.work_programme_code.localeCompare(b.work_programme_code) },
        { title: 'Description', dataIndex: 'description', sorter: (a, b) => a.description.localeCompare(b.description) },
        { title: 'Plant Species', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Unit Amount', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item },
        { title: 'Active', dataIndex: 'is_active', sorter: (a, b) => a.is_active - b.is_active, render: (is_active) => {
            if (is_active) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title={location.state.title}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">{location.state.description}</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col offset={1} span={22}>
                    <TableTransfer dataSource={workProgTableData} targetKeys={targetKeys} showSearch={true} onChange={onChange} titles={["Available Work Programme(s)", "Assigned Work Programme(s)"]}
                        filterOption={(inputValue, item) => item.work_programme_code.indexOf(inputValue) !== -1 || item.description.indexOf(inputValue) !== -1 || item.item.indexOf(inputValue) !== -1}
                        leftColumns={columns} rightColumns={columns}
                    />
                </Col>
                </Row>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row justify="center">
                    <Col span={6}/>
                    <Col span={12} offset={1}>
                        <Button htmlType="button" type="primary" onClick={onSave}><SaveOutlined/>Save Current Selections</Button>
                        <Button htmlType="button" type="primary" onClick={onBack} danger><CloseCircleFilled/>Go Back Without Saving</Button>
                    </Col>
                    <Col span={6}/>
                </Row>

            </Content>
            <Footer><Row justify="center"><PageHeader title={location.state.title} subTitle={location.state.description}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default WorkProgrammeTransferTable;