import React from 'react';
import { Layout, Row, Col, BackTop, PageHeader, Descriptions, Space } from 'antd';
import { OTHERSYSPARAM } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import AGISFooter from '../Common/AGISFooter';
import { walltiles } from '../Common/Layout';
import TransferToBatasTable from './TransferToBatasTable';


const { Content } = Layout;

const TransferToBatasMain = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    return (
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight, ...walltiles}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Transfer To Batas">
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Manage Batas Transferring</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify="center"><Col span={22}><TransferToBatasTable /></Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

            </Content>
            <AGISFooter />
            <BackTop />
        </Layout>
    );
};

export default TransferToBatasMain;