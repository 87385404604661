import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, Modal, DatePicker, Table, Input, InputNumber, Radio, message, Statistic, Spin } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import { formLayoutWithTable } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_CROPFAILURE, UNIDATETIMEFORMAT, TASK_TYPE_CROP_FAILURE, AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE, AREA_TYPE_NURSERY, UNIT_OF_MEASUREMENT_PERCENTAGE, UNIT_OF_MEASUREMENT_UNIT_SIGN,
    UNIT_OF_MEASUREMENT_UNIT, ITEM_GROUP_TRAY, STATUS_INPROGRESS, LIFECYCLE_GERMINATION, LIFECYCLE_SEEDLING, LIFECYCLE_VEGETATIVE, LIFECYCLE_MATURITY, LOADING, VALUE_NONE } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { SaveOutlined, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
const EditableContext = React.createContext(null);


const { Option } = Select;
const { confirm } = Modal;
const { Content, Footer } = Layout;
const { TextArea } = Input;

const CropFailureCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemItemOption, setItemItemOption] = useState("");
    const [batchOption, setBatchOption] = useState("");
    const [areaOption, setAreaOption] = useState("");
    const [reasonOption, setReasonOption] = useState("");
    const [itemLocationFromTableData, setItemLocationFromTableData] = useState([]);
    const [unitOfMeasurementOption, setUnitOfMeasurementOption] = useState("");
    const [task_type, setTaskType] = useState(TASK_TYPE_CROP_FAILURE);
    const [batch, setBatch] = useState(0);
    const [reason, setReason] = useState(0);
    const [areaList, setAreaList] = useState([]);
    const [area_type_list, setAreaTypeList] = useState([AREA_TYPE_NURSERY, AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE]);
    const [disableButton, setDisableButton] = useState("");
    const [disableUomButton, setDisableUomButton] = useState("");
    const [total_crop_failure, setTotalCropFailure] = useState(0);
    const [quantity_item_from_uom, setQuantityItemFromUom] = useState(UNIT_OF_MEASUREMENT_UNIT);
    const [quantity_item_from_uom_sign, setQuantityItemFromUomSign] = useState(UNIT_OF_MEASUREMENT_UNIT_SIGN);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBatch, setIsLoadingBatch] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);  // The selectRowKeys name shall not be change as this is required for Antd to control over the selected rows
    const quantity_item_label = "Total Amount of Crop Failure in ";

    const createActivity = (actual_completion_date, remark) => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "activity/create/cropfailure/", {
            site: getUserSiteId(),
            batch: batch,
            task_type: task_type,
            item_location_from_list: form.getFieldValue('item_location_from_list'),
            actual_executed_datetime: actual_completion_date.format(UNIDATETIMEFORMAT),
            quantity_tool_uom: UNIT_OF_MEASUREMENT_UNIT,
            quantity_item_from_uom: quantity_item_from_uom,
            reason: reason,
            remark_reason: remark,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New activity ${response.data[0].id} created.`);
            navigate(MENUPATH_CROPFAILURE);
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getItemItem = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofilebytasktype/", {
            params: {
                site: getUserSiteId(),
                task_type: task_type,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemItemOption(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getBatch = (itemId) => {
        setIsLoadingBatch(true);

        axios.get(AGISAPIURL + "batchavailablecropfailure/", {
            params: {
                site: getUserSiteId(),
                item: itemId,
                area_type_list: area_type_list,
                status_type: STATUS_INPROGRESS,
                lifecycle_list: [LIFECYCLE_GERMINATION, LIFECYCLE_SEEDLING, LIFECYCLE_VEGETATIVE, LIFECYCLE_MATURITY],
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setBatchOption(response.data.results.map( batch => <Option key={batch.id} value={batch.id}>{batch.batch_code}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingBatch(false);
            refreshUserSession();
        });
    };

    let promiseGetArea = function (batchId) {
        return new Promise(function(myResolve, myReject) {
            setIsLoading(true);

            axios.get(AGISAPIURL + "batcharea/", {
                params: {
                    site: getUserSiteId(),
                    batch: batchId,
                    area_type_list: area_type_list,
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`},
            })
            .then( response => {
                let options = response.data.results.map( area => {
                        let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                        return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                    });
                myResolve(options);
            })
            .catch( error => {
                myReject(error);
            })
            .finally(() => {
                setIsLoading(false);
                refreshUserSession();
            });
        });
    };

    const getItemLocationFrom = ({batch = "", areaList = [], uom = UNIT_OF_MEASUREMENT_UNIT}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batchiteminventorysummary/", {
            params: {
                batch: batch,
                area_list: areaList,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemLocationFromRawData(response, uom);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectItemLocationFromRawData = ( response, uom ) => {
        const data = []
        response.data.results.forEach( batchItemInventoryTransactionSummary => {
            let quantity_item = 100;
            if (uom === UNIT_OF_MEASUREMENT_UNIT) quantity_item = parseInt(batchItemInventoryTransactionSummary.quantity_item);

            data.push({
                key: batchItemInventoryTransactionSummary.item_tool.id,
                id: batchItemInventoryTransactionSummary.item_tool.id,
                item_code: batchItemInventoryTransactionSummary.item_tool.item_code,
                description: batchItemInventoryTransactionSummary.item_tool.description,
                item_area: batchItemInventoryTransactionSummary.area.area_code,
                quantity_tool: batchItemInventoryTransactionSummary.quantity_tool,
                quantity_item: quantity_item,
                quantity_item_max: parseInt(batchItemInventoryTransactionSummary.quantity_item),
                total_item: parseInt(batchItemInventoryTransactionSummary.quantity_item),
            });
        });
        setItemLocationFromTableData(data);
    };

    const getUnitOfMeasurement = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemgroupuom/", {
            params: {
                item_group_list: [ITEM_GROUP_TRAY],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = [];
            response.data.results.map( itemgroupuom => {
                if (itemgroupuom.unit_of_measurement.id === UNIT_OF_MEASUREMENT_UNIT || itemgroupuom.unit_of_measurement.id === UNIT_OF_MEASUREMENT_PERCENTAGE) {
                    options = options.concat(<Radio.Button key={itemgroupuom.unit_of_measurement.id} value={itemgroupuom.unit_of_measurement.id} label={itemgroupuom.unit_of_measurement.unit}>{itemgroupuom.unit_of_measurement.unit}</Radio.Button>);
                };
            });
            setUnitOfMeasurementOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getReason = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "cropfailurereason/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setReasonOption(response.data.results.map( reason => <Radio key={reason.id} value={reason.id}>{reason.reason}</Radio>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const onChangeBatch = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                area_from: [],
                item_location_from_list: "",
            })
            setAreaList([]);
            onChangeItemLocationFrom([]);
            setBatch(value);

            promiseGetArea(value).then(
                function(value) {setAreaOption(value)},
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            );
            getItemLocationFrom({ batch: value, uom: quantity_item_from_uom });
        };
    };

    const onChangeArea = (value) => {
        onChangeItemLocationFrom([]);
        setAreaList(value);
        getItemLocationFrom({ batch: batch, areaList: value, uom: quantity_item_from_uom });
    };

    const onChangeQuantityItemUom = (e) => {
        setQuantityItemFromUom(e.target.value);
        setQuantityItemFromUomSign(e.target.label);
        onChangeItemLocationFrom([]);
        getItemLocationFrom({ batch: batch, areaList: areaList, uom: e.target.value });
    };

    const onChangeItemLocationFrom = (newSelectedRowKeys) => {
        let list = []
        newSelectedRowKeys.forEach( rowKey => {
            const indexLoc = itemLocationFromTableData.findIndex((location) => rowKey === location.id);
            list.push({
                id: rowKey,
                quantity_tool: itemLocationFromTableData[indexLoc]['quantity_tool'],
                quantity_item: itemLocationFromTableData[indexLoc]['quantity_item'],
                quantity_item_max: itemLocationFromTableData[indexLoc]['quantity_item_max'],
                total_item: itemLocationFromTableData[indexLoc]['total_item'],
            });
        });

        form.setFieldsValue({
            item_location_from_list: list,
        });

        updateTotalCropFailure(list);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const onChangeReason = (e) => {
        setReason(e.target.value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeItemLocationFrom,
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getItemItem();
        getBatch();
        promiseGetArea(batch).then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getUnitOfMeasurement();
        getReason();
    }, []);

    const updateTotalCropFailure = (locationFromList) => {
        let totalUnit = 0;
        locationFromList.forEach(item => totalUnit = totalUnit + item.total_item);
        setTotalCropFailure(totalUnit);
    };

    const onItemItemSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                batch: "",
                area_from: [],
                item_location_from_list: "",
            })
            setBatch("");
            setAreaList([]);
            onChangeItemLocationFrom([]);

            getBatch(value);
            promiseGetArea().then(
                function(value) {setAreaOption(value)},
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            );
            getItemLocationFrom({ uom: quantity_item_from_uom });
        };
    };

    const onItemItemClear = () => {
        form.setFieldsValue({
            batch: "",
            area_from: [],
            item_location_from_list: "",
        })
        setBatch("");
        setAreaList([]);
        onChangeItemLocationFrom([]);

        getBatch();
        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getItemLocationFrom({ uom: quantity_item_from_uom });
    };

    const onBatchClear = () => {
        setBatch();
        form.setFieldsValue({
            area_from: [],
            item_location_from_list: "",
        })
        setAreaList([]);
        onChangeItemLocationFrom([]);

        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getItemLocationFrom({ uom: quantity_item_from_uom });
    };

    const onAreaClear = () => {
        setAreaList([]);
        form.setFieldsValue({
            item_location_from_list: "",
        })
        onChangeItemLocationFrom([]);

        getItemLocationFrom({ batch: batch, uom: quantity_item_from_uom });
    };

    const onBack = () => {
        navigate(MENUPATH_CROPFAILURE);
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_CROPFAILURE) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (values) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create Crop Failure activity confirmed?</p></Space>,
            onOk() { createActivity(values.actualExecutedDate, values.remark) },
            onCancel() {},
            centered: true
        });
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onChangeTable = () => {
        // To restore the item_location_from_list as changing the table pagination/sorting/filter will reset the fields in the form
        onChangeItemLocationFrom(selectedRowKeys);
    };

    const EditableRow = ({ index, ...props }) => {
        const [form1] = Form.useForm();
        return (
            <Form form={form1} component={false}>
                <EditableContext.Provider value={form1}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ editable, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = <></>
        let errorMessage = ""
        const form1 = useContext(EditableContext);

        const save = async () => {
            try {
                let tempKeyList = selectedRowKeys.slice(0);
                if (tempKeyList.indexOf(record.key) === -1) {
                    tempKeyList.push(record.key);
                    onChangeItemLocationFrom(tempKeyList);
                };

                const row = await form1.validateFields();
                const newData = [...itemLocationFromTableData];
                const newDataIndex = newData.findIndex((item) => record.key === item.key);
                if (quantity_item_from_uom === UNIT_OF_MEASUREMENT_UNIT) {
                    row['total_item'] = row['quantity_item'];
                }
                else if (quantity_item_from_uom === UNIT_OF_MEASUREMENT_PERCENTAGE) {
                    row['total_item'] = row['quantity_item'] * parseInt(newData[newDataIndex]['quantity_item_max']) / 100;
                }
                else {
                    row['total_item'] = VALUE_NONE;
                };

                if (newDataIndex > -1) {
                    const item = newData[newDataIndex];
                    newData.splice(newDataIndex, 1, { ...item, ...row });
                    setItemLocationFromTableData(newData);
                } else {
                    newData.push(row);
                    setItemLocationFromTableData(newData);
                }

                // Update form data with location list
                const newLocationList = form.getFieldValue('item_location_from_list');
                const indexLoc = newLocationList.findIndex((location) => record.key === location.id);
                newLocationList[indexLoc] = { id: record.key, quantity_tool: newData[newDataIndex]['quantity_tool'], quantity_item: row['quantity_item'], quantity_item_max: newData[newDataIndex]['quantity_item_max'], total_item: row['total_item'] }

                // Update Total Crop Failure
                let totalUnit = 0;
                tempKeyList.forEach( rowKey => {
                    const index = newData.findIndex((location) => rowKey === location.id);
                    totalUnit = totalUnit + newData[index]['total_item'];
                });
                setTotalCropFailure(totalUnit);

            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        switch(title) {
            case quantity_item_label + "%":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={1} max={100} addonAfter="%"/>;
                errorMessage = "with numbers only";
                break;
            case quantity_item_label + "KG":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={0.001}/>;
                errorMessage = "with numbers only";
                break;
            case quantity_item_label + "Unit":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={1} max={record.quantity_item_max}/>;
                inputType = "integer";
                errorMessage = "with integer only";
                break;

            default:
                inputNode = inputType === 'number' ? <InputNumber onPressEnter={save} onBlur={save}/> : <Input onPressEnter={save} onBlur={save}/>;
        };

        let childNode = children;
        if (editable) {
            childNode = (
                <Form.Item initialValue={record.quantity_item} name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `Please Input ${title} ${errorMessage}!`, type: inputType}]}>
                    {inputNode}
                </Form.Item>
            );
        };
        return <td {...restProps}>{childNode}</td>;
    };

    const defaultColumns = [
        { title: 'Zone', dataIndex: 'item_area', sorter: (a, b) => a.item_area.localeCompare(b.item_area) },
        { title: 'Medium Type(s)', dataIndex: 'item_code', sorter: (a, b) => a.item_code.localeCompare(b.item_code) },
        // { title: 'Quantity (Planting Medium)', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: 'Balance Units of Plant', dataIndex: 'quantity_item_max', sorter: (a, b) => a.quantity_item_max - b.quantity_item_max },
        { title: quantity_item_label + quantity_item_from_uom_sign, dataIndex: 'quantity_item', editable: 1, sorter: (a, b) => a.quantity_item - b.quantity_item,
            render: (quantity_item) => {
                if (quantity_item_from_uom === UNIT_OF_MEASUREMENT_PERCENTAGE) return <span>{quantity_item}{quantity_item_from_uom_sign}</span>;
                else return <span>{quantity_item}</span>;
            },
        },
        { title: 'Total Units of Crop Failure', dataIndex: 'total_item', sorter: (a, b) => a.total_item - b.total_item,
            render: (total_item) => {
                return (<b>{total_item}</b>);
            },
        },
    ]

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Crop Failure Activity" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Crop Failure Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="end"><Col span={20}/><Col span={4} style={{textAlign: "center"}}><Statistic valueStyle={{ color: 'red' }} title="Total Units Crop Failure" value={total_crop_failure} /></Col></Row>
                <Form form={form} onFinish={values => onSave(values)} {...formLayoutWithTable} autoComplete="off">
                    <Form.Item name="item_item" label="Plant Species">
                        <Select onSelect={(value) => onItemItemSelect(value)} allowClear={true} onClear={onItemItemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemItemOption}
                        </Select>
                    </Form.Item>

                    <Spin spinning={isLoadingBatch} size="large" tip={LOADING}>
                        <Form.Item name="batch" label="Batch Code" rules={[{required: true, message: 'Please select the Batch Code for this Crop Failure Activity!'}]}>
                            <Select onChange={(value) => onChangeBatch(value)} allowClear={true} onClear={onBatchClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {batchOption}
                            </Select>
                        </Form.Item>
                    </Spin>

                    <Form.Item initialValue={moment()} name="actualExecutedDate" label="Actual Crop Failure Date" rules={[{required: true, message: 'Please input the Actual Crop Failure Date!'}]}>
                        <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment()}} disabledDate={disabledDateAfterToday}/>
                    </Form.Item>

                    <Form.Item name="area_from" label="Zone">
                        <Select onChange={(value) => onChangeArea(value)} allowClear={true} mode='multiple' onClear={onAreaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {areaOption}
                        </Select>
                    </Form.Item>

                    <Form.Item initialValue={quantity_item_from_uom} name="quantity_item_from_uom" label="UOM of Plant">
                        <Radio.Group onChange={onChangeQuantityItemUom} buttonStyle="solid" disabled={disableUomButton}>
                            {unitOfMeasurementOption}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name="item_location_from_list" label="Location" rules={[{required: true, message: 'Please select at least one Location!'}]} tooltip={{ title: "Please select a Zone first", icon: <ExclamationCircleTwoTone/>}}>
                        <Table components={{ body: {row: EditableRow, cell: EditableCell} }} rowClassName={() => 'editable-row'} rowSelection={rowSelection} columns={columns} dataSource={itemLocationFromTableData} onChange={onChangeTable} pagination={{ size: 'small',  position: ["bottomCenter"], total: itemLocationFromTableData.length, showTotal: showTotal }}/>
                    </Form.Item>

                    <Form.Item name="reason" label="Crop Failure Reason" rules={[{required: true, message: 'Please select the Crop Failure Reason!'}]}>
                        <Radio.Group onChange={onChangeReason} value={reason}>
                            <Space direction="vertical">
                                {reasonOption}
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name="remark" label="Remark" rules={[{required: true, message: 'Please input the Remark!'}]}>
                        <TextArea rows={2} />
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><SaveOutlined/>Save</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Crop Failure Activity" subTitle="Create New Crop Failure Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default CropFailureCreateNew;