import React , { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Typography, Tag, Spin } from 'antd';
import { AGISAPIURL, UNIT_OF_MEASUREMENT_UNIT, STATUS_AVAILABLE, STATUS_BLOCKED, STATUS_EMPTY, STATUS_FULLY_OCCUPIED, STATUS_REST, STATUS_RETIRED, STATUS_PARTIALLY_OCCUPIED, LOADING } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import { reportError } from "../Common/Utility";

const { Title, Text } = Typography;

const BatchDistributionTable = ({batchId, showPlant = true, showMedium = false}) => {

    const [totalItemUnit, setTotalItemUnit] = useState(0);
    const [plantLocationTableData, setPlantLocationTableData] = useState([]);
    const [mediumLocationTableData, setMediumLocationTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const searchCurrentPlantLocation = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batchiteminventorysummary/", {
            params: {
                batch: batchId,
                is_all_area: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectPlantLocationRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const searchCurrentMediumLocation = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batchiteminventoryreleasemedium/", {
            params: {
                batch: batchId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectMediumLocationRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectPlantLocationRawData = ( response ) => {
        const data = []
        let totalUnit = 0
        response.data.results.forEach( batchItemInventoryTransactionSummary => {
            let maxUnit = 0;
            let quantity_item_secondary_uom = "";
            if (batchItemInventoryTransactionSummary.item_repository_profile) {
                if (batchItemInventoryTransactionSummary.item_repository_profile.primary_uom.id === UNIT_OF_MEASUREMENT_UNIT) {
                    maxUnit = batchItemInventoryTransactionSummary.item_repository_profile.primary_quantity;
                }
                else if (batchItemInventoryTransactionSummary.item_repository_profile.secondary_uom.id === UNIT_OF_MEASUREMENT_UNIT) {
                    maxUnit = batchItemInventoryTransactionSummary.item_repository_profile.secondary_quantity;
                }
            }
            if (batchItemInventoryTransactionSummary.quantity_item_secondary_uom) quantity_item_secondary_uom = batchItemInventoryTransactionSummary.quantity_item_secondary_uom.unit;

            totalUnit = totalUnit + parseInt(batchItemInventoryTransactionSummary.quantity_item);
            data.push({
                key: batchItemInventoryTransactionSummary.id,
                id: batchItemInventoryTransactionSummary.id,
                item_tool: batchItemInventoryTransactionSummary.item_tool.item_code,
                area: batchItemInventoryTransactionSummary.area.area_code,
                quantity_tool: batchItemInventoryTransactionSummary.quantity_tool,
                quantity_item: batchItemInventoryTransactionSummary.quantity_item,
                quantity_item_uom: batchItemInventoryTransactionSummary.quantity_item_uom.unit,
                quantity_item_perc: batchItemInventoryTransactionSummary.quantity_item_perc,
                quantity_item_uom_perc: batchItemInventoryTransactionSummary.quantity_item_uom_perc.unit,
                maxUnit: parseInt(maxUnit * Math.ceil(batchItemInventoryTransactionSummary.quantity_tool)),
                quantity_item_secondary: batchItemInventoryTransactionSummary.quantity_item_secondary,
                quantity_item_secondary_uom: quantity_item_secondary_uom,
            })
        });
        setPlantLocationTableData(data);
        setTotalItemUnit(totalUnit);
    };

    const collectMediumLocationRawData = ( response ) => {
        const data = []
        response.data.results.forEach( batchItemInventoryReleaseMedium => {
            data.push({
                key: batchItemInventoryReleaseMedium.id,
                id: batchItemInventoryReleaseMedium.id,
                item_tool: batchItemInventoryReleaseMedium.item_tool.item_code,
                area: batchItemInventoryReleaseMedium.area.area_code,
                quantity_tool: batchItemInventoryReleaseMedium.quantity_tool,
                statusType: batchItemInventoryReleaseMedium.item_tool.status_type.description,
                statusTypeId: batchItemInventoryReleaseMedium.item_tool.status_type.id,
                daysPassed: batchItemInventoryReleaseMedium.days_passed,
            })
        });
        setMediumLocationTableData(data);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if (showPlant) searchCurrentPlantLocation();
        if (showMedium) searchCurrentMediumLocation();
    }, []);

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const plantLocationColumns = [
        { title: 'Medium', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        // { title: 'Quantity', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: 'Units of Plant over Total Sowed', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item,
            render: (quantity_item, record) => {
                return (<Text>{parseInt(quantity_item)} / {totalItemUnit} {record.quantity_item_uom} ({(parseInt(quantity_item) / totalItemUnit * 100).toFixed(1)} %)</Text>);
            }
        },
        { title: 'Units of Plant over Medium Size', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item,
            render: (quantity_item, record) => {
                if (record.quantity_item_uom_perc) return (<Text>{parseInt(quantity_item)} / {record.maxUnit} {record.quantity_item_uom} ({parseInt(record.quantity_item_perc)} {record.quantity_item_uom_perc})</Text>)
                else return (<Text>{parseInt(quantity_item)} / {record.maxUnit} {record.quantity_item_uom}</Text>)
            }
        },
        { title: 'Amount Harvested', dataIndex: 'quantity_item_secondary', sorter: (a, b) => a.quantity_item_secondary - b.quantity_item_secondary,
            render: (quantity_item_secondary, record) => {
                if (record.quantity_item_secondary_uom) return (<Text>{parseFloat(quantity_item_secondary)} {record.quantity_item_secondary_uom}</Text>);
            }
        },
    ];

    const mediumLocationColumns = [
        { title: 'Medium', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        // { title: 'Quantity', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: 'Status', dataIndex: 'statusType', sorter: (a, b) => a.statusType.localeCompare(b.statusType),
            render: (statusType, record) => {
                if (record.statusTypeId === STATUS_EMPTY) return <Tag color="success">{statusType}</Tag>
                else if (record.statusTypeId === STATUS_PARTIALLY_OCCUPIED) return <Tag color="processing" style={{borderStyle: 'dashed'}}>{statusType}</Tag>
                else if (record.statusTypeId === STATUS_FULLY_OCCUPIED) return <Tag color="processing">{statusType}</Tag>
                else if (record.statusTypeId === STATUS_BLOCKED) return <Tag color="error">{statusType}</Tag>
                else if (record.statusTypeId === STATUS_REST) return <Tag color="warning">{statusType}</Tag>
                else if (record.statusTypeId === STATUS_RETIRED) return <Tag color="default">{statusType}</Tag>
                else if (record.statusTypeId === STATUS_AVAILABLE) return <Tag color="success">{statusType}</Tag>
                else return <>{statusType}</>
            },
        },
        { title: 'No. of Days Passed', dataIndex: 'daysPassed', sorter: (a, b) => a.daysPassed - b.daysPassed },
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            {showPlant ?
                <>
                    <Title level={5}>[Current] Plant Distribution:</Title>
                    <Table columns={plantLocationColumns} dataSource={plantLocationTableData} pagination={{size: "small", position: ["bottomCenter"], total: plantLocationTableData.length, showTotal: showTotal}}/>
                </>:<></>}

            {showMedium ?
                <>
                    <Title level={5}>[Current] Medium Distribution:</Title>
                    <Table columns={mediumLocationColumns} dataSource={mediumLocationTableData} pagination={{size: "small", position: ["bottomCenter"], total: mediumLocationTableData.length, showTotal: showTotal}}/>
                </>:<></>}
        </Spin>
        </>
    );

};

export default BatchDistributionTable;