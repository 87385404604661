import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Spin, Row, Col, Layout, Space, PageHeader, Descriptions, Form, DatePicker, Button, Statistic, Card, message, Checkbox, Tabs } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, MENUPATH_DASHBOARD, UNIDATEFORMAT, UNIT_OF_MEASUREMENT_KILOGRAM_SIGN, VALUE_NONE } from '../Common/SysParameters';
import AGISHeader from '../Common/AGISHeader';
import { reportError } from "../Common/Utility";
import moment from "moment";
import axios from 'axios';
import DashboardChartsYieldSummaryBySpeciesByBatch from './DashboardChartsYieldSummaryBySpeciesByBatch';
import DashboardChartsYieldSummaryBySpeciesByDay from './DashboardChartsYieldSummaryBySpeciesByDay';
import DashboardChartsYieldSummaryBySpeciesByWeek from './DashboardChartsYieldSummaryBySpeciesByWeek';


const { Content, Footer } = Layout;
const { RangePicker } = DatePicker;

const DashboardChartsYieldSummaryBySpecies = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const item = location.state.record.item;
    const item_id = location.state.record.item_id;

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [inclExpectedHarvest, setInclExpectedHarvest] = useState(location.state.inclExpectedHarvest);
    const [forecastOnWorkProg, setForecastOnWorkProg] = useState(location.state.forecastOnWorkProg);
    const [disableForecast, setDisableForecast] = useState(location.state.disableForecast);
    const [isLoading, setIsLoading] = useState(false);
    const [yieldByBatchData, setYieldByBatchData] = useState([]);
    const [yieldByDayData, setYieldByDayData] = useState([]);
    const [yieldByWeekData, setYieldByWeekData] = useState([]);
    const [yieldByBatchTableData, setYieldByBatchTableData] = useState([]);
    const [yieldByDayTableData, setYieldByDayTableData] = useState([]);
    const [yieldByWeekTableData, setYieldByWeekTableData] = useState([]);
    const [totalExpected, setTotalExpected] = useState(0);
    const [totalActual, setTotalActual] = useState(0);
    const [dateRange, setDateRange] = useState([moment(location.state.record.dateRange[0], UNIDATEFORMAT), moment(location.state.record.dateRange[1], UNIDATEFORMAT)]);

    const SearchBatchYieldSummary = () => {
        setIsLoading(true);

        if (forecastOnWorkProg && !disableForecast) {
            axios.post(AGISAPIURL + "batchforecast/generate/", {
                site: getUserSiteId(),
                toDate: dateRange[1].format(UNIDATEFORMAT),
            }, {
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Batch Forecast generated.");
                SearchAllBatchSummary();
            })
            .catch( error => {
                reportError(error, "Failed to generate Batch Forecast. " + error.message)
            })
            .finally(() => {
                refreshUserSession();
            })
        }
        else SearchAllBatchSummary();
    };

    const SearchAllBatchSummary = () => {
        axios.get(AGISAPIURL + "allbatchwithforecastsummary/", {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                item: item_id,
                incl_expected_harvest: inclExpectedHarvest ? 1 : 0,
                forecast_on_work_programme: forecastOnWorkProg ? 1 : 0,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectBatchYieldData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectBatchYieldData = ( response ) => {
        let dataByBatch = [];
        let dataByDay = [];
        let dataByWeek = [];
        let tableDataByBatch = [];
        let tableDataByDay = [];
        let tableDataByWeek = [];
        let tempExpected = 0;
        let tempActual = 0;
        let tempDataByDay = {};
        let tempDataByWeek = {};

        // Construct data array by days and weeks
        let currentDate = moment(dateRange[0]);
        const endDate = moment(dateRange[1]);
        while(currentDate.isSameOrBefore(endDate)) {
            tempDataByDay[currentDate.format(UNIDATEFORMAT)] = {
                "Expected": {
                    name: "Expected",
                    sub_name: "Existing",
                    total_yield: 0,
                    percentage_archived: 0,
                },
                "Actual": {
                    name: "Actual",
                    sub_name: "Actual",
                    total_yield: 0,
                    percentage_archived: 0,
                },
                "all_batches": [],
            };

            if (tempDataByWeek[currentDate.weekYear().toString() + " Week " +  currentDate.week().toString()] == undefined) {
                tempDataByWeek[currentDate.weekYear().toString() + " Week " +  currentDate.week().toString()] = {
                    "Expected": {
                        name: "Expected",
                        sub_name: "Existing",
                        total_yield: 0,
                        percentage_archived: 0,
                    },
                    "Actual": {
                        name: "Actual",
                        sub_name: "Actual",
                        total_yield: 0,
                        percentage_archived: 0,
                    },
                    "all_batches": [],
                    "year": currentDate.weekYear(),
                    "week": currentDate.week(),
                };
            };
            currentDate.add(1, 'days');
        };

        response.data.forEach( batch => {
            let batch_id = VALUE_NONE;
            let actual_harvest_date = VALUE_NONE;
            let expected_completion_date = VALUE_NONE;
            let lifecycle = VALUE_NONE;
            let work_programme = VALUE_NONE;
            let all_sowed = VALUE_NONE;
            let quantity_uom = VALUE_NONE;
            let expected_name = VALUE_NONE;
            let expected_yield = 0;
            let quantity = 0;

            if (batch.is_forecast) {
                quantity_uom = batch.quantity_uom.unit;
                quantity = parseInt(batch.quantity);
                expected_name = "Forecast";
                expected_yield = parseFloat(batch.all_sowed);
            }
            else {
                expected_name = "Existing";
                [all_sowed, expected_yield] = collectAllSowed(batch.all_sowed);
            }

            if (batch.batch) batch_id = batch.batch.id;
            if (batch.actual_harvest_date) actual_harvest_date = moment(batch.actual_harvest_date).format(UNIDATEFORMAT);
            if (batch.expected_completion_date) expected_completion_date = moment(batch.expected_completion_date).format(UNIDATEFORMAT);
            if (batch.lifecycle) lifecycle = batch.lifecycle.name;
            if (batch.work_programme) work_programme = batch.work_programme.description;
            tempExpected = tempExpected + expected_yield;
            tempActual = tempActual + parseFloat(batch.total_harvested_kg);

            // Construct data list by batches
            dataByBatch.push({
                batch: batch.batch_code,
                name: "Expected",
                sub_name: expected_name,
                total_yield: expected_yield,
                percentage_archived: 1,
            });

            dataByBatch.push({
                batch: batch.batch_code,
                name: "Actual",
                sub_name: "Actual",
                total_yield: parseFloat(batch.total_harvested_kg),
                percentage_archived: parseFloat(batch.total_harvested_kg) / expected_yield,
            });

            let dateLabel = VALUE_NONE;
            let weekLabel = VALUE_NONE;
            if (actual_harvest_date !== VALUE_NONE) {
                dateLabel = actual_harvest_date;
                weekLabel = moment(actual_harvest_date).weekYear().toString() + " Week " + moment(actual_harvest_date).week().toString();
            }
            else if (expected_completion_date !== VALUE_NONE) {
                dateLabel = expected_completion_date;
                weekLabel = moment(expected_completion_date).weekYear().toString() + " Week " + moment(expected_completion_date).week().toString();
            }
            else throw new Error('Invalid date returned!');

            // Consolidate yield by day
            tempDataByDay[dateLabel]["Expected"]["sub_name"] = expected_name;
            tempDataByDay[dateLabel]["Expected"]["total_yield"] += expected_yield;
            tempDataByDay[dateLabel]["Actual"]["total_yield"] += parseFloat(batch.total_harvested_kg);
            tempDataByDay[dateLabel]["all_batches"].push({
                key: batch.id,
                id: batch.id,
                batch_id: batch_id,
                batch_code: batch.batch_code,
                item: batch.item.description,
                all_sowed: all_sowed,
                work_programme: work_programme,
                status_type: batch.status_type.description,
                status_id: batch.status_type.id,
                is_active: batch.is_active,
                lifecycle: lifecycle,
                is_harvested: batch.is_harvested,
                remark: batch.remark,
                actual_harvest_date: actual_harvest_date,
                expected_completion_date: expected_completion_date,
                total_harvested_kg: parseFloat(batch.total_harvested_kg),
                quantity: quantity,
                quantity_uom: quantity_uom,
                total_yield: expected_yield.toFixed(2),
                is_forecast: batch.is_forecast,
            });

            // Consolidate yield by week
            tempDataByWeek[weekLabel]["Expected"]["sub_name"] = expected_name;
            tempDataByWeek[weekLabel]["Expected"]["total_yield"] += expected_yield;
            tempDataByWeek[weekLabel]["Actual"]["total_yield"] += parseFloat(batch.total_harvested_kg);
            tempDataByWeek[weekLabel]["all_batches"].push({
                key: batch.id,
                id: batch.id,
                batch_id: batch_id,
                batch_code: batch.batch_code,
                item: batch.item.description,
                all_sowed: all_sowed,
                work_programme: work_programme,
                status_type: batch.status_type.description,
                status_id: batch.status_type.id,
                is_active: batch.is_active,
                lifecycle: lifecycle,
                is_harvested: batch.is_harvested,
                remark: batch.remark,
                actual_harvest_date: actual_harvest_date,
                expected_completion_date: expected_completion_date,
                total_harvested_kg: parseFloat(batch.total_harvested_kg),
                quantity: quantity,
                quantity_uom: quantity_uom,
                total_yield: expected_yield.toFixed(2),
                is_forecast: batch.is_forecast,
            });

            // Table data by batches
            tableDataByBatch.push({
                key: batch.id,
                id: batch.id,
                batch_id: batch_id,
                batch_code: batch.batch_code,
                item: batch.item.description,
                all_sowed: all_sowed,
                work_programme: work_programme,
                status_type: batch.status_type.description,
                status_id: batch.status_type.id,
                is_active: batch.is_active,
                lifecycle: lifecycle,
                is_harvested: batch.is_harvested,
                remark: batch.remark,
                actual_harvest_date: actual_harvest_date,
                expected_completion_date: expected_completion_date,
                total_harvested_kg: parseFloat(batch.total_harvested_kg),
                quantity: quantity,
                quantity_uom: quantity_uom,
                total_yield: expected_yield.toFixed(2),
                is_forecast: batch.is_forecast,
            })
        });

        // Construct data list by days
        for (const [key, value] of Object.entries(tempDataByDay)) {
            dataByDay.push({
                harvest_date: key,
                name: "Expected",
                sub_name: value["Expected"]["sub_name"],
                total_yield: value["Expected"]["total_yield"],
                percentage_archived: 1,
            });

            dataByDay.push({
                harvest_date: key,
                name: "Actual",
                sub_name: value["Actual"]["sub_name"],
                total_yield: value["Actual"]["total_yield"],
                percentage_archived: parseFloat(value["Actual"]["total_yield"] / parseFloat(value["Expected"]["total_yield"])),
            });

            // Collect only non-zero value for table data
            if (value["Expected"]["total_yield"] > 0 || value["Actual"]["total_yield"] > 0) {
                tableDataByDay.push({
                    key: key,
                    id: key,
                    harvest_date: key,
                    total_yield: value["Expected"]["total_yield"],
                    total_harvested_kg: value["Actual"]["total_yield"],
                    all_batches: value["all_batches"],
                });
            };
        };

        // Construct data list by weeks
        for (const [key, value] of Object.entries(tempDataByWeek)) {
            dataByWeek.push({
                harvest_week: key,
                name: "Expected",
                sub_name: value["Expected"]["sub_name"],
                total_yield: value["Expected"]["total_yield"],
                percentage_archived: 1,
            });

            dataByWeek.push({
                harvest_week: key,
                name: "Actual",
                sub_name: value["Actual"]["sub_name"],
                total_yield: value["Actual"]["total_yield"],
                percentage_archived: parseFloat(value["Actual"]["total_yield"] / parseFloat(value["Expected"]["total_yield"])),
            });

            // Collect only non-zero value for table data
            if (value["Expected"]["total_yield"] > 0 || value["Actual"]["total_yield"] > 0) {
                tableDataByWeek.push({
                    key: key,
                    id: key,
                    harvest_week: key,
                    total_yield: value["Expected"]["total_yield"],
                    total_harvested_kg: value["Actual"]["total_yield"],
                    all_batches: value["all_batches"],
                    year: value["year"],
                    week: value["week"],
                });
            };
        };

        setYieldByBatchData(dataByBatch);
        setYieldByBatchTableData(tableDataByBatch);
        setYieldByDayData(dataByDay);
        setYieldByDayTableData(tableDataByDay);
        setYieldByWeekData(dataByWeek);
        setYieldByWeekTableData(tableDataByWeek);
        setTotalExpected(tempExpected);
        setTotalActual(tempActual);
    };

    const collectAllSowed = (response) => {
        const data = [];
        let totalYield = 0;
        response.forEach( value => {
            data.push({
                id: value.id,
                key: value.id,
                item_tool: value.item_tool.description,
                item_tool_type: value.item_tool_type.item_type_code,
                quantity_item: parseFloat(value.quantity_item),
                quantity_item_uom: value.quantity_item_uom.unit,
                yield_amount_kg: parseFloat(value.yield_amount_kg).toFixed(2),
                is_average_yield_used: value.is_average_yield_used,
            });

            totalYield += parseFloat(value.yield_amount_kg);
        });
        return [data, totalYield];
    };

    const onBack = () => {
        navigate(MENUPATH_DASHBOARD);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchBatchYieldSummary();
    }, []);

    const onChangeDateRange = (value) => {
        setDateRange(value);

        // The Forecast option is available only if future date has been selected (Include Today)
        if (moment(value[1], UNIDATEFORMAT).isBefore(moment(), 'day')) {
            setDisableForecast(true);

            // Uncheck the Forecast option when it is disabled
            setForecastOnWorkProg(false);
            form.getFieldValue('options').splice(1, 1);
        }
        else setDisableForecast(false);
    };

    const onChange = (checkValues) => {
        if (checkValues.includes("incl_expected_harvest")) setInclExpectedHarvest(true)
        else setInclExpectedHarvest(false);

        if (checkValues.includes("forecast_on_work_programme")) setForecastOnWorkProg(true)
        else setForecastOnWorkProg(false);
    };

    const onApply = () => {
        SearchBatchYieldSummary();
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title={`Yield Summary Chart - ${item}`} onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">A Grouped Column chart showing yield summary for {item}</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Form form={form}>
                        <Row justify='center'>
                            <Col span={14} style={{textAlign: "left"}}>
                                <Form.Item initialValue={dateRange} name="daterange" label="Harvest Date Range">
                                    <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                                </Form.Item>

                                <Form.Item initialValue={(inclExpectedHarvest ? ["incl_expected_harvest"] : []).concat(forecastOnWorkProg ? ["forecast_on_work_programme"] : [])} name="options" label="Options">
                                    <Checkbox.Group options={[
                                        {label: 'Include In-Progress Batch(es) with Planned Harvest Date that falls within the above selected Harvest Date Range', value: 'incl_expected_harvest'},
                                        {label: 'Forecast future Batch(es) based on Work Plan (Include uncreated Batch(es) planned for today)', value: 'forecast_on_work_programme', disabled: disableForecast},
                                    ]} onChange={onChange} />
                                </Form.Item>
                            </Col>

                            <Col span={1}>
                                <Button onClick={onApply} type="primary">Apply</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col></Row>

                <Row justify='center'>
                    <Col span={3}><Card bordered={false}><Statistic title={`Expected Yield (${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN})`} precision={2} value={totalExpected} valueStyle={{ color: 'blue' }} /></Card></Col>
                    <Col span={3}><Card bordered={false}><Statistic title={`Actual Harvested (${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN})`} precision={2} value={totalActual} valueStyle={{ color: 'green' }} suffix={`(${((totalActual / totalExpected) * 100).toFixed(2)}%)`} /></Card></Col>
                    <Col span={3}><Card bordered={false}><Statistic title={`Difference (${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN})`} precision={2} value={totalExpected - totalActual} valueStyle={{ color: 'red' }} suffix={`(${(((totalExpected - totalActual) / totalExpected) * 100).toFixed(2)}%)`} /></Card></Col>
                </Row>

                <Tabs defaultActiveKey="1" size="middle" centered={true} animated={true}>
                    <Tabs.TabPane tab={<>By Batch</>} key="1"><DashboardChartsYieldSummaryBySpeciesByBatch yieldSummaryData={yieldByBatchData} yieldSummaryTableData={yieldByBatchTableData} navigate={navigate}/></Tabs.TabPane>
                    <Tabs.TabPane tab={<>By Day</>} key="2"><DashboardChartsYieldSummaryBySpeciesByDay yieldSummaryData={yieldByDayData} yieldSummaryTableData={yieldByDayTableData} navigate={navigate}/></Tabs.TabPane>
                    <Tabs.TabPane tab={<>By Week</>} key="3"><DashboardChartsYieldSummaryBySpeciesByWeek yieldSummaryData={yieldByWeekData} yieldSummaryTableData={yieldByWeekTableData} navigate={navigate}/></Tabs.TabPane>
                </Tabs>

            </Content>
            <Footer><Row justify="center"><PageHeader title={`Yield Summary Chart - ${item}`} subTitle={`A Grouped Column chart showing yield summary for ${item}`} onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default DashboardChartsYieldSummaryBySpecies;