import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, message, Modal, DatePicker, Spin } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, refreshUserSession, getUserAuthToken } from '../Common/UserSession';
import { formLayout } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_ACTIVITY, UNIDATETIMEFORMAT, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { SaveOutlined, CloseCircleFilled, QuestionCircleTwoTone } from '@ant-design/icons';


const { Option } = Select;
const { confirm } = Modal;
const { Content, Footer } = Layout;

const ActivityCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [taskCategoryOption, setTaskCategoryOption] = useState("");
    const [taskTypeOption, setTaskTypeOption] = useState("");
    const [itemItemOption, setItemItemOption] = useState("");
    const [batchOption, setBatchOption] = useState("");
    const [itemLocationFromOption, setItemLocationFromOption] = useState("");
    const [itemLocationToOption, setItemLocationToOption] = useState("");
    const [lifecycleOption, setLifecycleOption] = useState("");
    const [workPhaseOption, setWorkPhaseOption] = useState("");
    const [task_category, setTaskCategory] = useState(0);
    const [task_type, setTaskType] = useState(0);
    const [item_item, setItemItem] = useState(0);
    const [batch, setBatch] = useState();
    const [item_location_to_list, setItemLocationToList] = useState([]);
    const [item_location_from, setItemLocationFrom] = useState();
    const [lifecycle, setLifecycle] = useState(0);
    const [work_phase, setWorkPhase] = useState(0);
    const [disableButton, setDisableButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const createActivity = (actual_completion_date) => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "activity/create/", {
            site: getUserSiteId(),
            batch: batch,
            task_type: task_type,
            item_item: item_item,
            item_location_to_list: item_location_to_list,
            item_location_from: item_location_from,
            actual_executed_datetime: actual_completion_date.format(UNIDATETIMEFORMAT),
            lifecycle: lifecycle,
            work_phase: work_phase,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New activity ${response.data[0].id} created.`);
            response.data[1].map(subactivity => message.info(`New sub-activity ${subactivity.id} created.`));
            navigate(MENUPATH_ACTIVITY);
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getTaskCategory = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "taskcategory/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setTaskCategoryOption(response.data.results.map( taskcategory => <Option key={taskcategory.id} value={taskcategory.id}>{taskcategory.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getTaskType = (taskCategoryId) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "tasktype/", {
            params: {
                task_category: taskCategoryId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setTaskTypeOption(response.data.results.map( tasktype => <Option key={tasktype.id} value={tasktype.id}>{tasktype.task}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItemItem = (task_type) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofilebytasktype/", {
            params: {
                site: getUserSiteId(),
                task_type: task_type,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemItemOption(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getBatch = (itemId) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batch/", {
            params: {
                site: getUserSiteId(),
                item: itemId,
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setBatchOption(response.data.map( batch => <Option key={batch.id} value={batch.id}>{batch.batch_code}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItemLocationTo = (itemList=[]) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "item/", {
            params: {
                site: getUserSiteId(),
                is_inventory_repository: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let sources = response.data.results.filter(item => itemList.includes(item.id) ? false : true);
            setItemLocationToOption(sources.map(item => <Option key={"_" + item.id} value={item.id}>{item.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItemLocationFrom = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "item/", {
            params: {
                site: getUserSiteId(),
                is_inventory_repository: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemLocationFromOption(response.data.results.map( item => <Option key={item.id} value={item.id}>{item.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getLifecycle = ({itemId = "", taskId = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "lifecyclebytasktypeitem/", {
            params: {
                item: itemId,
                task_type: taskId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setLifecycleOption(response.data.results.map( lifecyclebytaskitem => <Option key={lifecyclebytaskitem.lifecycle.id} value={lifecyclebytaskitem.lifecycle.id}>{lifecyclebytaskitem.lifecycle.name}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getWorkPhase = (taskId) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "workphasebytasktype/", {
            params: {
                task_type: taskId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setWorkPhaseOption(response.data.results.map( workphasebytask => <Option key={workphasebytask.work_phase.id} value={workphasebytask.work_phase.id}>{workphasebytask.work_phase.name}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const onChangeItemItem = (value) => {
        setItemItem(value);
    };

    const onChangeBatch = (value) => {
        setBatch(value);
    };

    const onChangeItemLocationTo = (value) => {
        setItemLocationToList(value);
        getItemLocationTo(value);
    };

    const onChangeItemLocationFrom = (value) => {
        setItemLocationFrom(value);
    };

    const onChangeTaskCategory = (value) => {
        setTaskCategory(value);
    };

    const onChangeTaskType = (value) => {
        setTaskType(value);
    };

    const onChangeLifecycle = (value) => {
        setLifecycle(value);
    };

    const onChangeWorkPhase = (value) => {
        setWorkPhase(value);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getTaskCategory();
        getTaskType();
        getItemItem();
        getBatch();
        getItemLocationFrom();
        getItemLocationTo();
        getLifecycle({});
        getWorkPhase();
    }, []);

    const onTaskCategorySelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                task_type: "",
                item_item: "",
                batch: "",
                lifecycle: "",
                work_phase: "",
            })
            onChangeTaskType("");
            onChangeItemItem("");
            onChangeBatch("");
            onChangeLifecycle("");
            onChangeWorkPhase("");
            setTaskCategory(value);

            getTaskType(value);
            getItemItem();
            getBatch();
            getLifecycle({});
            getWorkPhase();
        };
    };

    const onTaskSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                item_item: "",
                batch: "",
                lifecycle: "",
                work_phase: "",
            })
            onChangeItemItem("");
            onChangeBatch("");
            onChangeLifecycle("");
            onChangeWorkPhase("");
            setTaskType(value);

            getItemItem(value);
            getBatch();
            getLifecycle({ taskId: value});
            getWorkPhase(value);
        };
    };

    const onItemItemSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                batch: "",
                lifecycle: "",
            })
            onChangeBatch("");
            onChangeLifecycle("");
            setItemItem(value);

            getBatch(value);
            getLifecycle({ itemId: value, taskId: task_type});
        };
    };

    const onItemLocationToClear = () => {
        setItemLocationToList([]);
        getItemLocationTo();
    };

    const onTaskCategoryClear = () => {
        setTaskCategory();
        form.setFieldsValue({
            task_type: "",
            item_item: "",
            batch: "",
            lifecycle: "",
            work_phase: "",
        })
        onChangeTaskType("");
        onChangeItemItem("");
        onChangeBatch("");
        onChangeLifecycle("");
        onChangeWorkPhase("");
        setTaskCategory();

        getTaskType();
        getItemItem();
        getBatch();
        getLifecycle({});
        getWorkPhase();
    };

    const onTaskClear = () => {
        onChangeTaskType();
        form.setFieldsValue({
            item_item: "",
            batch: "",
            lifecycle: "",
            work_phase: "",
        })
        onChangeItemItem("");
        onChangeBatch("");
        onChangeLifecycle("");
        onChangeWorkPhase("");
        setTaskType();

        getItemItem();
        getBatch();
        getLifecycle({});
        getWorkPhase();
    };

    const onItemItemClear = () => {
        setItemItem();
        form.setFieldsValue({
            batch: "",
            lifecycle: "",
        })
        onChangeBatch("");
        onChangeLifecycle("");
        setItemItem();

        getBatch();
        getLifecycle({ taskId: task_type });
    };

    const onBack = () => {
        navigate(MENUPATH_ACTIVITY);
    };

    const onCancel = () => {
        navigate(MENUPATH_ACTIVITY);
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (values) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create activity confirmed?</p></Space>,
            onOk() { createActivity(values.actualExecutedDate) },
            onCancel() {},
            centered: true
        });
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Activity" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={values => onSave(values)} {...formLayout} autoComplete="off">
                    <Form.Item name="task_category" label="Task Category">
                        <Select onChange={(value) => onChangeTaskCategory(value)} onSelect={(value) => onTaskCategorySelect(value)} allowClear={true} onClear={onTaskCategoryClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {taskCategoryOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="task_type" label="Task" rules={[{required: true, message: 'Please select the Task for this Activity!'}]}>
                        <Select onChange={(value) => onChangeTaskType(value)} onSelect={(value) => onTaskSelect(value)} allowClear={true} onClear={onTaskClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {taskTypeOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="item_item" label="Item" rules={[{required: true, message: 'Please select the Item for this Activity!'}]}>
                        <Select onChange={(value) => onChangeItemItem(value)} onSelect={(value) => onItemItemSelect(value)} allowClear={true} onClear={onItemItemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemItemOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="batch" label="Batch Code">
                        <Select onChange={(value) => onChangeBatch(value)} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {batchOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="item_location_from" label="Source Location">
                        <Select onChange={(value) => onChangeItemLocationFrom(value)} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemLocationFromOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="item_location_to_list" label="Destination Location(s)" rules={[{required: true, message: 'Please select at least one To Location for this Activity!'}]}>
                        <Select onChange={(value) => onChangeItemLocationTo(value)} onClear={onItemLocationToClear} allowClear={true} mode="multiple" showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemLocationToOption}
                        </Select>
                    </Form.Item>

                    <Form.Item initialValue={moment()} name="actualExecutedDate" label="Actual Executed Date" rules={[{required: true, message: 'Please input the Actual Executed Date for this Activity!'}]}>
                        <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment()}} disabledDate={disabledDateAfterToday}/>
                    </Form.Item>

                    <Form.Item name="lifecycle" label="Life Cycle" rules={[{required: true, message: 'Please select the Life Cycle for this Activity!'}]}>
                        <Select onChange={(value) => onChangeLifecycle(value)} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {lifecycleOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="work_phase" label="Work Phase" rules={[{required: true, message: 'Please select the Work Phase for this Activity!'}]}>
                        <Select onChange={(value) => onChangeWorkPhase(value)} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {workPhaseOption}
                        </Select>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><SaveOutlined/>Save</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Activity" subTitle="Create New Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default ActivityCreateNew;