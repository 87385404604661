import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, Modal, DatePicker, Table, message, Spin, Switch } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import { formLayoutWithTable } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_FERTILIZATION, UNIDATETIMEFORMAT, AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE, STATUS_INPROGRESS, TASK_CATEGORY_FERTILIZATION, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { SaveOutlined, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
const EditableContext = React.createContext(null);


const { Option } = Select;
const { confirm } = Modal;
const { Content, Footer } = Layout;

const FertilizationCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [taskTypeOption, setTaskTypeOption] = useState("");
    const [itemItemOption, setItemItemOption] = useState("");
    const [batchOption, setBatchOption] = useState("");
    const [areaOption, setAreaOption] = useState("");
    const [itemLocationToTableData, setItemLocationToTableData] = useState([]);
    const [task_type, setTaskType] = useState(0);
    const [item, setItem] = useState(0);
    const [batch, setBatch] = useState(0);
    const [area_type_list, setAreaTypeList] = useState([AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE]);
    const [showMatched, setShowMatched] = useState(true);
    const [disableButton, setDisableButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingItem, setIsLoadingItem] = useState(false);
    const [isLoadingBatch, setIsLoadingBatch] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);  // The selectRowKeys name shall not be change as this is required for Antd to control over the selected rows

    const createActivity = (actual_completion_date) => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "activity/create/fertilization/", {
            site: getUserSiteId(),
            batch: batch,
            task_type: task_type,
            item_location_to_list: form.getFieldValue('item_location_to_list'),
            actual_executed_datetime: actual_completion_date.format(UNIDATETIMEFORMAT),
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New activity ${response.data[0].id} created.`);
            navigate(MENUPATH_FERTILIZATION);
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getTaskType = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "tasktype/", {
            params: {
                task_category: TASK_CATEGORY_FERTILIZATION,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setTaskTypeOption(response.data.results.map( task_type => <Option key={task_type.id} value={task_type.id}>{task_type.task}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItemItem = (task_type) => {
        setIsLoadingItem(true);

        axios.get(AGISAPIURL + "itemprofilebytasktype/", {
            params: {
                site: getUserSiteId(),
                task_type: task_type,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemItemOption(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingItem(false);
            refreshUserSession();
        });
    };

    const getBatch = (itemId, task_type, show_matched) => {
        setIsLoadingBatch(true);

        axios.get(AGISAPIURL + "batchavailablefertilization/", {
            params: {
                site: getUserSiteId(),
                item: itemId,
                task_type: task_type,
                area_type_list: area_type_list,
                status_type: STATUS_INPROGRESS,
                is_active: 1,
                show_matched: show_matched ? 1 : 0,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setBatchOption(response.data.results.map( batch => <Option key={batch.id} value={batch.id}>{batch.batch_code}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingBatch(false);
            refreshUserSession();
        });
    };

    let promiseGetArea = function (batchId) {
        return new Promise(function(myResolve, myReject) {
            setIsLoading(true);

            axios.get(AGISAPIURL + "batcharea/", {
                params: {
                    site: getUserSiteId(),
                    batch: batchId,
                    area_type_list: area_type_list,
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`},
            })
            .then( response => {
                let options = response.data.results.map( area => {
                        let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                        return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                    });
                myResolve(options);
            })
            .catch( error => {
                myReject(error);
            })
            .finally(() => {
                setIsLoading(false);
                refreshUserSession();
            });
        });
    };

    const getItemLocationTo = ({batch = "", areaList = [], task_type = "", show_matched = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batchiteminventoryfertilization/", {
            params: {
                batch: batch,
                task_type: task_type,
                area_list: areaList,
                show_matched: show_matched ? 1 : 0,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemLocationToRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectItemLocationToRawData = ( response ) => {
        const data = []
        response.data.results.forEach( batchItemInventoryTransactionSummary => {
            data.push({
                key: batchItemInventoryTransactionSummary.item_tool.id,
                id: batchItemInventoryTransactionSummary.item_tool.id,
                item_code: batchItemInventoryTransactionSummary.item_tool.item_code,
                description: batchItemInventoryTransactionSummary.item_tool.description,
                item_area: batchItemInventoryTransactionSummary.area.area_code,
                quantity_tool: batchItemInventoryTransactionSummary.quantity_tool,
                quantity_item_max: parseInt(batchItemInventoryTransactionSummary.quantity_item),
            });
        });
        setItemLocationToTableData(data);
    };

    const onChangeTaskType = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                item_item: "",
                batch: "",
                area_to: [],
                item_location_to_list: "",
            })
            setBatch("");
            onChangeItemLocationTo([]);
            setTaskType(value);

            promiseGetArea().then(
                function(value) {setAreaOption(value)},
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            );
            getBatch("", value, showMatched);
            getItemItem(value);
            getItemLocationTo({});
        };
    };

    const onChangeBatch = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                area_to: [],
                item_location_to_list: "",
            })
            onChangeItemLocationTo([]);
            setBatch(value);

            promiseGetArea(value).then(
                function(value) {setAreaOption(value)},
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            );
            getItemLocationTo({ batch: value, task_type: task_type, show_matched: showMatched });
        };
    };

    const onShowMatchedClick = (value) => {
        form.setFieldsValue({
            batch: "",
            area_to: [],
            item_location_to_list: "",
        })
        setBatch("");
        onChangeItemLocationTo([]);
        setShowMatched(value);

        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getBatch(item, task_type, value);
        getItemLocationTo({});
    };

    const onChangeArea = (value) => {
        onChangeItemLocationTo([]);
        getItemLocationTo({ batch: batch, areaList: value, task_type: task_type, show_matched: showMatched });
    };

    const onChangeItemLocationTo = (newSelectedRowKeys) => {
        let list = []
        newSelectedRowKeys.forEach( rowKey => {
            list.push({id: rowKey});
        });

        form.setFieldsValue({
            item_location_to_list: list,
        });

        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeItemLocationTo,
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getTaskType();
        getItemItem();
        getBatch();
        promiseGetArea(batch).then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
    }, []);

    const onItemItemSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                batch: "",
                area_to: [],
                item_location_to_list: "",
            })
            setBatch("");
            onChangeItemLocationTo([]);
            setItem(value);

            getBatch(value, task_type, showMatched);
            promiseGetArea().then(
                function(value) {setAreaOption(value)},
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            );
            getItemLocationTo({});
        };
    };

    const onTaskTypeClear = () => {
        setTaskType();
        form.setFieldsValue({
            item_item: "",
            batch: "",
            area_to: [],
            item_location_to_list: "",
        })
        setBatch("");
        onChangeItemLocationTo([]);

        getItemItem();
        getBatch();
        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getItemLocationTo({});
    };

    const onItemItemClear = () => {
        form.setFieldsValue({
            batch: "",
            area_to: [],
            item_location_to_list: "",
        })
        setItem("");
        setBatch("");
        onChangeItemLocationTo([]);

        getBatch("", task_type, showMatched);
        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getItemLocationTo({});
    };

    const onBatchClear = () => {
        setBatch();
        form.setFieldsValue({
            area_to: [],
            item_location_to_list: "",
        })
        onChangeItemLocationTo([]);

        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getItemLocationTo({});
    };

    const onAreaClear = () => {
        form.setFieldsValue({
            item_location_to_list: "",
        })
        onChangeItemLocationTo([]);

        getItemLocationTo({ batch: batch, task_type: task_type, show_matched: showMatched });
    };

    const onBack = () => {
        navigate(MENUPATH_FERTILIZATION);
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_FERTILIZATION) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (values) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create Fertilization activity confirmed?</p></Space>,
            onOk() { createActivity(values.actualExecutedDate) },
            onCancel() {},
            centered: true
        });
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onChangeTable = () => {
        // To restore the item_location_to_list as changing the table pagination/sorting/filter will reset the fields in the form
        onChangeItemLocationTo(selectedRowKeys);
    };

    const EditableRow = ({ index, ...props }) => {
        const [form1] = Form.useForm();
        return (
            <Form form={form1} component={false}>
                <EditableContext.Provider value={form1}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const columns = [
        { title: 'Zone', dataIndex: 'item_area', sorter: (a, b) => a.item_area.localeCompare(b.item_area) },
        { title: 'Medium Type(s)', dataIndex: 'item_code', sorter: (a, b) => a.item_code.localeCompare(b.item_code) },
        // { title: 'Quantity (Planting Medium)', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: 'Balance Units of Plant', dataIndex: 'quantity_item_max', sorter: (a, b) => a.quantity_item_max - b.quantity_item_max },
    ]

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Fertilization Activity" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Fertilization Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={values => onSave(values)} {...formLayoutWithTable} autoComplete="off">
                    <Form.Item name="task_type" label="Task Type" rules={[{required: true, message: 'Please select the Task Type for this Fertilization Activity!'}]}>
                        <Select onChange={(value) => onChangeTaskType(value)} allowClear={true} onClear={onTaskTypeClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {taskTypeOption}
                        </Select>
                    </Form.Item>

                    <Spin spinning={isLoadingItem} size="large" tip={LOADING}>
                        <Form.Item name="item_item" label="Plant Species">
                            <Select onSelect={(value) => onItemItemSelect(value)} allowClear={true} onClear={onItemItemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {itemItemOption}
                            </Select>
                        </Form.Item>
                    </Spin>

                    <Form.Item valuePropName="show_matched" label="Show Expected Batch Only" tooltip={{ title: "Show only batches that match with the expectation of its Work Programme", icon: <ExclamationCircleTwoTone/>}}>
                        <Switch onClick={onShowMatchedClick} defaultChecked={showMatched}/>
                    </Form.Item>

                    <Spin spinning={isLoadingBatch} size="large" tip={LOADING}>
                        <Form.Item name="batch" label="Batch Code" rules={[{required: true, message: 'Please select the Batch Code for this Fertilization Activity!'}]}>
                            <Select onChange={(value) => onChangeBatch(value)} allowClear={true} onClear={onBatchClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {batchOption}
                            </Select>
                        </Form.Item>
                    </Spin>

                    <Form.Item initialValue={moment()} name="actualExecutedDate" label="Actual Fertilization Date" rules={[{required: true, message: 'Please input the Actual Fertilization Date!'}]}>
                        <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment()}} disabledDate={disabledDateAfterToday}/>
                    </Form.Item>

                    <Form.Item name="area_to" label="Zone">
                        <Select onChange={(value) => onChangeArea(value)} allowClear={true} mode='multiple' onClear={onAreaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {areaOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="item_location_to_list" label="Location" rules={[{required: true, message: 'Please select at least one Location!'}]} tooltip={{ title: "Please select a Zone first", icon: <ExclamationCircleTwoTone/>}}>
                        <Table components={{ body: {row: EditableRow} }} rowClassName={() => 'editable-row'} rowSelection={rowSelection} columns={columns} dataSource={itemLocationToTableData} onChange={onChangeTable} pagination={{ size: 'small',  position: ["bottomCenter"], total: itemLocationToTableData.length, showTotal: showTotal }}/>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><SaveOutlined/>Save</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Fertilization Activity" subTitle="Create New Fertilization Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default FertilizationCreateNew;