import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Spin, Button, Form, Modal, message, Input, DatePicker } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, refreshUserSession, getUserAuthToken } from '../Common/UserSession';
import { formLayoutWithTable } from '../Common/Layout';
import { AGISAPIURL, UNIDATEFORMAT, MENUPATH_WORKPLAN, MENUPATH_WORKPROGRAMMETRANSFERTABLE, MENUPATH_WORKPLANCREATENEW, LOADING, VALUE_NONE } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';
import { PlusCircleTwoTone, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined, EditOutlined, BarChartOutlined } from '@ant-design/icons';
import moment from "moment";
import WorkProgrammePriorityDragTable from "./WorkProgrammePriorityDragTable";
import WorkPlanCharts from "./WorkPlanCharts";


const { confirm } = Modal;
const { Content, Footer } = Layout;
const { RangePicker } = DatePicker;

const WorkPlanCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const defaultWorkPlanCode = location.state ? location.state.record.workPlanCode : null;
    const defaultDescription = location.state ? location.state.record.description : null;
    const defaultWorkProgList = location.state ? location.state.record.workProgrammeList : [];
    const defaultDateRangeList = location.state ? location.state.record.date_range_list : [];
    const defaultResetPriority = location.state ? location.state.record.reset_priority : true;

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [siteCode, setSiteCode] = useState("");
    const [workPlanData, setWorkPlanData] = useState("");
    const [disableButton, setDisableButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const createWorkPlan = (workPlanCode, description) => {
        setDisableButton("disabled");
        setIsLoading(true);

        let date_range_list = [];
        form.getFieldValue('date_range_list').forEach(dateRange => date_range_list.push([dateRange['daterange'][0].format(UNIDATEFORMAT), dateRange['daterange'][1].format(UNIDATEFORMAT)]));

        axios.post(AGISAPIURL + "workplan/create/", {
            site: getUserSiteId(),
            work_plan_code: workPlanCode,
            description: description,
            work_programme_list: form.getFieldValue('work_programme_list') ? form.getFieldValue('work_programme_list') : [],
            date_range_list: date_range_list,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New work plan ${response.data[0].work_plan_code} created.`);
            navigate(MENUPATH_WORKPLAN);
        })
        .catch( error => {
            reportError(error, "Failed to create work plan. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getSiteCode = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "site/" + getUserSiteId(), {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setSiteCode(response.data.site_code);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const SearchWorkPlan = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "workplan/", {
            params: {
                site: getUserSiteId(),
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectWorkPlanRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectWorkPlanRawData = ( response ) => {
        const data = [];
        response.data.forEach( workPlan => {
            data.push({
                key: workPlan.id,
                id: workPlan.id,
                work_plan_code: workPlan.work_plan_code,
                description: workPlan.description,
                date_range_list: collectDateRangeListRawData(workPlan.date_range_list),
            });
        });
        setWorkPlanData(data);
    };

    const collectDateRangeListRawData = ( response ) => {
        const data = [];
        response.forEach( dataRange => {
            data.push({
                key: dataRange.id,
                id: dataRange.id,
                start_date: dataRange.start_date ? moment(dataRange.start_date).format(UNIDATEFORMAT) : VALUE_NONE,
                end_date: dataRange.end_date ? moment(dataRange.end_date).format(UNIDATEFORMAT) : VALUE_NONE,
            });
        });
        return data;
    };

    useEffect(() => {
        getSiteCode();
        if (!workPlanData) SearchWorkPlan();
    }, []);

    const collectDateRangeListData = ( response ) => {
        const data = [];
        response.forEach( dataRange => {
            data.push({daterange: [moment(dataRange[0]), moment(dataRange[1])]});
        });
        return data;
    };

    const validateDateRange = (date_range_list) => {
        let dateList = [];
        let overlapped = false;
        date_range_list.forEach(dateRangeRef => {
            if (dateRangeRef != undefined) {
                dateList.forEach(dateRangeExist => {
                    if (!(moment(dateRangeRef["daterange"][0]).isAfter(moment(dateRangeExist[1]), 'date') ||
                    moment(dateRangeRef["daterange"][1]).isBefore(moment(dateRangeExist[0]), 'date'))) overlapped = true;
                });
                dateList.push(dateRangeRef["daterange"]);
            };
        });

        if (overlapped) return Promise.reject(new Error('Date ranges have been overlapped!'));
    };

    const onBack = () => {
        navigate(MENUPATH_WORKPLAN);
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_WORKPLAN) },
            onCancel() {},
            centered: true
        });
    };

    const onAddRemoveWorkProgramme = () => {
        let record = null;
        let date_range_list = [];
        let work_programme_key_list = [];
        record = form.getFieldsValue();

        if (form.getFieldValue('date_range_list')) form.getFieldValue('date_range_list').forEach(dateRange => {if (dateRange !== undefined) date_range_list.push([dateRange['daterange'][0].format(UNIDATEFORMAT), dateRange['daterange'][1].format(UNIDATEFORMAT)])});
        if (form.getFieldValue('work_programme_list')) form.getFieldValue('work_programme_list').forEach(workProg => work_programme_key_list.push(workProg.id));
        record.date_range_list = date_range_list;
        record.workProgrammeList = record.work_programme_list;
        record.work_programme_key_list = work_programme_key_list;

        navigate(MENUPATH_WORKPROGRAMMETRANSFERTABLE, {state: {title: "New Work Plan", description: "Create New Work Plan", returnPage: MENUPATH_WORKPLANCREATENEW, record: record }});
    };

    const onShowWorkPlanChart = () => {
        let date_range_list = [];
        form.getFieldValue('date_range_list').forEach(dateRange => {
            if (dateRange) date_range_list.push({
                start_date: moment(dateRange['daterange'][0]),
                end_date: moment(dateRange['daterange'][1])
            });
        });

        const workPlanTableData = workPlanData.slice();
        if (date_range_list.length) {
            workPlanTableData.push({
                work_plan_code: "(*Currently Adding)",
                date_range_list: date_range_list,
            });
        };

        const modal = Modal.info({
            title: <div><h3>Work Plan Chart</h3></div>,
            icon: null,
            width: '100%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <WorkPlanCharts workPlanTableData={workPlanTableData}/>
        })
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (values) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create Work Plan confirmed?</p></Space>,
            onOk() { createWorkPlan(values.workPlanCode, values.description) },
            onCancel() {},
            centered: true
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Work Plan" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Work Plan</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={values => onSave(values)} {...formLayoutWithTable} autoComplete="off">
                    <Form.Item initialValue={defaultWorkPlanCode} name="workPlanCode" label="Work Plan Code"
                        rules={[{required: true, message: 'Please input the Work Plan Code for this Work Plan!'},
                                {pattern: new RegExp("^[0-9a-zA-Z]+$"), message: "Accept only letter and numbers. No space or special character is allowed!"}]}
                    >
                        <Input addonBefore={`${siteCode}-`} addonAfter={`-PLN-${moment().format('YYYYMMDD')}-XX`} style={{width: 350}} maxLength={10} showCount onInput={e => e.target.value = e.target.value.toUpperCase()}/>
                    </Form.Item>

                    <Form.Item initialValue={defaultDescription} name="description" label="Description" rules={[{required: true, message: 'Please input the description for this Work Plan!'}]}>
                        <Input maxLength={50} allowClear={true} showCount />
                    </Form.Item>

                    <Row justify="end">
                        <Col span={6}><Button onClick={onShowWorkPlanChart} type="primary" icon={<BarChartOutlined />} ghost>Show Work Plan Chart</Button></Col>
                    </Row>

                    <Form.Item name="date_range_list" label="Effective Date Range" rules={[{required: true, message: 'Please select at least one Effective Date Range!'}]}>
                        <Form.List initialValue={collectDateRangeListData(defaultDateRangeList)} name="date_range_list" rules={[{validator: async (_, date_range_list) => validateDateRange(date_range_list)}]}>
                            {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field) => (
                                    <Space key={field.key} align="baseline" size={19}>

                                        <Form.Item {...field} label="Date Range" name={[field.name, 'daterange']} rules={[{required: true, message: 'Please input the Date Range for this Work Plan!'}]}>
                                            <RangePicker format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}} disabledTime={true}/>
                                        </Form.Item>

                                        <MinusCircleOutlined onClick={() => {
                                            remove(field.name);
                                        }} />
                                    </Space>
                                ))}

                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>Add Date Range</Button>
                                </Form.Item>
                                <Form.ErrorList errors={errors} />
                            </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item name="add_remove_workprogramme" label="Add/Remove Work Programme">
                        <Button htmlType="button" disabled={disableButton} onClick={onAddRemoveWorkProgramme} type="primary"><EditOutlined/>Add/Remove Work Programme</Button>
                    </Form.Item>

                    <Form.Item name="work_programme_list" label="Work Programme">
                        <WorkProgrammePriorityDragTable form={form} defaultTableData={defaultWorkProgList} resetPriority={defaultResetPriority}/>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><PlusCircleTwoTone/>Create</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Work Plan" subTitle="Create New Work Plan" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default WorkPlanCreateNew;