import React,  { useEffect, useState } from "react";
import { Select, Form, Switch, Spin } from "antd";
import { AGISAPIURL, AREA_TYPE_ALL_LIST, ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT, LOADING, VALUE_NONE } from "./SysParameters";
import { getUserSiteId, getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./UserSession";
import { reportError } from "./Utility";
import axios from "axios";

const { Option } = Select;

const BatchSelect = ({withBlank, form, defaultBatch, defaultItemGroup, defaultItemArea, defaultItem, defaultWorkProgramme, defaultSearchActiveBatch, defaultLifecycle, defaultTaskCategory, defaultTask,
    onBatchChange, onItemGroupChange, onItemAreaChange, onItemChange, onWorkProgrammeChange, onLifecycleChange, onTaskCategoryChange, onTaskChange, useBatch = "",
    taskCategoryLabel = "Task Category", taskLabel = "Task", itemGroupLabel = "Item Group", itemAreaLabel = "Area", itemLabel = "Item", activeBatchLabel = "Active Batch", batchLabel = "Batch Code", workProgrammeLabel = "Work Prog.", lifecycleLabel = "Life Cycle"}) => {
    const [batchOption, setBatchOption] = useState("");
    const [itemGroupOption, setItemGroupOption] = useState("");
    const [itemAreaOption, setItemAreaOption] = useState("");
    const [itemOption, setItemOption] = useState("");
    const [workProgOption, setWorkProgOption] = useState("");
    const [lifecycleOption, setLifecycleOption] = useState("");
    const [taskCategoryOption, setTaskCategoryOption] = useState("");
    const [taskOption, setTaskOption] = useState("");
    const [itemGroup, setItemGroup] = useState(defaultItemGroup);
    const [item, setItem] = useState(defaultItem);
    const [task, setTask] = useState(defaultTask);
    const [taskCategory, setTaskCategory] = useState(defaultTaskCategory);
    const [isActiveBatch, setIsActiveBatch] = useState(true);
    const [isLoadingBatch, setIsLoadingBatch] = useState(false);
    const [isLoadingItem, setIsLoadingItem] = useState(false);
    const [isLoadingItemGroup, setIsLoadingItemGroup] = useState(false);
    const [isLoadingArea, setIsLoadingArea] = useState(false);
    const [isLoadingWorkProg, setIsLoadingWorkProg] = useState(false);
    const [isLoadingLifecycle, setIsLoadingLifecycle] = useState(false);
    const [isLoadingTaskCategory, setIsLoadingTaskCategory] = useState(false);
    const [isLoadingTask, setIsLoadingTask] = useState(false);

    const showBatch = onBatchChange != null;
    const showItemGroup = onItemGroupChange != null;
    const showItem = onItemChange != null;
    const showItemArea = onItemAreaChange != null;
    const showWorkProg = onWorkProgrammeChange != null;
    const showLifecycle = onLifecycleChange != null;
    const showTaskCategory = onTaskCategoryChange != null;
    const showTask = onTaskChange != null;

    const getBatch = ({itemId = "", isActive = "", item_group = ""}) => {
        setIsLoadingBatch(true);

        axios.get(AGISAPIURL + "batch/", {
            params: {
                site: getUserSiteId(),
                item: itemId,
                item_group: item_group,
                is_active: (isActive !== "") ? ((isActive) ? 1 : 0) : "",
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.map( batch => <Option key={batch.id} value={batch.id}>{batch.batch_code}</Option>));
            setBatchOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingBatch(false);
            refreshUserSession();
        });
    };

    const getItemGroup = (useBatchFlag) => {
        setIsLoadingItemGroup(true);

        axios.get(AGISAPIURL + "itemgroupbyusebatch/", {
            params: {
                use_batch: useBatchFlag,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => <Option key={itemprofile.item_group.id} value={itemprofile.item_group.id}>{itemprofile.item_group.name}</Option>));
            setItemGroupOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setIsLoadingItemGroup(false);
            refreshUserSession();
        });
    };

    const getItemArea = (area_type_list = []) => {
        setIsLoadingArea(true);

        axios.get(AGISAPIURL + "area/", {
            params: {
                site: getUserSiteId(),
                area_type_list: area_type_list,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( area => {
                    let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                    return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                }));
            setItemAreaOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingArea(false);
            refreshUserSession();
        });
    };

    const getItem = ({area_list = [], item_group = "", useBatchFlag = "", task_type = "", task_category = ""}) => {
        setIsLoadingItem(true);

        axios.get(AGISAPIURL + "itemprofilebytasktype/", {
            params: {
                area_list: area_list,
                item_group: item_group,
                use_batch: useBatchFlag,
                task_type: task_type,
                task_category: task_category,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
            setItemOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingItem(false);
            refreshUserSession();
        });
    };

    const getWorkProgramme = ({itemId = "", item_group = ""}) => {
        setIsLoadingWorkProg(true);

        axios.get(AGISAPIURL + "itemprofileworkprogramme/", {
            params: {
                site: getUserSiteId(),
                item: itemId,
                item_group: item_group,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options.push(<Option key={VALUE_NONE} value={VALUE_NONE}>{VALUE_NONE}</Option>); // None
            options = options.concat(response.data.results.map( itemprofileworkprogramme => <Option key={itemprofileworkprogramme.work_programme.id} value={itemprofileworkprogramme.work_programme.id}>{itemprofileworkprogramme.work_programme.description}</Option>));
            setWorkProgOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingWorkProg(false);
            refreshUserSession();
        });
    };

    const getLifecycle = ({itemId = "", item_group= "", taskId = "", task_category = ""}) => {
        setIsLoadingLifecycle(true);

        axios.get(AGISAPIURL + "lifecyclebytasktypeitem/", {
            params: {
                item: itemId,
                item_group: item_group,
                task_type: taskId,
                task_category: task_category,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( lifecyclebytaskitem => <Option key={lifecyclebytaskitem.lifecycle.id} value={lifecyclebytaskitem.lifecycle.id}>{lifecyclebytaskitem.lifecycle.name}</Option>));
            setLifecycleOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingLifecycle(false);
            refreshUserSession();
        });
    };

    const getTaskCategory = () => {
        setIsLoadingTaskCategory(true);

        axios.get(AGISAPIURL + "taskcategory/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( taskcategory => <Option key={taskcategory.id} value={taskcategory.id}>{taskcategory.description}</Option>));
            setTaskCategoryOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingTaskCategory(false);
            refreshUserSession();
        });
    };

    const getTask = (taskCategoryId) => {
        setIsLoadingTask(true);

        axios.get(AGISAPIURL + "tasktype/", {
            params: {
                task_category: taskCategoryId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( tasktype => <Option key={tasktype.id} value={tasktype.id}>{tasktype.task}</Option>));
            setTaskOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingTask(false);
            refreshUserSession();
        });
    };

    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const batchChange = (key, value) => {
        if(value !== undefined)
            onBatchChange(key, value);
        else
            onBatchChange(0, "");
    };

    const itemGroupChange = (key, value) => {
        if(value !== undefined)
            onItemGroupChange(key, value);
        else
            onItemGroupChange(0, "");
    };

    const itemAreaChange = (key, value) => {
        if(value !== undefined)
            onItemAreaChange(key, value);
        else
            onItemAreaChange(0, "");
    };

    const itemChange = (key, value) => {
        if(value !== undefined)
            onItemChange(key, value);
        else
            onItemChange(0, "");
    };

    const workProgrammeChange = (key, value) => {
        if(value !== undefined)
            onWorkProgrammeChange(key, value);
        else
            onWorkProgrammeChange(0, "");
    };

    const lifecycleChange = (key, value) => {
        if(value !== undefined)
            onLifecycleChange(key, value);
        else
            onLifecycleChange(0, "");
    };

    const taskCategoryChange = (key, value) => {
        if(value !== undefined)
            onTaskCategoryChange(key, value);
        else
            onTaskCategoryChange(0, "");
    };

    const taskChange = (key, value) => {
        if(value !== undefined)
            onTaskChange(key, value);
        else
            onTaskChange(0, "");
    };

    const itemGroupClear = () => {
        onItemGroupChange(0, "");
        form.setFieldsValue({
            item: "",
            item_area: "",
            batch_code: "",
            work_programme: "",
            lifecycle: "",
        })
        if (showItemArea) onItemAreaChange(0, "");
        if (showItem) onItemChange(0, "");
        if (showBatch) onBatchChange(0, "");
        if (showWorkProg) onWorkProgrammeChange(0, "");
        if (showLifecycle) onLifecycleChange(0, "");
        setItemGroup();

        if (showItem) getItem({ useBatchFlag: useBatch, task_type: task, task_category: taskCategory });
        if (showBatch) getBatch({ isActive: isActiveBatch });
        if (showWorkProg) getWorkProgramme({});
        if (showLifecycle) getLifecycle({ taskId: task, task_category: taskCategory });
    };

    const itemAreaClear = () => {
        onItemAreaChange(0, "");
        form.setFieldsValue({
            item: "",
            batch_code: "",
            work_programme: "",
            lifecycle: "",
        })
        if (showItem) onItemChange(0, "");
        if (showBatch) onBatchChange(0, "");
        if (showWorkProg) onWorkProgrammeChange(0, "");
        if (showLifecycle) onLifecycleChange(0, "");

        if (showItem) getItem({ item_group: itemGroup, useBatchFlag: useBatch, task_type: task, task_category: taskCategory });
        if (showBatch) getBatch({ isActive: isActiveBatch, item_group: itemGroup });
        if (showWorkProg) getWorkProgramme({ item_group: itemGroup });
        if (showLifecycle) getLifecycle({ item_group: itemGroup, taskId: task, task_category: taskCategory });
    };

    const itemClear = () => {
        onItemChange(0, "");
        form.setFieldsValue({
            batch_code: "",
            work_programme: "",
            lifecycle: "",
        })
        if (showBatch) onBatchChange(0, "");
        if (showWorkProg) onWorkProgrammeChange(0, "");
        if (showLifecycle) onLifecycleChange(0, "");
        setItem();

        if (showBatch) getBatch({ isActive: isActiveBatch, item_group: itemGroup });
        if (showWorkProg) getWorkProgramme({ item_group: itemGroup });
        if (showLifecycle) getLifecycle({ item_group: itemGroup, taskId: task, task_category: taskCategory });
    };

    const batchClear = () => {
        onBatchChange(0, "");
        form.setFieldsValue({
            work_programme: "",
        })
        if (showWorkProg) onWorkProgrammeChange(0, "");
    };

    const taskCategoryClear = () => {
        onTaskCategoryChange(0, "");
        form.setFieldsValue({
            task: "",
            item: "",
            batch_code: "",
            work_programme: "",
            lifecycle: "",
        })
        if (showTask) onTaskChange(0, "");
        if (showItem) onItemChange(0, "");
        if (showBatch) onBatchChange(0, "");
        if (showWorkProg) onWorkProgrammeChange(0, "");
        if (showLifecycle) onLifecycleChange(0, "");
        setTaskCategory();

        if (showTask) getTask();
        if (showItem) getItem({ item_group: itemGroup, useBatchFlag: useBatch });
        if (showBatch) getBatch({ isActive: isActiveBatch, item_group: itemGroup });
        if (showWorkProg) getWorkProgramme({ item_group: itemGroup });
        if (showLifecycle) getLifecycle({ item_group: itemGroup });
    };

    const taskClear = () => {
        onTaskChange(0, "");
        form.setFieldsValue({
            item: "",
            batch_code: "",
            work_programme: "",
            lifecycle: "",
        })
        if (showItem) onItemChange(0, "");
        if (showBatch) onBatchChange(0, "");
        if (showWorkProg) onWorkProgrammeChange(0, "");
        if (showLifecycle) onLifecycleChange(0, "");
        setTask();

        if (showItem) getItem({ item_group: itemGroup, useBatchFlag: useBatch, task_category: taskCategory });
        if (showBatch) getBatch({ isActive: isActiveBatch, item_group: itemGroup });
        if (showWorkProg) getWorkProgramme({ item_group: itemGroup });
        if (showLifecycle) getLifecycle({ item_group: itemGroup, task_category: taskCategory });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if (showItemGroup) getItemGroup(useBatch);
        if (showItemArea) getItemArea(AREA_TYPE_ALL_LIST);
        if (showItem) getItem({ useBatchFlag: useBatch, task_type: defaultTask, task_category: defaultTaskCategory });
        if (showBatch) getBatch({ itemId: defaultItem, isActive: isActiveBatch, item_group: itemGroup });
        if (showWorkProg) getWorkProgramme({ itemId: defaultItem, item_group: itemGroup });
        if (showLifecycle) getLifecycle({ itemId: defaultItem, item_group: itemGroup, taskId: defaultTask, task_category: defaultTaskCategory });
        if (showTaskCategory) getTaskCategory();
        if (showTask) getTask(defaultTaskCategory);
    }, []);

    const onItemGroupSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                item: "",
                item_area: "",
                batch_code: "",
                work_programme: "",
                lifecycle: "",
            })
            if (showItemArea) onItemAreaChange(0, "");
            if (showItem) onItemChange(0, "");
            if (showBatch) onBatchChange(0, "");
            if (showWorkProg) onWorkProgrammeChange(0, "");
            if (showLifecycle) onLifecycleChange(0, "");
            setItemGroup(value);

            if (showItem) getItem({ item_group: value, useBatchFlag: useBatch, task_type: task, task_category: taskCategory });
            if (showBatch) getBatch({ isActive: isActiveBatch, item_group: value });
            if (showWorkProg) getWorkProgramme({ item_group: value });
            if (showLifecycle) getLifecycle({ item_group: value, taskId: task, task_category: taskCategory });
        };
    };

    const onItemAreaSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                item: "",
                batch_code: "",
                work_programme: "",
                lifecycle: "",
            })
            if (showItem) onItemChange(0, "");
            if (showBatch) onBatchChange(0, "");
            if (showWorkProg) onWorkProgrammeChange(0, "");
            if (showLifecycle) onLifecycleChange(0, "");

            if (showItem) {
                if (itemGroup === ITEM_GROUP_FRUITY_PLANT || itemGroup === ITEM_GROUP_LEAFY_PLANT)
                    getItem({ item_group: itemGroup, useBatchFlag: useBatch, task_type: task, task_category: taskCategory });
                else
                    getItem({ area_list: [value],  item_group: itemGroup, useBatchFlag: useBatch, task_type: task, task_category: taskCategory });
            }
            if (showBatch) getBatch({ isActive: isActiveBatch, item_group: itemGroup });
            if (showWorkProg) getWorkProgramme({ item_group: itemGroup });
            if (showLifecycle) getLifecycle({ item_group: itemGroup, taskId: task, task_category: taskCategory });
        };
    };

    const onItemSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                batch_code: "",
                work_programme: "",
                lifecycle: "",
            })
            if (showBatch) onBatchChange(0, "");
            if (showWorkProg) onWorkProgrammeChange(0, "");
            if (showLifecycle) onLifecycleChange(0, "");
            setItem(value);

            if (showBatch) getBatch({ itemId: value, isActive: isActiveBatch, item_group: itemGroup });
            if (showWorkProg) getWorkProgramme({ itemId: value, item_group: itemGroup });
            if (showLifecycle) getLifecycle({ itemId: value, item_group: itemGroup, taskId: task, task_category: taskCategory });
        };
    };

    const onBatchSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                work_programme: "",
            })
            if (showWorkProg) onWorkProgrammeChange(0, "");
        };
    };

    const onActiveBatchClick = (value) => {
        form.setFieldsValue({
            batch_code: "",
            work_programme: "",
        })
        if (showBatch) onBatchChange(0, "");
        if (showWorkProg) onWorkProgrammeChange(0, "");
        setIsActiveBatch(value);

        if (showBatch) getBatch({ itemId: item, isActive: value, item_group: itemGroup });
    };

    const onTaskCategorySelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                task: "",
                item: "",
                batch_code: "",
                work_programme: "",
                lifecycle: "",
            })
            if (showTask) onTaskChange(0, "");
            if (showItem) onItemChange(0, "");
            if (showBatch) onBatchChange(0, "");
            if (showWorkProg) onWorkProgrammeChange(0, "");
            if (showLifecycle) onLifecycleChange(0, "");
            setTaskCategory(value);

            if (showTask) getTask(value);
            if (showItem) getItem({ item_group: itemGroup, useBatchFlag: useBatch, task_category: value });
            if (showBatch) getBatch({ isActive: isActiveBatch, item_group: itemGroup });
            if (showWorkProg) getWorkProgramme({ item_group: itemGroup });
            if (showLifecycle) getLifecycle({ item_group: itemGroup, task_category: value });
        };
    };

    const onTaskSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                item: "",
                batch_code: "",
                work_programme: "",
                lifecycle: "",
            })
            if (showItem) onItemChange(0, "");
            if (showBatch) onBatchChange(0, "");
            if (showWorkProg) onWorkProgrammeChange(0, "");
            if (showLifecycle) onLifecycleChange(0, "");
            setTask(value);

            if (showItem) getItem({ item_group: itemGroup, useBatchFlag: useBatch, task_type: value, task_category: taskCategory });
            if (showBatch) getBatch({ isActive: isActiveBatch, item_group: itemGroup });
            if (showWorkProg) getWorkProgramme({ item_group: itemGroup });
            if (showLifecycle) getLifecycle({ item_group: itemGroup, taskId: value, task_category: taskCategory });
        };
    };

    return (
        <>
            <Spin spinning={isLoadingTaskCategory} size="large" tip={LOADING}>
                <Form.Item initialValue={defaultTaskCategory} name="taskcategory" label={taskCategoryLabel} hidden={!showTaskCategory}>
                    <Select onChange={taskCategoryChange} onSelect={(value) => onTaskCategorySelect(value)} allowClear={true} onClear={taskCategoryClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {taskCategoryOption}
                    </Select>
                </Form.Item>
            </Spin>

            <Spin spinning={isLoadingTask} size="large" tip={LOADING}>
                <Form.Item initialValue={defaultTask} name="task" label={taskLabel} hidden={!showTask}>
                    <Select onChange={taskChange} onSelect={(value) => onTaskSelect(value)} allowClear={true} onClear={taskClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {taskOption}
                    </Select>
                </Form.Item>
            </Spin>

            <Spin spinning={isLoadingItemGroup} size="large" tip={LOADING}>
                <Form.Item initialValue={defaultItemGroup} name="itemgroup" label={itemGroupLabel} hidden={!showItemGroup}>
                    <Select onChange={itemGroupChange} onSelect={(value) => onItemGroupSelect(value)} allowClear={true} onClear={itemGroupClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {itemGroupOption}
                    </Select>
                </Form.Item>
            </Spin>

            <Spin spinning={isLoadingArea} size="large" tip={LOADING}>
                <Form.Item initialValue={defaultItemArea} name="item_area" label={itemAreaLabel} hidden={!showItemArea}>
                    <Select onChange={itemAreaChange} onSelect={(value) => onItemAreaSelect(value)} allowClear={true} onClear={itemAreaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {itemAreaOption}
                    </Select>
                </Form.Item>
            </Spin>

            <Spin spinning={isLoadingItem} size="large" tip={LOADING}>
                <Form.Item initialValue={defaultItem} name="item" label={itemLabel} hidden={!showItem}>
                    <Select onChange={itemChange} onSelect={(value) => onItemSelect(value)} allowClear={true} onClear={itemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {itemOption}
                    </Select>
                </Form.Item>
            </Spin>

            <Form.Item valuePropName="active_batch" label={activeBatchLabel} hidden={!showBatch}>
                <Switch onClick={onActiveBatchClick} defaultChecked={defaultSearchActiveBatch}/>
            </Form.Item>

            <Spin spinning={isLoadingBatch} size="large" tip={LOADING}>
                <Form.Item initialValue={defaultBatch} name="batch_code" label={batchLabel} hidden={!showBatch}>
                    <Select onChange={batchChange} onSelect={(value) => onBatchSelect(value)} allowClear={true} onClear={batchClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {batchOption}
                    </Select>
                </Form.Item>
            </Spin>

            <Spin spinning={isLoadingWorkProg} size="large" tip={LOADING}>
                <Form.Item initialValue={defaultWorkProgramme} name="work_programme" label={workProgrammeLabel} hidden={!showWorkProg}>
                    <Select onChange={workProgrammeChange} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {workProgOption}
                    </Select>
                </Form.Item>
            </Spin>

            <Spin spinning={isLoadingLifecycle} size="large" tip={LOADING}>
                <Form.Item initialValue={defaultLifecycle} name="lifecycle" label={lifecycleLabel} hidden={!showLifecycle}>
                    <Select onChange={lifecycleChange} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {lifecycleOption}
                    </Select>
                </Form.Item>
            </Spin>
        </>
    );

};

export default BatchSelect;