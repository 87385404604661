import { Layout } from "antd";

const { Footer } = Layout;

const AGISFooter = () => {
    return (
        <Footer style={{ textAlign: 'center' }}>AGIS Agriculture 2024</Footer>
    );
};

export default AGISFooter;