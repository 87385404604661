import React , { useEffect, useState } from 'react';
import { Table, Typography, Spin, message, Modal, Space, Button, Pagination, Row, Col, Tag, DatePicker } from 'antd';
import { AGISAPIURL, LOADING, STATUS_OPEN, PAGESIZE, APPJSON, UNIDATETIMEFORMAT, UNIDATEFORMAT, DATEFORMAT } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, getUserSiteId, refreshUserSession, getUserSiteName } from '../Common/UserSession';
import { reportError } from '../Common/Utility';
import moment from 'moment';
import axios from 'axios';
import { QuestionCircleTwoTone, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { confirm } = Modal;

const DailyToDoSowingTable = () => {
    const FileDownload = require('js-file-download');
    const [workProgTableData, setWorkProgTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState(moment());
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);  // The selectRowKeys name shall not be change as this is required for Antd to control over the selected rows

    const SearchDailyToDo = (date, currentPage) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "dailytodosowing/", {
            params: {
                site: getUserSiteId(),
                date: date.format(UNIDATEFORMAT),
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectWorkProgrammeData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        axios.get(`${AGISAPIURL}download/dailytodo/sowing/`, {
            params: {
                site: getUserSiteId(),
                date: date.format(UNIDATEFORMAT),
                search_criteria: `Site: ${getUserSiteName()}\nDate: ${date.format(DATEFORMAT)}`,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Daily To-Do Sowing ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    };

    const createBatch = (item, item_group, work_programme) => {
        setIsLoading(true);
        
        axios.post(AGISAPIURL + "batch/create/", {
            site: getUserSiteId(),
            item: item,
            item_group: item_group,
            work_programme: work_programme,
            status_type: STATUS_OPEN,
            actual_date: date.format("YYYY-MM-DD")
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New batch ${response.data.batch_code} created.`);
            SearchDailyToDo(date, currentPage);
        })
        .catch( error => {
            reportError(error, "Failed to create batch. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const createBatchAndSowing = (item, item_group, work_programme, quantity_to_sow) => {
        setIsLoading(true);
                        
        axios.post(AGISAPIURL + "batch/createbatchandsowing/", {
            site: getUserSiteId(),
            item: item,
            item_group: item_group,
            work_programme: work_programme,
            status_type: STATUS_OPEN,
            actual_date: date.format("YYYY-MM-DD"),
            quantity_to_sow: quantity_to_sow
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New batch ${response.data.batch_code} created.`);
            SearchDailyToDo(date, currentPage);
        })
        .catch( error => {
            reportError(error, "Failed to create batch and sowing. " + error.message)
        })
        .finally(() => {
            //setIsLoading(false);
            refreshUserSession();
        })
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    useEffect(() => {
        SearchDailyToDo(date, currentPage);
    }, []);

    const collectWorkProgrammeData = ( response ) => {
        const data = []
        response.data.results.forEach( workProg => {
            console.log(workProg)
            data.push({
                key: workProg.id,
                id: workProg.id,
                work_programme: workProg.description,
                item: workProg.item.item.description,
                item_id: workProg.item.item.id,
                item_group: workProg.item.item_group.name,
                item_group_id: workProg.item.item_group.id,
                repositories: workProg.repositories,
                quantity_item: parseInt(workProg.quantity_item),
                has_batch_today: workProg.has_batch_today,
            });
        });
        setWorkProgTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const onCreateNewBatch = (record) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            title: <p>Create batch confirmed?</p>,
            content: (<>
                <p>Item Group: <b>{record.item_group}</b></p>
                <p>Plant Species: <b>{record.item}</b></p>
                <p>Work Programme: <b>{record.work_programme}</b></p>
            </>),
            onOk() { createBatch(record.item_id, record.item_group_id, record.id) },
            onCancel() {},
            centered: true,
            width: 500,
        });
    };

    const onCreateNewBatchAndSowing = (record) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            title: <p>Fast-track create batch and sowing activity confirmed?</p>,
            content: (<>
                <p>Item Group: <b>{record.item_group}</b></p>
                <p>Plant Species: <b>{record.item}</b></p>
                <p>Work Programme: <b>{record.work_programme}</b></p>
            </>),
            onOk() { createBatchAndSowing(record.item_id, record.item_group_id, record.id, record.quantity_item) },
            onCancel() {},
            centered: true,
            width: 500,
        });
    };

    const onCreateNewBatchMultiple = (allRecord) => {
        const selectedRecords = allRecord.filter(obj => selectedRowKeys.includes(obj["id"]));
        confirm({
            icon: <QuestionCircleTwoTone />,
            title: <p>Create multiple batches confirmed?</p>,
            content: <Table columns={createNewBatchColumns} dataSource={selectedRecords} pagination={false}/>,
            onOk() {
                selectedRecords.forEach( record => {
                    createBatch(record.item_id, record.item_group_id, record.id);
                });
                setSelectedRowKeys([]);
            },
            onCancel() {},
            centered: true,
            width: 1000,
        });
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchDailyToDo(date, page);
    };

    const onDateChange = (value) => {
        setDate(value);
        setCurrentPage(1);
        SearchDailyToDo(value, 1);
    };

    const title = () => {
        return (
            <Title level={4}>
                <Row justify="center">
                    <Col span={12} style={{textAlign: "left"}}>What To Sow ({date.format(UNIDATEFORMAT)})</Col>
                    <Col span={12} style={{textAlign: "right"}}>
                        <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />}>Download PDF</Button>
                        <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />}>Download Excel</Button>
                    </Col>
                    <Col span={24} style={{textAlign: "left"}}><DatePicker defaultValue={date} onChange={onDateChange} format={UNIDATEFORMAT} clearIcon={false} /></Col>
                </Row>
            </Title>
        );
    };

    const footer = (allRecord) => {
        return (<Typography.Link disabled={selectedRowKeys.length ? false : true} title="Sure to create new batch?" onClick={() => onCreateNewBatchMultiple(allRecord)}>Create New Batch for Selected Row(s)</Typography.Link>)
    };

    const createNewBatchColumns = [
        { title: 'Item Group', dataIndex: 'item_group', sorter: (a, b) => a.item_group.localeCompare(b.item_group) },
        { title: 'Plant Species', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Work Programme', dataIndex: 'work_programme', sorter: (a, b) => a.work_programme.localeCompare(b.work_programme) },
        { title: 'Created?', dataIndex: 'has_batch_today', sorter: (a, b) => a.has_batch_today - b.has_batch_today, render: (has_batch_today) => {
            if (has_batch_today) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
    ];

    const columns = [
        { title: 'Item Group', dataIndex: 'item_group', sorter: (a, b) => a.item_group.localeCompare(b.item_group) },
        { title: 'Plant Species', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Repositories', dataIndex: 'repositories', sorter: (a, b) => a.repositories.localeCompare(b.repositories) },
        { title: 'Unit Amount', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item },
        { title: 'Work Programme', dataIndex: 'work_programme', sorter: (a, b) => a.work_programme.localeCompare(b.work_programme) },
        { title: 'Created?', dataIndex: 'has_batch_today', sorter: (a, b) => a.has_batch_today - b.has_batch_today, render: (has_batch_today) => {
            if (has_batch_today) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
        { title: 'Action (Manual)', dataIndex: 'action',
            render: (_, record) => {
                if(!record.has_batch_today)
                    return (<Typography.Link title="Sure to create new batch?" onClick={() => onCreateNewBatch(record)}>Create Batch</Typography.Link>)
                else
                    return(<></>)
            },
        },
        { title: 'Action (Auto)', dataIndex: 'action',
            render: (_, record) => {
                if(!record.has_batch_today)
                    return (<Typography.Link title="Sure to create new batch and sowing activity?" onClick={() => onCreateNewBatchAndSowing(record)}>Create Batch & Sowing</Typography.Link>)
                else
                    return(<></>)
            },
        },
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            {/* 2024-10-11 */}
            <Table title={title} columns={columns} dataSource={workProgTableData} /*rowSelection={rowSelection}*/ pagination={false} /*footer={footer}*//>
            <Row><Col><Space><br/></Space></Col></Row>

            <Row justify="center">
                <Col span={8}></Col>
                <Col span={8} style={{textAlign: "center"}}><Pagination total={totalRecord} showTotal={(total) => `Total ${total} item(s)`} defaultPageSize={PAGESIZE} current={currentPage} onChange={onPaginationChange} /></Col>
                <Col span={8} style={{textAlign: "right"}}>
                    <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />}>Download PDF</Button>
                    <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />}>Download Excel</Button>
                </Col>
            </Row>
        </Spin>
    );
};

export default DailyToDoSowingTable;