import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Column } from '@ant-design/plots';
import { each, groupBy } from '@antv/util';
import { Spin, Row, Col, Layout, Space, PageHeader, Descriptions, Table, Tag } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, MENUPATH_DASHBOARD, MENUPATH_DAILYTODOHARVEST, UNIDATEFORMAT } from '../Common/SysParameters';
import AGISHeader from '../Common/AGISHeader';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { ClockCircleOutlined } from '@ant-design/icons';

const { Content, Footer } = Layout;

const DashboardChartsHarvestingBySpecies = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const item_item = location.state.record.item_item;
    const item_item_id = location.state.record.item_item_id;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [overdueBatch, setOverdueBatch] = useState([]);
    const [dailyToDoHarvestingData, setDailyToDoHarvestingData] = useState([]);
    const [annotations, setAnnotations] = useState([]);

    const SearchDailyToDoHarvesting = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "dailytodoharvestingbyitem/", {
            params: {
                site: getUserSiteId(),
                item_item: item_item_id,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectDailyToDoHarvesting(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectDailyToDoHarvesting = ( response ) => {
        const data = []
        const annotation_data = []
        const batchOverdue = []
        response.data.results.forEach( transferSummary => {
            let overdue = false;
            if (moment(transferSummary.expected_harvesting_date).startOf('day') < moment().startOf('day')) {
                batchOverdue.push(transferSummary.batch.batch_code);
                overdue = true;
            };

            data.push({
                key: transferSummary.id,
                id: transferSummary.id,
                batch: transferSummary.batch.batch_code,
                item_item: transferSummary.item_item.description,
                item_tool: transferSummary.item_tool.item_code,
                area: transferSummary.area.area_code,
                expected_harvesting_date: moment(transferSummary.expected_harvesting_date),
                quantity_item: parseInt(transferSummary.quantity_item),
                quantity_tool: parseFloat(transferSummary.quantity_tool),
                overdue: overdue,
            });
        });
        setDailyToDoHarvestingData(data);
        setOverdueBatch(batchOverdue);

        // Set Annotations
        each(groupBy(data, 'item_tool'), (values, k) => {
            const quantity_item = values.reduce((a, b) => a + b.quantity_item, 0);
            annotation_data.push({
                type: 'text',
                position: [k, quantity_item],
                content: `${quantity_item}`,
                style: {
                    textAlign: 'center',
                    fontSize: 14,
                    fill: 'rgba(0,0,0,0.85)',
                },
                offsetY: -10,
            });
        });
        setAnnotations(annotation_data);
    };

    const onBack = () => {
        navigate(MENUPATH_DASHBOARD);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchDailyToDoHarvesting();
    }, []);

    const onClickTableRow = () => {
        return {
            onClick: () => {
                navigate(MENUPATH_DAILYTODOHARVEST);
            }
        };
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'Batch Code', dataIndex: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Planting Bed', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Plant Species', dataIndex: 'item_item', sorter: (a, b) => a.item_item.localeCompare(b.item_item) },
        { title: 'Quantity (Units)', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item },
        { title: 'Expected Harvesting Date', dataIndex: 'expected_harvesting_date', sorter: (a, b) => a.expected_harvesting_date - b.expected_harvesting_date,
            render: (expected_harvesting_date, record) => {
                if (record.overdue) {
                    return (<>{moment(expected_harvesting_date).format(UNIDATEFORMAT)} <Tag icon={<ClockCircleOutlined />} color='error'>Overdue</Tag></>);
                }
                else return (<>{moment(expected_harvesting_date).format(UNIDATEFORMAT)}</>);
            }
        },
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title={`Chart of Ready For Harvest (${item_item})`} onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">A stacked column chart showing {item_item} is ready to harvest</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Column
                        data={dailyToDoHarvestingData}
                        isStack={true}
                        xField='item_tool'
                        yField='quantity_item'
                        seriesField='batch'
                        appendPadding={20}
                        label={{
                            position: 'middle',
                            layout: [{type: 'adjust-color'}],
                        }}
                        annotations={annotations}
                        pattern={(value) => {
                            if (overdueBatch.includes(value.batch)) return { type: 'line', cfg: {backgroundColor: 'red'} }
                        }}
                    />
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Table onRow={onClickTableRow} columns={columns} dataSource={dailyToDoHarvestingData} pagination={{position: ["bottomCenter"], total: dailyToDoHarvestingData.length, showTotal: showTotal}}/>
                </Col></Row>

            </Content>
            <Footer><Row justify="center"><PageHeader title={`Chart of Ready For Harvest (${item_item})`} subTitle={`A stacked column chart showing ${item_item} is ready to harvest`} onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default DashboardChartsHarvestingBySpecies;