import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Button, Table, Collapse, Form, Spin, Badge, Avatar, Modal, Tag, Select, Typography, message } from 'antd';
import { OTHERSYSPARAM, getUserSiteId, getUserSiteName, getUserAuthToken, refreshUserSession, setLastPage } from "../Common/UserSession";
import axios from 'axios';
import { AGISAPIURL, UNIDATETIMEFORMAT, LOADING, PAGESIZE, UNIDATEFORMAT, VALUE_NONE, ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT, MENUPATH_WORKPLAN, MENUPATH_WORKPLANCREATENEW,
    MENUPATH_WORKPLANUPDATE, APPJSON } from '../Common/SysParameters';
import { reportError } from '../Common/Utility';
import moment from 'moment';
import { formLayout } from '../Common/Layout';
import { PlusCircleTwoTone, CalendarOutlined, CheckOutlined, ScheduleOutlined, QuestionCircleTwoTone, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import WorkProgrammeListTable from "./WorkProgrammeListTable";
import TrueFalseSelect from '../Common/TrueFalseSelect';
import DateRangeListTable from "./DateRangeListTable";
import WorkPlanCharts from "./WorkPlanCharts";


const { Option } = Select;
const { Panel } = Collapse;
const { confirm } = Modal;
const { Text } = Typography;

const WorkPlanTable = () => {
    const FileDownload = require('js-file-download');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemOption, setItemOption] = useState("");
    const [workProgOption, setWorkProgOption] = useState("");
    const [workPlanTableData, setWorkPlanTableData] = useState([]);
    const [item, setItem] = useState(0);
    const [workProg, setWorkProg] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const [isInEffect, setIsInEffect] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [itemVal, setItemVal] = useState("");
    const [workProgVal, setWorkProgVal] = useState("");

    const SearchWorkPlan = (currentPage) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "workplan/", {
            params: {
                site: getUserSiteId(),
                item: item,
                work_programme: workProg,
                is_active: (isActive !== "") ? ((isActive) ? 1 : 0) : "",
                is_in_effect: (isInEffect !== "") ? ((isInEffect) ? 1 : 0) : "",
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectWorkPlanRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            //setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItem = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofileofworkprogramme/", {
            params: {
                site: getUserSiteId(),
                item_group_list: [ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
            setItemOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            //setIsLoading(false);
            refreshUserSession();
        });
    };

    const getWorkProgramme = ({item = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "workprogramme/", {
            params: {
                site: getUserSiteId(),
                item: item,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.map( workProg => <Option key={workProg.id} value={workProg.id}>{workProg.description}</Option>));
            setWorkProgOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const deleteWorkPlan = (id) => {
        setIsLoading(true);

        axios.delete(AGISAPIURL + "workplan/delete/" + id, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then(() => {
            message.info(`Work Plan ${id} deleted!`);
            SearchWorkPlan(currentPage);
        })
        .catch( error => {
            setIsLoading(false);
            reportError(error, "Failed to delete work plan. " + error.message);
        })
        .finally(() => {
            refreshUserSession();
        });
    };

    // Download pdf, excel search result.
    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(workProg != 0) searchCriteria = `${searchCriteria}\nWork Programme: ${workProgVal}`
        if(item != 0) searchCriteria = `${searchCriteria}\nPlant Species: ${itemVal}`
        if(isInEffect !== "") searchCriteria = `${searchCriteria}\nIs Active: ${isInEffect ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}`
        if(isActive !== "") searchCriteria = `${searchCriteria}\nIs Active: ${isActive ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}`

        axios.get(`${AGISAPIURL}download/workplan/`, {
            params: {
                site: getUserSiteId(),
                item: item,
                work_programme: workProg,
                is_active: (isActive !== "") ? ((isActive) ? 1 : 0) : "",
                is_in_effect: (isInEffect !== "") ? ((isInEffect) ? 1 : 0) : "",
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Work Plan ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    };

    useEffect(() => {
        setLastPage(MENUPATH_WORKPLAN);
        SearchWorkPlan(currentPage);
        getItem();
        getWorkProgramme({});
    }, []);

    const collectWorkPlanRawData = ( response ) => {
        const data = [];
        response.data.results.forEach( workPlan => {
            data.push({
                key: workPlan.id,
                id: workPlan.id,
                work_plan_code: workPlan.work_plan_code,
                description: workPlan.description,
                is_active: workPlan.is_active,
                is_in_effect: workPlan.is_in_effect,
                date_range_list: collectDateRangeListRawData(workPlan.date_range_list),
                work_programme_list: collectWorkProgrammeListRawData(workPlan.work_programme_list),
                created_on: moment(workPlan.created_on).format(UNIDATETIMEFORMAT),
                updated_on: moment(workPlan.updated_on).format(UNIDATETIMEFORMAT),
            });
        });
        setWorkPlanTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const collectDateRangeListRawData = ( response ) => {
        const data = [];
        response.forEach( dataRange => {
            data.push({
                key: dataRange.id,
                id: dataRange.id,
                start_date: dataRange.start_date ? moment(dataRange.start_date).format(UNIDATEFORMAT) : VALUE_NONE,
                end_date: dataRange.end_date ? moment(dataRange.end_date).format(UNIDATEFORMAT) : VALUE_NONE,
            });
        });
        return data;
    };

    const collectWorkProgrammeListRawData = ( response ) => {
        const data = [];
        response[0].forEach((workProg, i) => {
            let item = VALUE_NONE;
            if (workProg.item) item = workProg.item.item.description;

            data.push({
                key: workProg.id,
                id: workProg.id,
                priority: response[1][i],
                work_programme_code: workProg.work_programme_code,
                description: workProg.description,
                workday: workProg.workday,
                monday: workProg.workday.monday,
                tuesday: workProg.workday.tuesday,
                wednesday: workProg.workday.wednesday,
                thursday: workProg.workday.thursday,
                friday: workProg.workday.friday,
                saturday: workProg.workday.saturday,
                sunday: workProg.workday.sunday,
                item: item,
                quantity_item: parseInt(workProg.quantity_item),
                work_stage_list: collectWorkStageListRawData(workProg.work_stage_list),
                work_task_list: collectWorkTaskListRawData(workProg.work_task_list),
                is_active: workProg.workday.is_active,
                created_on: moment(workProg.created_on).format(UNIDATETIMEFORMAT),
                updated_on: moment(workProg.updated_on).format(UNIDATETIMEFORMAT),
                has_batch: workProg.has_batch,
            });
        });
        return data;
    };

    const collectWorkStageListRawData = ( response ) => {
        const data = [];
        response.forEach( workStage => {
            data.push({
                key: workStage.id,
                id: workStage.id,
                seq: workStage.seq,
                duration: parseInt(workStage.duration),
                duration_uom: workStage.duration_type.unit,
                lifecycle: workStage.lifecycle.name,
                work_phase: workStage.work_phase.name,
            });
        });
        return data;
    };

    const collectWorkTaskListRawData = ( response ) => {
        const data = [];
        response.forEach( workTask => {
            data.push({
                key: workTask.id,
                id: workTask.id,
                seq: workTask.seq,
                execute_on: parseInt(workTask.execute_on),
                execute_on_uom: workTask.execute_on_type.unit,
                task_type: workTask.task_type.task,
                lifecycle: workTask.work_stage.lifecycle.name,
                work_phase: workTask.work_stage.work_phase.name,
            });
        });
        return data;
    };

    const onChangeItem = (key, value) => {
        if(key !== undefined) {
            setItem(key);
            setItemVal(value.children);
        };
        form.setFieldsValue({
            workProgramme: "",
        });
        setWorkProg(0);
        setWorkProgVal("");

        getWorkProgramme({ item: key });
    };

    const onChangeWorkProgramme = (key, value) => {
        if(key !== undefined) {
            setWorkProg(key);
            setWorkProgVal(value.children);
        };
    };

    const onChangeIsActive = (value) => {
        setIsActive(value);
    };

    const onChangeIsInEffect = (value) => {
        setIsInEffect(value);
    };

    const onItemClear = () => {
        setItem(0);
        setItemVal("");
        form.setFieldsValue({
            workProgramme: "",
        });
        setWorkProg(0);
        setWorkProgVal("");

        getWorkProgramme({});
    };

    const onWorkProgrammeClear = () => {
        setWorkProg(0);
        setWorkProgVal("");
    };

    const onSearch = () => {
        setCurrentPage(1);
        SearchWorkPlan(1);
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onClickDateRangeList = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Effective Date Range ({record.description})</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <DateRangeListTable dataSource={record.date_range_list}/>
        })
    };

    const onCreateNewWorkPlan = () => {
        navigate(MENUPATH_WORKPLANCREATENEW);
    };

    const onClickTableRow = (record, rowIndex) => {
        return {
            // style: {background: record.is_in_effect ? '#B2FFFF' : 'default'},  // highlight the row with work plan is in effect
            onClick: () => {
                navigate(MENUPATH_WORKPLANUPDATE, {state: {record: record}});
            }
        };
    };

    const onClickWorkProgrammeList = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Work Plan: {record.description}</h3></div>,
            icon: null,
            width: '90%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <WorkProgrammeListTable dataSource={record.work_programme_list} navigate={navigate}/>
        })
    };

    //---------------------------
    // On delete
    //---------------------------
    const onDelete = (e, key) => {
        e.stopPropagation();

        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Delete work plan confirmed?</p></Space>,
            onOk() { deleteWorkPlan(key) },
            onCancel() {},
            centered: true
        });
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchWorkPlan(page);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Work Plan Code', dataIndex: 'work_plan_code', sorter: (a, b) => a.work_plan_code.localeCompare(b.work_plan_code),
            render: (work_plan_code, record) => {
                if (record.is_in_effect) return (<Text>{work_plan_code} <Tag icon={<CheckOutlined />} color="success">In Effect Now</Tag></Text>)
                return (<Text>{work_plan_code}</Text>);
            }
        },
        { title: 'Description', dataIndex: 'description', sorter: (a, b) => a.description.localeCompare(b.description) },
        { title: 'Status', dataIndex: 'is_active', render: (is_active) => {return (is_active ? <Tag color="success">Active</Tag> : <Tag color="default">Inactive</Tag>)} },
        { title: 'Date Range', dataIndex: 'date_range_list',
            render: (date_range_list, record) => {
                if (date_range_list.length) { return (
                    <Badge count={date_range_list.length} onClick={(e) => onClickDateRangeList(e, record)}>
                        <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<ScheduleOutlined />} />
                    </Badge>);
                }
                else return (<>{VALUE_NONE}</>);
            }
        },
        { title: 'In Effect', dataIndex: 'is_in_effect', render: (is_in_effect) => {return (is_in_effect ? <Tag color="success">{OTHERSYSPARAM("YES")}</Tag> : <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>)} },
        { title: 'Work Programme(s)', dataIndex: 'work_programme_list',
            render: (work_programme_list, record) => {
                if (work_programme_list.length) { return (
                    <Badge count={work_programme_list.length} onClick={(e) => onClickWorkProgrammeList(e, record)}>
                        <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<CalendarOutlined />} />
                    </Badge>);
                }
                else return (<>{VALUE_NONE}</>);
            }
        },
        { title: 'Action', dataIndex: 'action',
            render: (_, record) => {
                return record.work_programme_list.length === 0 ? (
                    <Typography.Link title="Sure to delete this work plan?" onClick={(e) => onDelete(e, record.key) }>Delete</Typography.Link>
                ) : (
                    <Typography.Link disabled title="The work plan can't be delete if it has work programme attached to it." onClick={(e) => onDelete(e, record.key) }>Delete</Typography.Link>
                );
            },
        },
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search Work Plan" key="1">
                    <Form form={form} {...formLayout}>
                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item initialValue={item} name="item" label="Plant Species">
                                    <Select onChange={(e, value) => onChangeItem(e, value)} allowClear={true} onClear={onItemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {itemOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={workProg} name="workProgramme" label="Work Prog.">
                                    <Select onChange={(e, value) => onChangeWorkProgramme(e, value)} allowClear={true} onClear={onWorkProgrammeClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {workProgOption}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item initialValue={isInEffect} name="isInEffect" label="Is In Effect">
                                    <TrueFalseSelect withBlank={true} defaultSelect="" onChange={onChangeIsInEffect}/>
                                </Form.Item>

                                <Form.Item initialValue={isActive} name="isActive" label="Is Active">
                                    <TrueFalseSelect withBlank={true} defaultSelect={OTHERSYSPARAM("YES")} onChange={onChangeIsActive}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>
            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewWorkPlan} type="primary"><PlusCircleTwoTone /> New Work Plan</Button></Col></Row>
            <Row><Col><Space><br/></Space></Col></Row>

            <Table bordered={true} onRow={onClickTableRow} columns={columns} dataSource={workPlanTableData}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: PAGESIZE,
                    current: currentPage,
                    total: totalRecord,
                    showTotal: showTotal,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                }}/>

            <Row><Col><Space><br/></Space></Col></Row>
            {workPlanTableData.length ? <WorkPlanCharts workPlanTableData={workPlanTableData}/> : <></>}
            <Row><Col><Space><br/></Space></Col></Row>

            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewWorkPlan} type="primary"><PlusCircleTwoTone /> New Work Plan</Button></Col></Row>
        </Spin>
        </>
    );
};

export default WorkPlanTable;