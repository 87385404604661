import { Tag } from 'antd';
import { STATUS_EMPTY, STATUS_FULLY_OCCUPIED, STATUS_BLOCKED, STATUS_REST, STATUS_RETIRED, STATUS_AVAILABLE, STATUS_PARTIALLY_OCCUPIED, ITEM_GROUP_LEAFY_BATAS, ITEM_GROUP_LEAFY_PLANT,
    ITEM_GROUP_FRUITY_BATAS, ITEM_GROUP_FRUITY_PLANT, ITEM_GROUP_BIN, ITEM_GROUP_TRAY, ITEM_GROUP_FERTILIZER, ITEM_GROUP_PEST_CONTROL, ITEM_GROUP_WATER } from '../Common/SysParameters';


const columnItemCode = { title: 'Item Code', dataIndex: 'item_code', sorter: (a, b) => a.item_code.localeCompare(b.item_code) };
const columnDescription = { title: 'Description', dataIndex: 'description', sorter: (a, b) => a.description.localeCompare(b.description) };
const columnItemCategory = { title: 'Item Category', dataIndex: 'item_category', sorter: (a, b) => a.item_category.localeCompare(b.item_category) };
const columnItemGroup = { title: 'Item Group', dataIndex: 'item_group', sorter: (a, b) => a.item_group.localeCompare(b.item_group) };
const columnItemType = { title: 'Item Type', dataIndex: 'item_type', sorter: (a, b) => a.item_type.localeCompare(b.item_type) };
const columnItemVariety = { title: 'Item Variety', dataIndex: 'item_variety', sorter: (a, b) => a.item_variety.localeCompare(b.item_variety) };
const columnStatus = { title: 'Status', dataIndex: 'status_type', sorter: (a, b) => a.status_type.localeCompare(b.status_type),
    render: (status_type, record) => {
        if (record.status_type_id === STATUS_EMPTY) return <Tag color="success">{status_type}</Tag>
        else if (record.status_type_id === STATUS_PARTIALLY_OCCUPIED) return <Tag color="processing" style={{borderStyle: 'dashed'}}>{status_type}</Tag>
        else if (record.status_type_id === STATUS_FULLY_OCCUPIED) return <Tag color="processing">{status_type}</Tag>
        else if (record.status_type_id === STATUS_BLOCKED) return <Tag color="error">{status_type}</Tag>
        else if (record.status_type_id === STATUS_REST) return <Tag color="warning">{status_type}</Tag>
        else if (record.status_type_id === STATUS_RETIRED) return <Tag color="default">{status_type}</Tag>
        else if (record.status_type_id === STATUS_AVAILABLE) return <Tag color="success">{status_type}</Tag>
        else return <>{status_type}</>
    },
};
const columnUnitPlantsOccupied = { title: 'Unit Plants Occupied', dataIndex: 'amount_occupied', align: "center", sorter: (a, b) => a.amount_occupied - b.amount_occupied,
    render: (amount_occupied) => {
        return (<>{parseInt(amount_occupied)}</>)
    },
};
const columnArea = { title: 'Area', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) };
const columnPlantingCycleRevive = { title: 'Planting Cycle (Revive)', dataIndex: 'planting_cycle_after_revive', align: "center", sorter: (a, b) => a.planting_cycle_after_revive - b.planting_cycle_after_revive };
const columnPlantingCycleRehabilitate = { title: 'Planting Cycle (Rehabilitate)', dataIndex: 'planting_cycle_after_rehabilitate', align: "center", sorter: (a, b) => a.planting_cycle_after_rehabilitate - b.planting_cycle_after_rehabilitate };
const columnAvailableQuantity = { title: 'Available Quantity', dataIndex: 'available_quantity', align: "center", sorter: (a, b) => a.available_quantity - b.available_quantity,
    render: (available_quantity, record) => {
        return (<><b>{parseInt(available_quantity)}</b> / {parseInt(record.total_quantity)}</>)
    },
};


const columnsAll = [
    columnItemCode,
    columnDescription,
    columnItemCategory,
    columnItemGroup,
    columnItemType,
    columnItemVariety,
    columnStatus,
    columnUnitPlantsOccupied,
    columnArea,
    columnPlantingCycleRevive,
    columnPlantingCycleRehabilitate,
    columnAvailableQuantity,
];

const columnsPlantGroup = [
    columnItemCode,
    columnDescription,
    columnItemCategory,
    columnItemGroup,
    columnItemVariety,
];

const columnsBatasGroup = [
    columnItemCode,
    columnDescription,
    columnItemCategory,
    columnItemGroup,
    columnItemType,
    columnStatus,
    columnUnitPlantsOccupied,
    columnArea,
    columnPlantingCycleRevive,
    columnPlantingCycleRehabilitate,
];

const columnsEquipmentGroup = [
    columnItemCode,
    columnDescription,
    columnItemCategory,
    columnItemGroup,
    columnItemType,
    columnStatus,
    columnArea,
    // Obsolete this due to we are not going to keep track the quantity of the tray and harvest bin
    // columnAvailableQuantity,
];

const columnsMaterialGroup = [
    columnItemCode,
    columnDescription,
    columnItemCategory,
    columnItemGroup,
    columnArea,
];


export const getItemTableColumns = (itemGroup) => {
    if (itemGroup === ITEM_GROUP_LEAFY_PLANT || itemGroup === ITEM_GROUP_FRUITY_PLANT) return columnsPlantGroup;
    else if (itemGroup === ITEM_GROUP_LEAFY_BATAS || itemGroup === ITEM_GROUP_FRUITY_BATAS) return columnsBatasGroup;
    else if (itemGroup === ITEM_GROUP_TRAY || itemGroup === ITEM_GROUP_BIN) return columnsEquipmentGroup;
    else if (itemGroup === ITEM_GROUP_FERTILIZER || itemGroup === ITEM_GROUP_PEST_CONTROL || itemGroup === ITEM_GROUP_WATER) return columnsMaterialGroup;
    else return columnsAll;
};
