import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Spin, Button, Form, Select, Modal, message, Input, Checkbox, Switch, InputNumber, Table } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, refreshUserSession, getUserAuthToken } from '../Common/UserSession';
import { formLayout } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_WORKPROGRAMME, LOADING, ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT, UNIT_OF_TIME_DAY, UNIT_OF_TIME_DAY_SIGN, UNIT_OF_MEASUREMENT_UNIT_SIGN, VALUE_NOT_APPLICABLE } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';
import { PlusCircleTwoTone, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
const EditableContext = React.createContext(null);


const { Option } = Select;
const { confirm } = Modal;
const { Content, Footer } = Layout;

const WorkProgrammeCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemGroupOptionList, setItemGroupOptionList] = useState([ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT]);
    const [itemGroupOption, setItemGroupOption] = useState("");
    const [itemOption, setItemOption] = useState("");
    const [workStageOption, setWorkStageOption] = useState("");
    const [taskTypeOption, setTaskTypeOption] = useState("");
    const [workStageTableData, setWorkStageTableData] = useState([]);
    const [itemGroup, setItemGroup] = useState("");
    const [item, setItem] = useState("");
    const [isWorkProgActive, setIsWorkProgActive] = useState(true);
    const [disableButton, setDisableButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const workdayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [workdayList, setWorkdayList] = useState([]);
    const checkAll = workdayOptions.length === workdayList.length;
    const indeterminate = workdayList.length > 0 && workdayList.length < workdayOptions.length;

    const createWorkProgramme = (description) => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "workprogramme/create/", {
            site: getUserSiteId(),
            item: item,
            item_group: itemGroup,
            description: description,
            workday_list: workdayList,
            quantity: form.getFieldValue('quantity'),
            is_active: isWorkProgActive,
            work_stage_list: form.getFieldValue('work_stage_list'),
            work_task_list: form.getFieldValue('work_task_list'),
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New work programme ${response.data[0].work_programme_code} created.`);
            navigate(MENUPATH_WORKPROGRAMME);
        })
        .catch( error => {
            reportError(error, "Failed to create work programme. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getItemGroup = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemgroupbyusebatch/", {
            params: {
                use_batch: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            const itemGroups = [];
            response.data.results.forEach( itemprofile => {
                if (itemGroupOptionList.includes(itemprofile.item_group.id)) itemGroups.push(<Option key={itemprofile.item_group.id} value={itemprofile.item_group.id}>{itemprofile.item_group.name}</Option>);
            })
            setItemGroupOption(itemGroups);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItem = ({ item_group = "" }) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofile/", {
            params: {
                item_group: item_group,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemOption(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getWorkStageStructure = ({ item_group = "" }) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "workstagestructure/", {
            params: {
                item_group: item_group,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectWorkStageStructureRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getTaskType = ({ item_group = "", item = "" }) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemtasktype/", {
            params: {
                item: item,
                item_group: item_group,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setTaskTypeOption(response.data.results.map( itemtasktype => <Option key={itemtasktype.task_type.id} value={itemtasktype.task_type.id}>{itemtasktype.task_type.task}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectWorkStageStructureRawData = ( response ) => {
        const data = [];
        const workStageSeq = [];
        let offset = 0;
        response.data.results.forEach( workStageStructure => {
            data.push({
                key: workStageStructure.id,
                seq: workStageStructure.seq,
                lifecycle: workStageStructure.lifecycle.name,
                lifecycle_id: workStageStructure.lifecycle.id,
                work_phase: workStageStructure.work_phase.name,
                work_phase_id: workStageStructure.work_phase.id,
                area_type: workStageStructure.area_type.id,
                duration: parseInt(workStageStructure.duration),
                duration_type: workStageStructure.duration_type.unit,
                duration_type_id: workStageStructure.duration_type.id,
                offset: offset + 1,
            });
            if (workStageStructure.duration_type.id === UNIT_OF_TIME_DAY) offset = offset + parseInt(workStageStructure.duration);
            workStageSeq.push(<Option key={workStageStructure.seq} value={workStageStructure.seq}>{workStageStructure.seq}</Option>);
        });
        setWorkStageTableData(data);

        // Update form data
        form.setFieldsValue({
            "work_stage_list": data,
        });

        // Set Work Stage options
        setWorkStageOption(workStageSeq);
    };

    const itemGroupChange = (value) => {
        if(value !== undefined)
            setItemGroup(value);
        else
            setItemGroup("");
    };

    const itemChange = (value) => {
        if(value !== undefined)
            setItem(value);
        else
            setItem("");
    };

    const onChangeWorkday = (list) => {
        setWorkdayList(list);
    };

    const onChangeCheckAll = (e) => {
        setWorkdayList(e.target.checked ? workdayOptions : []);
        form.setFieldsValue({
            workday: e.target.checked ? workdayOptions : [],
        });
    };

    const onClickIsWorkProgrammeActive = (value) => {
        setIsWorkProgActive(value);
        if (!value)
        Modal.warning({
            title: 'Warning when deactivating the Work Programme!',
            content: (<div>
                <p>Note that the de-activation of this work programme will be reflected in all work plans that this work programme has attached to.</p>
            </div>),
            width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"),
      });
    };

    const itemGroupClear = () => {
        setItemGroup();
        form.setFieldsValue({
            item: "",
            description: "",
            work_stage_list: "",
            work_task_list: "",
        })
        setItem();
        setWorkStageTableData([]);
        setWorkStageOption("");

        getItem({});
        getTaskType({});
    };

    const itemClear = () => {
        setItem();
        form.setFieldsValue({
            description: "",
            work_task_list: "",
        })

        getTaskType({ item_group: itemGroup });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getItemGroup();
        getItem({});
    }, []);

    const onItemGroupSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                item: "",
                description: "",
                work_stage_list: "",
                work_task_list: "",
            })
            setItem();
            setWorkStageTableData([]);

            if(value === ITEM_GROUP_LEAFY_PLANT || value === ITEM_GROUP_FRUITY_PLANT & value !== 0) getItem({ item_group: value });
            getWorkStageStructure({ item_group: value });
            getTaskType({ item_group: value });
        };
    };

    const onItemSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                description: "",
                work_task_list: "",
            })

            getTaskType({ item_group: itemGroup, item: value });
        };
    };

    const onBack = () => {
        navigate(MENUPATH_WORKPROGRAMME);
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_WORKPROGRAMME) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (values) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create Work Programme confirmed?</p></Space>,
            onOk() { createWorkProgramme(values.description) },
            onCancel() {},
            centered: true
        });
    };

    const onChangeTable = () => {
        // To restore the work_stage_list as changing the table pagination/sorting/filter will reset the fields in the form
        form.setFieldsValue({
            work_stage_list: workStageTableData,
        });
    };

    const validateWorkTask = (tasks) => {
        const workStageList = form.getFieldValue('work_stage_list');
        let errorIndex = -1;
        let index = 0;

        if(tasks !== "") {
            tasks.forEach(task => {
                let workStageData = workStageList[task.work_stage_seq - 1]
                if ((task.execute_on < workStageData.offset) || (task.execute_on > (workStageData.offset + workStageData.duration - 1))) {
                    errorIndex = index;
                };
                index += 1;
            });
        };
        if (errorIndex !== -1) {
            if ( workStageList[tasks[errorIndex].work_stage_seq - 1].duration > 0) return Promise.reject(new Error("Planned Task #" + (errorIndex + 1) + " has exceeded the day range of Work Stage Seq " + tasks[errorIndex].work_stage_seq + " (Day " + workStageList[tasks[errorIndex].work_stage_seq - 1].offset + " - Day " + (workStageList[tasks[errorIndex].work_stage_seq - 1].offset + workStageList[tasks[errorIndex].work_stage_seq - 1].duration - 1) + ")!"))
            else return Promise.reject(new Error("Error in Planned Task #" + (errorIndex + 1) + " due to Work Stage Seq " + tasks[errorIndex].work_stage_seq + " is unavailable!"));
        }
    };

    const EditableRow = ({ index, ...props }) => {
        const [form1] = Form.useForm();
        return (
            <Form form={form1} component={false}>
                <EditableContext.Provider value={form1}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ editable, dataIndex, title, record, index, children, ...restProps }) => {
        let childNode = children;
        const form1 = useContext(EditableContext);

        const save = async () => {
            try {
                const row = await form1.validateFields();
                const newData = [...workStageTableData];
                const newDataIndex = newData.findIndex((item) => record.key === item.key);

                if (newDataIndex > -1) {
                    const item = newData[newDataIndex];
                    newData.splice(newDataIndex, 1, { ...item, ...row });

                    // Re-Calculate the day range if it is in Unit of Day
                    if (item["duration_type_id"] === UNIT_OF_TIME_DAY) {
                        let offset = item["offset"] + row['duration'];
                        for (let i = newDataIndex + 1; i < newData.length; i++) {
                            const tempData = newData[i];
                            newData.splice(i, 1, { ...tempData, offset: offset });
                            if (newData[i]["duration_type_id"] === UNIT_OF_TIME_DAY) offset = offset + newData[i]["duration"];
                        };
                    };
                    setWorkStageTableData(newData);
                } else {
                    newData.push(row);
                    setWorkStageTableData(newData);
                }

                // Update form data
                form.setFieldsValue({
                    "work_stage_list": newData,
                });

            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        if (editable) {
            childNode = (
                <Form.Item initialValue={record.duration} name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `Please Input ${title} with integer only!`, type: 'integer'}]}>
                    <InputNumber onPressEnter={save} onBlur={save} addonAfter={`${record.duration_type}`}/>
                </Form.Item>
            );
        };
        return <td {...restProps}>{childNode}</td>;
    };

    const defaultColumns = [
        { title: 'Sequence', dataIndex: 'seq', sorter: (a, b) => a.seq - b.seq },
        { title: 'Lifecycle', dataIndex: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Work Phase', dataIndex: 'work_phase', sorter: (a, b) => a.work_phase.localeCompare(b.work_phase) },
        { title: 'Duration', dataIndex: 'duration', editable: 1},
        { title: 'Period', dataIndex: 'offset', render: (offset, record) => {
            if (record.duration_type_id === UNIT_OF_TIME_DAY) {
                if (record.duration > 0) return(<>Day {offset} - Day {offset + record.duration - 1}</>)
                else return(<>{VALUE_NOT_APPLICABLE}</>)
            }
            else return (<>{record.duration_type} {record.duration}</>)
        }},
    ]

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Work Programme" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Work Programme</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={values => onSave(values)} {...formLayout} autoComplete="off">
                    <Form.Item name="item_group" label="Item Group" rules={[{required: true, message: 'Please select the Item Group for this Work Programme!'}]}>
                        <Select onChange={(value) => itemGroupChange(value)} onSelect={(value) => onItemGroupSelect(value)} allowClear={true} onClear={itemGroupClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemGroupOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="item" label="Plant Species" rules={[{required: true, message: 'Please select the Plant Species for this Work Programme!'}]}>
                        <Select onChange={(value) => itemChange(value)} onSelect={(value) => onItemSelect(value)} allowClear={true} onClear={itemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="description" label="Description" rules={[{required: true, message: 'Please input the description for this Work Programme!'}]}>
                        <Input maxLength={50} allowClear={true} showCount />
                    </Form.Item>

                    <Form.Item name="workday" label="Planned Work Day">
                        <Checkbox.Group options={workdayOptions} value={workdayList} onChange={onChangeWorkday}/>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 7 }} name="checkAll">
                        <Checkbox indeterminate={indeterminate} onChange={onChangeCheckAll} checked={checkAll}>Check all</Checkbox>
                    </Form.Item>

                    <Form.Item name="quantity" label="Unit Amount" rules={[{ required: true, message: `Please input the unit amount to sow with integer only!`, type: 'integer'}]}>
                        <InputNumber min={1} addonAfter={UNIT_OF_MEASUREMENT_UNIT_SIGN}/>
                    </Form.Item>

                    <Form.Item valuePropName="is_work_programme_active" label="Is Work Programme Active">
                        <Switch onClick={onClickIsWorkProgrammeActive} defaultChecked={true}/>
                    </Form.Item>

                    <Form.Item name="work_stage_list" label="Work Stage" rules={[{required: true, message: 'Please fill up the Work Stage table!'}]} >
                        <Table components={{ body: {row: EditableRow, cell: EditableCell} }} columns={columns} dataSource={workStageTableData} pagination={false} onChange={onChangeTable}/>
                    </Form.Item>

                    {/* <Form.Item name="work_task_list" label="Planned Task" rules={[{required: true, message: 'Please create at least one Planned Task for this Work Programme!'}]} > */}
                    <Form.Item name="work_task_list" label="Planned Task">
                        <Form.List name="work_task_list" rules={[{validator: async (_, names) => validateWorkTask(names)}]} >
                            {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field) => (
                                    <Space key={field.key} align="baseline" size={19}>
                                        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.item_group !== curValues.item_group}>
                                            {() => (
                                                <Form.Item {...field} label="Assigned Work Stage (Seq)" name={[field.name, 'work_stage_seq']} rules={[{required: true, message: 'Missing Assigned Work Stage'}]}>
                                                    <Select style={{ width: 50 }} >
                                                        {workStageOption}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </Form.Item>

                                        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.item_group !== curValues.item_group || prevValues.work_stage_list !== curValues.work_stage_list}>
                                            {() => {
                                                const workStageList = form.getFieldValue('work_stage_list');
                                                const workTaskList = form.getFieldValue('work_task_list');
                                                const workTaskData = workTaskList[field.name];
                                                if (workTaskData) {if (workTaskData.work_stage_seq) {
                                                    return (
                                                        <Form.Item {...field} label="Execute On" name={[field.name, 'execute_on']} rules={[{required: true, message: 'Missing Execute On (Integer Only)', type: 'integer'}]}>
                                                            <InputNumber style={{ width: 120 }} min={workStageList[workTaskData.work_stage_seq - 1].offset} max={workStageList[workTaskData.work_stage_seq - 1].offset + workStageList[workTaskData.work_stage_seq - 1].duration - 1} addonBefore={UNIT_OF_TIME_DAY_SIGN}/>
                                                        </Form.Item>
                                                    )
                                                }}
                                            }}
                                        </Form.Item>

                                        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.item_group !== curValues.item_group || prevValues.work_stage_list !== curValues.work_stage_list}>
                                            {() => {
                                                const workTaskList = form.getFieldValue('work_task_list');
                                                const workTaskData = workTaskList[field.name];
                                                if (workTaskData) {if (workTaskData.work_stage_seq) {
                                                    return (
                                                        <Form.Item {...field} label="Task Type" name={[field.name, 'task_type']} rules={[{required: true, message: 'Missing Task Type'}]}>
                                                            <Select style={{ width: 250 }}>
                                                                {taskTypeOption}
                                                            </Select>
                                                        </Form.Item>
                                                    )
                                                }}
                                            }}
                                        </Form.Item>

                                        <MinusCircleOutlined onClick={() => {
                                            remove(field.name);
                                        }} />
                                    </Space>
                                ))}

                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>Add Planned Task</Button>
                                </Form.Item>
                                <Form.ErrorList errors={errors} />
                            </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><PlusCircleTwoTone/>Create</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Work Programme" subTitle="Create New Work Programme" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default WorkProgrammeCreateNew;