import React from 'react';
import { Table, Typography } from 'antd';

const { Text } = Typography;

const SowingDistributionTable = ({dataSource}) => {

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Sowed On', dataIndex: 'item_location_to', sorter: (a, b) => a.item_location_to.localeCompare(b.item_location_to) },
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        // { title: 'Quantity', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: 'Total Plant Sowed (Planting Medium Occupied in %)', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item,
            render: (quantity_item, record) => {
                if (record.quantity_item_uom_perc) return (<Text>{parseInt(quantity_item)} / {record.maxUnit} {record.quantity_item_uom} ({parseInt(record.quantity_item_perc)} {record.quantity_item_uom_perc})</Text>)
                else return (<Text>{parseInt(quantity_item)} / {record.maxUnit} {record.quantity_item_uom}</Text>)
            }
        },
    ];

    return (
        <Table columns={columns} dataSource={dataSource} pagination={{size: "small", position: ["bottomCenter"], total: dataSource.length, showTotal: showTotal}}/>
    );
};

export default SowingDistributionTable;