import React, { useState } from 'react';
import axios from 'axios';
import { Button } from 'antd';
import { AGISAPIURL, MENUPATH_BATCHUPDATE, UNIDATETIMEFORMAT, VALUE_NONE } from './SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, setLastPage } from './UserSession';
import { reportError } from "./Utility";
import moment from 'moment';


const ButtonToBatchUpdatePage = ({batchId, buttonName, navigate, lastPage}) => {

    const [disableButton, setDisableButton] = useState("");

    const directToBatchUpdatePage = (e, batchId) => {
        setDisableButton("disabled");

        axios.get(AGISAPIURL + "batch/" + batchId, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let data = {};
            let expectedDate = VALUE_NONE;
            let actualDate = VALUE_NONE;
            let lifecycle = VALUE_NONE;
            let work_phase = VALUE_NONE;
            let item = VALUE_NONE;
            let item_id = VALUE_NONE;
            let area = VALUE_NONE;
            let work_programme = VALUE_NONE;
            if (response.data.expected_completion_date) expectedDate = moment(response.data.expected_completion_date).format(UNIDATETIMEFORMAT);
            if (response.data.actual_completion_date) actualDate = moment(response.data.actual_completion_date).format(UNIDATETIMEFORMAT);
            if (response.data.lifecycle) lifecycle = response.data.lifecycle.name
            if (response.data.work_phase) work_phase = response.data.work_phase.name
            if (response.data.work_programme) work_programme = response.data.work_programme.description

            // Check if Area is an array
            if (Array.isArray(response.data.area)) {
                if (response.data.area.length >= 1) {
                    const area_data = []
                    response.data.area.forEach( area_item => {
                        area_data.push({
                            key: area_item.id,
                            id: area_item.id,
                            item: area_item.description,
                            area: area_item.area.area_code,
                        })
                    })
                    area = area_data;
                }
            }
            else area = response.data.area.area_code;

            // Check if Item is an array
            if (Array.isArray(response.data.item)) {
                if (response.data.item.length >= 1) {
                    const item_data = []
                    response.data.item.forEach( item_value => {
                        item_data.push({
                            key: item_value.id,
                            id: item_value.id,
                            item: item_value.description,
                            area: item_value.area.area_code,
                        })
                    })
                    item = item_data;
                }
            }
            else {
                item = response.data.item.description;
                item_id = response.data.item.id;
            }

            data = {
                id: response.data.id,
                batch_code: response.data.batch_code,
                item_group: response.data.item_group.name,
                item_group_id: response.data.item_group.id,
                item: item,
                item_id: item_id,
                area: area,
                status_type: response.data.status_type.description,
                work_programme: work_programme,
                created_on: moment(response.data.created_on).format(UNIDATETIMEFORMAT),
                updated_on: moment(response.data.updated_on).format(UNIDATETIMEFORMAT),
                expected_completion_date: expectedDate,
                actual_completion_date: actualDate,
                is_active: response.data.is_active,
                lifecycle: lifecycle,
                work_phase: work_phase,
                is_harvested: response.data.is_harvested,
                remark: response.data.remark,
                days_from_start: response.data.days_from_start,
            }
            setLastPage(lastPage);
            navigate(MENUPATH_BATCHUPDATE, {state: {record: data}});
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message);
        })
        .finally(() => {
            setDisableButton("");
            refreshUserSession();
        });
    };

    return (<Button type="link" disabled={disableButton} onClick={(e) => directToBatchUpdatePage(e, batchId)}>{buttonName}</Button>);

};

export default ButtonToBatchUpdatePage;