import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, message, Modal, Spin } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, refreshUserSession, getUserAuthToken } from '../Common/UserSession';
import { formLayout } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_ITEM, STATUS_OPEN, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';
import { PlusCircleTwoTone, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';


const { confirm } = Modal;
const { Content, Footer } = Layout;

const ItemCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [item, setItem] = useState(0);
    const [workProg, setWorkProg] = useState(0);

    const [disableButton, setDisableButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const createItem = () => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "item/create/", {
            // site: getUserSiteId(),
            // item: item,
            // work_programme: workProg,
            // status_type: STATUS_OPEN,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New item ${response.data.item_code} created.`);
            navigate(MENUPATH_ITEM);
        })
        .catch( error => {
            reportError(error, "Failed to create item. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    }, []);

    const onBack = () => {
        navigate(MENUPATH_ITEM);
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_ITEM) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create item confirmed?</p></Space>,
            onOk() { createItem() },
            onCancel() {},
            centered: true
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Item" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Item</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout} autoComplete="off">


                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><PlusCircleTwoTone/>Create</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Item" subTitle="Create New Item" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default ItemCreateNew;