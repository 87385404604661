import React,  { useEffect, useState } from "react";
import { Select, Form, Spin } from "antd";
import { AGISAPIURL, LOADING } from "./SysParameters";
import { reportError } from "./Utility";
import { OTHERSYSPARAM, getUserAuthToken, getUserSiteId, refreshUserSession } from "./UserSession";
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import axios from "axios";

const { Option } = Select;

const AreaSelect = ({withBlank, form, defaultArea, defaultItem, defaultAreaTypeList = [], onAreaChange, onItemChange, areaLabel = "Destination Zone", itemLabel = "Destination Location", areaFormName = "area", itemFormName = "itemLocation"}) => {
    const [areaOption, setAreaOption] = useState("");
    const [itemOption, setItemOption] = useState("");
    const [area, setArea] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const showArea = onAreaChange != null;
    const showItem = onItemChange != null;

    const getArea = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "area/", {
            params: {
                site: getUserSiteId(),
                area_type_list: defaultAreaTypeList,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( area => {
                    let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                    return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                }));
            setAreaOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItem = (areaList = []) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "item/", {
            params: {
                area_list: areaList,
                is_inventory_repository: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( item => <Option key={item.id} value={item.id}>{item.description}</Option>));
            setItemOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const areaChange = (key, value) => {
        if(value !== undefined)
            onAreaChange(key, value);
        else
            onAreaChange(0, "");
    };

    const itemChange = (key, value) => {
        if(value !== undefined)
            onItemChange(key, value);
        else
            onItemChange(0, "");
    };

    const areaClear = () => {
        onAreaChange(0, "");
        form.setFieldsValue({
            [itemFormName]: "",
        })
        if(showItem) onItemChange(0, "");
        setArea();

        if(showItem) getItem();
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(showArea) getArea();
        if(showItem) getItem();
    }, []);

    const onAreaSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                [itemFormName]: "",
            })
            if(showItem) onItemChange(0, "");
            setArea(value);

            if(showItem) getItem([value]);
        };
    };

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Form.Item initialValue={defaultArea} name={areaFormName} label={areaLabel} hidden={!showArea}>
                <Select onChange={areaChange} onSelect={(value) => onAreaSelect(value)} allowClear={true} onClear={areaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                    {areaOption}
                </Select>
            </Form.Item>

            <Form.Item initialValue={defaultItem} name={itemFormName} label={itemLabel} hidden={!showItem} tooltip={{ title: "Please select a Zone first", icon: <ExclamationCircleTwoTone twoToneColor="#eb2f96"/> }}>
                <Select onChange={itemChange} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                    {itemOption}
                </Select>
            </Form.Item>
        </Spin>
        </>
    );
};

export default AreaSelect;