import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Table, Collapse, Form, Button, Select, Spin, Typography, Avatar, Badge, Modal, DatePicker, message } from 'antd';
import { OTHERSYSPARAM, getUserSiteId, getUserSiteName, getUserAuthToken, refreshUserSession } from "../Common/UserSession";
import axios from 'axios';
import { AGISAPIURL, UNIDATEFORMAT, UNIDATETIMEFORMAT, DATEFORMAT, LOADING, PAGESIZE, VALUE_NONE, MENUPATH_INVENTORYSUMMARY, ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT,
    AREA_TYPE_NURSERY, AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE, AREA_TYPE_BIN_STORE, APPJSON } from '../Common/SysParameters';
import { reportError } from '../Common/Utility';
import { formLayout } from '../Common/Layout';
import { InboxOutlined, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import ButtonToBatchUpdatePage from '../Common/ButtonToBatchUpdatePage';
import InventorySummaryAreaTable from './InventorySummaryAreaTable';


const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

const InventorySummaryTable = () => {
    const FileDownload = require('js-file-download');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemOption, setItemOption] = useState("");
    const [lifecycleOption, setLifecycleOption] = useState("");
    const [areaOption, setAreaOption] = useState("");
    const [inventorySummaryTableData, setInventorySummaryTableData] = useState([]);
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    const [area, setArea] = useState(0);
    const [item, setItem] = useState(0);
    const [lifecycle, setLifecycle] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [itemVal, setItemVal] = useState("");
    const [lifecycleVal, setLifecycleVal] = useState("");
    const [areaVal, setAreaVal] = useState("");

    const SearchInventorySummary = (currentPage) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batchinventorysummary/", {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                item: item,
                area: area,
                lifecycle: lifecycle,
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectInventoryRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItem = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofileforyield/", {
            params: {
                item_group_list: [ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
            setItemOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getLifecycle = ({itemId = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "lifecycleforyield/", {
            params: {
                item: itemId,
                item_group_list: [ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( lifecycle => <Option key={lifecycle.id} value={lifecycle.id}>{lifecycle.name}</Option>));
            setLifecycleOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getArea = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "area/", {
            params: {
                site: getUserSiteId(),
                area_type_list: [AREA_TYPE_NURSERY, AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE, AREA_TYPE_BIN_STORE],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( area => {
                    let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                    return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                }));
            setAreaOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    // Download pdf, excel search result.
    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(dateRange[0] != null) searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(item != 0) searchCriteria = `${searchCriteria}\nPlant Species: ${itemVal}`
        if(lifecycle != 0) searchCriteria = `${searchCriteria}\nLifecycle: ${lifecycleVal}`
        if(area != 0) searchCriteria = `${searchCriteria}\nArea (Zone): ${areaVal}`

        axios.get(`${AGISAPIURL}download/inventorysummary/`, {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                item: item,
                area: area,
                lifecycle: lifecycle,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Inventory Summary ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    };

    useEffect(() => {
        SearchInventorySummary(currentPage);
        getItem();
        getLifecycle({});
        getArea();
    }, []);

    const collectInventoryRawData = ( response ) => {
        const data = []
        response.data.results.forEach( batch => {
            let createdOn = VALUE_NONE;
            let lifecycle = VALUE_NONE;
            let area = VALUE_NONE;
            let work_programme = VALUE_NONE;
            if (batch.created_on) createdOn = moment(batch.created_on).format(UNIDATETIMEFORMAT);
            if (batch.lifecycle) lifecycle = batch.lifecycle.name;
            if (batch.work_programme) work_programme = batch.work_programme.description;
            if (batch.area.length >= 1) {
                let area_data = [];
                batch.area.forEach( area_item => {
                    area_data.push({
                        key: area_item.id,
                        id: area_item.id,
                        item: area_item.description,
                        area: area_item.area.area_code,
                        quantity_item: parseInt(area_item.quantity_item),
                    })
                })
                area = area_data;
            }

            data.push({
                key: batch.id,
                id: batch.id,
                batch_code: batch.batch_code,
                item: batch.item.description,
                area: area,
                work_programme: work_programme,
                status_type: batch.status_type.description,
                status_id: batch.status_type.id,
                lifecycle: lifecycle,
                created_on: createdOn,
                total_quantity_item: parseInt(batch.total_quantity_item),
            })
        });
        setInventorySummaryTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const onChangeDateRange = (value) => {
        setDateRange(value);
    };

    const onChangeItem = (key, value) => {
        if(key !== undefined){
            setLifecycle(0);
            setLifecycleVal("");
            form.setFieldsValue({
                lifecycle: "",
            })
            setItem(key);
            setItemVal(value.children);

            getLifecycle({ itemId: key });
        };
    };

    const onChangeLifecycle = (key, value) => {
        if (key !== undefined) {
            setLifecycle(key);
            setLifecycleVal(value.children);
        } else {
            setLifecycle(0);
            setLifecycleVal("");
        };
    };

    const onChangeArea = (key, value) => {
        if (key !== undefined) {
            setArea(key);
            setAreaVal(value.children);
        } else {
            setArea(0);
            setAreaVal("");
        };
    };

    const onItemClear = () => {
        setLifecycle(0);
        setLifecycleVal("");
        form.setFieldsValue({
            lifecycle: "",
        })
        setItem(0);
        setItemVal("");

        getLifecycle({});
    };

    const onSearch = () => {
        setCurrentPage(1);
        SearchInventorySummary(1);
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onClickArea = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Batch: {record.batch_code} ({record.item})</h3>{record.work_programme !== VALUE_NONE ? <h3>Work Programme: {record.work_programme}</h3> : <></>}</div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <InventorySummaryAreaTable dataSource={record.area} />
        })
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchInventorySummary(page);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Created On', dataIndex: 'created_on', sorter: (a, b) => a.created_on.localeCompare(b.created_on) },
        { title: 'Batch Code', dataIndex: 'batch_code', sorter: (a, b) => a.batch_code.localeCompare(b.batch_code), render: (batch_code, record) => {
            return <ButtonToBatchUpdatePage batchId={record.id} buttonName={batch_code} navigate={navigate} lastPage={MENUPATH_INVENTORYSUMMARY}/>
        }},
        { title: 'Item', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Area', dataIndex: 'area', render: (area, record) => {
            if (Array.isArray(area)) { return (
                <Badge count={area.length} onClick={(e) => onClickArea(e, record)}>
                    <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<InboxOutlined />} />
                </Badge>);
            }
            else return (<Text>{area}</Text>);
        }},
        { title: 'Status', dataIndex: 'status_type', sorter: (a, b) => a.status_type.localeCompare(b.status_type) },
        { title: 'Life Cycle', dataIndex: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Total Unit(s)', dataIndex: 'total_quantity_item', align: 'center', sorter: (a, b) => a.total_quantity_item - b.total_quantity_item },
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search Batch" key="1">
                    <Form form={form} {...formLayout}>
                        <Form.Item initialValue={dateRange} name="daterange" label="Date Range">
                            <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                        </Form.Item>

                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item initialValue={item} name="item" label="Item">
                                    <Select onChange={onChangeItem} allowClear={true} onClear={onItemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {itemOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={lifecycle} name="lifecycle" label="Lifecycle">
                                    <Select onChange={onChangeLifecycle} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {lifecycleOption}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item initialValue={area} name="area" label="Area (Zone)">
                                    <Select onChange={onChangeArea} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {areaOption}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>

            <Table columns={columns} dataSource={inventorySummaryTableData}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: PAGESIZE,
                    current: currentPage,
                    total: totalRecord,
                    showTotal: showTotal,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                }}/>
        </Spin>
        </>
    );
};

export default InventorySummaryTable;