import React , { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Table, Tag, Typography, Spin } from 'antd';
import { AGISAPIURL, LOADING, MENUPATH_ITEM } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import ButtonToBatchUpdatePage from '../Common/ButtonToBatchUpdatePage';
import { reportError } from "../Common/Utility";


const { Title } = Typography;

const ItemDistributionTable = ({itemId}) => {

    const navigate = useNavigate();
    const [batchTableData, setBatchTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const searchCurrentBatch = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itembatchinventorysummary/", {
            params: {
                item: itemId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectBatchRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectBatchRawData = ( response ) => {
        const data = []
        response.data.results.forEach( itemBatchInventoryTransactionSummary => {
            data.push({
                key: itemBatchInventoryTransactionSummary.batch.id,
                id: itemBatchInventoryTransactionSummary.batch.id,
                batch_code: itemBatchInventoryTransactionSummary.batch.batch_code,
                item_item: itemBatchInventoryTransactionSummary.item_item.item_code,
                item_tool: itemBatchInventoryTransactionSummary.item_tool.item_code,
                status_type: itemBatchInventoryTransactionSummary.batch.status_type.description,
                lifecycle: itemBatchInventoryTransactionSummary.batch.lifecycle.name,
                is_active: itemBatchInventoryTransactionSummary.batch.is_active,
            })
        });
        setBatchTableData(data);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchCurrentBatch();
    }, []);

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Batch Code', dataIndex: 'batch_code', sorter: (a, b) => a.batch_code.localeCompare(b.batch_code), render: (_, record) => {
            return <ButtonToBatchUpdatePage batchId={record.id} buttonName={record.batch_code} navigate={navigate} lastPage={MENUPATH_ITEM}/>
        }},
        { title: 'Item Tool', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Item Item', dataIndex: 'item_item', sorter: (a, b) => a.item_item.localeCompare(b.item_item) },
        { title: 'Status', dataIndex: 'status_type', sorter: (a, b) => a.status_type.localeCompare(b.status_type) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Active', dataIndex: 'is_active', sorter: (a, b) => a.is_active - b.is_active, render: (is_active) => {
            if (is_active) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Title level={5}>Batch Distribution:</Title>
            <Table columns={columns} dataSource={batchTableData} pagination={{size: "small", position: ["bottomCenter"], total: batchTableData.length, showTotal: showTotal}}/>
        </Spin>
        </>
    );

};

export default ItemDistributionTable;