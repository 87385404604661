import React from 'react';
import { Table, Row, Col, Typography, Modal, Space, Avatar, Badge } from 'antd';
import { Column } from '@ant-design/plots';
import { UNIDATEFORMAT, UNIT_OF_MEASUREMENT_KILOGRAM_SIGN, VALUE_NONE } from '../Common/SysParameters';
import { TagsOutlined } from '@ant-design/icons';
import moment from "moment";
import DashboardChartsYieldSummaryBatchTable from './DashboardChartsYieldSummaryBatchTable';


const { Text } = Typography;

const DashboardChartsYieldSummaryBySpeciesByWeek = ({yieldSummaryData, yieldSummaryTableData, navigate}) => {

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onClickLocations = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3><b>BATCHES</b> - {record.harvest_week}</h3><h3>Date Range: {moment().weekYear(record.year).day('Sunday').week(record.week).format(UNIDATEFORMAT)} to {moment().weekYear(record.year).day('Saturday').week(record.week).format(UNIDATEFORMAT)}</h3></div>,
            icon: null,
            width: '100%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <DashboardChartsYieldSummaryBatchTable tableData={record.all_batches} navigate={navigate}/>,
        })
    };

    const columns = [
        { title: 'Harvest Week', dataIndex: 'harvest_week', sorter: (a, b) => a.harvest_week.localeCompare(b.harvest_week) },
        { title: 'Date Range', dataIndex: 'harvest_week', render: (harvest_week, record) => {
            return <Text>{moment().weekYear(record.year).day('Sunday').week(record.week).format(UNIDATEFORMAT)} to {moment().weekYear(record.year).day('Saturday').week(record.week).format(UNIDATEFORMAT)}</Text>;
        }},
        { title: 'Batch(es)', dataIndex: 'all_batches', sorter: (a, b) => a.all_batches.length - b.all_batches.length,
            render: (all_batches, record) => {
                if (all_batches.length) { return (
                    <Badge count={all_batches.length} onClick={(e) => onClickLocations(e, record)}>
                        <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<TagsOutlined />} />
                    </Badge>);
                } else return (<>{VALUE_NONE}</>);
            }
        },
        { title: 'Expected Yield', dataIndex: 'total_yield', render: (total_yield) => {
            return <Text>{total_yield.toFixed(2)} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}</Text>
        }},
        { title: 'Total Harvested', dataIndex: 'total_harvested_kg', sorter: (a, b) => a.total_harvested_kg - b.total_harvested_kg, render: (total_harvested_kg, record) => {
            if (record.total_yield > total_harvested_kg) return <Text type="danger">{total_harvested_kg.toFixed(2)} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}</Text>
            else return <Text type="success">{total_harvested_kg.toFixed(2)} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}</Text>
        }},
    ];

    return (
        <>
        <Row justify='center'><Col span={22}>
            <Column
                data={yieldSummaryData}
                isGroup={true}
                isStack={true}
                xField='harvest_week'
                yField='total_yield'
                seriesField='sub_name'
                groupField='name'
                legend={{
                    custom: true,
                    position: 'bottom',
                    items: [{
                        name: 'Existing',
                        marker: { symbol: 'square', style: {fill: '#4096ff'} },
                    },
                    {
                        name: 'Actual',
                        marker: { symbol: 'square', style: {fill: '#73d13d'} },
                    },
                    {
                        name: 'Forecast',
                        marker: { symbol: 'square', style: {fill: '#4096ff', opacity: 0.5} },
                    }],
                    itemName: {
                        formatter: (item) => {
                            if (item === 'Forecast' || item === 'Existing') return `Expected (${item})`
                            else return `${item}`
                        }
                    },
                }}
                marginRatio={0}
                appendPadding={20}
                columnStyle={({ sub_name }) => {
                    if (sub_name === 'Forecast') return { fillOpacity: 0.5 }
                    else return { fillOpacity: 1 }
                }}
                color={({ sub_name }) => {
                    if (sub_name === 'Forecast' || sub_name === 'Existing') return '#4096ff'
                    else if (sub_name === 'Actual') return '#73d13d'
                    else return '#d9d9d9'
                }}
                // pattern={({ sub_name }) => {
                //     if (sub_name === 'Forecast') return { type: 'line', cfg: {rotation: 135} }
                //     else return { type: null }
                // }}
                label={{
                    position: 'top',
                    layout: [{type: 'adjust-color'}],
                    formatter: (value) => {if (value.total_yield > 0) return `${value.total_yield.toFixed(2)} ${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}`},
                }}
                tooltip={{
                    formatter: (datum) => ({
                        name: `${datum.name} (${datum.sub_name})`,
                        value: `${datum.total_yield.toFixed(2)} ${UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}`
                    }),
                }}
                xAxis={{ label: { autoRotate: true, autoHide: true } }}
            />
        </Col></Row>

        <Row><Col><Space><br/></Space></Col></Row>

        <Row justify='center'><Col span={22}>
            <Table columns={columns} dataSource={yieldSummaryTableData} pagination={{position: ["bottomCenter"], total: yieldSummaryTableData.length, showTotal: showTotal}}/>
        </Col></Row>
        </>
    );
};

export default DashboardChartsYieldSummaryBySpeciesByWeek;