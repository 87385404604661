import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Typography, Card, DatePicker, Modal, message, Collapse, Divider, Table, InputNumber, Input, Popconfirm, Spin } from 'antd';
import { AGISAPIURL, LIFECYCLE_MATURITY, MENUPATH_HARVESTING, UNIDATETIMEFORMAT, UNIT_OF_MEASUREMENT_UNIT, LOADING, VALUE_NONE } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import HarvestingDistributionTable from './HarvestingDistributionTable';
import axios from 'axios';
import moment from 'moment';
import { reportError } from "../Common/Utility";
import { CloseCircleFilled, QuestionCircleTwoTone, SaveOutlined, DeleteFilled } from '@ant-design/icons';


const { Content, Footer } = Layout;
const { confirm } = Modal;
const { Title, Text } = Typography;
const { Panel } = Collapse;

const HarvestingUpdate = () => {

    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");
    const paddingCardGrid = OTHERSYSPARAM("CARD_GRID_PADDING");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [subActivityTableDataFrom, setSubActivityTableDataFrom] = useState([]);
    const [subActivityTableDataTo, setSubActivityTableDataTo] = useState([]);
    const [activityId, setActivityId] = useState(location.state.record.id);
    const [timestamp, setTimestamp] = useState(location.state.record.timestamp);
    const [task_category, setTaskCategory] = useState(location.state.record.task_category);
    const [task_type, setTaskType] = useState(location.state.record.task_type);
    const [item_item, setItemItem] = useState(location.state.record.item_item);
    const [batch, setBatch] = useState(location.state.record.batch);
    const [lifecycle, setLifecycle] = useState(location.state.record.lifecycle);
    const [work_phase, setWorkPhase] = useState(location.state.record.work_phase);
    const [actualExecutedDate, setActualExecutedDate] = useState(location.state.record.actual_executed_datetime);
    const [updatedOn, setUpdatedOn] = useState(location.state.record.updated_on);
    const [totalWeight, setTotalWeight] = useState(location.state.record.total_weight);
    const [totalWeightUom, setTotalWeightUom] = useState(location.state.record.total_weight_uom);
    const [disableCancelButton, setDisableCancelButton] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState("");
    const [disableDeleteButton, setDisableDeleteButton] = useState((location.state.record.batch_isActive && location.state.record.batch_lifecycle === LIFECYCLE_MATURITY && !location.state.record.has_bin_returns) ? "" : "disable");
    const [isLoading, setIsLoading] = useState(false);
    // const [editingKey, setEditingKey] = useState('');
    // const isEditing = (record) => record.key === editingKey;

    const updateActivity = (actual_executed_datetime) => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setIsLoading(true);

        axios.patch(AGISAPIURL + "activity/update/" + location.state.record.id, {
            actual_executed_datetime: actual_executed_datetime.format(UNIDATETIMEFORMAT),
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Activity ID ${response.data.id} updated.`);
            navigate(MENUPATH_HARVESTING);
        })
        .catch( error => {
            reportError(error, "Failed to update data. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const deleteActivity = () => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setIsLoading(true);

        axios.delete(AGISAPIURL + "activity/delete/harvesting/" + location.state.record.id, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then(() => {
            message.info(`Activity ID ${location.state.record.id} deleted!`);
            navigate(MENUPATH_HARVESTING);
        })
        .catch( error => {
            reportError(error, "Failed to delete activity. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const SearchSubActivity = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "subactivity/", {
            params: {
                activity: activityId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectSubActivityRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectSubActivityRawData = ( response ) => {
        const dataFrom = []
        const dataTo = []
        // const fieldData = []
        response.data.results.forEach( subactivity => {
            if (subactivity.inventory_transaction.length) {
                subactivity.inventory_transaction.forEach( invTrans => {
                    let quantity_tool = VALUE_NONE;
                    let quantity_item = VALUE_NONE;
                    let quantity_item_uom = VALUE_NONE;
                    let quantity_item_perc = VALUE_NONE;
                    let quantity_item_uom_perc = VALUE_NONE;
                    let maxUnit = 0;

                    // Transaction To
                    if (invTrans.quantity_tool > 0 && invTrans.quantity_item > 0) {
                        quantity_tool = invTrans.quantity_tool;
                        quantity_item = parseFloat(invTrans.quantity_item_secondary);
                        quantity_item_uom = invTrans.quantity_item_secondary_uom.unit;

                        dataTo.push({
                            key: subactivity.id,
                            id: subactivity.id,
                            timestamp: moment(subactivity.timestamp).format(UNIDATETIMEFORMAT),
                            activity: subactivity.activity,
                            area_to: subactivity.area.area_code,
                            item_location_to: subactivity.item_location_to.description,
                            quantity_tool_to: quantity_tool,
                            quantity_item_to: quantity_item,
                            quantity_item_uom_to: quantity_item_uom,
                        });
                        // fieldData.push({id: subactivity.id, quantity_item_to: quantity_item_to, quantity_item_uom_to: quantity_item_uom_to});
                    }

                    // Transaction From
                    else if (invTrans.quantity_tool < 0 && invTrans.quantity_item < 0) {
                        quantity_tool = -invTrans.quantity_tool;
                        quantity_item = -invTrans.quantity_item;
                        quantity_item_uom = invTrans.quantity_item_uom.unit;
                        quantity_item_perc = invTrans.quantity_item_perc;
                        quantity_item_uom_perc = invTrans.quantity_item_uom_perc.unit;

                        if (invTrans.item_repository_profile) {
                            if (invTrans.item_repository_profile.primary_uom.id === UNIT_OF_MEASUREMENT_UNIT) {
                                maxUnit = parseInt(invTrans.item_repository_profile.primary_quantity * Math.ceil(quantity_tool));
                            }
                            else if (invTrans.item_repository_profile.secondary_uom.id === UNIT_OF_MEASUREMENT_UNIT) {
                                maxUnit = parseInt(invTrans.item_repository_profile.secondary_quantity * Math.ceil(quantity_tool));
                            }
                        }

                        dataFrom.push({
                            key: subactivity.id,
                            id: subactivity.id,
                            timestamp: moment(subactivity.timestamp).format(UNIDATETIMEFORMAT),
                            activity: subactivity.activity,
                            area_from: subactivity.item_location_from.area.area_code,
                            item_location_from: subactivity.item_location_from.description,
                            quantity_tool_from: quantity_tool,
                            quantity_item_from: quantity_item,
                            quantity_item_uom_from: quantity_item_uom,
                            quantity_item_perc_from: quantity_item_perc,
                            quantity_item_uom_perc_from: quantity_item_uom_perc,
                            maxUnit_from: maxUnit,
                        });
                    };
                });
            };
        });
        setSubActivityTableDataFrom(dataFrom);
        setSubActivityTableDataTo(dataTo);
        // form.setFieldsValue({total_harvested_list: fieldData});
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchSubActivity();
    }, []);

    const onBack = () => {
        navigate(MENUPATH_HARVESTING);
    };

    const onCancel = () => {
        navigate(MENUPATH_HARVESTING);
    };

    //---------------------------
    // On delete
    //---------------------------
    const onDelete = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Delete activity confirmed?</p></Space>,
            onOk() { deleteActivity() },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (value) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Edit activity confirmed?</p></Space>,
            onOk() { updateActivity(value.actualExecutedDate) },
            onCancel() {},
            centered: true
        });
    };

    // const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    //     let inputNode = <></>
    //     let errorMessage = ""

    //     switch(title) {
    //         case "Total Harvested":
    //             inputNode = <InputNumber min={0.001} addonAfter={record.quantity_item_uom_to}/>;
    //             errorMessage = "with numbers only";
    //             break;

    //         default:
    //             inputNode = inputType === 'number' ? <InputNumber/> : <Input />;
    //     };

    //     return (
    //         <td {...restProps}>
    //             {editing ? (
    //                 <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `Please Input ${title} ${errorMessage}!`, type: inputType}]}>
    //                     {inputNode}
    //                 </Form.Item>
    //             ) : (
    //                 children
    //             )}
    //         </td>
    //     );
    // };

    // const defaultColumns = [
    //     { title: 'Harvest Bin', dataIndex: 'item_location_to', sorter: (a, b) => a.item_location_to.localeCompare(b.item_location_to) },
    //     { title: 'Zone', dataIndex: 'area_to', sorter: (a, b) => a.area_to.localeCompare(b.area_to) },
    //     { title: 'Quantity (Harvest Bin)', dataIndex: 'quantity_tool_to', sorter: (a, b) => a.quantity_tool_to - b.quantity_tool_to },
    //     { title: 'Total Harvested', dataIndex: 'quantity_item_to', editable: 1, sorter: (a, b) => a.quantity_item_to - b.quantity_item_to,
    //         render: (quantity_item_to, record) => {
    //             return (<Text>{parseFloat(quantity_item_to)} {record.quantity_item_uom_to}</Text>)
    //         }
    //     },
    //     { title: 'Action', dataIndex: 'action',
    //         render: (_, record) => {
    //             const editable = isEditing(record);
    //             return editable ? (
    //                 <span>
    //                     <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>Apply</Typography.Link>
    //                     <Popconfirm title="Sure to cancel?" onConfirm={cancel}><a>Cancel</a></Popconfirm>
    //                 </span>
    //             ) : (
    //                 <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>Edit</Typography.Link>
    //             );
    //         },
    //     },
    // ];

    // const showTotal = (total) => {
    //     return (
    //         `Total ${total} item(s)`
    //     );
    // };

    // const edit = (record) => {
    //     form.setFieldsValue({
    //         quantity_item_to: '',
    //         quantity_item_uom_to: '',
    //         ...record,
    //     });
    //     setEditingKey(record.key);
    // };

    // const cancel = () => {
    //     setEditingKey('');
    // };

    // const save = async (key) => {
    //     try {
    //         const row = await form.validateFields();
    //         const newData = [...subActivityTableData];
    //         const index = newData.findIndex((item) => key === item.key);

    //         if (index > -1) {
    //             const item = newData[index];
    //             newData.splice(index, 1, { ...item, ...row });
    //             setSubActivityTableData(newData);
    //             setEditingKey('');
    //         } else {
    //             newData.push(row);
    //             setSubActivityTableData(newData);
    //             setEditingKey('');
    //         }

    //         // Update form data with location list
    //         const newLocationList = form.getFieldValue('total_harvested_list');
    //         const indexLoc = newLocationList.findIndex((location) => key === location.id);
    //         newLocationList[indexLoc] = { id: key, quantity_item_to: newData[index]['quantity_item_to'], quantity_item_uom_to: newData[index]['quantity_item_uom_to'] }

    //     } catch (errInfo) {
    //         console.log('Validate Failed:', errInfo);
    //     }
    // };

    // const columns = defaultColumns.map((col) => {
    //     if (!col.editable) {
    //       return col;
    //     }

    //     return {
    //         ...col,
    //         onCell: (record) => ({
    //             record,
    //             inputType: 'number',
    //             editable: col.editable,
    //             dataIndex: col.dataIndex,
    //             title: col.title,
    //             editing: isEditing(record),
    //         }),
    //     };
    // });

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Harvesting Activity Update" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Edit Harvesting Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center"><Col span={12}>
                    <Collapse defaultActiveKey={["1"]}>
                        <Panel header={<Title level={5}>{`Activity Summary: ${task_type} (${item_item}), Batch Code: ${batch}`}</Title>} key="1">
                            <Card title={<Text italic>{`Timestamp: ${timestamp}`}</Text>}>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Actual Harvesting Date:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{actualExecutedDate}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Task:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{task_type}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Task Category:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{task_category}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Batch Code:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{batch}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Plant Species:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{item_item}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Life Cycle:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{lifecycle}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Work Phase:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{work_phase}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{updatedOn}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Total Harvested:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{totalWeight} {totalWeightUom}</Card.Grid>
                            </Card>

                            <Row><Col><Space><br/></Space></Col></Row>
                            <HarvestingDistributionTable dataSource_from={subActivityTableDataFrom} dataSource_to={subActivityTableDataTo}/>
                        </Panel>
                    </Collapse>
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row justify="center"><Col span={12}>
                    <Divider orientation="left">Edit Harvesting Activity Fields</Divider>
                    <Form form={form} onFinish={onSave} autoComplete="off">
                        <Form.Item initialValue={moment(actualExecutedDate)} name="actualExecutedDate" label="Actual Harvesting Date" rules={[{required: true, message: 'Please input the Actual Harvesting Date for this activity!'}]}>
                            <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment(actualExecutedDate)}} disabledDate={disabledDateAfterToday}/>
                        </Form.Item>

                        {/* <Form.Item name="total_harvested_list" label="Harvesting Summary">
                            <Table components={{ body: {cell: EditableCell} }} rowClassName={() => 'editable-row'} columns={columns} dataSource={subActivityTableData} pagination={{size: "small", position: ["bottomCenter"], total: subActivityTableData.length, showTotal: showTotal, onChange: cancel}}/>
                        </Form.Item> */}

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={12} offset={3}>
                                <Button htmlType="button" hidden={disableDeleteButton} disabled={disableDeleteButton} type="primary" onClick={onDelete} danger><DeleteFilled/>Delete</Button>
                                <Button htmlType="submit" hidden={disableSubmitButton} disabled={disableSubmitButton} type="primary"><SaveOutlined/>Save</Button>
                                <Button htmlType="button" disabled={disableCancelButton} onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col></Row>
            </Content>
            <Footer><Row justify="center"><PageHeader title="Harvesting Activity Update" subTitle="Edit Harvesting Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default HarvestingUpdate;