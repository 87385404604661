import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Modal, message, Input, Select, Spin, Switch, Popconfirm } from 'antd';
import { AGISAPIURL, LOADING, MENUPATH_USER } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import { formLayout } from '../Common/Layout';
import axios from 'axios';
import { reportError } from "../Common/Utility";
import { CloseCircleFilled, QuestionCircleTwoTone, SaveOutlined, LockOutlined, DeleteFilled } from '@ant-design/icons';
import SiteSelect from '../Common/SiteSelect';
import UserGroupSelect from '../Common/UserGroupSelect';


const { Content, Footer } = Layout;
const { confirm } = Modal;
const { Option } = Select;

const UserUpdate = () => {

    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [allowedSitesOption, setAllowedSitesOption] = useState([]);
    const [allowedSiteIds, setAllowedSiteIds] = useState(location.state.record.allowedSites.filter(allowedSite => {return !allowedSite.is_default}).map( allowedSite => String(allowedSite.id)));
    const [defaultSiteId, setDefaultSiteId] = useState(location.state.record.defaultSiteId);
    const [userGroupId, setUserGroupId] = useState(location.state.record.groupId);
    const [isActive, setIsActive] = useState(location.state.record.isActive ? true: false);
    const [disableCancelButton, setDisableCancelButton] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState("");
    const [disableDeleteButton, setDisableDeleteButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [hideDeleteButton, setHideDeleteButton] = useState(false);

    const updateUser = () => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setDisableDeleteButton("disabled");
        setIsLoading(true);

        axios.patch(AGISAPIURL + "user/update/" + location.state.record.id, {
            username: form.getFieldValue('username'),
            first_name: form.getFieldValue('firstname'),
            last_name: form.getFieldValue('lastname'),
            email: form.getFieldValue('email'),
            whatsapp_contact: form.getFieldValue('whatsapp'),
            password: form.getFieldValue('password'),
            is_active: isActive,
            default_site: defaultSiteId,
            allowed_sites: allowedSiteIds,
            user_group: userGroupId,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`User ${location.state.record.username} updated.`);
            navigate(MENUPATH_USER);
        })
        .catch( error => {
            reportError(error, "Failed to update data. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setDisableDeleteButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    //---------------
    // Delete user
    //---------------
    const onDelete = () => {
        // Disable button.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setDisableDeleteButton("disabled");
        setIsLoading(true);

        axios.delete(AGISAPIURL + "user/delete/" + location.state.record.id, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`User ${location.state.record.username} deleted.`)
            navigate(MENUPATH_USER);
        })
        .catch( error => {
            reportError(error, "Failed to delete user.")
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setDisableDeleteButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getAllowableSites = (defaultSiteId) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "site/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const data = response.data.results.filter(site => site.id != defaultSiteId);
            let options = data.map( site => <Option key={site.id}>{site.name}</Option>);
            setAllowedSitesOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to get site(s).");
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        })
    };

    //----------------------------------
    // Determine if this user has Log
    //----------------------------------
    const toHideDeleteButton = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "userhaslog/" + location.state.record.id, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.has_log) setHideDeleteButton(true);
        })
        .catch( error => {
            reportError(error, "Failed to determine if user has log.");
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    //---------------------
    // On change
    //---------------------
    const onDefaultSiteChange = (e, value) => {
        setAllowedSitesOption([]);
        setAllowedSiteIds([]);
        form.setFieldsValue({
            allowedSites: [],
        });

        if(e != undefined) {
            setDefaultSiteId(e);
            getAllowableSites(e);
        }
        else
            setDefaultSiteId(0);
    };

    const onAllowedSitesChange = (e) => {
        if(e != undefined)
            setAllowedSiteIds(e);
        else
            setAllowedSiteIds([]);
    };

    const onUserGroupChange = (e, value) => {
        if(e != undefined)
            setUserGroupId(e);
        else
            setUserGroupId(0);
    };

    const onIsActiveChange = (checked) => {
        setIsActive(checked);
    };

    useEffect(() => {
        Modal.destroyAll();
        getAllowableSites(defaultSiteId);
        toHideDeleteButton();
    }, []);

    const onBack = () => {
        navigate(MENUPATH_USER);
    };

    const onCancel = () => {
        navigate(MENUPATH_USER);
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        form.validateFields()
        .then( values => {
            if(String(values.password).trim() != "" && String(values.password).trim().length < 8) {
                message.warning("Invalid reset password.");
                return
            };

            if(String(values.confirmPassword).trim() != "" && String(values.confirmPassword).trim().length < 8) {
                message.warning("Invalid confirm password.");
                return
            };

            if(String(values.password).trim() != "" && values.password != values.confirmPassword) {
                message.warning("Password do not match.");
                return
            }
            else {
                confirm({
                    icon: <QuestionCircleTwoTone />,
                    content: <Space><p>Edit user confirmed?</p></Space>,
                    onOk() { updateUser() },
                    onCancel() {},
                    centered: true
                });
            };
        })
        .catch( error => {
            message.warn("Required field validation failed.");
            return
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="User Update" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Edit User</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout} autoComplete="off">
                    <Form.Item initialValue={location.state.record.username} name="username" label="Username" rules={[{required: true, message: "Username is required!"}]}>
                        <Input maxLength={150} placeholder="Username"/>
                    </Form.Item>

                    <Form.Item initialValue={location.state.record.first_name} name="firstname" label="First Name" rules={[{required: true, message: 'First Name is required!'}]}>
                        <Input maxLength={50} placeholder="First Name"/>
                    </Form.Item>

                    <Form.Item initialValue={location.state.record.last_name} name="lastname" label="Last Name" rules={[{required: true, message: 'Last Name is required!'}]}>
                        <Input maxLength={50} placeholder="Last Name"/>
                    </Form.Item>

                    <Form.Item initialValue={location.state.record.email} name="email" label="Email" rules={[{required: true, type: "email", message: 'Email is required!'}]}>
                        <Input maxLength={254} placeholder="Email"/>
                    </Form.Item>

                    <Form.Item initialValue={location.state.record.whatsapp_contact} name="whatsapp" label="Whatsapp Contact">
                        <Input maxLength={100} placeholder="Whatsapp Contact"/>
                    </Form.Item>

                    <Form.Item name="password" label="Reset Password">
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="New Password"/>
                    </Form.Item>

                    <Form.Item name="confirmPassword" label="Confirm Reset Password">
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="Confirm New Password"/>
                    </Form.Item>

                    <SiteSelect initialValue={defaultSiteId} withBlank={false} onChange={onDefaultSiteChange}/>

                    <Form.Item initialValue={allowedSiteIds} name="allowedSites" label="Other Allowed Site(s)">
                        <Select onChange={onAllowedSitesChange} allowClear showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} mode="multiple">
                            {allowedSitesOption}
                        </Select>
                    </Form.Item>

                    <UserGroupSelect initialValue={userGroupId} withBlank={false} onChange={onUserGroupChange}/>

                    <Form.Item valuePropName="isActive" label="Is This User Active">
                        <Switch defaultChecked={isActive} onChange={onIsActiveChange} />
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Delete user confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableDeleteButton} hidden={hideDeleteButton} icon={<DeleteFilled />}>Delete</Button>
                            </Popconfirm>
                            <Button htmlType="submit" hidden={disableSubmitButton} disabled={disableSubmitButton} type="primary"><SaveOutlined/>Save</Button>
                            <Button htmlType="button" disabled={disableCancelButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>

            </Content>
            <Footer><Row justify="center"><PageHeader title="User Update" subTitle="Edit User" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default UserUpdate;