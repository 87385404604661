import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Modal, DatePicker, Table, message, Input, Spin } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import { formLayoutWithTable } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_SOILMAINTENANCE, UNIDATETIMEFORMAT, TASK_TYPE_RELEASE_MEDIUM, STATUS_CLOSED, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { SaveOutlined, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
const EditableContext = React.createContext(null);


const { confirm } = Modal;
const { Content, Footer } = Layout;
const { TextArea } = Input;

const SoilMaintenanceReleaseMedium = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const [closeBatchForm] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [batchId, setBatchId] = useState(location.state.batchId);
    const [itemLocationToTableData, setItemLocationToTableData] = useState([]);
    const [disableButton, setDisableButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);  // The selectRowKeys name shall not be change as this is required for Antd to control over the selected rows

    const createReleaseMediumActivity = (actual_completion_date) => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "activity/create/releasemedium/", {
            site: getUserSiteId(),
            batch: batchId,
            task_type: TASK_TYPE_RELEASE_MEDIUM,
            item_location_to_list: form.getFieldValue('item_location_to_list'),
            actual_executed_datetime: actual_completion_date.format(UNIDATETIMEFORMAT),
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New activity ${response.data[0].id} created.`);
            getBatch();
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getBatch = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batch/" + batchId, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            if (response.data.is_harvested) suggestCloseBatch()
            else navigate(MENUPATH_SOILMAINTENANCE);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const closeBatch = (values) => {
        setIsLoading(true);

        axios.patch(AGISAPIURL + "batch/update/" + batchId, {
            status_type: STATUS_CLOSED,
            is_active: false,
            actual_completion_date: values.actualCompletionDate.format(UNIDATETIMEFORMAT),
            remark: values.remark,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Batch ${response.data.batch_code} has been closed.`);
            navigate(MENUPATH_SOILMAINTENANCE);
        })
        .catch( error => {
            reportError(error, "Failed to close batch. " + error.message);
            navigate(MENUPATH_SOILMAINTENANCE);
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItemLocationTo = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batchiteminventoryreleasemedium/", {
            params: {
                batch: batchId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemLocationToRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectItemLocationToRawData = ( response ) => {
        const data = []
        response.data.results.forEach( value => {
            data.push({
                key: value.item_tool.id,
                id: value.item_tool.id,
                item: value.item_tool.item_code,
                batch: value.batch.batch_code,
                area: value.area.area_code,
                statusType: value.item_tool.status_type.description,
                daysPassed: value.days_passed,
            });
        });
        setItemLocationToTableData(data);
    };

    const onChangeItemLocationTo = (newSelectedRowKeys) => {
        let list = []
        newSelectedRowKeys.forEach( rowKey => {
            list.push({id: rowKey});
        });

        form.setFieldsValue({
            item_location_to_list: list,
        });

        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeItemLocationTo,
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        Modal.destroyAll();
        getItemLocationTo();
    }, []);

    const onBack = () => {
        navigate(MENUPATH_SOILMAINTENANCE);
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_SOILMAINTENANCE) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (values) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create Release Medium activity confirmed?</p></Space>,
            onOk() {
                onChangeItemLocationTo(selectedRowKeys);  // To restore the item_location_to_list as changing the table pagination will reset the fields in the form
                createReleaseMediumActivity(values.actualExecutedDate);
            },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // Suggest User to close Batch
    //---------------------------
    const suggestCloseBatch = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: (
                <>
                    <Space><p>All medium of this Batch has been released. Do you want to proceed with batch closure?</p></Space>
                    <Space><p><b>Note:</b> If you are not closing the batch right now, you can also go to Batch Setup page to close it anytime.</p></Space>
                    <Form form={closeBatchForm} onFinish={values => closeBatch(values)} autoComplete="off">
                        <Form.Item initialValue={moment()} name="actualCompletionDate" label="Actual Completion Date" rules={[{required: true, message: 'Please input the Actual Completion Date for this Batch before closure!'}]}>
                            <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment()}} disabledDate={disabledDateAfterToday}/>
                        </Form.Item>

                        <Form.Item name="remark" label="Remark">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Form>
                </>
                ),
            onOk() {
                closeBatchForm.validateFields()
                .then(() => {
                    closeBatchForm.submit();
                })
                .catch( error => {
                    reportError(error, "Failed to update batch. " + closeBatchForm.getFieldError('actualCompletionDate'));
                    navigate(MENUPATH_SOILMAINTENANCE);
                });
            },
            onCancel() { navigate(MENUPATH_SOILMAINTENANCE) },
            centered: true,
            width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"),
        });
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const EditableRow = ({ index, ...props }) => {
        const [form1] = Form.useForm();
        return (
            <Form form={form1} component={false}>
                <EditableContext.Provider value={form1}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const columns = [
        { title: 'Batch', dataIndex: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'Medium', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Status', dataIndex: 'statusType', sorter: (a, b) => a.statusType.localeCompare(b.statusType) },
        { title: 'No. of Days Passed', dataIndex: 'daysPassed', sorter: (a, b) => a.daysPassed - b.daysPassed },
    ]

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Release Soil Maintenance Medium" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Release Medium Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={values => onSave(values)} {...formLayoutWithTable} autoComplete="off">
                    <Form.Item initialValue={moment()} name="actualExecutedDate" label="Actual Release Date" rules={[{required: true, message: 'Please input the Actual Release Medium Date!'}]}>
                        <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment()}} disabledDate={disabledDateAfterToday}/>
                    </Form.Item>
                    
                    <Form.Item name="item_location_to_list" label="Medium To Release" rules={[{required: true, message: 'Please select at least one Location!'}]} >
                        <Table components={{ body: {row: EditableRow} }} rowClassName={() => 'editable-row'} rowSelection={rowSelection} columns={columns} dataSource={itemLocationToTableData} pagination={{ size: 'small',  position: ["bottomCenter"], total: itemLocationToTableData.length, showTotal: showTotal }}/>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><SaveOutlined/>Save</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="Release Soil Maintenance Medium" subTitle="Create New Release Medium Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default SoilMaintenanceReleaseMedium;