import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Table, Collapse, Form, DatePicker, Button, Tag, Typography, Avatar, Badge, Modal, message, Spin } from 'antd';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, getUserSiteName, refreshUserSession, setLastPage } from "../Common/UserSession";
import axios from 'axios';
import { AGISAPIURL, UNIDATEFORMAT, MENUPATH_BATCH, MENUPATH_BATCHCREATENEW, MENUPATH_BATCHUPDATE, UNIDATETIMEFORMAT, APPJSON, DATEFORMAT, LOADING, VALUE_NONE, PAGESIZE } from '../Common/SysParameters';
import { reportError } from '../Common/Utility';
import moment from 'moment';
import BatchSelect from '../Common/BatchSelect';
import StatusTypeSelect from '../Common/StatusTypeSelect';
import { formLayout } from '../Common/Layout';
import { PlusCircleTwoTone, InboxOutlined, ContainerOutlined, DownloadOutlined, FileExcelOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import TrueFalseSelect from '../Common/TrueFalseSelect';
import BatchItemAreaTable from './BatchItemAreaTable';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const BatchTable = () => {
    const FileDownload = require('js-file-download')
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [batchTableData, setBatchTableData] = useState([]);
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    const [batch, setBatch] = useState(0);
    const [itemGroup, setItemGroup] = useState(0);
    const [itemArea, setItemArea] = useState(0);
    const [item, setItem] = useState(0);
    const [status_type, setStatusType] = useState(0);
    const [lifecycle, setLifecycle] = useState(0);
    const [workProg, setWorkProg] = useState(0);
    const [is_active, setIsActive] = useState(true);
    const [is_harvested, setIsHarvested] = useState("");
    const [searchActiveBatch, setSearchActiveBatch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    // 2023-05-23
    // For pdf, excel search criteria.
    const [batchVal, setBatchVal] = useState("");
    const [itemGroupVal, setItemGroupVal] = useState("");
    const [itemAreaVal, setItemAreaVal] = useState("");
    const [workProgVal, setWorkProgVal] = useState("");
    const [lifecycleVal, setLifecycleVal] = useState("");
    const [itemVal, setItemVal] = useState("");
    const [statusTypeVal, setStatusTypeVal] = useState("");

    const SearchBatch = (currentPage) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batch/", {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batch,
                item_group: itemGroup,
                item: item,
                area: itemArea,
                status_type: status_type,
                lifecycle: lifecycle,
                work_programme: workProg,
                is_active: (is_active !== "") ? ((is_active) ? 1 : 0) : "",
                is_harvested: (is_harvested !== "") ? ((is_harvested) ? 1 : 0) : "",
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectBatchRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    // 2023-05-23
    // Download pdf, excel search result.
    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(dateRange[0] != null) searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(itemGroup != 0) searchCriteria = `${searchCriteria}\nItem Group: ${itemGroupVal}`
        if(itemArea != 0) searchCriteria = `${searchCriteria}\nArea: ${itemAreaVal}`
        if(item != 0) searchCriteria = `${searchCriteria}\nItem: ${itemVal}`
        if(batch != 0) searchCriteria = `${searchCriteria}\nBatch Code: ${batchVal}`
        if(workProg != 0) searchCriteria = `${searchCriteria}\nWork Programme: ${workProgVal}`
        if(lifecycle != 0) searchCriteria = `${searchCriteria}\nLifecycle: ${lifecycleVal}`
        if(status_type != 0) searchCriteria = `${searchCriteria}\nBatch Status: ${statusTypeVal}`
        if(is_active !== "") searchCriteria = `${searchCriteria}\nIs Active: ${is_active ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}`
        if(is_harvested !== "") searchCriteria = `${searchCriteria}\nIs Harvested: ${is_harvested ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}`

        axios.get(`${AGISAPIURL}download/batch/`, {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batch,
                item_group: itemGroup,
                item: item,
                area: itemArea,
                status_type: status_type,
                lifecycle: lifecycle,
                work_programme: workProg,
                is_active: (is_active !== "") ? ((is_active) ? 1 : 0) : "",
                is_harvested: (is_harvested !== "") ? ((is_harvested) ? 1 : 0) : "",
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Batch ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    }

    useEffect(() => {
        SearchBatch(currentPage);
    }, []);

    const collectBatchRawData = ( response ) => {
        const data = []
        response.data.results.forEach( batch => {
            let expectDate = VALUE_NONE;
            let actualDate = VALUE_NONE;
            let createdOn = VALUE_NONE;
            let updatedOn = VALUE_NONE;
            let lifecycle = VALUE_NONE;
            let work_phase = VALUE_NONE;
            let item = VALUE_NONE;
            let item_id = VALUE_NONE;
            let area = VALUE_NONE;
            let work_programme = VALUE_NONE;
            let work_programme_id = VALUE_NONE;
            if (batch.expected_completion_date) expectDate = moment(batch.expected_completion_date).format(UNIDATEFORMAT);
            if (batch.actual_completion_date) actualDate = moment(batch.actual_completion_date).format(UNIDATETIMEFORMAT);
            if (batch.created_on) createdOn = moment(batch.created_on).format(UNIDATETIMEFORMAT);
            if (batch.updated_on) updatedOn = moment(batch.updated_on).format(UNIDATETIMEFORMAT);
            if (batch.lifecycle) lifecycle = batch.lifecycle.name
            if (batch.work_phase) work_phase = batch.work_phase.name
            if (batch.work_programme) {
                work_programme = batch.work_programme.description;
                work_programme_id = batch.work_programme.id;
            }

            // Check if Area is an array
            if (Array.isArray(batch.area)) {
                if (batch.area.length >= 1) {
                    const area_data = []
                    batch.area.forEach( area_item => {
                        area_data.push({
                            key: area_item.id,
                            id: area_item.id,
                            item: area_item.description,
                            area: area_item.area.area_code,
                        })
                    })
                    area = area_data;
                }
            }
            else area = batch.area.area_code;

            // Check if Item is an array
            if (Array.isArray(batch.item)) {
                if (batch.item.length >= 1) {
                    const item_data = []
                    batch.item.forEach( item_value => {
                        item_data.push({
                            key: item_value.id,
                            id: item_value.id,
                            item: item_value.description,
                            area: item_value.area.area_code,
                        })
                    })
                    item = item_data;
                }
            }
            else {
                item = batch.item.description;
                item_id = batch.item.id;
            }

            data.push({
                key: batch.id,
                id: batch.id,
                batch_code: batch.batch_code,
                expected_completion_date: expectDate,
                actual_completion_date: actualDate,
                item_group: batch.item_group.name,
                item_group_id: batch.item_group.id,
                item: item,
                item_id: item_id,
                area: area,
                work_programme: work_programme,
                work_programme_id: work_programme_id,
                status_type: batch.status_type.description,
                status_id: batch.status_type.id,
                is_active: batch.is_active,
                lifecycle: lifecycle,
                work_phase: work_phase,
                is_harvested: batch.is_harvested,
                remark: batch.remark,
                created_on: createdOn,
                updated_on: updatedOn,
                days_from_start: batch.days_from_start,
            })
        });
        setBatchTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const onChangeDateRange = (value) => {
        setDateRange(value);
    };

    const onChangeBatch = (key, value) => {
        setBatch(key);
        setBatchVal(value.children);
    };

    const onChangeItemGroup = (key, value) => {
        setItemGroup(key);
        setItemGroupVal(value.children);
    };

    const onChangeItemArea = (key, value) => {
        setItemArea(key);
        setItemAreaVal(value.children)
    };

    const onChangeItem = (key, value) => {
        setItem(key);
        setItemVal(value.children);
    };

    const onChangeStatusType = (key, value) => {
        setStatusType(key);
        setStatusTypeVal(value.children);
    };

    const onChangeLifecycle = (key, value) => {
        setLifecycle(key);
        setLifecycleVal(value.children);
    };

    const onChangeWorkProgramme = (key, value) => {
        setWorkProg(key);
        setWorkProgVal(value.children);
    };

    const onChangeIsActive = (value) => {
        setIsActive(value);
    };

    const onChangeIsHarvested = (value) => {
        setIsHarvested(value);
    };

    const onSearch = () => {
        setCurrentPage(1);
        SearchBatch(1);
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onCreateNewBatch = () => {
        navigate(MENUPATH_BATCHCREATENEW);
    };

    const onClickTableRow = (record, rowIndex) => {
        return {
            onClick: () => {
                setLastPage(MENUPATH_BATCH);
                navigate(MENUPATH_BATCHUPDATE, {state: {record: record}});
            }
        };
    };

    const onClickItem = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Batch: {record.batch_code} ({record.area})</h3>{record.work_programme !== VALUE_NONE ? <h3>Work Programme: {record.work_programme}</h3> : <></>}</div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <BatchItemAreaTable dataSource={record.item} />
        })
    };

    const onClickArea = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Batch: {record.batch_code} ({record.item})</h3>{record.work_programme !== VALUE_NONE ? <h3>Work Programme: {record.work_programme}</h3> : <></>}</div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <BatchItemAreaTable dataSource={record.area} />
        })
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchBatch(page);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columnTitleCompleted = (title) => {
        return (
            <>{title} <InfoCircleTwoTone onClick={(e) => showColumnDescriptionCompleted(e, title)} /></>
        );
    };

    const columnTitleNoOfDaysFromStart = (title) => {
        return (
            <>{title} <InfoCircleTwoTone onClick={(e) => showColumnDescriptionNoOfDaysFromStart(e, title)} /></>
        );
    };

    const showColumnDescriptionCompleted = (e, title) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Definition of the Column Title - {title}</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <>
                <p>For <b>Planting Batch</b>: If Yes, it indicates all plant in this batch has been fully <b>harvested</b>.</p>
                <p>For <b>Soil Maintenance Batch</b>: If Yes, it indicates all planting medium in this batch has been fully <b>released</b>.</p>
            </>
        });
    };

    const showColumnDescriptionNoOfDaysFromStart = (e, title) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Definition of the Column Title - {title}</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <>
                <p>For <b>Planting Batch</b>: The number of days that have passed since the first <b>sowing</b> activity.</p>
                <p>For <b>Soil Maintenance Batch</b>: The number of days that have passed since the first <b>soil maintenance</b> activity.</p>
            </>
        });
    };

    const columns = [
        { title: 'Batch Code', dataIndex: 'batch_code', sorter: (a, b) => a.batch_code.localeCompare(b.batch_code) },
        { title: 'Item Group', dataIndex: 'item_group', sorter: (a, b) => a.item_group.localeCompare(b.item_group) },
        { title: 'Item', dataIndex: 'item', render: (item, record) => {
                if (Array.isArray(item)) { return (
                    <Badge count={item.length} onClick={(e) => onClickItem(e, record)}>
                        <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<ContainerOutlined />} />
                    </Badge>);
                }
                else return (<Text>{item}</Text>);
            }
        },
        { title: 'Area', dataIndex: 'area', render: (area, record) => {
                if (Array.isArray(area)) { return (
                    <Badge count={area.length} onClick={(e) => onClickArea(e, record)}>
                        <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<InboxOutlined />} />
                    </Badge>);
                }
                else return (<Text>{area}</Text>);
            }
        },
        { title: 'Work Programme', dataIndex: 'work_programme', sorter: (a, b) => a.work_programme.localeCompare(b.work_programme) },
        { title: 'Status', dataIndex: 'status_type', sorter: (a, b) => a.status_type.localeCompare(b.status_type) },
        { title: 'Life Cycle', dataIndex: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Work Phase', dataIndex: 'work_phase', sorter: (a, b) => a.work_phase.localeCompare(b.work_phase) },
        { title: 'Active', dataIndex: 'is_active', sorter: (a, b) => a.is_active - b.is_active, render: (is_active) => {
            if (is_active) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
        { title: columnTitleCompleted('Completed'), dataIndex: 'is_harvested', sorter: (a, b) => a.is_harvested - b.is_harvested, render: (is_harvested) => {
            if (is_harvested) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
        { title: columnTitleNoOfDaysFromStart('No. of Days from Start'), dataIndex: 'days_from_start', sorter: (a, b) => a.days_from_start - b.days_from_start },
        { title: 'Expected Completion Date', dataIndex: 'expected_completion_date', sorter: (a, b) => a.expected_completion_date.localeCompare(b.expected_completion_date) },
        { title: 'Actual Completion Date', dataIndex: 'actual_completion_date', sorter: (a, b) => a.actual_completion_date.localeCompare(b.actual_completion_date) },
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search Batch" key="1">
                    <Form form={form} {...formLayout}>
                        <Form.Item initialValue={dateRange} name="daterange" label="Date Range">
                            <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                        </Form.Item>

                        <Row justify="center">
                            <Col span={12}>
                                <BatchSelect withBlank={true} form={form} defaultBatch={batch} defaultItemGroup={itemGroup} defaultItemArea={itemArea} defaultItem={item} defaultWorkProgramme={workProg} defaultSearchActiveBatch={searchActiveBatch} defaultLifecycle={lifecycle}
                                    onBatchChange={onChangeBatch} onItemGroupChange={onChangeItemGroup} onItemAreaChange={onChangeItemArea} onItemChange={onChangeItem} onWorkProgrammeChange={onChangeWorkProgramme} onLifecycleChange={onChangeLifecycle} useBatch={1}/>
                            </Col>

                            <Col span={12}>
                                <Form.Item initialValue={status_type} name="status" label="Batch Status">
                                    <StatusTypeSelect withBlank={true} onChange={onChangeStatusType} is_batch={1}/>
                                </Form.Item>

                                <Form.Item initialValue={is_active} name="isActive" label="Is Active">
                                    <TrueFalseSelect withBlank={true} defaultSelect={OTHERSYSPARAM("YES")} onChange={onChangeIsActive}/>
                                </Form.Item>

                                <Form.Item initialValue={is_harvested} name="isHarvested" label="Is Completed">
                                    <TrueFalseSelect withBlank={true} defaultSelect={" "} onChange={onChangeIsHarvested}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>
            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewBatch} type="primary"><PlusCircleTwoTone /> New Batch</Button></Col></Row>
            <Row><Col><Space><br/></Space></Col></Row>

            <Table onRow={onClickTableRow} columns={columns} dataSource={batchTableData}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: PAGESIZE,
                    current: currentPage,
                    total: totalRecord,
                    showTotal: showTotal,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                }}/>

            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewBatch} type="primary"><PlusCircleTwoTone /> New Batch</Button></Col></Row>
        </Spin>
        </>
    );
};

export default BatchTable;