import React , { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Space, Table, Collapse, Button, DatePicker, Modal, message, Typography, Spin } from 'antd';
import { AGISAPIURL, DATEFORMAT, UNIDATEFORMAT, UNIDATETIMEFORMAT, TASK_CATEGORY_RETURNING, TASK_TYPE_BIN_RETURNS, LIFECYCLE_MATURITY, AREA_TYPE_BIN_STORE, MENUPATH_BINRETURNS,
    MENUPATH_BINRETURNSUPDATE, LOADING, VALUE_NONE, PAGESIZE, APPJSON } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, getUserSiteId, refreshUserSession, getUserSiteName } from '../Common/UserSession';
import { reportError } from '../Common/Utility';
import { formLayout } from '../Common/Layout';
import BatchSelect from '../Common/BatchSelect';
import AreaSelect from '../Common/AreaSelect';
import ButtonToBatchUpdatePage from '../Common/ButtonToBatchUpdatePage';
import moment from 'moment';
import axios from 'axios';
import { QuestionCircleTwoTone, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

const BinReturnsTable = () => {
    const FileDownload = require('js-file-download');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    const [activityTableData, setActivityTableData] = useState([]);
    const [areaFrom, setAreaFrom] = useState(0);
    const [areaTo, setAreaTo] = useState(0);
    const [item_location_from, setItemLocationFrom] = useState(0);
    const [batch, setBatch] = useState(0);
    const [item_item, setItemItem] = useState(0);
    const [searchActiveBatch, setSearchActiveBatch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [itemItemVal, setItemItemVal] = useState("");
    const [batchVal, setBatchVal] = useState("");
    const [areaFromVal, setAreaFromVal] = useState("");
    const [itemLocationFromVal, setItemLocationFromVal] = useState("");

    const SearchActivity = (currentPage) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "activity/", {
            params: {
                site: getUserSiteId(),
                area: areaTo,
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batch,
                item_item: item_item,
                item_location_from: item_location_from,
                task_type: TASK_TYPE_BIN_RETURNS,
                task_category: TASK_CATEGORY_RETURNING,
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectActivityRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    // Download pdf, excel search result.
    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(dateRange[0] != null) searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(item_item != 0) searchCriteria = `${searchCriteria}\nPlant Species: ${itemItemVal}`
        if(batch != 0) searchCriteria = `${searchCriteria}\nBatch Code: ${batchVal}`
        if(areaFrom != 0) searchCriteria = `${searchCriteria}\nZone: ${areaFromVal}`
        if(item_location_from != 0) searchCriteria = `${searchCriteria}\nItem: ${itemLocationFromVal}`

        axios.get(`${AGISAPIURL}download/activity/binreturns/`, {
            params: {
                site: getUserSiteId(),
                area: areaTo,
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batch,
                item_item: item_item,
                item_location_from: item_location_from,
                task_type: TASK_TYPE_BIN_RETURNS,
                task_category: TASK_CATEGORY_RETURNING,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Bin Returns ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession()
        })
    };

    const deleteActivity = (id) => {
        setIsLoading(true);

        axios.delete(AGISAPIURL + "activity/delete/" + id, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then(() => {
            message.info(`Activity ID ${id} deleted!`);
            SearchActivity(currentPage);
        })
        .catch( error => {
            setIsLoading(false);
            reportError(error, "Failed to delete activity. " + error.message);
        })
        .finally(() => {
            refreshUserSession();
        });
    };

    useEffect(() => {
        SearchActivity(currentPage);
    }, []);

    const collectActivityRawData = ( response ) => {
        const data = []
        response.data.results.forEach( activity => {
            let batchCode = VALUE_NONE;
            let batchId = "";
            let batchIsActive = "";
            let batchLifecycle = "";
            if (activity.batch) {
                batchCode = activity.batch.batch_code;
                batchId = activity.batch.id;
                batchIsActive = activity.batch.is_active;
                batchLifecycle = activity.batch.lifecycle;
            };

            data.push({
                key: activity.id,
                id: activity.id,
                timestamp: moment(activity.timestamp).format(UNIDATETIMEFORMAT),
                batch_id: batchId,
                batch: batchCode,
                batch_isActive: batchIsActive,
                batch_lifecycle: batchLifecycle,
                item_item_id: activity.item_item.id,
                item_item: activity.item_item.description,
                task_type: activity.task_type.task,
                task_category: activity.task_type.task_category.description,
                lifecycle: activity.lifecycle.name,
                work_phase: activity.work_phase.name,
                actual_executed_datetime: moment(activity.actual_executed_datetime).format(UNIDATETIMEFORMAT),
                updated_on: moment(activity.updated_on).format(UNIDATETIMEFORMAT),
                subactivity_count: activity.subactivity.length,
                area: activity.subactivity[0].item_location_from.area.area_code,
                item_location: activity.subactivity[0].item_location_from.description,
                quantity_tool: -activity.subactivity[0].inventory_transaction[0].quantity_tool,
            });
        });
        setActivityTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const onChangeDateRange = (value) => {
        setDateRange(value);
    };

    const onChangeAreaFrom = (key, value) => {
        setAreaFrom(key);
        setAreaFromVal(value.children);
    };

    const onChangeItemLocationFrom = (key, value) => {
        setItemLocationFrom(key);
        setItemLocationFromVal(value.children);
    };

    const onChangeBatch = (key, value) => {
        setBatch(key);
        setBatchVal(value.children);
    };

    const onChangeItemItem = (key, value) => {
        setItemItem(key);
        setItemItemVal(value.children);
    };

    const onSearch = () => {
        setCurrentPage(1);
        SearchActivity(1);
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onClickTableRow = (record, rowIndex) => {
        return {
            onClick: () => {
                navigate(MENUPATH_BINRETURNSUPDATE, {state: {record: record}});
            }
        };
    };

    //---------------------------
    // On delete
    //---------------------------
    const onDelete = (e, key) => {
        e.stopPropagation();

        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Delete activity confirmed?</p></Space>,
            onOk() { deleteActivity(key) },
            onCancel() {},
            centered: true
        });
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchActivity(page);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Date', dataIndex: 'actual_executed_datetime', sorter: (a, b) => a.actual_executed_datetime.localeCompare(b.actual_executed_datetime) },
        { title: 'Bin', dataIndex: 'item_location', sorter: (a, b) => a.item_location.localeCompare(b.item_location) },
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'Quantity', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: 'Batch Code', dataIndex: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch), render: (_, record) => {
            return <ButtonToBatchUpdatePage batchId={record.batch_id} buttonName={record.batch} navigate={navigate} lastPage={MENUPATH_BINRETURNS}/>
        }},
        { title: 'Plant Species', dataIndex: 'item_item', sorter: (a, b) => a.item_item.localeCompare(b.item_item) },
        { title: 'Timestamp', dataIndex: 'timestamp', sorter: (a, b) => a.timestamp.localeCompare(b.timestamp) },
        { title: 'Action', dataIndex: 'action',
            render: (_, record) => {
                return (record.batch_lifecycle === LIFECYCLE_MATURITY) ? (
                    <Typography.Link title="Sure to delete this activity?" onClick={(e) => onDelete(e, record.key) }>Delete</Typography.Link>
                ) : (
                    <Typography.Link disabled title="The batch needs to be in Maturity Lifecycle." onClick={(e) => onDelete(e, record.key) }>Delete</Typography.Link>
                );
            },
        },
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search Bin Returns Activity" key="1">
                    <Form form={form} {...formLayout}>
                        <Form.Item initialValue={dateRange} name="daterange" label="Date Range">
                            <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                        </Form.Item>

                        <Row justify="center">
                            <Col span={12}>
                                <BatchSelect withBlank={true} form={form} defaultBatch={batch} defaultItem={item_item} defaultSearchActiveBatch={searchActiveBatch} defaultTaskCategory={TASK_CATEGORY_RETURNING} defaultTask={TASK_TYPE_BIN_RETURNS}
                                    onBatchChange={onChangeBatch} onItemChange={onChangeItemItem} itemLabel="Plant Species" />
                            </Col>

                            <Col span={12}>
                                <AreaSelect withBlank={true} form={form} defaultArea={areaFrom} defaultItem={item_location_from} defaultAreaTypeList={[AREA_TYPE_BIN_STORE]} onAreaChange={onChangeAreaFrom} onItemChange={onChangeItemLocationFrom} areaLabel="Zone" itemLabel="Bin" areaFormName="areaFrom" itemFormName="itemLocationFrom" />
                            </Col>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button title='Download PDF'type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>

            <Table onRow={onClickTableRow} columns={columns} dataSource={activityTableData}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: PAGESIZE,
                    current: currentPage,
                    total: totalRecord,
                    showTotal: showTotal,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                }}/>
        </Spin>
        </>
    );
};

export default BinReturnsTable;