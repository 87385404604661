import React from 'react';
import { Table, Tag, Button } from 'antd';
import { OTHERSYSPARAM } from "../Common/UserSession";
import { MENUPATH_WORKPROGRAMMEUPDATE } from '../Common/SysParameters';
import { CheckOutlined } from '@ant-design/icons';


const WorkProgrammeListTable = ({dataSource, navigate}) => {

    const directToWorkProgrammeUpdatePage = (record) => {
        navigate(MENUPATH_WORKPROGRAMMEUPDATE, {state: {record: record}});
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Priority', dataIndex: 'priority', defaultSortOrder: 'ascend', sorter: (a, b) => a.priority - b.priority },
        { title: 'Work Programme Code', dataIndex: 'work_programme_code', sorter: (a, b) => a.work_programme_code.localeCompare(b.work_programme_code), render: (work_programme_code, record) => {
            return (<Button type="link" onClick={() => directToWorkProgrammeUpdatePage(record)}>{work_programme_code}</Button>)
        }},
        { title: 'Description', dataIndex: 'description', sorter: (a, b) => a.description.localeCompare(b.description) },
        { title: 'Plant Species', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Unit Amount', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item },
        { title: 'Active', dataIndex: 'is_active', sorter: (a, b) => a.is_active - b.is_active, render: (is_active) => {
            if (is_active) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
        { title: 'Planned Work Day', children: [
            { title: 'Monday', dataIndex: 'monday', render: (monday) => {return (monday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Tuesday', dataIndex: 'tuesday', render: (tuesday) => {return (tuesday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Wednesday', dataIndex: 'wednesday', render: (wednesday) => {return (wednesday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Thursday', dataIndex: 'thursday', render: (thursday) => {return (thursday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Friday', dataIndex: 'friday', render: (friday) => {return (friday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Saturday', dataIndex: 'saturday', render: (saturday) => {return (saturday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Sunday', dataIndex: 'sunday', render: (sunday) => {return (sunday ? <CheckOutlined /> : <>-</>)} },
        ]},
    ];

    return (
        <Table columns={columns} dataSource={dataSource} pagination={{size: "small", position: ["bottomCenter"], total: dataSource.length, showTotal: showTotal}}/>
    );
};

export default WorkProgrammeListTable;