import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Spin } from 'antd';
import { AGISURL, TIMEOUT, MENUPATH_DASHBOARD, LOGO, LOADING } from './Common/SysParameters';
import { loadSystemParameters } from './Common/LoadSysParameters';
import { clearUserSession, createUserSession } from './Common/UserSession';
import { reportError } from "./Common/Utility";
import { wallpapers } from './Common/Layout';
import AGISFooter from './Common/AGISFooter';
import axios from "axios";
import '../Login.css';


const Login = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [disableButton, setDisableButton] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {clearUserSession()}, []);

    const onFinish = (values) => {
        console.log('Received values of form: ', values);

        // Disable login button.
        setDisableButton(true);
        setLoading(true);

        form.validateFields()
        .then( values => {
            axios.post(`${AGISURL}dj-rest-auth/login/`, {
                username: values.username,
                password: values.password,
            }, {timeout: parseInt(TIMEOUT) * 2})
            .then( response => {
                createUserSession(
                    values.username,
                    response.data.user_groups.map( group => group.name),
                    response.data.default_site.site_data.id,
                    response.data.default_site.site_data.name,
                    response.data.key);
                loadSystemParameters()
                .then(() => {
                    navigate(MENUPATH_DASHBOARD);
                    message.info("Welcome to AGIS");
                })
                .catch( error => {
                    clearUserSession();
                    message.error("System anomaly detected.");
                });
            })
            .catch( error => {
                if(String(error).includes("timeout")) {
                    message.error("Login timeout.");
                }
                reportError(error);
            })
            .finally(() => {
                setDisableButton(false);
                setLoading(false);
            });
        })
        .catch( error => {
            message.warning("Required field validation failed.");
            return
        });
    };

    return (
        <Spin spinning={loading} size="large" tip={LOADING}>
        <React.Fragment>
            <div align="middle" style={{...wallpapers}}>

                <img style={{ position: 'relative', top: '20%' }} src={LOGO} alt="Logo"/>

                <Form form={form} name="normal_login" className="login-form"
                    initialValues={{remember: true}}
                    onFinish={onFinish}>

                    <Form.Item name="username"
                        rules={[{
                            required: true,
                            message: 'Please input your Username!'},
                        ]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                    </Form.Item>

                    <Form.Item name="password"
                        rules={[{
                            required: true,
                            message: 'Please input your Password!'},
                        ]}>
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={disableButton} loading={loading} className="login-form-button" shape="round" danger>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        <AGISFooter />
        </React.Fragment>
        </Spin>
    );
};

export default Login;