import { WALLPAPER, WALLTILE } from "./SysParameters";


export const wallpapers = {
    backgroundImage: `url(${WALLPAPER})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 'calc(100vh - 70px)'
};

export const walltiles = {
    backgroundImage: `url(${WALLTILE})`,
    backgroundRepeat: 'repeat',
    backgroundSize: '300px',
};

export const formLayout = {
    labelAlign: "left",
    labelCol: {
        xs: {offset: 4, span: 6},
        sm: {offset: 4, span: 6},
        md: {offset: 4, span: 6},
        lg: {offset: 4, span: 3},
    },
    wrapperCol: {
        xs: {offset: 0, span: 12},
        sm: {offset: 0, span: 12},
        md: {offset: 0, span: 12},
        lg: {offset: 0, span: 12}
    },
};

export const formLayoutWithTable = {
    labelAlign: "left",
    labelCol: {
        xs: {offset: 3, span: 3},
        sm: {offset: 3, span: 3},
        md: {offset: 3, span: 3},
        lg: {offset: 3, span: 3},
    },
    wrapperCol: {
        xs: {offset: 0, span: 15},
        sm: {offset: 0, span: 15},
        md: {offset: 0, span: 15},
        lg: {offset: 0, span: 15}
    },
};