import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, message, Modal, DatePicker, Table, Input, InputNumber, Spin, Statistic } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, refreshUserSession, getUserAuthToken } from '../Common/UserSession';
import { formLayoutWithTable } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_SOWING, UNIDATETIMEFORMAT, TASK_TYPE_SOWING, AREA_TYPE_NURSERY, UNIT_OF_MEASUREMENT_UNIT, STATUS_OPEN, STATUS_INPROGRESS, STATUS_AVAILABLE,
    LIFECYCLE_GERMINATION, WORK_PHASE_NURSING, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { SaveOutlined, ExclamationCircleOutlined, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';
const EditableContext = React.createContext(null);


const { Option } = Select;
const { confirm } = Modal;
const { Content, Footer } = Layout;

const SowingCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemItemOption, setItemItemOption] = useState("");
    const [batchOption, setBatchOption] = useState("");
    const [areaOption, setAreaOption] = useState("");
    const [itemLocationToTableData, setItemLocationToTableData] = useState([]);
    const [task_type, setTaskType] = useState(TASK_TYPE_SOWING);
    const [item_item, setItemItem] = useState(0);
    const [batch, setBatch] = useState();
    const [area_type_list, setAreaTypeList] = useState([AREA_TYPE_NURSERY]);
    const [area_list, setAreaList] = useState([]);
    const [item_location_from, setItemLocationFrom] = useState();
    const [disableButton, setDisableButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBatch, setIsLoadingBatch] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);  // The selectRowKeys name shall not be change as this is required for Antd to control over the selected rows
    const [actualUnitSow, setActualUnitSow] = useState(0);

    const createActivity = (actual_completion_date) => {
        setDisableButton("disabled");
        setIsLoading(true);
        
        axios.post(AGISAPIURL + "activity/create/sowing/", {
            site: getUserSiteId(),
            batch: batch,
            task_type: task_type,
            item_location_to_list: form.getFieldValue('item_location_to_list'),
            item_location_from: item_location_from,
            actual_executed_datetime: actual_completion_date.format(UNIDATETIMEFORMAT),
            lifecycle: LIFECYCLE_GERMINATION,
            work_phase: WORK_PHASE_NURSING,
            quantity_tool_uom: UNIT_OF_MEASUREMENT_UNIT,
            quantity_item_uom: UNIT_OF_MEASUREMENT_UNIT,
            status_type_batch: STATUS_INPROGRESS,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New activity ${response.data[0].id} created.`);
            // response.data[1].map(subactivity => message.info(`New sub-activity ${subactivity.id} created.`));
            // response.data[2].map(inventoryTransaction => message.info(`New inventory transaction ${inventoryTransaction.id} created.`));
            navigate(MENUPATH_SOWING);
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getItemItem = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofilebytasktype/", {
            params: {
                site: getUserSiteId(),
                task_type: task_type,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemItemOption(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getBatch = (itemId) => {
        setIsLoadingBatch(true);

        axios.get(AGISAPIURL + "batchavailablesowing/", {
            params: {
                site: getUserSiteId(),
                item: itemId,
                status_type_list: [STATUS_OPEN, STATUS_INPROGRESS],
                lifecycle_list: ["NULL", LIFECYCLE_GERMINATION],
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setBatchOption(response.data.results.map( batch => <Option key={batch.id} value={batch.id}>{batch.batch_code}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoadingBatch(false);
            refreshUserSession();
        });
    };

    let promiseGetItemIdUsingBatch = function (batchId) {
        return new Promise(function(myResolve, myReject) {
            setIsLoading(true);

            axios.get(AGISAPIURL + "batch/" + batchId, {
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`},
            })
            .then( response => {
                myResolve(response.data.item.id);
            })
            .catch( error => {
                myReject(error);
            })
            .finally(() => {
                setIsLoading(false);
                refreshUserSession();
            });
        });
    };

    let promiseGetArea = function () {
        return new Promise(function(myResolve, myReject) {
            setIsLoading(true);

            axios.get(AGISAPIURL + "area/", {
                params: {
                    site: getUserSiteId(),
                    area_type_list: area_type_list,
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`},
            })
            .then( response => {
                let options = response.data.results.map( area => {
                        let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                        return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                    });
                myResolve(options);
            })
            .catch( error => {
                myReject(error);
            })
            .finally(() => {
                setIsLoading(false);
                refreshUserSession();
            });
        });
    };

    const getItemLocationTo = ({areaList = [], item_in_repository = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "item/", {
            params: {
                area_list: areaList,
                is_inventory_repository: 1,
                status_type: STATUS_AVAILABLE,
                item_in_repository: item_in_repository,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemLocationToRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectItemLocationToRawData = ( response ) => {
        const data = []
        response.data.results.forEach( item => {
            let maxUnit = 0;
            if (item.item_repository_profile) {
                if (item.item_repository_profile.primary_uom.id === UNIT_OF_MEASUREMENT_UNIT) {
                    maxUnit = item.item_repository_profile.primary_quantity;
                }
                else if (item.item_repository_profile.secondary_uom.id === UNIT_OF_MEASUREMENT_UNIT) {
                    maxUnit = item.item_repository_profile.secondary_quantity;
                }
            }

            data.push({
                key: item.id,
                id: item.id,
                item_code: item.item_code,
                description: item.description,
                item_area: item.area.area_code,
                available_quantity: parseInt(item.available_quantity),
                quantity_tool: 1,
                quantity_item_max: parseInt(maxUnit),
                total_item: parseInt(maxUnit),
            });
        });
        setItemLocationToTableData(data);
    };

    const updateTotalActualUnitToSow = (locationToList) => {
        let totalUnit = 0;
        locationToList.forEach(item => totalUnit = totalUnit + item.total_item);
        setActualUnitSow(totalUnit);
    };

    const onChangeItemItem = (value) => {
        setItemItem(value);
    };

    const onChangeBatch = (value) => {
        setBatch(value);
        onChangeItemLocationTo([]);
        if(value !== undefined & value !== "") {
            promiseGetItemIdUsingBatch(value).then(
                function(value) {
                    setItemItem(value);
                    getItemLocationTo({ areaList: area_list, item_in_repository: value });
                },
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            )
        }
        else getItemLocationTo({ areaList: area_list, item_in_repository: item_item })
    };

    const onChangeArea = (value) => {
        setAreaList(value);
        onChangeItemLocationTo([]);
        getItemLocationTo({ areaList: value, item_in_repository: item_item });
    };

    const onChangeItemLocationTo = (newSelectedRowKeys) => {
        let list = []
        newSelectedRowKeys.forEach( rowKey => {
            const indexLoc = itemLocationToTableData.findIndex((location) => rowKey === location.id);
            list.push({ id: rowKey, quantity_tool: itemLocationToTableData[indexLoc]['quantity_tool'], total_item: itemLocationToTableData[indexLoc]['total_item'] });
        });

        form.setFieldsValue({
            item_location_to_list: list,
        });

        updateTotalActualUnitToSow(list);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeItemLocationTo,
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getItemItem();
        getBatch();
        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
    }, []);

    const onItemItemSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                batch: "",
            })
            onChangeBatch("");
            setItemItem(value);

            getBatch(value);
        };
    };

    const onItemItemClear = () => {
        setItemItem();
        form.setFieldsValue({
            batch: "",
        })
        onChangeBatch("");
        setItemItem();

        getBatch();
    };

    const onBatchClear = () => {
        form.setFieldsValue({
            batch: "",
            item_location_to_list: "",
        })
        onChangeItemLocationTo([]);
        onChangeBatch("");

        getItemLocationTo({ areaList: area_list });
    };

    const onAreaClear = () => {
        form.setFieldsValue({
            item_location_to_list: "",
        })
        onChangeItemLocationTo([]);
        onChangeArea([]);

        getItemLocationTo({ item_in_repository: item_item });
    };

    const onBack = () => {
        navigate(MENUPATH_SOWING);
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_SOWING) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (values) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create sowing activity confirmed?</p></Space>,
            onOk() { createActivity(values.actualExecutedDate) },
            onCancel() {},
            centered: true
        });
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onChangeTable = () => {
        // To restore the item_location_to_list as changing the table pagination/sorting/filter will reset the fields in the form
        onChangeItemLocationTo(selectedRowKeys);
    };

    const EditableRow = ({ index, ...props }) => {
        const [form1] = Form.useForm();
        return (
            <Form form={form1} component={false}>
                <EditableContext.Provider value={form1}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ editable, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = <></>
        let errorMessage = ""
        const form1 = useContext(EditableContext);

        const save = async () => {
            try {
                let tempKeyList = selectedRowKeys.slice(0);
                if (tempKeyList.indexOf(record.key) === -1) {
                    tempKeyList.push(record.key);
                    onChangeItemLocationTo(tempKeyList);
                };

                const row = await form1.validateFields();
                const newData = [...itemLocationToTableData];
                const newDataIndex = newData.findIndex((item) => record.key === item.key);
                row['quantity_tool'] = row['total_item'] / parseInt(newData[newDataIndex]['quantity_item_max']);

                if (newDataIndex > -1) {
                    const item = newData[newDataIndex];
                    newData.splice(newDataIndex, 1, { ...item, ...row });
                    setItemLocationToTableData(newData);
                } else {
                    newData.push(row);
                    setItemLocationToTableData(newData);
                }

                // Update form data with location list
                const newLocationList = form.getFieldValue('item_location_to_list');
                const indexLoc = newLocationList.findIndex((location) => record.key === location.id);
                newLocationList[indexLoc] = { id: record.key, quantity_tool: row['quantity_tool'], total_item: row['total_item'] }

                // Update Actual Units To Sow
                let totalUnit = 0;
                tempKeyList.forEach( rowKey => {
                    const index = newData.findIndex((location) => rowKey === location.id);
                    totalUnit = totalUnit + newData[index]['total_item'];
                });
                setActualUnitSow(totalUnit);

            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        switch(title) {
            case "Total Units of Plant to Sow":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={1} step={record.quantity_item_max}/>;
                inputType = "integer";
                errorMessage = "with integer only";
                break;

            default:
                inputNode = inputType === 'number' ? <InputNumber onPressEnter={save} onBlur={save}/> : <Input onPressEnter={save} onBlur={save}/>;
        };

        let childNode = children;
        if (editable) {
            switch(dataIndex) {
                case "total_item":
                    childNode = (
                        <Form.Item initialValue={record.total_item} name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `Please Input ${title} ${errorMessage}!`, type: inputType}]}>
                            {inputNode}
                        </Form.Item>
                    )
                    break;

                default:
                    childNode = (
                        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `Please Input ${title} ${errorMessage}!`, type: inputType}]}>
                            {inputNode}
                        </Form.Item>
                    )
            };
        }
        return <td {...restProps}>{childNode}</td>;
    };

    const defaultColumns = [
        { title: 'Zone', dataIndex: 'item_area', sorter: (a, b) => a.item_area.localeCompare(b.item_area) },
        { title: 'Medium Type(s)', dataIndex: 'item_code', sorter: (a, b) => a.item_code.localeCompare(b.item_code) },
        // Obsolete this due to we are not going to keep track the quantity of the tray and harvest bin
        // { title: 'Quantity Available (Planting Medium)', dataIndex: 'available_quantity', sorter: (a, b) => a.available_quantity - b.available_quantity },
        { title: 'Size of Medium (Units of Plant)', dataIndex: 'quantity_item_max', sorter: (a, b) => a.quantity_item_max - b.quantity_item_max },
        { title: 'Total Units of Plant to Sow', dataIndex: 'total_item', editable: 1, sorter: (a, b) => a.total_item - b.total_item },
        { title: 'Quantity of Medium Required', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
    ]

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Sowing Activity" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Sowing Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="end"><Col span={22}/><Col span={4} style={{textAlign: "center"}}><Statistic valueStyle={{ color: 'green' }} title="Total Units To Sow" value={actualUnitSow} /></Col></Row>
                <Form form={form} onFinish={values => onSave(values)} {...formLayoutWithTable} autoComplete="off">
                    <Form.Item name="item_item" label="Plant Species">
                        <Select onChange={(value) => onChangeItemItem(value)} onSelect={(value) => onItemItemSelect(value)} allowClear={true} onClear={onItemItemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemItemOption}
                        </Select>
                    </Form.Item>

                    <Spin spinning={isLoadingBatch} size="large" tip={LOADING}>
                        <Form.Item name="batch" label="Batch Code" rules={[{required: true, message: 'Please select the Batch Code for sowing!'}]}>
                            <Select onChange={(value) => onChangeBatch(value)} allowClear={true} onClear={onBatchClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {batchOption}
                            </Select>
                        </Form.Item>
                    </Spin>

                    <Form.Item initialValue={moment()} name="actualExecutedDate" label="Actual Sowing Date" rules={[{required: true, message: 'Please input the Actual Sowing Date!'}]}>
                        <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment()}} disabledDate={disabledDateAfterToday}/>
                    </Form.Item>

                    <Form.Item name="area" label="Sowing Zone">
                        <Select onChange={(value) => onChangeArea(value)} allowClear={true} mode='multiple' onClear={onAreaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {areaOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="item_location_to_list" label="Planting Medium(s)" rules={[{required: true, message: 'Please select at least one Sowing Location!'}]} tooltip={{ title: "Please select a Sowing Zone first", icon: <ExclamationCircleTwoTone/>}}>
                        <Table components={{ body: {row: EditableRow, cell: EditableCell} }} rowClassName={() => 'editable-row'} rowSelection={rowSelection} columns={columns} dataSource={itemLocationToTableData} onChange={onChangeTable} pagination={{ size: 'small',  position: ["bottomCenter"], total: itemLocationToTableData.length, showTotal: showTotal }}/>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><SaveOutlined/>Save</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Sowing Activity" subTitle="Create New Sowing Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default SowingCreateNew;