import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Button, Table, Collapse, Form, Spin, Badge, Avatar, Modal, Tag, Typography, Tooltip, Select, Checkbox, message } from 'antd';
import { OTHERSYSPARAM, getUserSiteId, getUserSiteName, getUserAuthToken, refreshUserSession, setLastPage } from "../Common/UserSession";
import axios from 'axios';
import { AGISAPIURL, UNIDATETIMEFORMAT, LOADING, PAGESIZE, UNIDATEFORMAT, VALUE_NONE, ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT, MENUPATH_WORKPROGRAMME,
    MENUPATH_WORKPROGRAMMEUPDATE, MENUPATH_WORKPROGRAMMECREATENEW, APPJSON } from '../Common/SysParameters';
import { reportError } from '../Common/Utility';
import moment from 'moment';
import { formLayout } from '../Common/Layout';
import { PlusCircleTwoTone, CalendarOutlined, CheckOutlined, ScheduleTwoTone, QuestionCircleTwoTone, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import WorkPlanListTable from "./WorkPlanListTable";
import WorkTaskTimeline from "./WorkTaskTimeline";
import TrueFalseSelect from '../Common/TrueFalseSelect';


const { Option } = Select;
const { Panel } = Collapse;
const { Text } = Typography;
const { confirm } = Modal;

const WorkProgrammeTable = () => {
    const FileDownload = require('js-file-download');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemOption, setItemOption] = useState("");
    const [workPlanOption, setWorkPlanOption] = useState("");
    const [workProgTableData, setWorkProgTableData] = useState([]);
    const [item, setItem] = useState(0);
    const [workPlan, setWorkPlan] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [itemVal, setItemVal] = useState("");
    const [workPlanVal, setWorkPlanVal] = useState("");

    const workdayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [workdayList, setWorkdayList] = useState([]);
    const checkAll = workdayOptions.length === workdayList.length;
    const indeterminate = workdayList.length > 0 && workdayList.length < workdayOptions.length;

    const SearchWorkProgramme = (currentPage) => {
        //setIsLoading(true);

        axios.get(AGISAPIURL + "workprogramme/", {
            params: {
                site: getUserSiteId(),
                item: item,
                is_active: (isActive !== "") ? ((isActive) ? 1 : 0) : "",
                work_plan: workPlan,
                workday_list: workdayList,
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectWorkProgrammeRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            // setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItem = ({workPlan = ""}) => {
        //setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofileofworkprogramme/", {
            params: {
                site: getUserSiteId(),
                item_group_list: [ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT],
                work_plan: workPlan,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
            setItemOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            //setIsLoading(false);
            refreshUserSession();
        });
    };

    const getWorkPlan = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "workplan/", {
            params: {
                site: getUserSiteId(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = []
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.map( workPlan => <Option key={workPlan.id} value={workPlan.id}>{workPlan.description}</Option>));
            setWorkPlanOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const deleteWorkProgramme = (id) => {
        setIsLoading(true);

        axios.delete(AGISAPIURL + "workprogramme/delete/" + id, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then(() => {
            message.info(`Work Programme ${id} deleted!`);
            SearchWorkProgramme(currentPage);
        })
        .catch( error => {
            setIsLoading(false);
            reportError(error, "Failed to delete work programme. " + error.message);
        })
        .finally(() => {
            refreshUserSession();
        });
    };

    // Download pdf, excel search result.
    const downloadSearchResult = (mode) => {
        setIsLoading(true);

        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Site: ${getUserSiteName()}`
        if(workPlan != 0) searchCriteria = `${searchCriteria}\nWork Plan: ${workPlanVal}`
        if(item != 0) searchCriteria = `${searchCriteria}\nPlant Species: ${itemVal}`
        if(isActive !== "") searchCriteria = `${searchCriteria}\nIs Active: ${isActive ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}`
        if(workdayList.length != 0) searchCriteria = `${searchCriteria}\nWork Day: ${workdayList}`

        axios.get(`${AGISAPIURL}download/workprogramme/`, {
            params: {
                site: getUserSiteId(),
                item: item,
                is_active: (isActive !== "") ? ((isActive) ? 1 : 0) : "",
                work_plan: workPlan,
                workday_list: workdayList,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 60),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Work Programme ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    };

    useEffect(() => {
        setLastPage(MENUPATH_WORKPROGRAMME);
        SearchWorkProgramme(currentPage);
        getWorkPlan();
        getItem({});
    }, []);

    const collectWorkProgrammeRawData = ( response ) => {
        const data = [];
        response.data.results.forEach( workProg => {
            let item = VALUE_NONE;
            if (workProg.item) item = workProg.item.item.description;

            data.push({
                key: workProg.id,
                id: workProg.id,
                work_programme_code: workProg.work_programme_code,
                description: workProg.description,
                workday: workProg.workday,
                monday: workProg.workday.monday,
                tuesday: workProg.workday.tuesday,
                wednesday: workProg.workday.wednesday,
                thursday: workProg.workday.thursday,
                friday: workProg.workday.friday,
                saturday: workProg.workday.saturday,
                sunday: workProg.workday.sunday,
                item: item,
                quantity_item: parseInt(workProg.quantity_item),
                work_plan_list: collectWorkPlanListRawData(workProg.work_plan_list),
                work_stage_list: collectWorkStageListRawData(workProg.work_stage_list),
                work_task_list: collectWorkTaskListRawData(workProg.work_task_list),
                is_active: workProg.workday.is_active,
                created_on: moment(workProg.created_on).format(UNIDATETIMEFORMAT),
                updated_on: moment(workProg.updated_on).format(UNIDATETIMEFORMAT),
                has_batch: workProg.has_batch,
            });
        });
        setWorkProgTableData(data);

        // Total pages
        setTotalRecord(response.data.count);
    };

    const collectWorkPlanListRawData = ( response ) => {
        const data = [];
        response.forEach( workPlan => {
            data.push({
                key: workPlan.id,
                id: workPlan.id,
                work_plan_code: workPlan.work_plan_code,
                description: workPlan.description,
                is_active: workPlan.is_active,
                is_in_effect: workPlan.is_in_effect,
                date_range_list: collectDateRangeListRawData(workPlan.date_range_list),
            });
        });
        return data;
    };

    const collectWorkStageListRawData = ( response ) => {
        const data = [];
        response.forEach( workStage => {
            data.push({
                key: workStage.id,
                id: workStage.id,
                seq: workStage.seq,
                duration: parseInt(workStage.duration),
                duration_uom: workStage.duration_type.unit,
                lifecycle: workStage.lifecycle.name,
                work_phase: workStage.work_phase.name,
            });
        });
        return data;
    };

    const collectWorkTaskListRawData = ( response ) => {
        const data = [];
        response.forEach( workTask => {
            data.push({
                key: workTask.id,
                id: workTask.id,
                seq: workTask.seq,
                execute_on: parseInt(workTask.execute_on),
                execute_on_uom: workTask.execute_on_type.unit,
                task_type: workTask.task_type.task,
                lifecycle: workTask.work_stage.lifecycle.name,
                work_phase: workTask.work_stage.work_phase.name,
            });
        });
        return data;
    };

    const collectDateRangeListRawData = ( response ) => {
        const data = [];
        response.forEach( dataRange => {
            data.push({
                key: dataRange.id,
                id: dataRange.id,
                start_date: dataRange.start_date ? moment(dataRange.start_date).format(UNIDATEFORMAT) : VALUE_NONE,
                end_date: dataRange.end_date ? moment(dataRange.end_date).format(UNIDATEFORMAT) : VALUE_NONE,
            });
        });
        return data;
    };

    const onChangeWorkPlan = (key, value) => {
        if(key !== undefined) {
            setWorkPlan(key);
            setWorkPlanVal(value.children);
            form.setFieldsValue({
                item: "",
            });
            setItem(0);
            setItemVal("");

            getItem({ workPlan: key });
        };
    };

    const onChangeItem = (key, value) => {
        if(key !== undefined) {
            setItem(key);
            setItemVal(value.children);
        };
    };

    const onChangeIsActive = (value) => {
        setIsActive(value);
    };

    const onChangeWorkday = (list) => {
        setWorkdayList(list);
    };

    const onChangeCheckAll = (e) => {
        setWorkdayList(e.target.checked ? workdayOptions : []);
        form.setFieldsValue({
            workday: e.target.checked ? workdayOptions : [],
        });
    };

    const onWorkPlanClear = () => {
        setWorkPlan(0);
        setWorkPlanVal("");
        form.setFieldsValue({
            item: "",
        });
        setItem(0);
        setItemVal("");

        getItem({});
    };

    const onItemClear = () => {
        setItem(0);
        setItemVal("");
    };

    const onSearch = () => {
        setCurrentPage(1);
        SearchWorkProgramme(1);
    };

    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0]);
    };

    const onCreateNewWorkProgramme = () => {
        navigate(MENUPATH_WORKPROGRAMMECREATENEW);
    };

    const onClickTableRow = (record, rowIndex) => {
        return {
            onClick: () => {
                navigate(MENUPATH_WORKPROGRAMMEUPDATE, {state: {record: record}});
            }
        };
    };

    const onClickWorkPlanList = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Work Programme: {record.description}</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <WorkPlanListTable dataSource={record.work_plan_list} navigate={navigate}/>
        })
    };

    const onClickWorkTaskList = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Work Programme: {record.description}</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <><Space><br/></Space><WorkTaskTimeline dataSource={record} /></>
        })
    };

    //---------------------------
    // On delete
    //---------------------------
    const onDelete = (e, key) => {
        e.stopPropagation();

        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Delete work programme confirmed?</p></Space>,
            onOk() { deleteWorkProgramme(key) },
            onCancel() {},
            centered: true
        });
    };

    const onPaginationChange = (page) => {
        setCurrentPage(page);
        SearchWorkProgramme(page);
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Work Programme Code', dataIndex: 'work_programme_code', sorter: (a, b) => a.work_programme_code.localeCompare(b.work_programme_code) },
        { title: 'Description', dataIndex: 'description', sorter: (a, b) => a.description.localeCompare(b.description),
            render: (description, record) => {
                return (<Text>{description} <Tooltip title="Show Planned Task"><ScheduleTwoTone onClick={(e) => onClickWorkTaskList(e, record)}/></Tooltip></Text>);
            }
        },
        { title: 'Plant Species', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Unit Amount', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item },
        { title: 'Active', dataIndex: 'is_active', sorter: (a, b) => a.is_active - b.is_active, render: (is_active) => {
            if (is_active) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
        { title: 'Assigned Work Plan(s)', dataIndex: 'work_plan_list',
            render: (work_plan_list, record) => {
                if (work_plan_list.length) { return (
                    <Badge count={work_plan_list.length} onClick={(e) => onClickWorkPlanList(e, record)}>
                        <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<CalendarOutlined />} />
                    </Badge>);
                }
                else return (<>{VALUE_NONE}</>);
            }
        },
        { title: 'Planned Work Day', children: [
            { title: 'Monday', dataIndex: 'monday', render: (monday) => {return (monday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Tuesday', dataIndex: 'tuesday', render: (tuesday) => {return (tuesday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Wednesday', dataIndex: 'wednesday', render: (wednesday) => {return (wednesday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Thursday', dataIndex: 'thursday', render: (thursday) => {return (thursday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Friday', dataIndex: 'friday', render: (friday) => {return (friday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Saturday', dataIndex: 'saturday', render: (saturday) => {return (saturday ? <CheckOutlined /> : <>-</>)} },
            { title: 'Sunday', dataIndex: 'sunday', render: (sunday) => {return (sunday ? <CheckOutlined /> : <>-</>)} },
        ]},
        { title: 'Action', dataIndex: 'action',
            render: (_, record) => {
                return (record.work_plan_list.length === 0 && !record.has_batch) ? (
                    <Typography.Link title="Sure to delete this work programme?" onClick={(e) => onDelete(e, record.key) }>Delete</Typography.Link>
                ) : (
                    <Typography.Link disabled title="The work programme can't be delete if it has batch/work plan attached to it." onClick={(e) => onDelete(e, record.key) }>Delete</Typography.Link>
                );
            },
        },
    ];

    return (
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Collapse>
                <Panel header="Search Work Programme" key="1">
                    <Form form={form} {...formLayout}>
                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item initialValue={workPlan} name="workPlan" label="Work Plan">
                                    <Select onChange={(e, value) => onChangeWorkPlan(e, value)} allowClear={true} onClear={onWorkPlanClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {workPlanOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={item} name="item" label="Plant Species">
                                    <Select onChange={(e, value) => onChangeItem(e, value)} allowClear={true} onClear={onItemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {itemOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={isActive} name="isActive" label="Is Active">
                                    <TrueFalseSelect withBlank={true} defaultSelect={OTHERSYSPARAM("YES")} onChange={onChangeIsActive}/>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item initialValue={workdayList} name="workday" label="Work Day">
                                    <Checkbox.Group style={{ width: '110%' }} options={workdayOptions} value={workdayList} onChange={onChangeWorkday}/>
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 7 }} name="checkAll">
                                    <Checkbox indeterminate={indeterminate} onChange={onChangeCheckAll} checked={checkAll}>Check all</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button onClick={onSearch} type="primary">Search</Button>
                                <Button title='Download PDF' type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} icon={<DownloadOutlined />} />
                                <Button title='Download Excel' type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} icon={<FileExcelOutlined />} />
                                <Button onClick={onReset} type="primary" danger>Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Form>
                </Panel>
            </Collapse>

            <Row><Col><Space><br/></Space></Col></Row>
            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewWorkProgramme} type="primary"><PlusCircleTwoTone /> New Work Programme</Button></Col></Row>
            <Row><Col><Space><br/></Space></Col></Row>

            <Table bordered={true} onRow={onClickTableRow} columns={columns} dataSource={workProgTableData}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: PAGESIZE,
                    current: currentPage,
                    total: totalRecord,
                    showTotal: showTotal,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                }}/>

            <Row justify="end"><Col span={24} style={{textAlign: "end"}}><Button onClick={onCreateNewWorkProgramme} type="primary"><PlusCircleTwoTone /> New Work Programme</Button></Col></Row>
        </Spin>
        </>
    );
};

export default WorkProgrammeTable;