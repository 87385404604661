import { useNavigate } from "react-router-dom";
import { message } from 'antd';
import moment from 'moment';
import { LOGIN_PATH, UNIDATETIMEFORMAT } from "./SysParameters";
import { useEffect } from "react";


const AUTHTOKEN_KEY = "authToken";
const USERGROUPS_KEY = "userGroups";
const USERNAME_KEY = "userName";
const SITEID_KEY = "siteID";
const SITENAME_KEY = "siteName";
const LASTACTIVE_KEY = "lastActive";
const LASTPAGE_KEY = "lastPage";

export const createUserSession = (username, userGroups, defaultSiteId, defaultSiteName, authToken) => {
    // Each user session must contain at least the following items.
    sessionStorage.setItem(AUTHTOKEN_KEY, authToken);
    sessionStorage.setItem(USERNAME_KEY, username);
    sessionStorage.setItem(USERGROUPS_KEY, userGroups);
    sessionStorage.setItem(SITEID_KEY, defaultSiteId);
    sessionStorage.setItem(SITENAME_KEY, defaultSiteName);
    sessionStorage.setItem(LASTACTIVE_KEY, new Date());
};

// React Hook (useHistory) can only be called from React function component so function name must start with Capital letter.
export const ValidateUserSession = () => {
    const navigate = useNavigate();

    // Redirect user to login page if not authenticated or session expired.
    useEffect(() => {
        const lastActive = moment(sessionStorage.getItem(LASTACTIVE_KEY));

        if(sessionStorage.getItem(AUTHTOKEN_KEY) === null || lastActive.add(parseInt(OTHERSYSPARAM("USER_SESSION_HOUR")), 'hours').isBefore(moment())) {
            navigate(LOGIN_PATH);
            message.info("Please kindly login.");
        }
    });
};

export const getUserAuthToken = () => {
    return sessionStorage.getItem(AUTHTOKEN_KEY);
};

export const refreshUserSession = () => {
    sessionStorage.setItem(LASTACTIVE_KEY, moment().format(UNIDATETIMEFORMAT));
};

export const clearUserSession = () => {
    sessionStorage.clear();
};

export const getUsername = () => {
    return sessionStorage.getItem(USERNAME_KEY);
};

export const getUserGroups = () => {
    try{
        return sessionStorage.getItem(USERGROUPS_KEY).split(",");
    }
    catch(error) {
        window.location.href = "/";
    }
};

export const setUserSite = (siteId, siteName) => {
    sessionStorage.setItem(SITEID_KEY, siteId);
    sessionStorage.setItem(SITENAME_KEY, siteName);
};

export const getUserSiteId = () => {
    return sessionStorage.getItem(SITEID_KEY);
};

export const getUserSiteName = () => {
    return sessionStorage.getItem(SITENAME_KEY);
};

// System parameters from database
export const storeSystemParameter = (key, value) => {
    if(sessionStorage.getItem(key) !== null) {
        message.error(`Duplicated system parameter detected: ${key}: ${value}.`);
        return
    }

    sessionStorage.setItem(key, value);
};

// Always return an interger (primary key)
export const SYSPARAM = (key) => {
    try{
        if(sessionStorage.getItem(key) === null) {
            message.error(`Unknown system parameter requested: ${key}`);
            //throw `Unknown system parameter requested: ${key}`
        }

        return parseInt(sessionStorage.getItem(key));
    }
    catch(error) {
        window.location.href = "/";
    }
};

// Always return string
export const OTHERSYSPARAM = (key) => {
    try{
        if(sessionStorage.getItem(key) === null) {
            message.error(`Unknown other system parameter requested: ${key}`);
            //throw `Unknown system parameter requested: ${key}`
        }

        return sessionStorage.getItem(key);
    }
    catch(error) {
        window.location.href = "/";
    }
};

export const setLastPage = (lastPage) => {
    sessionStorage.setItem(LASTPAGE_KEY, lastPage);
};