import React,  { useEffect, useState } from "react";
import { Select, Form } from "antd";
import { AGISAPIURL } from "./SysParameters";
import { reportError } from "./Utility";
import { OTHERSYSPARAM, getUserAuthToken, getUserSiteId, refreshUserSession } from "./UserSession";
import axios from "axios";

const { Option } = Select;

const SiteSelect = ({withBlank, initialValue, onChange, ownSiteOnly, excludeOwnSite}) => {
    const [siteOption, setSiteOption] = useState("");

    const getAllSite = () => {

        axios.get(AGISAPIURL + "site/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = [];
            if(withBlank) options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            options = options.concat(response.data.results.map( site => <Option key={site.id} value={site.id}>{site.name}</Option>));
            if(ownSiteOnly) options = options.filter( option => option.key == getUserSiteId());
            if(excludeOwnSite) options = options = options.filter( option => option.key != getUserSiteId());
            setSiteOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            refreshUserSession();
        });
    };

    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        onChange(e, value);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllSite();
    }, [])

    return(
        <Form.Item initialValue={initialValue} name="defaultSite" label="Default Site" rules={[{required: true, message: "Default site is required!"}]}>
            <Select onChange={onSelectionChange} allowClear showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {siteOption}
            </Select>
        </Form.Item>
    )
};

export default SiteSelect;