import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, message, Modal, Spin, Typography, DatePicker } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, refreshUserSession, getUserAuthToken } from '../Common/UserSession';
import { formLayout } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_BATCH, STATUS_OPEN, AREA_TYPE_ALL_LIST, ITEM_GROUP_LEAFY_PLANT, ITEM_GROUP_FRUITY_PLANT, LOADING, UNIDATEFORMAT } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';
import { PlusCircleTwoTone, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { confirm } = Modal;
const { Content, Footer } = Layout;
const { Text } = Typography

const BatchCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const default_item = location.state ? location.state.record.item : "";
    const default_area = location.state ? location.state.record.area : "";
    const default_item_group = location.state ? location.state.record.item_group : "";
    const default_work_programme = location.state ? location.state.record.work_programme : "";
    const default_show_item = location.state ? location.state.record.show_item : false;
    const default_show_area = location.state ? location.state.record.show_area : false;

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemGroupOption, setItemGroupOption] = useState("");
    const [itemAreaOption, setItemAreaOption] = useState("");
    const [itemOption, setItemOption] = useState("");
    const [workProgOption, setWorkProgOption] = useState("");
    const [itemGroup, setItemGroup] = useState(default_item_group);
    const [itemArea, setItemArea] = useState(default_area);
    const [item, setItem] = useState(default_item);
    const [workProg, setWorkProg] = useState(default_work_programme);
    const [disableButton, setDisableButton] = useState("");
    const [showItemArea, setShowItemArea] = useState(default_show_area);
    const [showItem, setShowItem] = useState(default_show_item);
    const [isLoading, setIsLoading] = useState(false);

    const createBatch = () => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "batch/create/", {
            site: getUserSiteId(),
            item: item === 0 ? null : item,
            item_group: itemGroup === 0 ? null : itemGroup,
            area: itemArea,
            work_programme: workProg,
            status_type: STATUS_OPEN,
            actual_date: moment(form.getFieldValue("batchStartDate")).format(UNIDATEFORMAT)
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New batch ${response.data.batch_code} created.`);
            navigate(MENUPATH_BATCH);
        })
        .catch( error => {
            reportError(error, "Failed to create batch. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getItemGroup = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemgroupbyusebatch/", {
            params: {
                use_batch: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemGroupOption(response.data.results.map( itemprofile => <Option key={itemprofile.item_group.id} value={itemprofile.item_group.id}>{itemprofile.item_group.name}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItemArea = (area_type_list = []) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "area/", {
            params: {
                site: getUserSiteId(),
                area_type_list: area_type_list,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = response.data.results.map( area => {
                let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                return <Option key={area.id} value={area.id}>{zoneName}</Option>;
            });
            setItemAreaOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getItem = ({ item_group = "" }) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofile/", {
            params: {
                item_group: item_group,
                use_batch: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemOption(response.data.results.map( itemprofile => <Option key={itemprofile.item.id} value={itemprofile.item.id}>{itemprofile.item.description}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const isActive = (work_plan_list) => {
        return work_plan_list.some(function(workPlan) {
            return workPlan.is_in_effect === true;
        });
    };

    const getWorkProgramme = ({itemId = "", item_group = "", item_area = ""}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemprofileworkprogramme/", {
            params: {
                site: getUserSiteId(),
                item: itemId,
                item_group: item_group,
                area: item_area,
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = [];
            response.data.results.forEach(itemprofileworkprogramme => {
                if (isActive(itemprofileworkprogramme.work_programme.work_plan_list)) options.push(<Option key={itemprofileworkprogramme.work_programme.id} value={itemprofileworkprogramme.work_programme.id}>{itemprofileworkprogramme.work_programme.description} - <Text keyboard>Under Current In Effect Work Plan</Text> <Text type="success">Suggested</Text></Option>)
                else options.push(<Option key={itemprofileworkprogramme.work_programme.id} value={itemprofileworkprogramme.work_programme.id}>{itemprofileworkprogramme.work_programme.description}</Option>);
            });
            setWorkProgOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const itemGroupChange = (value) => {
        if(value !== undefined) {
            setItemGroup(value);
            if(value !== ITEM_GROUP_LEAFY_PLANT & value !== ITEM_GROUP_FRUITY_PLANT & value !== 0) {
                setShowItemArea(true);
                setShowItem(false);
            }
            else {
                setShowItemArea(false);
                setShowItem(true);
            };
        }
        else
            setItemGroup("");
    };

    const itemAreaChange = (value) => {
        if(value !== undefined)
            setItemArea(value);
        else
            setItemArea("");
    };

    const itemChange = (value) => {
        if(value !== undefined)
            setItem(value);
        else
            setItem("");
    };

    const workProgrammeChange = (value) => {
        if(value !== undefined)
            setWorkProg(value);
        else
            setWorkProg("");
    };

    const itemGroupClear = () => {
        setItemGroup();
        form.setFieldsValue({
            item: "",
            item_area: "",
            work_programme: "",
        })
        setItem();
        setItemArea();
        setShowItem(false);
        setShowItemArea(false);

        getItem({});
        getWorkProgramme({});
    };

    const itemAreaClear = () => {
        setItemArea();
        form.setFieldsValue({
            work_programme: "",
        })
        getWorkProgramme({ item_group: itemGroup });
    };

    const itemClear = () => {
        setItem();
        form.setFieldsValue({
            work_programme: "",
        })
        getWorkProgramme({ item_group: itemGroup });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getItemGroup();
        getItemArea(AREA_TYPE_ALL_LIST);
        getItem({});
        getWorkProgramme({});
    }, []);

    const onItemGroupSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                item: "",
                item_area: "",
                work_programme: "",
            })
            setItem();
            setItemArea();
            getItem({ item_group: value });
            getWorkProgramme({ item_group: value });
        };
    };

    const onItemAreaSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                work_programme: "",
            })
            getWorkProgramme({ item_group: itemGroup, item_area: value });
        };
    };

    const onItemSelect = (value) => {
        if(value !== undefined){
            form.setFieldsValue({
                work_programme: "",
            })
            getWorkProgramme({ itemId: value, item_group: itemGroup });
        };
    };

    const onBack = () => {
        navigate(MENUPATH_BATCH);
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_BATCH) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create batch confirmed?</p></Space>,
            onOk() { createBatch() },
            onCancel() {},
            centered: true
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Batch" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Batch</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout} autoComplete="off">
                    <Form.Item initialValue={moment()} name="batchStartDate" label="Batch Start Date" rules={[{required: true, message: 'Please select actual batch start date!'}]}>
                        <DatePicker format={UNIDATEFORMAT} /*showTime={{defaultValue: moment()}}*/ showTime={false} />
                    </Form.Item>

                    <Form.Item initialValue={default_item_group} name="item_group" label="Item Group" rules={[{required: true, message: 'Please select the Item Group for this Batch!'}]}>
                        <Select onChange={(value) => itemGroupChange(value)} onSelect={(value) => onItemGroupSelect(value)} allowClear={true} onClear={itemGroupClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemGroupOption}
                        </Select>
                    </Form.Item>

                    { showItemArea ?
                        <Form.Item initialValue={default_area} name="item_area" label="Area" rules={[{required: true, message: 'Please select the Area for this Batch!'}]}>
                            <Select onChange={(value) => itemAreaChange(value)} onSelect={(value) => onItemAreaSelect(value)} allowClear={true} onClear={itemAreaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {itemAreaOption}
                            </Select>
                        </Form.Item>
                    : <></>}

                    { showItem ?
                    <Form.Item initialValue={default_item} name="item" label="Item" rules={[{required: true, message: 'Please select the Item for this Batch!'}]}>
                        <Select onChange={(value) => itemChange(value)} onSelect={(value) => onItemSelect(value)} allowClear={true} onClear={itemClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {itemOption}
                        </Select>
                    </Form.Item>
                    : <></>}

                    <Form.Item initialValue={default_work_programme} name="work_programme" label="Work Programme" /*rules={[{required: true, message: 'Please select the Work Programme for this Batch!'}]}*/>
                        <Select onChange={(value) => workProgrammeChange(value)} allowClear={true} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {workProgOption}
                        </Select>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><PlusCircleTwoTone/>Create</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Batch" subTitle="Create New Batch" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default BatchCreateNew;