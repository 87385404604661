import React from 'react';
import { Table } from 'antd';

const LocationDistributionTable = ({dataSource}) => {

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Location', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Unit Amount', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item },
    ];

    return (
        <Table columns={columns} dataSource={dataSource} pagination={{size: "small", position: ["bottomCenter"], total: dataSource.length, showTotal: showTotal}}/>
    );
};

export default LocationDistributionTable;