import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Button, Spin } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import { AGISAPIURL, MENUPATH_BATCH, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';


const { Content, Footer } = Layout;

const BatchItemSelect = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const navigate = useNavigate();
    const [itemGroupOption, setItemGroupOption] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getItemGroup = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemgroup/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            setItemGroupOption(response.data.results.map( itemgroup => <Row justify="center"><Col span={4}><Button type="primary" shape="round" size="large" onClick={() => onClickItemGroup(itemgroup.id)} key={itemgroup.id} block>{itemgroup.name}</Button></Col></Row>));
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getItemGroup();
    }, []);

    const onClickItemGroup = (value) => {
        navigate(MENUPATH_BATCH, {state: {itemgroup: value}});
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Batch Item Group">
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Batch Item Group Selection</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                {itemGroupOption}
            </Content>
            <Footer><Row justify="center"><PageHeader title="Batch Item Group" subTitle="Batch Item Group Selection"></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default BatchItemSelect;