import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Typography, Card, DatePicker, Modal, message, Collapse, Select, Divider, Table, Spin } from 'antd';
import { AGISAPIURL, MENUPATH_ACTIVITY, UNIDATETIMEFORMAT, LOADING, VALUE_NONE } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import axios from 'axios';
import moment from 'moment';
import { reportError } from "../Common/Utility";
import { CloseCircleFilled, QuestionCircleTwoTone, SaveOutlined } from '@ant-design/icons';


const { Content, Footer } = Layout;
const { confirm } = Modal;
const { Title, Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const ActivityUpdate = () => {

    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");
    const paddingCardGrid = OTHERSYSPARAM("CARD_GRID_PADDING");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [subActivityTableData, setSubActivityTableData] = useState([]);
    const [activityId, setActivityId] = useState(location.state.record.id);
    const [timestamp, setTimestamp] = useState(location.state.record.timestamp);
    const [task_category, setTaskCategory] = useState(location.state.record.task_category);
    const [task_type, setTaskType] = useState(location.state.record.task_type);
    const [item_item, setItemItem] = useState(location.state.record.item_item);
    const [item_item_id, setItemItemId] = useState(location.state.record.item_item_id);
    const [batch, setBatch] = useState(location.state.record.batch);
    const [batch_id, setBatchId] = useState(location.state.record.batch_id);
    const [lifecycle, setLifecycle] = useState(location.state.record.lifecycle);
    const [work_phase, setWorkPhase] = useState(location.state.record.work_phase);
    const [actualExecutedDate, setActualExecutedDate] = useState(location.state.record.actual_executed_datetime);
    const [updatedOn, setUpdatedOn] = useState(location.state.record.updated_on);
    const [disableCancelButton, setDisableCancelButton] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState("");
    const [batchOption, setBatchOption] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const updateActivity = (actual_executed_datetime) => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setIsLoading(true);

        axios.patch(AGISAPIURL + "activity/update/" + location.state.record.id, {
            batch: batch_id === 0 | batch_id === undefined ? "" : batch_id,
            actual_executed_datetime: actual_executed_datetime.format(UNIDATETIMEFORMAT),
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Activity ID ${response.data.id} updated.`);
            navigate(MENUPATH_ACTIVITY);
        })
        .catch( error => {
            reportError(error, "Failed to update data. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const SearchSubActivity = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "subactivity/", {
            params: {
                activity: activityId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectSubActivityRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const getBatch = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batch/", {
            params: {
                site: getUserSiteId(),
                item: item_item_id,
                is_active: 1,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = [];
            options.push(<Option key={0} value={0}>{" "}</Option>); // Blank
            setBatchOption(options.concat(response.data.map( batch => <Option key={batch.id} value={batch.id}>{batch.batch_code}</Option>)));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectSubActivityRawData = ( response ) => {
        const data = []
        response.data.results.forEach( subactivity => {
            let itemLocationFromCode = VALUE_NONE;
            if (subactivity.item_location_from) itemLocationFromCode = subactivity.item_location_from.description;

            data.push({
                key: subactivity.id,
                id: subactivity.id,
                timestamp: moment(subactivity.timestamp).format(UNIDATETIMEFORMAT),
                activity: subactivity.activity,
                area: subactivity.area.area_code,
                item_location_from: itemLocationFromCode,
                item_location_to: subactivity.item_location_to.description,
            })
        });
        setSubActivityTableData(data);
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchSubActivity();
        getBatch();
    }, []);

    const onBatchChange = (value) => {
        setBatchId(value);
    };

    const onBack = () => {
        navigate(MENUPATH_ACTIVITY);
    };

    const onCancel = () => {
        navigate(MENUPATH_ACTIVITY);
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (value) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Edit activity confirmed?</p></Space>,
            onOk() { updateActivity(value.actualExecutedDate) },
            onCancel() {},
            centered: true
        });
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Source Location', dataIndex: 'item_location_from', sorter: (a, b) => a.item_location_from.localeCompare(b.item_location_from) },
        { title: 'Destination Location', dataIndex: 'item_location_to', sorter: (a, b) => a.item_location_to.localeCompare(b.item_location_to) },
        { title: 'Destination Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Activity Update" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Edit Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center"><Col span={12}>
                    <Collapse defaultActiveKey={["1"]}>
                        <Panel header={<Title level={5}>{`Activity Summary: ${task_type} (${item_item}), Batch Code: ${batch}`}</Title>} key="1">
                            <Card title={<Text italic>{`Timestamp: ${timestamp}`}</Text>}>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Actual Executed Date:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{actualExecutedDate}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Task:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{task_type}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Task Category:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{task_category}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Batch Code:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{batch}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Item:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{item_item}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Life Cycle:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{lifecycle}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Work Phase:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{work_phase}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{updatedOn}</Card.Grid>
                            </Card>

                            <Row><Col><Space><br/></Space></Col></Row>
                            <Table columns={columns} dataSource={subActivityTableData} pagination={{size: "small", position: ["bottomCenter"], total: subActivityTableData.length, showTotal: showTotal}}/>
                        </Panel>
                    </Collapse>
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row justify="center"><Col span={12}>
                    <Divider orientation="left">Edit Activity Fields</Divider>
                    <Form initialValues={{batch: batch_id}} form={form} onFinish={onSave} autoComplete="off">
                        <Form.Item initialValue={moment(actualExecutedDate)} name="actualExecutedDate" label="Actual Executed Date" rules={[{required: true, message: 'Please input the Actual Executed Date for this activity!'}]}>
                            <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment(actualExecutedDate)}} disabledDate={disabledDateAfterToday}/>
                        </Form.Item>

                        <Form.Item name="batch" label="Batch Code">
                            <Select allowClear={true} onChange={(value) => onBatchChange(value)} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {batchOption}
                            </Select>
                        </Form.Item>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={12} offset={6}>
                                <Button htmlType="submit" hidden={disableSubmitButton} disabled={disableSubmitButton} type="primary"><SaveOutlined/>Save</Button>
                                <Button htmlType="button" disabled={disableCancelButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col></Row>
            </Content>
            <Footer><Row justify="center"><PageHeader title="Activity Update" subTitle="Edit Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default ActivityUpdate;