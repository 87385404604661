import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from '@ant-design/plots';
import { Spin, Row, Col, Typography, Select, DatePicker, Form, Radio, Button, Statistic, Card, Space } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, UNIDATEFORMAT, MENUPATH_DASHBOARDSOWINGHISTORYBYSPECIES } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from "moment";
import axios from "axios";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const DashboardChartsSowingHistory = () => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [sowingHistoryData, setSowingHistoryData] = useState([]);
    const [totalPlanned, setTotalPlanned] = useState(0);
    const [totalActual, setTotalActual] = useState(0);
    const [lastNDays, setLastNDays] = useState(3);
    const [lastNDaysOption, setLastNDaysOption] = useState("");
    const [showLastNDays, setShowLastNDays] = useState(true);
    const [showDateRange, setShowDateRange] = useState(false);
    const [dateRange, setDateRange] = useState([moment(moment().subtract(lastNDays, "days"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    const [customDateRange, setCustomDateRange] = useState([moment(moment().subtract(lastNDays, "days"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);

    const SearchSowingHistory = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "sowingbacklogs/", {
            params: {
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectSowingHistory(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectSowingHistory = ( response ) => {
        let totalQuantityBySpecies = {}
        const data = []
        let tempPlanned = 0
        let tempActual = 0
        response.data.results.forEach( sowingHistory => {
            tempPlanned = tempPlanned + parseInt(sowingHistory.expected_quantity_item);
            tempActual = tempActual + parseInt(sowingHistory.actual_quantity_item);

            // Consolidate total quantiy group by species
            if (totalQuantityBySpecies[sowingHistory.item.description] == undefined) { totalQuantityBySpecies[sowingHistory.item.description] = {item_id: sowingHistory.item.id, expected_quantity_item: 0, actual_quantity_item: 0} }
            totalQuantityBySpecies[sowingHistory.item.description]["expected_quantity_item"] += parseInt(sowingHistory.expected_quantity_item);
            totalQuantityBySpecies[sowingHistory.item.description]["actual_quantity_item"] += parseInt(sowingHistory.actual_quantity_item);

        });

        for (const [key, value] of Object.entries(totalQuantityBySpecies)) {
            data.push({
                item: key,
                item_id: value["item_id"],
                name: "Planned",
                total_quantity_item: value["expected_quantity_item"],
                percentage_archived: 1,
                lastNDays: lastNDays,
                dateRange: [dateRange[0].format(UNIDATEFORMAT), dateRange[1].format(UNIDATEFORMAT)],
            });

            data.push({
                item: key,
                item_id: value["item_id"],
                name: "Actual",
                total_quantity_item: value["actual_quantity_item"],
                percentage_archived: parseFloat(value["actual_quantity_item"] / value["expected_quantity_item"]),
                lastNDays: lastNDays,
                dateRange: [dateRange[0].format(UNIDATEFORMAT), dateRange[1].format(UNIDATEFORMAT)],
            });
        };

        setSowingHistoryData(data);
        setTotalPlanned(tempPlanned);
        setTotalActual(tempActual);
    };

    const setLastNDaysOptions = () => {
        let options = [];
        for (let i = 0; i <= 90; i++) {options = options.concat(<Option key={i} value={i}>{i}</Option>)};
        setLastNDaysOption(options);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        setLastNDaysOptions();
        SearchSowingHistory();
    }, []);

    const onChangePeriod = (e) => {
        switch(e.target.value) {
            case "1":
                setShowLastNDays(true);
                setShowDateRange(false);
                setDateRange([moment(moment().subtract(lastNDays, "days"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
                break;

            case "2":
                setShowLastNDays(false);
                setShowDateRange(true);
                setDateRange(customDateRange);
                break
        };
    };

    const onChangeLastNDays = (value) => {
        setLastNDays(value);
        setDateRange([moment(moment().subtract(value, "days"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    };

    const onChangeDateRange = (value) => {
        setCustomDateRange(value);
        setDateRange(value);
    };

    const onApply = () => {
        SearchSowingHistory();
    };

    const onClickChart = (plot) => {
        plot.on('element:click', (...args) => {
            navigate(MENUPATH_DASHBOARDSOWINGHISTORYBYSPECIES, {state: {record: args[0].data.data}});
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Row justify='space-between'>
                <Col span={22} style={{textAlign: "center"}}>
                    <Title level={3}>Sowing History</Title>
                    <Title level={5}>From {dateRange[0].format(UNIDATEFORMAT)} to {dateRange[1].format(UNIDATEFORMAT)}</Title>

                    <Space><br/></Space>

                    <Form form={form}>
                        <Row justify='space-between'>
                            <Col span={4}>
                                <Radio.Group defaultValue="1" buttonStyle='solid' onChange={onChangePeriod}>
                                    <Radio.Button value="1">Last N days</Radio.Button>
                                    <Radio.Button value="2">Custom Date</Radio.Button>
                                </Radio.Group>
                            </Col>

                            <Col span={8}>
                                <Form.Item initialValue={lastNDays} name="lastNdays" label="Last Number of Days (0 - 90)" hidden={!showLastNDays}>
                                    <Select onChange={onChangeLastNDays} showSearch optionFilterProp="children">
                                        {lastNDaysOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={dateRange} name="daterange" label="Date Range" hidden={!showDateRange}>
                                    <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}} />
                                </Form.Item>
                            </Col>

                            <Col span={2}>
                                <Button onClick={onApply} type="primary">Apply</Button>
                            </Col>
                        </Row>
                    </Form>

                    <Column
                        data={sowingHistoryData}
                        isGroup={true}
                        xField='item'
                        yField='total_quantity_item'
                        seriesField='name'
                        legend={{ position: 'bottom' }}
                        marginRatio={0}
                        appendPadding={20}
                        onReady={onClickChart}
                        label={{
                            position: 'top',
                            layout: [{type: 'adjust-color'}],
                            formatter: (value) => `${(value.percentage_archived * 100).toFixed(1)}%`,
                        }}
                    />
                </Col>

                <Col span={2}>
                    <Card bordered={false}><Statistic title="Amount Planned" value={totalPlanned} valueStyle={{ color: 'blue' }} /></Card>
                    <Card bordered={false}><Statistic title="Actual Amount" value={totalActual} valueStyle={{ color: 'green' }} suffix={`(${((totalActual / totalPlanned) * 100).toFixed(2)}%)`} /></Card>
                    <Card bordered={false}><Statistic title="Amount Difference" value={totalPlanned - totalActual} valueStyle={{ color: 'red' }} suffix={`(${(((totalPlanned - totalActual) / totalPlanned) * 100).toFixed(2)}%)`} /></Card>
                </Col>
            </Row>
        </Spin>
    );
};

export default DashboardChartsSowingHistory;