import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, message, Modal, Spin, Input } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, refreshUserSession, getUserAuthToken } from '../Common/UserSession';
import { formLayout } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_USER, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';
import { PlusCircleTwoTone, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';
import SiteSelect from '../Common/SiteSelect';
import UserGroupSelect from '../Common/UserGroupSelect';


const { Option } = Select;
const { confirm } = Modal;
const { Content, Footer } = Layout;

const UserCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [allowedSitesOption, setAllowedSitesOption] = useState([]);
    const [allowedSiteIds, setAllowedSiteIds] = useState([]);
    const [defaultSiteId, setDefaultSiteId] = useState(0);
    const [userGroupId, setUserGroupId] = useState(0);
    const [disableButton, setDisableButton] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const createUser = () => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "user/create/", {
            username: form.getFieldValue('username'),
            first_name: form.getFieldValue('firstname'),
            last_name: form.getFieldValue('lastname'),
            email: form.getFieldValue('email'),
            password: form.getFieldValue('password'),
            whatsapp_contact: form.getFieldValue('whatsapp'),
            default_site: defaultSiteId,
            allowed_sites: allowedSiteIds,
            user_group: userGroupId,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New user ${form.getFieldValue('username')} created.`);
            navigate(MENUPATH_USER);
        })
        .catch( error => {
            reportError(error, "Failed to create new user. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getAllowableSites = (defaultSiteId) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "site/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const data = response.data.results.filter(site => site.id != defaultSiteId);
            let options = data.map( site => <Option key={site.id}>{site.name}</Option>);
            setAllowedSitesOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to get site(s).");
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        })
    };

    //---------------------
    // On change
    //---------------------
    const onDefaultSiteChange = (e, value) => {
        setAllowedSitesOption([]);
        setAllowedSiteIds([]);
        form.setFieldsValue({
            allowedSites: [],
        });

        if(e != undefined) {
            setDefaultSiteId(e);
            getAllowableSites(e);
        }
        else
            setDefaultSiteId(0);
    };

    const onAllowedSitesChange = (e) => {
        if(e != undefined)
            setAllowedSiteIds(e);
        else
            setAllowedSiteIds([]);
    };

    const onUserGroupChange = (e, value) => {
        if(e != undefined)
            setUserGroupId(e);
        else
            setUserGroupId(0);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllowableSites(defaultSiteId);
    }, []);

    const onBack = () => {
        navigate(MENUPATH_USER);
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_USER) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        form.validateFields()
        .then( values => {
            if(values.password.trim().length < 8) {
                message.warn("Invalid password.");
                return
            };

            if(values.confirmPassword.trim().length < 8) {
                message.warn("Invalid confirm password.");
                return
            };

            if(values.password != values.confirmPassword) {
                message.warn("Password do not match.");
                return
            }
            else {
                confirm({
                    icon: <QuestionCircleTwoTone />,
                    content: <Space><p>Create user confirmed?</p></Space>,
                    onOk() { createUser() },
                    onCancel() {},
                    centered: true
                });
            };
        })
        .catch( error => {
            message.warn("Required field validation failed.");
            return
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New User" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New User</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout} autoComplete="off">
                    <Form.Item name="username" label="Username" rules={[{required: true, message: 'Username is required!'}]}>
                        <Input maxLength={150} placeholder="Username"/>
                    </Form.Item>

                    <Form.Item name="firstname" label="First Name" rules={[{required: true, message: 'First Name is required!'}]}>
                        <Input maxLength={50} placeholder="First Name"/>
                    </Form.Item>

                    <Form.Item name="lastname" label="Last Name" rules={[{required: true, message: 'Last Name is required!'}]}>
                        <Input maxLength={50} placeholder="Last Name"/>
                    </Form.Item>

                    <Form.Item name="email" label="Email" rules={[{required: true, type: "email", message: 'Email is required!'}]}>
                        <Input maxLength={254} placeholder="Email"/>
                    </Form.Item>

                    <Form.Item name="whatsapp" label="Whatsapp Contact">
                        <Input maxLength={100} placeholder="Whatsapp Contact"/>
                    </Form.Item>

                    <Form.Item name="password" label="Password" rules={[{required: true, message: 'Password is required!'}]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="New Password"/>
                    </Form.Item>

                    <Form.Item name="confirmPassword" label="Confirm Password" rules={[{required: true, message: 'Confirm Password is required!'}]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="Confirm New Password"/>
                    </Form.Item>

                    <SiteSelect withBlank={false} onChange={onDefaultSiteChange}/>

                    <Form.Item name="allowedSites" label="Other Allowed Site(s)">
                        <Select onChange={onAllowedSitesChange} allowClear showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} mode="multiple">
                            {allowedSitesOption}
                        </Select>
                    </Form.Item>

                    <UserGroupSelect withBlank={false} onChange={onUserGroupChange}/>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><PlusCircleTwoTone/>Create</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New User" subTitle="Create New User" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default UserCreateNew;