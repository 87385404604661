import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Column } from '@ant-design/plots';
import { Spin, Row, Col, Layout, Space, PageHeader, Descriptions, Table, Tag } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, MENUPATH_DASHBOARD, MENUPATH_DAILYTODOTRANSFERTOBATAS, UNIDATEFORMAT } from '../Common/SysParameters';
import AGISHeader from '../Common/AGISHeader';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { ClockCircleOutlined } from '@ant-design/icons';

const { Content, Footer } = Layout;

const DashboardChartsTransferToBatasBySpecies = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const item_item = location.state.record.item_item;
    const item_item_id = location.state.record.item_item_id;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [overdueBatch, setOverdueBatch] = useState([]);
    const [dailyToDoTransferToBatasData, setDailyToDoTransferToBatasData] = useState([]);
    const [dailyToDoTransferToBatasTableData, setDailyToDoTransferToBatasTableData] = useState([]);

    const SearchDailyToDoTransferToBatas = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "dailytodotransfertobatasbyitem/", {
            params: {
                site: getUserSiteId(),
                item_item: item_item_id,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectDailyToDoTransferToBatas(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectDailyToDoTransferToBatas = ( response ) => {
        const tableData = []
        const tempData = {}
        const data = []
        const batchOverdue = []
        response.data.results.forEach( sowingSummary => {
            let overdue = false;
            if (moment(sowingSummary.expected_transfer_date).startOf('day') < moment().startOf('day')) {
                batchOverdue.push(sowingSummary.batch.batch_code);
                overdue = true;
            };

            if (tempData[sowingSummary.batch.batch_code] == undefined) {
                tempData[sowingSummary.batch.batch_code] = {
                    key: sowingSummary.id,
                    id: sowingSummary.id,
                    batch: sowingSummary.batch.batch_code,
                    quantity_item: 0,
                    quantity_tool: 0,
                };
            };
            tempData[sowingSummary.batch.batch_code]["quantity_item"] += parseInt(sowingSummary.quantity_item);
            tempData[sowingSummary.batch.batch_code]["quantity_tool"] += parseFloat(sowingSummary.quantity_tool);

            tableData.push({
                key: sowingSummary.id,
                id: sowingSummary.id,
                batch: sowingSummary.batch.batch_code,
                item_item: sowingSummary.item_item.description,
                item_tool: sowingSummary.item_tool.description,
                area: sowingSummary.area.area_code,
                expected_transfer_date: moment(sowingSummary.expected_transfer_date),
                quantity_item: parseInt(sowingSummary.quantity_item),
                quantity_tool: parseFloat(sowingSummary.quantity_tool),
                overdue: overdue,
            });
        });

        for (const key in tempData) { data.push(tempData[key]) };
        setDailyToDoTransferToBatasData(data);
        setDailyToDoTransferToBatasTableData(tableData);
        setOverdueBatch(batchOverdue);
    };

    const onBack = () => {
        navigate(MENUPATH_DASHBOARD);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchDailyToDoTransferToBatas();
    }, []);

    const onClickTableRow = () => {
        return {
            onClick: () => {
                navigate(MENUPATH_DAILYTODOTRANSFERTOBATAS);
            }
        };
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Zone', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'Batch Code', dataIndex: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Plant Species', dataIndex: 'item_item', sorter: (a, b) => a.item_item.localeCompare(b.item_item) },
        { title: 'Quantity (Units)', dataIndex: 'quantity_item', sorter: (a, b) => a.quantity_item - b.quantity_item },
        { title: 'Tray Type', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Quantity (Trays)', dataIndex: 'quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: 'Expected Transfer Date', dataIndex: 'expected_transfer_date', sorter: (a, b) => a.expected_transfer_date - b.expected_transfer_date,
            render: (expected_transfer_date, record) => {
                if (record.overdue) {
                    return (<>{moment(expected_transfer_date).format(UNIDATEFORMAT)} <Tag icon={<ClockCircleOutlined />} color='error'>Overdue</Tag></>);
                }
                else return (<>{moment(expected_transfer_date).format(UNIDATEFORMAT)}</>);
            }
        },
    ];

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title={`Chart of Ready For Transfer To Batas (${item_item})`} onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">A basic column chart showing {item_item} is ready to transfer to batas</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Column
                        data={dailyToDoTransferToBatasData}
                        xField='batch'
                        yField='quantity_item'
                        legend={false}
                        appendPadding={20}
                        label={{
                            position: 'top',
                            layout: [{type: 'adjust-color'}],
                            content: (value) => {return `${value.quantity_item} - ${value.quantity_tool} Tray(s)`}
                        }}
                        pattern={(value) => {
                            if (overdueBatch.includes(value.batch)) return { type: 'line', cfg: {backgroundColor: 'red'} }
                        }}
                    />
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Table onRow={onClickTableRow} columns={columns} dataSource={dailyToDoTransferToBatasTableData} pagination={{position: ["bottomCenter"], total: dailyToDoTransferToBatasTableData.length, showTotal: showTotal}}/>
                </Col></Row>

            </Content>
            <Footer><Row justify="center"><PageHeader title={`Chart of Ready For Transfer To Batas (${item_item})`} subTitle={`A basic column chart showing ${item_item} is ready to transfer to batas`} onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default DashboardChartsTransferToBatasBySpecies;