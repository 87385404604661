//export const LOCAL_HOST = "10.1.4.169:8000"           // Localhost
export const IP = "agistestapi.pkmgroup.com"        // Staging
// export const IP = "agisapi.pkmgroup.com"        // Production
//export const AGISURL = `http://${LOCAL_HOST}/`      // Localhost
export const AGISURL = `https://${IP}/`             // Staging
export const AGISAPIURL = AGISURL + "agis/"

// Menu paths
export const LOGIN_PATH = "/"
export const MENUPATH_DASHBOARD = "/dashboard"
export const MENUPATH_DASHBOARDTRANSFERTOBATASBYSPECIES = "/dashboardtransfertobatasbyspecies"
export const MENUPATH_DASHBOARDSOWINGHISTORYBYSPECIES = "/dashboardsowinghistorybyspecies"
export const MENUPATH_DASHBOARDYIELDSUMMARYBYSPECIES = "/dashboardyieldsummarybyspecies"
export const MENUPATH_DASHBOARDHARVESTINGBYSPECIES = "/dashboardharvestingbyspecies"
export const MENUPATH_DASHBOARDWORKPROGRAMMEBYSPECIES = "/dashboardworkprogrammebyspecies"
export const MENUPATH_DASHBOARDBATASBYZONE = "/dashboardbatasbyzone"
export const MENUPATH_DASHBOARDPOWERBI = "/dashboardpowerbi"
export const MENUPATH_DRONEVIEWDASHBOARD = "/droneviewdashboard"
export const MENUPATH_CONSOLIDATEDCONSOLE = "/consolidatedconsole"
export const MENUPATH_SOWING = "/sowing"
export const MENUPATH_SOWINGCREATENEW = "/sowingcreatenew"
export const MENUPATH_SOWINGUPDATE = "/sowingupdate"
export const MENUPATH_TRANSFERTOBATAS = "/transfertobatas"
export const MENUPATH_TRANSFERTOBATASCREATENEWPAGE1 = "/transfertobatascreatenewpage1"
export const MENUPATH_TRANSFERTOBATASCREATENEWPAGE2 = "/transfertobatascreatenewpage2"
export const MENUPATH_TRANSFERTOBATASUPDATE = "/transfertobatasupdate"
export const MENUPATH_HARVESTING = "/harvesting"
export const MENUPATH_HARVESTINGCREATENEWPAGE1 = "/harvestingcreatenewpage1"
export const MENUPATH_HARVESTINGCREATENEWPAGE2 = "/harvestingcreatenewpage2"
export const MENUPATH_HARVESTINGUPDATE = "/harvestingupdate"
export const MENUPATH_BINRETURNS = "/binreturns"
export const MENUPATH_BINRETURNSCREATENEW = "/binreturnscreatenew"
export const MENUPATH_BINRETURNSUPDATE = "/binreturnsupdate"
export const MENUPATH_CROPFAILURE = "/cropfailure"
export const MENUPATH_CROPFAILURECREATENEW = "/cropfailurecreatenew"
export const MENUPATH_CROPFAILUREUPDATE = "/cropfailureupdate"
export const MENUPATH_FERTILIZATION = "/fertilization"
export const MENUPATH_FERTILIZATIONCREATENEW = "/fertilizationcreatenew"
export const MENUPATH_FERTILIZATIONUPDATE = "/fertilizationupdate"
export const MENUPATH_PESTCONTROL = "/pestcontrol"
export const MENUPATH_PESTCONTROLCREATENEW = "/pestcontrolcreatenew"
export const MENUPATH_PESTCONTROLUPDATE = "/pestcontrolupdate"
export const MENUPATH_SOILMAINTENANCE = "/soilmaintenance"
export const MENUPATH_SOILMAINTENANCECREATENEW = "/soilmaintenancecreatenew"
export const MENUPATH_SOILMAINTENANCEUPDATE = "/soilmaintenanceupdate"
export const MENUPATH_SOILMAINTENANCERELEASEMEDIUM = "/soilmaintenancereleasemedium"
export const MENUPATH_INVENTORYSUMMARY = "/inventorysummary"
export const MENUPATH_YIELDSUMMARY = "/yieldsummary"
export const MENUPATH_STOCKTRANSACTION = "/stocktransaction"
export const MENUPATH_DAILYTODOHARVEST = "/dailytodoharvest"
export const MENUPATH_DAILYTODOTRANSFERTOBATAS = "/dailytodotransfertobatas"
export const MENUPATH_DAILYTODOSOWING = "/dailytodosowing"
export const MENUPATH_ITEM = "/item"
export const MENUPATH_ITEMCREATENEW = "/itemcreatenew"
export const MENUPATH_ITEMUPDATE = "/itemupdate"
export const MENUPATH_BATCH = "/batch"
export const MENUPATH_BATCHCREATENEW = "/batchcreatenew"
export const MENUPATH_BATCHUPDATE = "/batchupdate"
export const MENUPATH_BATCHITEMSELECT = "/batchitemselect"
export const MENUPATH_WORKPROGRAMME = "/workprogramme"
export const MENUPATH_WORKPROGRAMMECREATENEW = "/workprogrammecreatenew"
export const MENUPATH_WORKPROGRAMMEUPDATE = "/workprogrammeupdate"
export const MENUPATH_WORKPLAN = "/workplan"
export const MENUPATH_WORKPLANCREATENEW = "/workplancreatenew"
export const MENUPATH_WORKPLANUPDATE = "/workplanupdate"
export const MENUPATH_WORKPROGRAMMETRANSFERTABLE = "/workprogrammetransfertable"
export const MENUPATH_ACTIVITY = "/activity"
export const MENUPATH_ACTIVITYCREATENEW = "/activitycreatenew"
export const MENUPATH_ACTIVITYUPDATE = "/activityupdate"
export const MENUPATH_ACTIVITYSUMMARYREPORT = "/activitysummaryreport"
export const MENUPATH_USER = "/user"
export const MENUPATH_USERCREATENEW = "/usercreatenew"
export const MENUPATH_USERCHANGEPASSWORD = "/userchangepassword"
export const MENUPATH_USERUPDATE = "/userupdate"
export const TEMPLATE_PATH = "/templatePage"

// Logo and Images
export const LOGO = "agis_logo_offset.png"
export const WALLPAPER = "kinarut_site.jpg"
export const WALLTILE = "vege.png"

// Status Type
export const STATUS_EMPTY = 1
export const STATUS_FULLY_OCCUPIED = 2
export const STATUS_BLOCKED = 3
export const STATUS_REST = 4
export const STATUS_RETIRED = 5
export const STATUS_OPEN = 6
export const STATUS_CLOSED = 7
export const STATUS_INPROGRESS = 9
export const STATUS_AVAILABLE = 10
export const STATUS_PARTIALLY_OCCUPIED = 12

// Task Category and Task Type
export const TASK_CATEGORY_PLANTING = 1
export const TASK_CATEGORY_HARVESTING = 2
export const TASK_CATEGORY_FERTILIZATION = 4
export const TASK_CATEGORY_PEST_CONTROL = 5
export const TASK_CATEGORY_SOIL_MAINTENANCE = 6
export const TASK_CATEGORY_RETURNING = 7
export const TASK_CATEGORY_CROP_LOSS = 8
export const TASK_TYPE_SOWING = 1
export const TASK_TYPE_TRANSFER_TO_BATAS = 2
export const TASK_TYPE_HARVEST = 3
export const TASK_TYPE_PEST_CONTROL = 5
export const TASK_TYPE_REVIVE = 9
export const TASK_TYPE_REHABILITATE = 10
export const TASK_TYPE_BIN_RETURNS = 11
export const TASK_TYPE_CROP_FAILURE = 12
export const TASK_TYPE_RELEASE_MEDIUM = 13

// Area Type
export const AREA_TYPE_NURSERY = 1
export const AREA_TYPE_LEAFY_VEGE = 2
export const AREA_TYPE_FRUITY_VEGE = 3
export const AREA_TYPE_BIN_STORE = 4
export const AREA_TYPE_GEN_STORE = 5
export const AREA_TYPE_ALL_LIST = [AREA_TYPE_NURSERY, AREA_TYPE_LEAFY_VEGE, AREA_TYPE_FRUITY_VEGE, AREA_TYPE_BIN_STORE, AREA_TYPE_GEN_STORE]

// Item Group
export const ITEM_GROUP_LEAFY_BATAS = 1
export const ITEM_GROUP_TRAY = 2
export const ITEM_GROUP_BIN = 3
export const ITEM_GROUP_LEAFY_PLANT = 4
export const ITEM_GROUP_FRUITY_BATAS = 5
export const ITEM_GROUP_FRUITY_PLANT = 6
export const ITEM_GROUP_FERTILIZER = 7
export const ITEM_GROUP_PEST_CONTROL = 8
export const ITEM_GROUP_WATER = 9

// Item Type
export const ITEM_TYPE_TRAY_TYPE_A = 1
export const ITEM_TYPE_TRAY_TYPE_B = 4
export const ITEM_TYPE_TRAY_TYPE_C = 5
export const ITEM_TYPE_BATAS_TYPE_A = 2
export const ITEM_TYPE_BIN_TYPE_A = 3

// Format and other settings
export const TIMEOUT = 5000
export const PAGESIZE = 20 // Match this with django default page size.
export const UNIDATEFORMAT = "YYYY-MM-DD"
export const UNIDATETIMEFORMAT = "YYYY-MM-DD HH:mm:ss"
export const DATEFORMAT = "DD-MM-YYYY"
export const LOADING = "Please wait..."
export const VALUE_NONE = "NONE"
export const VALUE_NOT_APPLICABLE = "NA"

// Unit Of Measurement
export const UNIT_OF_MEASUREMENT_KILOGRAM = 1
export const UNIT_OF_MEASUREMENT_KILOGRAM_SIGN = "KG"
export const UNIT_OF_MEASUREMENT_METER_SQUARE = 2
export const UNIT_OF_MEASUREMENT_METER_SQUARE_SIGN = "m2"
export const UNIT_OF_MEASUREMENT_CENTIMETER_SQUARE = 3
export const UNIT_OF_MEASUREMENT_CENTIMETER_SQUARE_SIGN = "cm2"
export const UNIT_OF_MEASUREMENT_L = 4
export const UNIT_OF_MEASUREMENT_L_SIGN = "L"
export const UNIT_OF_MEASUREMENT_UNIT = 5
export const UNIT_OF_MEASUREMENT_UNIT_SIGN = "Unit"
export const UNIT_OF_MEASUREMENT_PERCENTAGE = 8
export const UNIT_OF_MEASUREMENT_PERCENTAGE_SIGN = "%"

// Unit Of Time
export const UNIT_OF_TIME_DAY = 2
export const UNIT_OF_TIME_DAY_SIGN = "Day"

// Lifecycle
export const LIFECYCLE_GERMINATION = 1
export const LIFECYCLE_SEEDLING = 2
export const LIFECYCLE_VEGETATIVE = 3
export const LIFECYCLE_MATURITY = 4
export const LIFECYCLE_DEGRADATION = 5
export const LIFECYCLE_DEPLETION = 6

// Work Phase
export const WORK_PHASE_NURSING = 1
export const WORK_PHASE_PLANTING = 2
export const WORK_PHASE_HARVESTING = 3
export const WORK_PHASE_PARTIAL_SOIL_RESTORATION = 4
export const WORK_PHASE_FULL_SOIL_RESTORATION = 5

// Crop Failure Reason
export const REASON_PEST_AND_DISEASE = 1
export const REASON_NUTRIENT_DEFICIENCY = 2
export const REASON_SOIL_DEGRADATION = 3
export const REASON_FLOOD = 4
export const REASON_DROUGHT = 5
export const REASON_EXTREME_TEMP = 6
export const REASON_OTHER = 7

// Restrict and Warning constants
export const RESTRICT_FLAG = false  // Restrict user to proceed if true
export const WARNING_FLAG = true  // Show warning if true
export const STATUS_TYPE_WARNING_LIST = [STATUS_BLOCKED, STATUS_REST, STATUS_RETIRED, STATUS_PARTIALLY_OCCUPIED]

// 2023-05-22
// Other constants
export const APPJSON = "application/json"