import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Select, message, Modal, Table, InputNumber, Radio, Typography, Statistic, Input, DatePicker, Spin } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, refreshUserSession, getUserAuthToken } from '../Common/UserSession';
import { formLayoutWithTable } from '../Common/Layout';
import { AGISAPIURL, MENUPATH_HARVESTINGCREATENEWPAGE1, AREA_TYPE_BIN_STORE, UNIT_OF_MEASUREMENT_UNIT, UNIT_OF_MEASUREMENT_KILOGRAM_SIGN, ITEM_GROUP_BIN, STATUS_AVAILABLE,
    STATUS_INPROGRESS, STATUS_CLOSED, UNIT_OF_MEASUREMENT_KILOGRAM, TASK_TYPE_HARVEST, LIFECYCLE_MATURITY, WORK_PHASE_HARVESTING, UNIDATETIMEFORMAT, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { SaveOutlined, CloseCircleFilled, QuestionCircleTwoTone, ExclamationCircleTwoTone, MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';


const { Option } = Select;
const { confirm } = Modal;
const { Title } = Typography;
const { Content, Footer } = Layout;
const { TextArea } = Input;

const HarvestingCreateNewPage2 = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const prevRecord = location.state.record;
    const batch = prevRecord.batch;
    const item_item = prevRecord.item_item;
    const item_location_from_list = prevRecord.item_location_from_list;
    const actual_completion_date = prevRecord.actualExecutedDate;
    const quantity_item_from_uom = prevRecord.quantity_item_from_uom;
    const default_area_to_list = prevRecord.area_to ? prevRecord.area_to : [];
    const default_item_location_to_list = prevRecord.item_location_to_list ? prevRecord.item_location_to_list : [];
    const default_quantity_item_to_secondary_uom = prevRecord.quantity_item_to_secondary_uom ? prevRecord.quantity_item_to_secondary_uom : UNIT_OF_MEASUREMENT_KILOGRAM;
    const default_quantity_item_to_secondary_uom_sign = prevRecord.quantity_item_to_secondary_uom_sign ? prevRecord.quantity_item_to_secondary_uom_sign : UNIT_OF_MEASUREMENT_KILOGRAM_SIGN;
    const default_total_weight = prevRecord.total_weight ? prevRecord.total_weight : 0;

    const [form] = Form.useForm();
    const [closeBatchForm] = Form.useForm();
    const navigate = useNavigate();
    const [areaOption, setAreaOption] = useState("");
    const [itemLocationToOption, setItemLocationToOption] = useState("");
    // Obsolete this due to we are not going to keep track the quantity of the harvest bin
    // const [itemLocationToTableData, setItemLocationToTableData] = useState([]);
    const [unitOfMeasurementOption, setUnitOfMeasurementOption] = useState("");
    const [task_type, setTaskType] = useState(TASK_TYPE_HARVEST);
    const [areaList, setAreaList] = useState(default_area_to_list);
    const [area_type_list, setAreaTypeList] = useState([AREA_TYPE_BIN_STORE]);
    const [disableButton, setDisableButton] = useState("");
    const [total_weight, setTotalWeight] = useState(default_total_weight);
    const [quantity_item_to_secondary_uom, setQuantityItemToSecondaryUom] = useState(default_quantity_item_to_secondary_uom);
    const [quantity_item_to_secondary_uom_sign, setQuantityItemToSecondaryUomSign] = useState(default_quantity_item_to_secondary_uom_sign);
    const [isLoading, setIsLoading] = useState(false);

    const createActivity = () => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "activity/create/harvesting/", {
            site: getUserSiteId(),
            batch: batch,
            task_type: task_type,
            item_location_to_list: form.getFieldValue('item_location_to_list'),
            item_location_from_list: item_location_from_list,
            actual_executed_datetime: actual_completion_date,
            lifecycle: LIFECYCLE_MATURITY,
            work_phase: WORK_PHASE_HARVESTING,
            quantity_tool_uom: UNIT_OF_MEASUREMENT_UNIT,
            quantity_item_from_uom: quantity_item_from_uom,
            quantity_item_to_uom: UNIT_OF_MEASUREMENT_UNIT,
            quantity_item_to_secondary_uom: quantity_item_to_secondary_uom,
            status_type_batch: STATUS_INPROGRESS,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New activity ${response.data[0].id} created.`);
            getBatch();
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getBatch = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "batch/" + batch, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            if (response.data.is_harvested) suggestCloseBatch()
            else navigate(OTHERSYSPARAM("lastPage"));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const closeBatch = (values) => {
        setIsLoading(true);

        axios.patch(AGISAPIURL + "batch/update/" + batch, {
            status_type: STATUS_CLOSED,
            is_active: false,
            actual_completion_date: values.actualCompletionDate.format(UNIDATETIMEFORMAT),
            remark: values.remark,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Batch ${response.data.batch_code} has been closed.`);
            navigate(OTHERSYSPARAM("lastPage"));
        })
        .catch( error => {
            reportError(error, "Failed to close batch. " + error.message);
            navigate(OTHERSYSPARAM("lastPage"));
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    let promiseGetArea = function () {
        return new Promise(function(myResolve, myReject) {
            setIsLoading(true);

            axios.get(AGISAPIURL + "area/", {
                params: {
                    site: getUserSiteId(),
                    area_type_list: area_type_list,
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`},
            })
            .then( response => {
                let options = response.data.results.map( area => {
                        let zoneName = "[" + area.area_type.description + "] " + area.area_code;
                        return <Option key={area.id} value={area.id}>{zoneName}</Option>;
                    });
                myResolve(options);
            })
            .catch( error => {
                myReject(error);
            })
            .finally(() => {
                setIsLoading(false);
                refreshUserSession();
            });
        });
    };

    const getItemLocationTo = ({areaList = []}) => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "item/", {
            params: {
                area_list: areaList,
                is_inventory_repository: 1,
                status_type: STATUS_AVAILABLE,
                item_in_repository: item_item,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemLocationToRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectItemLocationToRawData = ( response ) => {
        const data = []
        response.data.results.forEach( item => {
            data.push({
                key: item.id,
                id: item.id,
                item_code: item.item_code,
                description: item.description,
                item_area: item.area.area_code,
                quantity_tool: parseInt(item.available_quantity),
            });
        });
        setItemLocationToOption(response.data.results.map( item => <Option key={item.id} value={item.id}>{item.description}</Option>));
        // Obsolete this due to we are not going to keep track the quantity of the harvest bin
        // setItemLocationToTableData(data);
    };

    const getUnitOfMeasurement = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemgroupuom/", {
            params: {
                item_group_list: [ITEM_GROUP_BIN],
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            let options = [];
            response.data.results.map( itemgroupuom => {
                if (itemgroupuom.unit_of_measurement.id === UNIT_OF_MEASUREMENT_KILOGRAM) {
                    options = options.concat(<Radio.Button key={itemgroupuom.unit_of_measurement.id} value={itemgroupuom.unit_of_measurement.id} label={itemgroupuom.unit_of_measurement.unit}>{itemgroupuom.unit_of_measurement.unit}</Radio.Button>);
                };
            });
            setUnitOfMeasurementOption(options);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    let promiseGetItemLocationTo = function (areaList) {
        return new Promise(function(myResolve, myReject) {
            setIsLoading(true);

            axios.get(AGISAPIURL + "item/", {
                params: {
                    area_list: areaList,
                    is_inventory_repository: 1,
                    status_type: STATUS_AVAILABLE,
                    item_in_repository: item_item,
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`},
            })
            .then( response => {
                const data = []
                response.data.results.forEach( item => {
                    data.push({
                        key: item.id,
                        id: item.id,
                        item_code: item.item_code,
                        description: item.description,
                        item_area: item.area.area_code,
                        quantity_tool: parseInt(item.available_quantity),
                    });
                });
                setItemLocationToOption(response.data.results.map( item => <Option key={item.id} value={item.id}>{item.description}</Option>));
                myResolve(data);
            })
            .catch( error => {
                myReject(error);
            })
            .finally(() => {
                setIsLoading(false);
                refreshUserSession();
            });
        })
    };

    const onChangeTotalWeightHarvested = () => {
        let totalWeight = 0;
        form.getFieldValue('item_location_to_list').forEach(item => totalWeight = totalWeight + item.quantity_item_secondary);
        setTotalWeight(totalWeight);
    };

    const onChangeArea = (value) => {
        setAreaList(value);
        onChangeItemLocationTo([]);
        getItemLocationTo({ areaList: value });
    };

    const onChangeQuantityItemSecondaryUom = (e) => {
        setQuantityItemToSecondaryUom(e.target.value);
        setQuantityItemToSecondaryUomSign(e.target.label);
        onChangeItemLocationTo([]);
        getItemLocationTo({ areaList: areaList });
    };

    const onChangeItemLocationTo = (list) => {
        form.setFieldsValue({
            item_location_to_list: list,
        });
    };

    // Obsolete this due to we are not going to keep track the quantity of the harvest bin
    // const validateHarvestMedium = (names) => {
    //     let count = {}
    //     let errorIndex = -1
    //     names.every(item => {
    //         if (item !== undefined) {
    //             let index = itemLocationToTableData.findIndex((location) => item.id === location.id);
    //             if (count[item.id] !== undefined) count[item.id] += 1;
    //             else count[item.id] = 1;

    //             if (count[item.id] > itemLocationToTableData[index].quantity_tool) {
    //                 errorIndex=index;
    //                 return false;
    //             }
    //             else return true;
    //         };
    //     });
    //     if (errorIndex !== -1) return Promise.reject(new Error("Exceeded (" + itemLocationToTableData[errorIndex].quantity_tool + ") the maximum available quantity of " + itemLocationToTableData[errorIndex].description));
    // };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        promiseGetArea().then(
            function(value) {setAreaOption(value)},
            function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
        );
        getUnitOfMeasurement();

        if (location.state.record.area_to !== null) {
            promiseGetItemLocationTo(default_area_to_list).then(
                function(value) {
                    form.setFieldsValue({ item_location_to_list: default_item_location_to_list });
                    // Obsolete this due to we are not going to keep track the quantity of the harvest bin
                    // setItemLocationToTableData(value);
                },
                function(error) {reportError(error, "Failed to retrieve data. " + error.message)},
            )
        }

    }, []);

    const onAreaClear = () => {
        onChangeItemLocationTo([]);
        getItemLocationTo({});
    };

    const onBack = () => {
        prevRecord.area_to = form.getFieldValue('area_to');
        prevRecord.item_location_to_list = form.getFieldValue('item_location_to_list');
        prevRecord.quantity_item_to_secondary_uom = quantity_item_to_secondary_uom;
        prevRecord.quantity_item_to_secondary_uom_sign = quantity_item_to_secondary_uom_sign;
        prevRecord.total_weight = total_weight;
        navigate(MENUPATH_HARVESTINGCREATENEWPAGE1, {state: {record: prevRecord}});
    };

    const onCancel = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to exit without saving it?</p></Space>,
            onOk() { navigate(OTHERSYSPARAM("lastPage")) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create Harvesting activity confirmed?</p></Space>,
            onOk() {createActivity()},
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // Suggest User to close Batch
    //---------------------------
    const suggestCloseBatch = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: (
                <>
                    <Space><p>This Batch has been fully harvested. Do you want to proceed with batch closure?</p></Space>
                    <Space><p><b>Note:</b> If you are not closing the batch right now, you can also go to Batch Setup page to close it anytime.</p></Space>
                    <Form form={closeBatchForm} onFinish={values => closeBatch(values)} autoComplete="off">
                        <Form.Item initialValue={moment()} name="actualCompletionDate" label="Actual Completion Date" rules={[{required: true, message: 'Please input the Actual Completion Date for this Batch before closure!'}]}>
                            <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment()}} disabledDate={disabledDateAfterToday}/>
                        </Form.Item>

                        <Form.Item name="remark" label="Remark">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Form>
                </>
                ),
            onOk() {
                closeBatchForm.validateFields()
                .then(() => {
                    closeBatchForm.submit();
                })
                .catch( error => {
                    reportError(error, "Failed to update batch. " + closeBatchForm.getFieldError('actualCompletionDate'));
                    navigate(OTHERSYSPARAM("lastPage"));
                });
            },
            onCancel() { navigate(OTHERSYSPARAM("lastPage")) },
            centered: true,
            width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"),
        });
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    // Obsolete this due to we are not going to keep track the quantity of the harvest bin
    // const showTotal = (total) => {
    //     return (
    //         `Total ${total} item(s)`
    //     );
    // };

    // const columns = [
    //     { title: 'Zone', dataIndex: 'item_area', sorter: (a, b) => a.item_area.localeCompare(b.item_area) },
    //     { title: 'Medium(s)', dataIndex: 'item_code', sorter: (a, b) => a.item_code.localeCompare(b.item_code) },
    //     { title: 'Available Quantity', dataIndex: 'quantity_tool', align: 'center', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
    // ]

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Harvesting Activity" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Harvesting Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="end"><Col span={22}/><Col span={2} style={{textAlign: "center"}}><Statistic valueStyle={{ color: 'green' }} title="Total Harvested" value={total_weight.toFixed(3)} suffix={`${quantity_item_to_secondary_uom_sign}`} /></Col><Col span={2}/></Row>
                <Form form={form} onFinish={values => onSave(values)} {...formLayoutWithTable} autoComplete="off">
                    <Form.Item initialValue={default_area_to_list} name="area_to" label="Zone (Harvest To)">
                        <Select onChange={(value) => onChangeArea(value)} allowClear={true} mode='multiple' onClear={onAreaClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                            {areaOption}
                        </Select>
                    </Form.Item>

                    {/* Obsolete this due to we are not going to keep track the quantity of the harvest bin */}
                    {/* <Row justify="start"><Col offset={6}>
                        <Table title={() => <Title level={5}>Medium's Quantity Availability</Title>} columns={columns} dataSource={itemLocationToTableData} pagination={{ size: 'small',  position: ["bottomCenter"], total: itemLocationToTableData.length, showTotal: showTotal }}/>
                    </Col></Row>
                    <Row><Col><Space><br/></Space></Col></Row> */}

                    <Form.Item initialValue={default_quantity_item_to_secondary_uom} name="quantity_item_to_secondary_uom" label="Unit Of Measurement">
                        <Radio.Group onChange={onChangeQuantityItemSecondaryUom} buttonStyle="solid">
                            {unitOfMeasurementOption}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name="item_location_to_list" label="Harvest To" rules={[{required: true, message: 'Please select at least one Medium to Harvest!'}]} tooltip={{ title: "Please select a Transfer Zone first", icon: <ExclamationCircleTwoTone/>}}>
                        <Form.List name="item_location_to_list" /*rules={[{validator: async (_, names) => validateHarvestMedium(names)}]}*/>
                            {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field) => (
                                    <Space key={field.key} align="baseline" size={19}>
                                        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area_to !== curValues.area_to || prevValues.mediums !== curValues.mediums}>
                                            {() => (
                                                <Form.Item {...field} label="Medium" name={[field.name, 'id']} rules={[{required: true, message: 'Missing medium'}]}>
                                                    <Select disabled={!form.getFieldValue('area_to')} style={{ width: 200 }}>
                                                        {itemLocationToOption}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </Form.Item>

                                        <Form.Item {...field} label="Weight" name={[field.name, 'quantity_item_secondary']} rules={[{required: true, message: 'Missing weight'}]}>
                                            <InputNumber min={0.001} addonAfter={quantity_item_to_secondary_uom_sign} onChange={onChangeTotalWeightHarvested}/>
                                        </Form.Item>

                                        <MinusCircleOutlined onClick={() => {
                                            remove(field.name);
                                            onChangeTotalWeightHarvested();
                                        }} />
                                    </Space>
                                ))}

                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>Add Medium</Button>
                                </Form.Item>
                                <Form.ErrorList errors={errors} />
                            </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><SaveOutlined/>Save</Button>
                            <Button htmlType="button" disabled={disableButton} type="primary" onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Harvesting Activity" subTitle="Create New Harvesting Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default HarvestingCreateNewPage2;