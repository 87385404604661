import React from 'react';
import { Table, Typography } from 'antd';
import { UNIT_OF_MEASUREMENT_PERCENTAGE_SIGN } from '../Common/SysParameters';


const { Text } = Typography;

const SuggestLocationTable = ({dataSource}) => {

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Location', dataIndex: 'item_tool', sorter: (a, b) => a.item_tool.localeCompare(b.item_tool) },
        { title: 'Area', dataIndex: 'area', sorter: (a, b) => a.area.localeCompare(b.area) },
        { title: 'Status', dataIndex: 'status_type', sorter: (a, b) => a.status_type.localeCompare(b.status_type) },
        { title: 'Unit Amount', dataIndex: 'quantity', sorter: (a, b) => a.quantity - b.quantity },
        { title: 'Occupied Percentage', dataIndex: 'occupied_perc', sorter: (a, b) => a.occupied_perc - b.occupied_perc, render: (occupied_perc) => {
            return (<>{occupied_perc}{UNIT_OF_MEASUREMENT_PERCENTAGE_SIGN}</>)
        }},
        { title: 'Assigned Worker', dataIndex: 'worker', sorter: (a, b) => a.worker.localeCompare(b.worker) },
        { title: 'Note', dataIndex: 'is_pending_harvest', render: (is_pending_harvest) => {
            if (is_pending_harvest) return <Text type='warning'>Please harvest the batas first</Text>
            else return <></>
        }},
    ];

    return (
        <Table columns={columns} dataSource={dataSource} pagination={{size: "small", position: ["bottomCenter"], total: dataSource.length, showTotal: showTotal}}/>
    );
};

export default SuggestLocationTable;