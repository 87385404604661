import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Bar } from '@ant-design/plots';
import { Spin, Row, Col, Typography, Statistic, Card, Space } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, MENUPATH_DAILYTODOSOWING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import axios from 'axios';

const { Title } = Typography;

const DashboardChartsWhatToSow = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [dailyToDoSowingData, setDailyToDoSowingData] = useState([]);
    const [totalUnits, setTotalUnits] = useState(0);

    const SearchDailyToDoSowing = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "dailytodosowing/", {
            params: {
                site: getUserSiteId(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectDailyToDoSowingData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectDailyToDoSowingData = ( response ) => {
        const data = []
        let totalCnt = 0
        response.data.forEach( workProg => {
            data.push({
                key: workProg.id,
                id: workProg.id,
                item: workProg.item.item.description,
                quantity_item: parseInt(workProg.quantity_item),
            });
            totalCnt += parseInt(workProg.quantity_item);
        });
        setDailyToDoSowingData(data);
        setTotalUnits(totalCnt);
    };

    const onClickChart = (plot) => {
        plot.on('plot:click', () => {
            navigate(MENUPATH_DAILYTODOSOWING);
        });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchDailyToDoSowing();
    }, []);

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Row justify='center'>
                <Col span={24} style={{textAlign: "center"}}>
                    <Title level={5}>What To Sow Today</Title>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Total Units To Sow</Title>}
                            value={totalUnits}
                            valueStyle={{ color: 'green' }}
                        />
                    </Card>
                </Col>

                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title={<Title level={4}>Number Of Species</Title>}
                            value={dailyToDoSowingData.length}
                            valueStyle={{ color: 'blue' }}
                        />
                    </Card>
                </Col>
            </Row>

            <Row><Col><Space><br/></Space></Col></Row>

            <Row justify='center'>
                <Col span={24}>
                    <Bar
                        data={dailyToDoSowingData}
                        xField='quantity_item'
                        yField='item'
                        seriesField='item'
                        legend={false}
                        onReady={onClickChart}
                        label={{
                            position: 'middle',
                            layout: [{type: 'adjust-color'}],
                        }}
                    />
                </Col>
            </Row>
        </Spin>
    );
};

export default DashboardChartsWhatToSow;