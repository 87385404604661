import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Modal, DatePicker, Table, Input, InputNumber, message, Statistic, Spin } from 'antd';
import AGISHeader from '../Common/AGISHeader';
import { OTHERSYSPARAM, getUserSiteId, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import { formLayoutWithTable } from '../Common/Layout';
import { AGISAPIURL, UNIDATETIMEFORMAT, UNIT_OF_MEASUREMENT_UNIT, MENUPATH_BINRETURNS, LIFECYCLE_MATURITY, WORK_PHASE_HARVESTING, TASK_TYPE_BIN_RETURNS, ITEM_GROUP_BIN, LOADING } from '../Common/SysParameters';
import { reportError } from "../Common/Utility";
import moment from 'moment';
import axios from 'axios';
import { SaveOutlined, InfoCircleTwoTone, QuestionCircleTwoTone, ExclamationCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
const EditableContext = React.createContext(null);


const { confirm } = Modal;
const { Content, Footer } = Layout;

const BinReturnsCreateNew = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [itemLocationFromTableData, setItemLocationFromTableData] = useState([]);
    const [task_type, setTaskType] = useState(TASK_TYPE_BIN_RETURNS);
    const [disableButton, setDisableButton] = useState("");
    const [total_bin, setTotalBin] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);  // The selectRowKeys name shall not be change as this is required for Antd to control over the selected rows
    const [isLoading, setIsLoading] = useState(false);

    const createActivity = (actual_completion_date) => {
        setDisableButton("disabled");
        setIsLoading(true);

        axios.post(AGISAPIURL + "activity/create/binreturns/", {
            site: getUserSiteId(),
            task_type: task_type,
            item_location_from_list: form.getFieldValue('item_location_from_list'),
            actual_executed_datetime: actual_completion_date.format(UNIDATETIMEFORMAT),
            lifecycle: LIFECYCLE_MATURITY,
            work_phase: WORK_PHASE_HARVESTING,
            quantity_tool_uom: UNIT_OF_MEASUREMENT_UNIT,
            quantity_item_uom: UNIT_OF_MEASUREMENT_UNIT,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.map(activity => message.info(`New activity ${activity[0].id} created.`));
            navigate(MENUPATH_BINRETURNS);
        })
        .catch( error => {
            reportError(error, "Failed to create activity. " + error.message)
        })
        .finally(() => {
            setDisableButton("");
            setIsLoading(false);
            refreshUserSession();
        })
    };

    const getItemLocationFrom = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "itemgroupinventorysummary/", {
            params: {
                item_group: ITEM_GROUP_BIN,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectItemLocationFromRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectItemLocationFromRawData = ( response ) => {
        const data = []
        response.data.results.forEach( itemGroupInventoryTransactionSummary => {
            data.push({
                key: itemGroupInventoryTransactionSummary.id,
                id: itemGroupInventoryTransactionSummary.id,
                batch: itemGroupInventoryTransactionSummary.batch.id,
                batch_code: itemGroupInventoryTransactionSummary.batch.batch_code,
                item_tool: itemGroupInventoryTransactionSummary.item_tool.id,
                item_code: itemGroupInventoryTransactionSummary.item_tool.item_code,
                description: itemGroupInventoryTransactionSummary.item_tool.description,
                item_area: itemGroupInventoryTransactionSummary.area.area_code,
                available_quantity_tool: itemGroupInventoryTransactionSummary.quantity_tool,
                quantity_tool: itemGroupInventoryTransactionSummary.quantity_tool,
                quantity_item: itemGroupInventoryTransactionSummary.quantity_item,
            });
        });
        setItemLocationFromTableData(data);
    };

    const onChangeItemLocationFrom = (newSelectedRowKeys) => {
        let list = []
        let totalBin = 0
        newSelectedRowKeys.forEach( rowKey => {
            const indexLoc = itemLocationFromTableData.findIndex((location) => rowKey === location.id);
            list.push({
                id: rowKey,
                batch: itemLocationFromTableData[indexLoc]['batch'],
                item_tool: itemLocationFromTableData[indexLoc]['item_tool'],
                quantity_tool: itemLocationFromTableData[indexLoc]['quantity_tool'],
                quantity_item: itemLocationFromTableData[indexLoc]['quantity_item'],
                available_quantity_tool: itemLocationFromTableData[indexLoc]['available_quantity_tool'],
            });
            totalBin += parseFloat(itemLocationFromTableData[indexLoc]['quantity_tool']);
        });

        form.setFieldsValue({
            item_location_from_list: list,
        });

        setTotalBin(totalBin);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeItemLocationFrom,
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getItemLocationFrom();
    }, []);

    const onMoreDetails = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <Space><p>Your change is not saved. Do you still want to continue without saving it?</p></Space>,
            onOk() { navigate(MENUPATH_BINRETURNS) },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (values) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Create bin returns activity confirmed?</p></Space>,
            onOk() { createActivity(values.actualExecutedDate) },
            onCancel() {},
            centered: true
        });
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const onChangeTable = () => {
        // To restore the item_location_from_list as changing the table pagination/sorting/filter will reset the fields in the form
        onChangeItemLocationFrom(selectedRowKeys);
    };

    const EditableRow = ({ index, ...props }) => {
        const [form1] = Form.useForm();
        return (
            <Form form={form1} component={false}>
                <EditableContext.Provider value={form1}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ editable, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = <></>
        let errorMessage = ""
        const form1 = useContext(EditableContext);

        const save = async () => {
            try {
                let tempKeyList = selectedRowKeys.slice(0);
                if (tempKeyList.indexOf(record.key) === -1) {
                    tempKeyList.push(record.key);
                    onChangeItemLocationFrom(tempKeyList);
                };

                const row = await form1.validateFields();
                const newData = [...itemLocationFromTableData];
                const newDataIndex = newData.findIndex((item) => record.key === item.key);

                if (newDataIndex > -1) {
                    const item = newData[newDataIndex];
                    newData.splice(newDataIndex, 1, { ...item, ...row });
                    setItemLocationFromTableData(newData);
                } else {
                    newData.push(row);
                    setItemLocationFromTableData(newData);
                }

                // Update form data with location list
                const newLocationList = form.getFieldValue('item_location_from_list');
                const indexLoc = newLocationList.findIndex((location) => record.key === location.id);
                newLocationList[indexLoc] = { id: record.key, batch: newData[newDataIndex]['batch'], item_tool: newData[newDataIndex]['item_tool'], quantity_tool: newData[newDataIndex]['quantity_tool'], quantity_item: newData[newDataIndex]['quantity_item'], available_quantity_tool: newData[newDataIndex]['available_quantity_tool'] }

                // Update total number of harvested bin has been returned
                let totalBin = 0
                form.getFieldValue('item_location_from_list').forEach(item => totalBin += parseFloat(item.quantity_tool));
                setTotalBin(totalBin);

            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
        };

        switch(title) {
            case "Returned Quantity":
                inputNode = <InputNumber onPressEnter={save} onBlur={save} min={1} max={record.available_quantity_tool}/>;
                inputType = "integer";
                errorMessage = "with integer only";
                break;

            default:
                inputNode = inputType === 'number' ? <InputNumber onPressEnter={save} onBlur={save}/> : <Input onPressEnter={save} onBlur={save}/>;
        };

        let childNode = children;
        if (editable) {
            childNode = (
                <Form.Item initialValue={record.quantity_tool} name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `Please Input ${title} ${errorMessage}!`, type: inputType}]}>
                    {inputNode}
                </Form.Item>
            );
        }
        return <td {...restProps}>{childNode}</td>;
    };

    const defaultColumns = [
        { title: 'Batch', dataIndex: 'batch_code', sorter: (a, b) => a.batch_code.localeCompare(b.batch_code) },
        { title: 'Zone', dataIndex: 'item_area', sorter: (a, b) => a.item_area.localeCompare(b.item_area) },
        { title: 'Medium Type(s)', dataIndex: 'item_code', sorter: (a, b) => a.item_code.localeCompare(b.item_code) },
        { title: 'Quantity', dataIndex: 'available_quantity_tool', sorter: (a, b) => a.quantity_tool - b.quantity_tool },
        { title: 'Returned Quantity', dataIndex: 'quantity_tool', editable: 1, sorter: (a, b) => a.quantity_tool - b.quantity_tool },
    ]

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="New Bin Returns Activity">
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Create New Bin Returns Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="end"><Col span={20}/><Col span={4} style={{textAlign: "center"}}><Statistic valueStyle={{ color: 'green' }} title="Total Bin Returned" value={total_bin} /></Col></Row>
                <Form form={form} onFinish={values => onSave(values)} {...formLayoutWithTable} autoComplete="off">
                    <Form.Item initialValue={moment()} name="actualExecutedDate" label="Actual Bin Returns Date" rules={[{required: true, message: 'Please input the Actual Bin Returns Date!'}]}>
                        <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment()}} disabledDate={disabledDateAfterToday}/>
                    </Form.Item>

                    <Form.Item name="item_location_from_list" label="Harvested Bin" rules={[{required: true, message: 'Please select at least one Harvest Bin!'}]} tooltip={{ title: "Please select a Zone first", icon: <ExclamationCircleTwoTone/>}}>
                        <Table components={{ body: {row: EditableRow, cell: EditableCell} }} rowClassName={() => 'editable-row'} rowSelection={rowSelection} columns={columns} dataSource={itemLocationFromTableData} onChange={onChangeTable} pagination={{ size: 'small',  position: ["bottomCenter"], total: itemLocationFromTableData.length, showTotal: showTotal }}/>
                    </Form.Item>

                    <Row><Col><Space><br/></Space></Col></Row>
                    <Row justify="center">
                        <Col span={6}/>
                        <Col span={12} offset={3}>
                            <Button htmlType="submit" disabled={disableButton} type="primary"><SaveOutlined/>Save</Button>
                            <Button htmlType="button" disabled={disableButton} onClick={onMoreDetails}><InfoCircleTwoTone />More Details</Button>
                        </Col>
                        <Col span={6}/>
                    </Row>
                </Form>
            </Content>
            <Footer><Row justify="center"><PageHeader title="New Bin Returns Activity" subTitle="Create New Bin Returns Activity"></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default BinReturnsCreateNew;