import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Button, Row, Col, Drawer, message } from 'antd';
import { clearUserSession, ValidateUserSession, getUsername, getUserGroups, OTHERSYSPARAM } from './UserSession';
import AGISSiteSelect from './AGISSiteSelect';
import { LOGIN_PATH, MENUPATH_ACTIVITY, MENUPATH_BATCH, MENUPATH_CONSOLIDATEDCONSOLE, MENUPATH_DASHBOARD, MENUPATH_DRONEVIEWDASHBOARD, MENUPATH_FERTILIZATION, MENUPATH_CROPFAILURE,
    MENUPATH_HARVESTING, MENUPATH_BINRETURNSCREATENEW, MENUPATH_INVENTORYSUMMARY, MENUPATH_YIELDSUMMARY, MENUPATH_ITEM, MENUPATH_SOILMAINTENANCE, MENUPATH_SOWING, MENUPATH_STOCKTRANSACTION,
    MENUPATH_DAILYTODOHARVEST, MENUPATH_DAILYTODOTRANSFERTOBATAS, MENUPATH_DAILYTODOSOWING, MENUPATH_TRANSFERTOBATAS, MENUPATH_ACTIVITYSUMMARYREPORT, MENUPATH_PESTCONTROL, MENUPATH_WORKPROGRAMME,
    MENUPATH_WORKPLAN, MENUPATH_USERCHANGEPASSWORD, MENUPATH_USER, MENUPATH_DASHBOARDPOWERBI } from './SysParameters';
import {
    MenuUnfoldOutlined,
    // LineChartOutlined,
    PieChartOutlined,
    BarChartOutlined,
    CloudOutlined,
    DesktopOutlined,
    // DatabaseOutlined,
    ProfileOutlined,
    FundOutlined,
    SettingOutlined,
    LogoutOutlined,
    UserOutlined,
  } from '@ant-design/icons';


const { Header } = Layout;

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const AGISHeader = () => {
    ValidateUserSession();

    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);

    const onClick = (info) => {
        setVisible(false);

        switch(info.key) {
            case "1":
                navigate(MENUPATH_DASHBOARD);
                break;
            case "10":
                navigate(MENUPATH_DASHBOARDPOWERBI);
                break;
            case "2":
                navigate(MENUPATH_DRONEVIEWDASHBOARD);
                break;
            case "3":
                navigate(MENUPATH_CONSOLIDATEDCONSOLE);
                break;
            case "4Sub1":
                navigate(MENUPATH_SOWING);
                break;
            case "4Sub2":
                navigate(MENUPATH_TRANSFERTOBATAS);
                break;
            case "4Sub3":
                navigate(MENUPATH_HARVESTING);
                break;
            case "4Sub4":
                navigate(MENUPATH_BINRETURNSCREATENEW);
                break;
            case "4Sub5":
                navigate(MENUPATH_FERTILIZATION);
                break;
            case "4Sub6":
                navigate(MENUPATH_PESTCONTROL);
                break;
            case "4Sub7":
                navigate(MENUPATH_CROPFAILURE);
                break;
            case "4Sub8":
                navigate(MENUPATH_SOILMAINTENANCE);
                break;
            case "6Sub1":
                navigate(MENUPATH_INVENTORYSUMMARY);
                break;
            case "6Sub2":
                navigate(MENUPATH_YIELDSUMMARY);
                break;
            case "6Sub3":
                navigate(MENUPATH_STOCKTRANSACTION);
                break;
            case "6Sub4":
                navigate(MENUPATH_DAILYTODOHARVEST);
                break;
            case "6Sub5":
                navigate(MENUPATH_DAILYTODOTRANSFERTOBATAS);
                break;
            case "6Sub6":
                navigate(MENUPATH_DAILYTODOSOWING);
                break;
            case "6Sub7":
                navigate(MENUPATH_ACTIVITYSUMMARYREPORT);
                break;
            case "8Sub1":
                navigate(MENUPATH_ITEM);
                break;
            case "8Sub2":
                navigate(MENUPATH_BATCH);
                break;
            case "8Sub3":
                navigate(MENUPATH_WORKPROGRAMME);
                break;
            case "8Sub4":
                navigate(MENUPATH_WORKPLAN);
                break;
            case "8Sub5":
                navigate(MENUPATH_ACTIVITY);
                break;
            case "9Sub1":
                navigate(MENUPATH_USERCHANGEPASSWORD);
                break;
            case "9Sub2":
                navigate(MENUPATH_USER);
                break;

            default:
                message.error("Page not found.");
        };

    };

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const handleLogout = () => {
        clearUserSession();
        navigate(LOGIN_PATH);
    };

    return (
        <Header style={{ position: 'fixed', zIndex: 2, width: '100%' }}>
            <Row justify="space-evenly" align="middle" style={{textAlign: "center"}}>
                <Col flex={2}>
                    <Button type="primary" onClick={showDrawer}><MenuUnfoldOutlined /></Button>
                </Col>
                <Col flex={20}>
                    <AGISSiteSelect />
                </Col>
                <Col flex={2}>
                    <Button onClick={handleLogout} type="primary" danger><LogoutOutlined /></Button>
                </Col>
            </Row>

            <Drawer title={`Hi! ${getUsername()}`} placement="left" onClose={onClose} visible={visible} width="30vw">
                <Menu
                    theme="light"
                    mode="inline"
                    // defaultSelectedKeys={['sub1']}
                    onClick={onClick}
                    items={[
                        getItem('Dashboard', '1', <PieChartOutlined />),
                        getItem('Power BI Dashboard', '10', <BarChartOutlined />),
                        getItem('Drone View Dashboard', '2', <CloudOutlined />),
                        getItem('Consolidated Console', '3', <FundOutlined />),
                        getItem('Agriculture Process', '4', <DesktopOutlined />, [
                            getItem('Sowing', '4Sub1'),
                            getItem('Transfer To Batas', '4Sub2'),
                            getItem('Harvesting', '4Sub3'),
                            getItem('Bin Returns', '4Sub4'),
                            getItem('Fertilization', '4Sub5'),
                            getItem('Pest Control', '4Sub6'),
                            getItem('Crop Failure', '4Sub7'),
                            getItem('Soil Maintenance', '4Sub8'),
                        ]),
                        // getItem('Inventory', '5', <DatabaseOutlined />, [
                        //     getItem('Inventory A', '5Sub1'),
                        //     getItem('Inventory B', '5Sub2'),
                        //     getItem('Inventory C', '5Sub3')]),
                        getItem('Report & Listing', '6', <ProfileOutlined />, [
                            getItem('Inventory Summary', '6Sub1'),
                            getItem('Yield Summary Report', '6Sub2'),
                            getItem('Stock Transaction', '6Sub3'),
                            getItem('Daily To-Do Harvesting Report', '6Sub4'),
                            getItem('Daily To-Do Transfer To Batas Report', '6Sub5'),
                            getItem('Daily To-Do Sowing Report', '6Sub6'),
                            // getItem('Activity Summary', '6Sub7'),
                        ]),
                        // getItem('Analytics', '7', <LineChartOutlined />, [
                        //     getItem('Batch Genealogy', '7Sub1')]),
                        getItem('Setup', '8', <SettingOutlined />, [
                            getItem('Item', '8Sub1'),
                            getItem('Batch', '8Sub2'),

                            // Only SuperAdmin and Admin have access to Work Programme and Work Plan pages
                            (getUserGroups().includes(OTHERSYSPARAM("SUPERADMIN")) || getUserGroups().includes(OTHERSYSPARAM("ADMIN"))) ? getItem('Work Programme', '8Sub3') : null,
                            (getUserGroups().includes(OTHERSYSPARAM("SUPERADMIN")) || getUserGroups().includes(OTHERSYSPARAM("ADMIN"))) ? getItem('Work Plan', '8Sub4') : null,

                            // getItem('Activity', '8Sub5'),
                        ]),

                        getItem('Account', '9', <UserOutlined />, [
                            getItem('Change Password', '9Sub1'),

                            // Only SuperAdmin has access to User Page
                            getUserGroups().includes(OTHERSYSPARAM("SUPERADMIN")) ? getItem('User', '9Sub2') : null,
                        ]),
                    ]}/>
                </Drawer>
        </Header>
    );
};

export default AGISHeader;
