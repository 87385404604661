import React from 'react';
import { Table, Typography, Tag, Modal, Tooltip } from 'antd';
import { OTHERSYSPARAM } from '../Common/UserSession';
import { MENUPATH_DASHBOARD, UNIT_OF_MEASUREMENT_KILOGRAM_SIGN, VALUE_NONE } from '../Common/SysParameters';
import { InfoCircleTwoTone, QuestionCircleTwoTone } from '@ant-design/icons';
import BatchDistributionTable from '../Batch/BatchDistributionTable';
import BatchActivitiesSummaryTable from '../Batch/BatchActivitiesSummaryTable';
import ButtonToBatchUpdatePage from '../Common/ButtonToBatchUpdatePage';
import YieldItemTable from '../Report/YieldItemTable';


const { Text } = Typography;

const DashboardChartsYieldSummaryBatchTable = ({tableData, navigate}) => {

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columnTitleHarvestDate = (title) => {
        return (<Text>{title} <Tooltip placement='bottomLeft' title="The First Actual Harvesting Date"><QuestionCircleTwoTone /></Tooltip></Text>);
    };

    const columnTitleCompleted = (title) => {
        return (
            <>{title} <InfoCircleTwoTone onClick={(e) => showColumnDescriptionCompleted(e, title)} /></>
        );
    };

    const showColumnDescriptionCompleted = (e, title) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Definition of the Column Title - {title}</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <>
                <p>For <b>Planting Batch</b>: If Yes, it indicates all plant in this batch has been fully <b>harvested</b>.</p>
                <p>For <b>Soil Maintenance Batch</b>: If Yes, it indicates all planting medium in this batch has been fully <b>released</b>.</p>
            </>
        });
    };

    const onClickAllSowed = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Batch: {record.batch_code} ({record.item})</h3>{record.work_programme !== VALUE_NONE ? <h3>Work Programme: {record.work_programme}</h3> : <></>}</div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <YieldItemTable dataSource={record.all_sowed}/>
        })
    };

    //--------------------------
    // Show Batch Plant Distribution
    //--------------------------
    const showBatchPlantDistributionPopup = (e, record) => {
        const modal = Modal.info({
            title: <div><h3><b>BATCH OVERVIEW</b> - Batch ID: {record.batch_code}<ButtonToBatchUpdatePage batchId={record.batch_id} buttonName="Go to Batch Update Page" navigate={navigate} lastPage={MENUPATH_DASHBOARD}/></h3></div>,
            icon: null,
            width: '70%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <><BatchDistributionTable batchId={record.batch_id} /><BatchActivitiesSummaryTable batchId={record.batch_id} /></>
        });
    };

    const columns = [
        { title: 'Batch Code', dataIndex: 'batch_code', sorter: (a, b) => a.batch_code.localeCompare(b.batch_code), render: (batch_code, record) => {
            if (record.is_forecast) return <Text><Tag bordered={true} color='cyan'>Forecast</Tag> {batch_code}</Text>
            else return <Text>{batch_code} <InfoCircleTwoTone onClick={(e) => showBatchPlantDistributionPopup(e, record)} /></Text>
        }},
        { title: 'Planned Harvest Date', dataIndex: 'expected_completion_date', sorter: (a, b) => a.expected_completion_date.localeCompare(b.expected_completion_date) },
        { title: columnTitleHarvestDate('Actual Harvest Date'), dataIndex: 'actual_harvest_date', sorter: (a, b) => a.actual_harvest_date.localeCompare(b.actual_harvest_date) },
        { title: 'Item', dataIndex: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Work Programme', dataIndex: 'work_programme', sorter: (a, b) => a.work_programme.localeCompare(b.work_programme) },
        { title: 'Active', dataIndex: 'is_active', sorter: (a, b) => a.is_active - b.is_active, render: (is_active) => {
            if (is_active) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
        { title: 'Life Cycle', dataIndex: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: columnTitleCompleted('Completed'), dataIndex: 'is_harvested', sorter: (a, b) => a.is_harvested - b.is_harvested, render: (is_harvested) => {
            if (is_harvested) return <Tag color="success">{OTHERSYSPARAM("YES")}</Tag>
            else return <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>
        }},
        { title: 'Expected Yield', dataIndex: 'all_sowed', render: (all_sowed, record) => {
            if (record.is_forecast) return <Text>{record.total_yield} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN} <Tooltip title={`Based on ${record.quantity} ${record.quantity_uom} using average yield.`}><InfoCircleTwoTone twoToneColor="#FFCC00" /></Tooltip></Text>
            else return <Text>{record.total_yield} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN} <InfoCircleTwoTone twoToneColor="#FFCC00" onClick={(e) => onClickAllSowed(e, record)} /></Text>
        }},
        { title: 'Total Harvested', dataIndex: 'total_harvested_kg', sorter: (a, b) => a.total_harvested_kg - b.total_harvested_kg, render: (total_harvested_kg, record) => {
            if (record.total_yield > total_harvested_kg) return <Text type="danger">{total_harvested_kg} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}</Text>
            else return <Text type="success">{total_harvested_kg} {UNIT_OF_MEASUREMENT_KILOGRAM_SIGN}</Text>
        }},
    ];

    return <Table columns={columns} dataSource={tableData} pagination={{position: ["bottomCenter"], total: tableData.length, showTotal: showTotal}}/>;
};

export default DashboardChartsYieldSummaryBatchTable;