import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, PageHeader, Descriptions, Space, Form, Button, Typography, Card, DatePicker, Modal, message, Collapse, Divider, Spin } from 'antd';
import { AGISAPIURL, LIFECYCLE_SEEDLING, MENUPATH_TRANSFERTOBATAS, UNIDATETIMEFORMAT, UNIT_OF_MEASUREMENT_UNIT, LOADING, VALUE_NONE } from '../Common/SysParameters';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession } from '../Common/UserSession';
import AGISHeader from '../Common/AGISHeader';
import TransferToBatasDistributionTable from './TransferToBatasDistributionTable';
import axios from 'axios';
import moment from 'moment';
import { reportError } from "../Common/Utility";
import { CloseCircleFilled, QuestionCircleTwoTone, SaveOutlined, DeleteFilled } from '@ant-design/icons';


const { Content, Footer } = Layout;
const { confirm } = Modal;
const { Title, Text } = Typography;
const { Panel } = Collapse;

const TransferToBatasUpdate = () => {

    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");
    const paddingCardGrid = OTHERSYSPARAM("CARD_GRID_PADDING");

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [subActivityTableData, setSubActivityTableData] = useState([]);
    const [activityId, setActivityId] = useState(location.state.record.id);
    const [timestamp, setTimestamp] = useState(location.state.record.timestamp);
    const [task_category, setTaskCategory] = useState(location.state.record.task_category);
    const [task_type, setTaskType] = useState(location.state.record.task_type);
    const [item_item, setItemItem] = useState(location.state.record.item_item);
    const [batch, setBatch] = useState(location.state.record.batch);
    const [lifecycle, setLifecycle] = useState(location.state.record.lifecycle);
    const [work_phase, setWorkPhase] = useState(location.state.record.work_phase);
    const [actualExecutedDate, setActualExecutedDate] = useState(location.state.record.actual_executed_datetime);
    const [updatedOn, setUpdatedOn] = useState(location.state.record.updated_on);
    const [disableCancelButton, setDisableCancelButton] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState("");
    const [disableDeleteButton, setDisableDeleteButton] = useState((location.state.record.batch_isActive && location.state.record.batch_lifecycle === LIFECYCLE_SEEDLING) ? "" : "disable");
    const [isLoading, setIsLoading] = useState(false);

    const updateActivity = (actual_executed_datetime) => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setIsLoading(true);

        axios.patch(AGISAPIURL + "activity/update/" + location.state.record.id, {
            actual_executed_datetime: actual_executed_datetime.format(UNIDATETIMEFORMAT),
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            message.info(`Activity ID ${response.data.id} updated.`);
            navigate(MENUPATH_TRANSFERTOBATAS);
        })
        .catch( error => {
            reportError(error, "Failed to update data. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const deleteActivity = () => {
        // Disable buttons.
        setDisableCancelButton("disabled");
        setDisableSubmitButton("disabled");
        setIsLoading(true);

        axios.delete(AGISAPIURL + "activity/delete/" + location.state.record.id, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then(() => {
            message.info(`Activity ID ${location.state.record.id} deleted!`);
            navigate(MENUPATH_TRANSFERTOBATAS);
        })
        .catch( error => {
            reportError(error, "Failed to delete activity. " + error.message);
        })
        .finally(() => {
            setDisableCancelButton("");
            setDisableSubmitButton("");
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const SearchSubActivity = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "subactivity/", {
            params: {
                activity: activityId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectSubActivityRawData(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectSubActivityRawData = ( response ) => {
        const data = []
        response.data.results.forEach( subactivity => {
            let itemLocationFromCode = VALUE_NONE;
            let quantity_tool = VALUE_NONE;
            let quantity_item = VALUE_NONE;
            let quantity_item_uom = VALUE_NONE;
            let quantity_item_perc = VALUE_NONE;
            let quantity_item_uom_perc = VALUE_NONE;
            let maxUnit = 0;
            if (subactivity.item_location_from) itemLocationFromCode = subactivity.item_location_from.description;
            if (subactivity.inventory_transaction.length) {
                quantity_tool = subactivity.inventory_transaction[0].quantity_tool;
                quantity_item = subactivity.inventory_transaction[0].quantity_item;
                quantity_item_uom = subactivity.inventory_transaction[0].quantity_item_uom.unit;
                quantity_item_perc = subactivity.inventory_transaction[0].quantity_item_perc;
                quantity_item_uom_perc = subactivity.inventory_transaction[0].quantity_item_uom_perc.unit;

                if (subactivity.inventory_transaction[0].item_repository_profile) {
                    if (subactivity.inventory_transaction[0].item_repository_profile.primary_uom.id === UNIT_OF_MEASUREMENT_UNIT) {
                        maxUnit = parseInt(subactivity.inventory_transaction[0].item_repository_profile.primary_quantity * Math.ceil(quantity_tool));
                    }
                    else if (subactivity.inventory_transaction[0].item_repository_profile.secondary_uom.id === UNIT_OF_MEASUREMENT_UNIT) {
                        maxUnit = parseInt(subactivity.inventory_transaction[0].item_repository_profile.secondary_quantity * Math.ceil(quantity_tool));
                    }
                }
            };

            data.push({
                key: subactivity.id,
                id: subactivity.id,
                timestamp: moment(subactivity.timestamp).format(UNIDATETIMEFORMAT),
                activity: subactivity.activity,
                area: subactivity.area.area_code,
                item_location_from: itemLocationFromCode,
                item_location_to: subactivity.item_location_to.description,
                quantity_tool: quantity_tool,
                quantity_item: quantity_item,
                quantity_item_uom: quantity_item_uom,
                quantity_item_perc: quantity_item_perc,
                quantity_item_uom_perc: quantity_item_uom_perc,
                maxUnit: maxUnit,
            })
        });
        setSubActivityTableData(data);
    };

    const disabledDateAfterToday = (current) => {
        return current.valueOf() > moment();
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        SearchSubActivity();
    }, []);

    const onBack = () => {
        navigate(MENUPATH_TRANSFERTOBATAS);
    };

    const onCancel = () => {
        navigate(MENUPATH_TRANSFERTOBATAS);
    };

    //---------------------------
    // On delete
    //---------------------------
    const onDelete = () => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Delete activity confirmed?</p></Space>,
            onOk() { deleteActivity() },
            onCancel() {},
            centered: true
        });
    };

    //---------------------------
    // On save
    //---------------------------
    const onSave = (value) => {
        confirm({
            icon: <QuestionCircleTwoTone />,
            content: <Space><p>Edit activity confirmed?</p></Space>,
            onOk() { updateActivity(value.actualExecutedDate) },
            onCancel() {},
            centered: true
        });
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title="Transfer To Batas Activity Update" onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">Edit Transfer To Batas Activity</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center"><Col span={12}>
                    <Collapse defaultActiveKey={["1"]}>
                        <Panel header={<Title level={5}>{`Activity Summary: ${task_type} (${item_item}), Batch Code: ${batch}`}</Title>} key="1">
                            <Card title={<Text italic>{`Timestamp: ${timestamp}`}</Text>}>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Actual Transfer To Batas Date:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{actualExecutedDate}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Task:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{task_type}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Task Category:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{task_category}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Batch Code:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{batch}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Plant Species:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{item_item}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Life Cycle:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{lifecycle}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Work Phase:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{work_phase}</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '40%', textAlign: 'left', padding: paddingCardGrid}}>Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} style={{width: '60%', textAlign: 'left', padding: paddingCardGrid, fontWeight: 'bold'}}>{updatedOn}</Card.Grid>
                            </Card>

                            <Row><Col><Space><br/></Space></Col></Row>
                            <TransferToBatasDistributionTable dataSource={subActivityTableData} />
                        </Panel>
                    </Collapse>
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row justify="center"><Col span={12}>
                    <Divider orientation="left">Edit Transfer To Batas Activity Fields</Divider>
                    <Form form={form} onFinish={onSave} autoComplete="off">
                        <Form.Item initialValue={moment(actualExecutedDate)} name="actualExecutedDate" label="Actual Transfer To Batas Date" rules={[{required: true, message: 'Please input the Actual Transfer To Batas Date for this activity!'}]}>
                            <DatePicker format={UNIDATETIMEFORMAT} showTime={{defaultValue: moment(actualExecutedDate)}} disabledDate={disabledDateAfterToday}/>
                        </Form.Item>

                        <Row><Col><Space><br/></Space></Col></Row>
                        <Row justify="center">
                            <Col span={12} offset={3}>
                                <Button htmlType="button" hidden={disableDeleteButton} disabled={disableDeleteButton} type="primary" onClick={onDelete} danger><DeleteFilled/>Delete</Button>
                                <Button htmlType="submit" hidden={disableSubmitButton} disabled={disableSubmitButton} type="primary"><SaveOutlined/>Save</Button>
                                <Button htmlType="button" disabled={disableCancelButton} onClick={onCancel} danger><CloseCircleFilled/>Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col></Row>
            </Content>
            <Footer><Row justify="center"><PageHeader title="Transfer To Batas Activity Update" subTitle="Edit Transfer To Batas Activity" onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default TransferToBatasUpdate;