import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { DualAxes } from '@ant-design/plots';
import { Spin, Row, Col, Layout, Space, PageHeader, Descriptions, Table, Form, Select, DatePicker, Radio, Button } from 'antd';
import { OTHERSYSPARAM, getUserAuthToken, refreshUserSession, getUserSiteId } from '../Common/UserSession';
import { AGISAPIURL, LOADING, MENUPATH_DASHBOARD, UNIDATEFORMAT } from '../Common/SysParameters';
import AGISHeader from '../Common/AGISHeader';
import { reportError } from "../Common/Utility";
import moment from "moment";
import axios from 'axios';

const { Content, Footer } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const DashboardChartsSowingHistoryBySpecies = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT");

    const location = useLocation();
    const item = location.state.record.item;
    const item_id = location.state.record.item_id;

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [yAxisLimit, setYAxisLimit] = useState(0);
    const [sowingHistoryData, setSowingHistoryData] = useState([]);
    const [movingAverageData, setMovingAverageData] = useState([]);
    const [sowingHistoryTableData, setMovingAverageTableData] = useState([]);
    const [lastNDays, setLastNDays] = useState(location.state.record.lastNDays);
    const [lastNDaysOption, setLastNDaysOption] = useState("");
    const [showLastNDays, setShowLastNDays] = useState(false);
    const [showDateRange, setShowDateRange] = useState(true);
    const [dateRange, setDateRange] = useState([moment(location.state.record.dateRange[0], UNIDATEFORMAT), moment(location.state.record.dateRange[1], UNIDATEFORMAT)]);
    const [customDateRange, setCustomDateRange] = useState([moment(location.state.record.dateRange[0], UNIDATEFORMAT), moment(location.state.record.dateRange[1], UNIDATEFORMAT)]);

    const SearchSowingHistory = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "sowingbacklogs/", {
            params: {
                site: getUserSiteId(),
                item: item_id,
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        })
        .then( response => {
            collectSowingHistory(response);
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    const collectSowingHistory = ( response ) => {
        const data = []
        const tableData = []
        const averageData = []
        let total_planned = 0
        let total_actual = 0
        let count = 1
        let yLimitMax = 0
        response.data.results.forEach( sowingHistory => {
            total_planned = total_planned + parseInt(sowingHistory.expected_quantity_item);
            total_actual = total_actual + parseInt(sowingHistory.actual_quantity_item);
            if (parseInt(sowingHistory.expected_quantity_item) > yLimitMax) yLimitMax = parseInt(sowingHistory.expected_quantity_item);
            if (parseInt(sowingHistory.actual_quantity_item) > yLimitMax) yLimitMax = parseInt(sowingHistory.actual_quantity_item);
            data.push({
                name: "Planned Unit",
                quantity_item: parseInt(sowingHistory.expected_quantity_item),
                schedule_date: moment(sowingHistory.schedule_date).format(UNIDATEFORMAT),
            });

            data.push({
                name: "Actual Unit",
                quantity_item: parseInt(sowingHistory.actual_quantity_item),
                schedule_date: moment(sowingHistory.schedule_date).format(UNIDATEFORMAT),
            });

            averageData.push({
                name: "Planned Moving Average",
                schedule_date: moment(sowingHistory.schedule_date).format(UNIDATEFORMAT),
                average: parseFloat(total_planned / count),
            });

            averageData.push({
                name: "Actual Moving Average",
                schedule_date: moment(sowingHistory.schedule_date).format(UNIDATEFORMAT),
                average: parseFloat(total_actual / count),
            });

            tableData.push({
                key: sowingHistory.id,
                id: sowingHistory.id,
                schedule_date: moment(sowingHistory.schedule_date).format(UNIDATEFORMAT),
                actual_quantity_item: parseInt(sowingHistory.actual_quantity_item),
                expected_quantity_item: parseInt(sowingHistory.expected_quantity_item),
                actual_average: parseFloat(total_actual / count).toFixed(2),
                expected_average: parseFloat(total_planned / count),
            });

            count = count + 1;
        });
        setYAxisLimit(yLimitMax);
        setSowingHistoryData(data);
        setMovingAverageData(averageData);
        setMovingAverageTableData(tableData);
    };

    const setLastNDaysOptions = () => {
        let options = [];
        for (let i = 0; i <= 90; i++) {options = options.concat(<Option key={i} value={i}>{i}</Option>)};
        setLastNDaysOption(options);
    };

    const onBack = () => {
        navigate(MENUPATH_DASHBOARD);
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        setLastNDaysOptions();
        SearchSowingHistory();
    }, []);

    const onChangePeriod = (e) => {
        switch(e.target.value) {
            case "1":
                setShowLastNDays(true);
                setShowDateRange(false);
                setDateRange([moment(moment().subtract(lastNDays, "days"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
                break;

            case "2":
                setShowLastNDays(false);
                setShowDateRange(true);
                setDateRange(customDateRange);
                break
        };
    };

    const onChangeLastNDays = (value) => {
        setLastNDays(value);
        setDateRange([moment(moment().subtract(value, "days"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)]);
    };

    const onChangeDateRange = (value) => {
        setCustomDateRange(value);
        setDateRange(value);
    };

    const onApply = () => {
        SearchSowingHistory();
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Date', dataIndex: 'schedule_date', sorter: (a, b) => a.schedule_date.localeCompare(b.schedule_date) },
        { title: 'Planned Unit', dataIndex: 'expected_quantity_item', sorter: (a, b) => a.expected_quantity_item - b.expected_quantity_item },
        { title: 'Actual Unit', dataIndex: 'actual_quantity_item', sorter: (a, b) => a.actual_quantity_item - b.actual_quantity_item },
        { title: 'Planned Moving Average', dataIndex: 'expected_average', sorter: (a, b) => a.expected_average - b.expected_average },
        { title: 'Actual Moving Average', dataIndex: 'actual_average', sorter: (a, b) => a.actual_average - b.actual_average },
    ];

    const config = {
        data: [sowingHistoryData, movingAverageData],
        xField: 'schedule_date',
        yField: ['quantity_item', 'average'],
        legend: { position: 'top' },
        yAxis: {
            quantity_item: { min: 0, max: yAxisLimit },
            average: { min: 0, max: yAxisLimit },
        },
        geometryOptions: [
            {
                geometry: 'column',
                isGroup: true,
                seriesField: 'name',
                marginRatio: 0,
                label: {
                    position: 'middle',
                    layout: [{type: 'adjust-color'}],
                },
            },
            {
                geometry: 'line',
                isGroup: true,
                seriesField: 'name',
                lineStyle: ({ name }) => {
                    if (name === 'Planned Moving Average') return { lineDash: [5, 5], opacity: 0.7 };
                    return { opacity: 1 };
                  },
            },
        ],
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <AGISHeader />
            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <PageHeader title={`Sowing History Chart - ${item}`} onBack={onBack}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">A Dual Axes chart with grouped columns and lines showing the {item} sowing history</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>
                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Form form={form}>
                        <Row justify='space-between'>
                            <Col span={4}>
                                <Radio.Group defaultValue="2" buttonStyle='solid' onChange={onChangePeriod}>
                                    <Radio.Button value="1">Last N days</Radio.Button>
                                    <Radio.Button value="2">Custom Date</Radio.Button>
                                </Radio.Group>
                            </Col>

                            <Col span={12}>
                                <Form.Item initialValue={lastNDays} name="lastNdays" label="Last Number of Days (0 - 90)" hidden={!showLastNDays}>
                                    <Select onChange={onChangeLastNDays} showSearch optionFilterProp="children">
                                        {lastNDaysOption}
                                    </Select>
                                </Form.Item>

                                <Form.Item initialValue={dateRange} name="daterange" label="Date Range" hidden={!showDateRange}>
                                    <RangePicker onChange={onChangeDateRange} allowClear={false} format={UNIDATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                                </Form.Item>
                            </Col>

                            <Col span={1}>
                                <Button onClick={onApply} type="primary">Apply</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col></Row>

                <Row justify='center'><Col span={22}>
                    <DualAxes {...config} />
                </Col></Row>

                <Row><Col><Space><br/></Space></Col></Row>

                <Row justify='center'><Col span={22}>
                    <Table columns={columns} dataSource={sowingHistoryTableData} pagination={{position: ["bottomCenter"], total: sowingHistoryTableData.length, showTotal: showTotal}}/>
                </Col></Row>

            </Content>
            <Footer><Row justify="center"><PageHeader title={`Sowing History Chart - ${item}`} subTitle={`A Dual Axes chart with grouped columns and lines showing the ${item} sowing history`} onBack={onBack}></PageHeader></Row></Footer>
        </Layout>
        </Spin>
    );
};

export default DashboardChartsSowingHistoryBySpecies;