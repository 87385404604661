import React,  { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { AGISAPIURL, MENUPATH_DASHBOARD, LOADING } from "./SysParameters";
import { getUserSiteName, setUserSite, getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./UserSession";
import { reportError } from "./Utility";
import { Select } from 'antd';
import axios from "axios";

const { Option } = Select;

const AGISSiteSelect = () => {
    const navigate = useNavigate();
    const [option, setOption] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getSite = () => {
        setIsLoading(true);

        axios.get(AGISAPIURL + "site/", {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`},
        },
        )
        .then( response => {
            setOption(response.data.results.map( item => <Option key={item.id} value={item.id}>{item.name}</Option>));
        })
        .catch( error => {
            reportError(error, "Failed to retrieve data. " + error.message)
        })
        .finally(() => {
            setIsLoading(false);
            refreshUserSession();
        });
    };

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getSite();
    }, []);

    const onChange = (siteId) => {
        const whichSite = option.filter( site => site.props.value === siteId )
        setUserSite(siteId, whichSite[0].props.children)
        navigate(MENUPATH_DASHBOARD);
        window.location.reload();
    };

    return (
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Select
            showSearch
            placeholder="Select a site"
            optionFilterProp="site"
            defaultValue={getUserSiteName()}
            onChange={onChange}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            style={{width: '33vw'}}>

            {option}
        </Select>
        </Spin>
    );
};

export default AGISSiteSelect;
