import React from 'react';
import { Table, Tag, Button, Badge, Avatar, Modal } from 'antd';
import { MENUPATH_WORKPLANUPDATE, VALUE_NONE } from '../Common/SysParameters';
import { OTHERSYSPARAM } from '../Common/UserSession';
import { CheckOutlined, ScheduleOutlined } from '@ant-design/icons';
import DateRangeListTable from "../WorkPlan/DateRangeListTable";


const WorkPlanListTable = ({dataSource, navigate}) => {

    const directToWorkPlanUpdatePage = (record) => {
        navigate(MENUPATH_WORKPLANUPDATE, {state: {record: record}});
    };

    const onClickDateRangeList = (e, record) => {
        e.stopPropagation();

        const modal = Modal.info({
            title: <div><h3>Effective Date Range ({record.description})</h3></div>,
            icon: null,
            width: '50%',
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <DateRangeListTable dataSource={record.date_range_list}/>
        })
    };

    const showTotal = (total) => {
        return (
            `Total ${total} item(s)`
        );
    };

    const columns = [
        { title: 'Work Plan Code', dataIndex: 'work_plan_code', sorter: (a, b) => a.work_plan_code.localeCompare(b.work_plan_code), render: (work_plan_code, record) => {
            if (record.is_in_effect) return (<Button type="link" onClick={() => directToWorkPlanUpdatePage(record)}>{work_plan_code} <Tag icon={<CheckOutlined />} color="success">In Effect Now</Tag></Button>)
            else return (<Button type="link" onClick={() => directToWorkPlanUpdatePage(record)}>{work_plan_code}</Button>);
        }},
        { title: 'Description', dataIndex: 'description', sorter: (a, b) => a.description.localeCompare(b.description) },
        { title: 'Status', dataIndex: 'is_active', render: (is_active) => {return (is_active ? <Tag color="success">Active</Tag> : <Tag color="default">Inactive</Tag>)} },
        { title: 'Date Range', dataIndex: 'date_range_list',
            render: (date_range_list, record) => {
                if (date_range_list.length) { return (
                    <Badge count={date_range_list.length} onClick={(e) => onClickDateRangeList(e, record)}>
                        <Avatar shape="square" size='large' style={{background: '#1890ff', cursor: 'pointer', borderRadius: '6px'}} icon={<ScheduleOutlined />} />
                    </Badge>);
                }
                else return (<>{VALUE_NONE}</>);
            }
        },
        { title: 'In Effect', dataIndex: 'is_in_effect', render: (is_in_effect) => {return (is_in_effect ? <Tag color="success">{OTHERSYSPARAM("YES")}</Tag> : <Tag color="error">{OTHERSYSPARAM("NO")}</Tag>)} },
    ];

    return (
        <Table /*onRow={(record) => ({style: {background: record.is_in_effect ? '#B2FFFF' : 'default'}})}*/ columns={columns} dataSource={dataSource} pagination={{size: "small", position: ["bottomCenter"], total: dataSource.length, showTotal: showTotal}}/>
    );
};

export default WorkPlanListTable;